import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import {
  Layout, Row, Col, DatePicker, Tabs
} from 'antd';
import 'antd/dist/antd.css';
import {
  LabelList, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, Label
} from 'recharts';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import moment from 'moment';
import * as Service from '../../core/Service';
import AppLayout from '../../components/AppLayout';
import TokenPurchaseData from '../../components/dashboard/TokenPurchaseData';
import IncomeByCatPieChart from '../../components/dashboard/IncomeByCatPieChart';
import RoomBookingCountRankChart from '../../components/dashboard/RoomBookingCountRankChart';
import IncomeByItemLineChart from '../../components/dashboard/IncomeByItemLineChart';
import TrendDataRoomChart from '../../components/dashboard/TrendDataRoomChart';
import AccessLogChart from '../../components/dashboard/AccessLogChart';
import ExportToPDFButton from "../../components/ExportToPDFButton";

const { Content } = Layout;
const { TabPane } = Tabs;

// const data = [
//   {
//     name: '10/7', door1: 4000, door2: 2400,
//   },
//   {
//     name: '11/7', door1: 3000, door2: 1398,
//   },
//   {
//     name: '12/7', door1: 2000, door2: 9800,
//   },
//   {
//     name: '13/7', door1: 2780, door2: 3908,
//   },
//   {
//     name: '14/7', door1: 1890, door2: 4800,
//   },
//   {
//     name: '15/7', door1: 2390, door2: 3800,
//   },
//   {
//     name: '16/7', door1: 3490, door2: 4300,
//   },
// ];

const styles = {
  chartCol: {
    height: "400px",
    width: "100%",
    textAlign: "center",
    // border: 'solid 1px #ccc',
    padding: '30px 40px 60px 10px',
    borderRadius: '5px',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
    marginBottom: '20px',
  },
  tick: {
    // width: "20px",
    height: "100px",
  },
  col: {
    width: "100%",
    minHeight: "638px",
    textAlign: "center",
    padding: '3%',
    borderRadius: '5px',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
    marginBottom: '20px',
  }
}

const Dashboard = () => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [accessLogData, setAccessLogData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [tokenData, setTokenData] = useState([]);
  const [IncomeData, setIncomeData] = useState([]);
  const [IncomeByItemStartDate, setIncomeByItemStartDate] = useState(moment().subtract(1, 'month').unix());
  const [IncomeByItemEndDate, setIncomeByItemEndDate] = useState(moment().unix());
  const hideDashboard = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_dashboard_r')
  );

  const colorTemplate = [
    '#058108',
    '#B99800',
    '#035950',
    '#339EFF',
    '#7333FF',
    '#EE33FF',
    '#717171'

  ]
  useEffect(() => {
    // getAccessLogData();// TODO: What is this? Still useful?
    getBookingData();
    getTokenData();
  }, []);

  useEffect(() => {
    if (!_.isNull(IncomeByItemStartDate) && !_.isNull(IncomeByItemEndDate)) {
      getIncomeDataByItem(IncomeByItemStartDate, IncomeByItemEndDate);
    }
  }, [IncomeByItemStartDate, IncomeByItemEndDate]);

  // TODO: What is this? Still useful?
  const getAccessLogData = async () => {
    let selectedData = [];
    try {
      let url = '/api/admin/company/user/access/log';
      let { access_log } = await Service.call('get', url);
      for (let i = 7; i > 0; i--) {
        let date = _.toString(moment().subtract(i, 'days').format('MM/DD'));
        if (access_log.hasOwnProperty(date)) {
          selectedData.push({ name: date, door: access_log[date] });
        } else {
          selectedData.push({ name: date, door: 0 });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAccessLogData(selectedData);
    }
  }

  const getBookingData = async () => {
    let frequencies = [];
    try {
      let userArr = await Service.call('get', '/api/dashboard/order_item_list');
      const reducer = (acc, o) => (acc[o.item_name] = (acc[o.item_name] || 0) + 1, acc);
      const frequenciesRaw = userArr.reduce(reducer, {});
      const keys = Object.keys(frequenciesRaw);
      const values = Object.values(frequenciesRaw);
      _.forEach(keys, (element, index) => {
        frequencies.push({
          item_name: keys[index],
          freq: values[index]
        });
      });
    } catch (error) {
      console.error(error);
    } finally {
      setBookingData(frequencies);
    }
  };

  const getTokenData = async () => {
    let mData = [];
    let oneMonthAgo = moment().subtract(1, 'months').unix();
    try {
      let url = `/api/dashboard/token_log`;
      mData = await Service.call('get', url);
      mData = mData.filter((element) => element.ctime > oneMonthAgo);
    } catch (error) {
      console.error(error);
    } finally {
      setTokenData(mData);
    }
  };

  const getIncomeDataByItem = async (start_date, end_date) => {
    let incomeData = []
    try {
      let url = `/api/dashboard/income_by_product?start_date=${start_date}&end_date=${end_date}`;
      let resp = await Service.call('get', url);
      incomeData = resp.data
      incomeData = _.map(incomeData, (value) => {
        let cloneValue = _.cloneDeep(value)
        cloneValue.income /= 100;
        return cloneValue;
      })
      incomeData = _.sortBy(incomeData, 'type')
    } catch (error) {
      console.error(error);
    } finally {
      setIncomeData(incomeData);
    }
  };

  const formatTickBooking = (tickItem) => {
    return tickItem.length < 15
      ? tickItem : tickItem.slice(0, 15) + "...";
  }



  const formatTick = (tickItem) => {
    return moment(tickItem * 1000).format('DD/MM');
  }

  const formatTooltip = (tooltipItem) => {
    return moment(tooltipItem * 1000).format('DD/MM HH:mm');
  }

  const renderTokenChart = () => {
    let total = 0;
    const reducer = (acc, currentValue) => acc + currentValue.token_change;
    total = tokenData.reduce(reducer, total);

    return (
      <Col
      // xs={24} sm={24} md={12} lg={12} xl={12}
        id="token_log"
        style={{...styles.chartCol, paddingBottom: "100px", height: "450px"}}
      >
        <ExportToPDFButton targetID="token_log" paddingRight="3%" />
        <h2>{t("token_log")}</h2>
        <h3>
          {' '}
          Token Used in the past 1 month:
          {' '}
          {total}
        </h3>
        <ResponsiveContainer>
          <LineChart data={tokenData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="ctime"
              tickMargin={5}
              tickFormatter={formatTick}
              padding={{ left: 10, right: 10 }}
            />
            <YAxis />
            <Tooltip labelFormatter={formatTooltip} />
            <Legend name="Token Used" />
            <Line type="monotone" dataKey="token_change" stroke="#006ABF" name="Token Used" />
          </LineChart>
        </ResponsiveContainer>
      </Col>
    )
  }
  const onIncomeByItemCalendarChange = (dateArray) => {
    if (_.isNull(dateArray)) {
      return;
    }

    let start_date = dateArray[0] ? dateArray[0].unix() : null;
    let end_date = dateArray[1] ? dateArray[1].unix() : null;

    setIncomeByItemStartDate(start_date);
    setIncomeByItemEndDate(end_date);
  }

  const renderIncomeChart = () => {
    let total = 0;
    const reducer = (acc, currentValue) => acc + currentValue.token_change;
    total = tokenData.reduce(reducer, total);

    return (
      <Col
      // xs={24} sm={24} md={12} lg={12} xl={12}
        id="income_chart"
        style={{...styles.chartCol, paddingBottom: "100px", height: "800px"}}
      >
        <ExportToPDFButton targetID="income_chart" paddingRight="3%" />
        <h2>{t('monthly_income_by_item')}</h2>
        <RangePicker
          onCalendarChange={onIncomeByItemCalendarChange}
          defaultValue={[moment(IncomeByItemStartDate, 'X'), moment(IncomeByItemEndDate, 'X')]}
        />
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            width={500}
            height={800}
            data={IncomeData}
            margin={{
              top: 5,
              right: 80,
              left: 50,
              bottom: 5,
            }}
          >
            <YAxis type="category" dataKey="item_name" hide />
            <XAxis type="number" dataKey="income" />
            <Tooltip />
            <Legend />
            <Bar dataKey="income" fill="#8884d8" >
              <LabelList dataKey="item_name" position="right" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Col>
    )
  }

  // const renderIncomeByDateByItemKOLRoomChart = () => {
  //   let total = 0;
  //   const reducer = (acc, currentValue) => acc + currentValue.token_change;
  //   total = tokenData.reduce(reducer, total);

  //   return (
  //     <Col
  //     // xs={24} sm={24} md={12} lg={12} xl={12}
  //       style={{...styles.chartCol, paddingBottom: "100px", height: "450px"}}
  //     >
  //       <RangePicker
  //         onCalendarChange={onIncomeByDateCalendarChange}
  //         defaultValue={[moment(IncomeByDateKOLRoomStartDate, 'X'), moment(IncomeByDateKOLRoomEndDate, 'X')]}
  //       />
  //       <ResponsiveContainer width="100%" height="100%">
  //         <LineChart
  //           width={500}
  //           height={500}
  //           data={IncomeKOLRoomData}
  //           margin={{
  //             top: 5,
  //             right: 30,
  //             left: 30,
  //             bottom: 5,
  //           }}
  //         >
  //           <XAxis dataKey="date" />
  //           <YAxis />
  //           <Tooltip />
  //           <Legend />
  //           {
  //             _.map(IncomeModel, (eachModel) => {
  //               return (<Line type="monotone" dataKey={eachModel.name} stroke={eachModel.color} />)
  //             })
  //           }
  //         </LineChart>
  //       </ResponsiveContainer>
  //     </Col>
  //   )
  // }

  // const renderIncomeByDateByItemMultiFunctionRoomChart = () => {
  //   let total = 0;
  //   const reducer = (acc, currentValue) => acc + currentValue.token_change;
  //   total = tokenData.reduce(reducer, total);

  //   return (
  //     <Col
  //     // xs={24} sm={24} md={12} lg={12} xl={12}
  //       style={{...styles.chartCol, paddingBottom: "100px", height: "450px"}}
  //     >
  //       <RangePicker
  //         onCalendarChange={onIncomeByDateCalendarChange}
  //         defaultValue={[moment(IncomeByDateMultiFunctionRoomStartDate, 'X'), moment(IncomeByDateMultiFunctionRoomEndDate, 'X')]}
  //       />
  //       <ResponsiveContainer width="100%" height="100%">
  //         <LineChart
  //           width={500}
  //           height={500}
  //           data={IncomeMultiFunctionRoomData}
  //           margin={{
  //             top: 5,
  //             right: 30,
  //             left: 30,
  //             bottom: 5,
  //           }}
  //         >
  //           <XAxis dataKey="date" />
  //           <YAxis />
  //           <Tooltip />
  //           <Legend />
  //           {
  //             _.map(IncomeModel, (eachModel) => {
  //               return (<Line type="monotone" dataKey={eachModel.name} stroke={eachModel.color} />)
  //             })
  //           }
  //         </LineChart>
  //       </ResponsiveContainer>
  //     </Col>
  //   )
  // }

  // const renderIncomeByDateByItemMeetingRoomChart = () => {
  //   let total = 0;
  //   const reducer = (acc, currentValue) => acc + currentValue.token_change;
  //   total = tokenData.reduce(reducer, total);

  //   return (
  //     <Col
  //     // xs={24} sm={24} md={12} lg={12} xl={12}
  //       style={{...styles.chartCol, paddingBottom: "100px", height: "450px"}}
  //     >
  //       <RangePicker
  //         onCalendarChange={onIncomeByDateCalendarChange}
  //         defaultValue={[moment(IncomeByDateMeetingRoomStartDate, 'X'), moment(IncomeByDateMeetingRoomEndDate, 'X')]}
  //       />
  //       <ResponsiveContainer width="100%" height="100%">
  //         <LineChart
  //           width={500}
  //           height={500}
  //           data={IncomeMeetingRoomData}
  //           margin={{
  //             top: 5,
  //             right: 30,
  //             left: 30,
  //             bottom: 5,
  //           }}
  //         >
  //           <XAxis dataKey="date" />
  //           <YAxis />
  //           <Tooltip />
  //           <Legend />
  //           {
  //             _.map(IncomeModel, (eachModel) => {
  //               return (<Line type="monotone" dataKey={eachModel.name} stroke={eachModel.color} />)
  //             })
  //           }
  //         </LineChart>
  //       </ResponsiveContainer>
  //     </Col>
  //   )
  // }


  return (
    <AppLayout title={t("dashboard")}>
      <Content
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        {
          hideDashboard ? '' : (
            <>
              {/* <Row>
                {
                  renderAccessLogChart()
                }
              </Row>
              <Row>
                {
                  renderBookingFreqChart()
                }
              </Row> */}
              <Row>
                {
                  renderIncomeChart()
                }
              </Row>
              <Row>
                <Col id="monthly_income" style={{...styles.col}}>
                  <ExportToPDFButton targetID="monthly_income" paddingRight="3%" />
                  <h2>{t("monthly_income")}</h2>
                  <Tabs defaultActiveKey="total">
                    <TabPane tab={t("Summary")} key="Summary">
                      <IncomeByItemLineChart colorTemplate={colorTemplate} itemName="Summary" />
                    </TabPane>
                    <TabPane tab={t("KOLRoom")} key="KOLRoom">
                      <IncomeByItemLineChart colorTemplate={colorTemplate} itemName="KOLRoom" />
                    </TabPane>
                    <TabPane tab={t("MultiFunctionRoom")} key="MultiFunctionRoom">
                      <IncomeByItemLineChart colorTemplate={colorTemplate} itemName="MultiFunctionRoom" />
                    </TabPane>
                    <TabPane tab={t("MeetingRoom")} key="MeetingRoom">
                      <IncomeByItemLineChart colorTemplate={colorTemplate} itemName="MeetingRoom" />
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
              <Row>
                {
                  renderTokenChart()
                }
              </Row>
              <Row>
                <Col style={{...styles.col}}>
                  <ResponsiveContainer>
                    <TokenPurchaseData />
                  </ResponsiveContainer>
                </Col>
              </Row>
              <Row>
                <Col style={{...styles.col}}>
                  <IncomeByCatPieChart />
                </Col>
              </Row>
              <Row>
                <Col style={{...styles.col}}>
                  <RoomBookingCountRankChart />
                </Col>
              </Row>
              <Row>
                <Col style={{...styles.col}}>
                  <TrendDataRoomChart />
                </Col>
              </Row>
              <Row>
                <Col style={{...styles.col}}>
                  <AccessLogChart />
                </Col>
              </Row>
            </>
          )
        }
      </Content>
    </AppLayout>
  )
}

export default Dashboard;
