import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  InputNumber,
  Select,
  Spin,
  Radio,
  DatePicker,
  message,
  Input,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";

const { Option } = Select;
const debug = require("debug")("app:admin:client:src:BookNowCarParkForm");

const BookNowCarParkForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [itemList, setItemList] = useState([]);
  const [parkingSlotList, setParkingSlotList] = useState([]);

  const [userID, setUserID] = useState(null);
  const [selectedDateUnix, setSelectedDateUnix] = useState(null);
  const [selectedItemID, setSelectedItemID] = useState(null);

  const [userList, setUserList] = useState(null);

  const { t } = useTranslation();
  const { item_category_id: parent_category_id } = props;

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    getItemList();
  }, [props]);

  useEffect(() => {
    getParkingSlotList();
  }, [selectedDateUnix, selectedItemID]);

  const getUserList = async () => {
    setLoading(true);
    let result = await Service.call("get", '/api/user/list/approved?user_id');

    setUserList(result);
    setLoading(false);
  };

  const getItemList = async () => {
    setLoading(true);
    let req = await Service.call("get", `/api/item/car_park/list`);

    let { data } = req;
    let sortedItems = data.sort((a, b) => {
      let a_startTime = a.item_name.split(":")[0];
      let b_startTime = b.item_name.split(":")[0];
      return _.toInteger(a_startTime) - _.toInteger(b_startTime);
    });

    setItemList(sortedItems);
    setLoading(false);
  };

  const getParkingSlotList = async () => {
    if (_.isNull(selectedItemID) || _.isNull(selectedDateUnix)) {
      return;
    }

    setLoading(true);
    let req = await Service.call(
      "get",
      `/api/item/detail?item_id=${selectedItemID}&ts=${selectedDateUnix}`
    );

    let { bookingArr } = req;
    setParkingSlotList(bookingArr);
    console.log(bookingArr);
    setLoading(false);
  };

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onSelectedDateChange = (value) => {
    // form.setFieldsValue({
    //   bookingIDArr: [],
    // });

    setSelectedDateUnix(value.unix());
  };

  const onSelectedItemChange = (value) => {
    setSelectedItemID(value);
  };

  const onFinish = async (putObj) => {
    setLoading(true);
    let {
      user_id, booking_id, name, license_plate, phone_number
    } = putObj;

    let finalPutObj = {
      user_id, booking_id, name, license_plate, phone_number
    };
    console.log("onFinish", finalPutObj);
    let data = await Service.call("put", `/api/order/parking`, finalPutObj);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      setLoading(false);
      return;
    }
    message.success(t("Success"));
    form.resetFields();
    props.openModal(false);

    setItemList([]);
    setParkingSlotList([]);
    setUserID(null);
    setSelectedDateUnix(null);
    setSelectedItemID(null);

    setLoading(false);
  };

  const userOptionMap = () => {
    return (
      _.map(userList, (u) => {
        const { user_id, nickname, email } = u;
        return <Option value={user_id}>{`${nickname}(${email}) #${user_id}`}</Option>
      })
    )
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="BookNowCarParkForm"
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label={t("book_now_form_user_id")}
          name="user_id"
          rules={[{ required: true }]}
        >
          <Select
            value={userID}
            onChange={(value) => { setUserID(value) }}
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) => optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())}
          >
            {userOptionMap()}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("book_now_form_date")}
          name="ts"
          rules={[{ required: true, message: "Please select date." }]}
        >
          <DatePicker
            onChange={onSelectedDateChange}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label={t("book_now_form_time")}
          name="item_id"
          rules={[{ required: true }]}
        >
          <Select allowClear onChange={onSelectedItemChange}>
            {_.map(itemList, (timeslot) => {
              let { item_id, item_name, is_active } = timeslot;
              return (
                <Option value={item_id} disabled={!is_active} key={item_id}>
                  {item_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("book_now_form_parking_slot")}
          name="booking_id"
          rules={[{ required: true }]}
        >
          <Radio.Group
            style={{ display: "flex", flexWrap: "wrap" }}
            buttonStyle="solid"
          >
            {_.map(parkingSlotList, (slot) => {
              let { booking_id, is_active, status } = slot;

              return (
                <Radio.Button
                  value={booking_id}
                  disabled={!is_active || status !== 1}
                  style={{ width: "25%" }}
                >
                  {booking_id}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t("book_now_form_license_plate")}
          name="license_plate"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("book_now_form_name")}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("book_now_form_phone")}
          name="phone_number"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default BookNowCarParkForm;
