import React, { useState, useEffect } from "react";
import _, { initial, set } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  Label,
  Spin
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import * as debugLib from "debug";
import NavAndSideFrame from "../NavAndSideFrame";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import FormUploadFile from "../form/FormUploadFile";
import { formItemLayout, tailLayout } from "../ModalLayout";

const debug = debugLib("app:pages/company/item/list/Modal");
const { RangePicker } = DatePicker;

const ItemRuleSetForm = (props) => {
  const { onClick } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [switchBool, setSwitchBool] = useState({
    remarks: '',
    effective_all_time: 0,
    all_full_day: 0,
    holiday_full_day: 0,
    mon_full_day: 0,
    tue_full_day: 0,
    wed_full_day: 0,
    thu_full_day: 0,
    fri_full_day: 0,
    sat_full_day: 0,
    sun_full_day: 0,
    effective_start: 0,
    effective_end: 0,
    holiday_start: '0',
    holiday_end: '0',
    all_day_start: '0',
    all_day_end: '0',
    mon_start: '0',
    mon_end: '0',
    tue_start: '0',
    tue_end: '0',
    wed_start: '0',
    wed_end: '0',
    thu_start: '0',
    thu_end: '0',
    fri_start: '0',
    fri_end: '0',
    sat_start: '0',
    sat_end: '0',
    sun_start: '0',
    sun_end: '0',
    effective_start_end: [],
    holiday_start_end: [],
    all_day_start_end: [],
    mon_start_end: [],
    tue_start_end: [],
    wed_start_end: [],
    thu_start_end: [],
    fri_start_end: [],
    sat_start_end: [],
    sun_start_end: [],
  })


  const onFinish = async (postObj) => {
    setLoading(true)
    console.log('postObj >>>>', postObj)
    // console.log('switchBool >>>>', switchBool)
    let postData = { ...postObj }
    // console.log('postData: ', postData)
    const week = [
      'all_day', 'holiday', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'
    ]
    week.map((item, index) => {
      console.log(index, ':', postData[`${item}_start_end`], 'item >>>', item)
      if (_.isNil(postData[`${item}_start_end`])) {
        postData[`${item}_start`] = '';
        postData[`${item}_end`] = '';
      } else {
        let start_time = postData[`${item}_start_end`][0].format("HH:mm")
        let end_time = postData[`${item}_start_end`][1].format("HH:mm")
        postData[`${item}_start`] = start_time;
        postData[`${item}_end`] = end_time;
      }
      delete postData[`${item}_start_end`]
      // if (_.isNil(postData[`${item}_start`])) {
      //   postData[`${item}_start`] = '';
      // } else {
      //   postData[`${item}_start`] = moment(postData[`${item}_start`]).format('HH:mm');
      // }
      // if (_.isNil(postData[`${item}_end`])) {
      //   postData[`${item}_end`] = '';
      // } else {
      //   postData[`${item}_end`] = moment(postData[`${item}_end`]).format('HH:mm');
      // }
    })
    if (!_.isNil(postData.effective_start_end)) {
      console.log('inside moment >>>>>>>>>')
      postData.effective_start = moment(postData.effective_start_end[0]).unix()
      postData.effective_end = moment(postData.effective_start_end[1]).unix()
    }
    // console.log('check moment >>>>>', postData);
    let method = 'post'
    // console.log(postData);
    if (props.dataObj.item_ruleset_id > 0) {
      method = 'patch';
      postData.item_ruleset_id = props.dataObj.item_ruleset_id
    }
    let result = await Service.call(method, '/api/item/ruleset', postData)
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t('error'),
        description: result.errorMessage,
      });
    }
    notification.success({
      message: t('Success'),
    });
    setLoading(false)
    onClick(false)
  };

  const renderingRuleSetOption = () => {
    let options = [
      'holiday_full_day',
      'mon_full_day',
      'tue_full_day',
      'wed_full_day',
      'thu_full_day',
      'fri_full_day',
      'sat_full_day',
      'sun_full_day'
    ]
    return options.map((value, i) => {
      const week = [
        'holiday', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'
      ]
      return (
        <Row gutter={24} key={value}>
          <Col span={12}>
            {/* <Form.Item label={t(value)} name={value} key={value} style={{ alignSelf: 'center' }}> */}
            <Form.Item style={{ textAlign: 'center' }} label={t(value)} name={value} key={value}>
              <Switch
                checked={switchBool[value]}
                disabled={switchBool.all_full_day}
                onChange={(bool) => {
                  let result = 0
                  if (bool === true) {
                    result = 1;
                    form.setFieldsValue({ [`${week[i]}_start_end`]: undefined })
                  }
                  setSwitchBool({ ...switchBool, [`${value}`]: result })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <Form.Item label={t(`${week[i]}`) + t('_start_end')} name={`${week[i]}_start_end`} key={`${week[i]}_start_end`}> */}
            <Form.Item style={{ textAlign: 'center' }} name={`${week[i]}_start_end`} key={`${week[i]}_start_end`}>
              <TimePicker.RangePicker
                disabled={switchBool[value]}
                format="HH:mm"
                onChange={(e) => {
                  setSwitchBool({ ...switchBool, [`${week[i]}_start_end`]: e })
                  // console.log('time ranger >>>>>>', e)
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item label={t(`${week[i]}_end`)} name={`${week[i]}_end`} key={`${week[i]}_end`}>
              <TimePicker
                disabled={switchBool[value]}
                format="HH:mm"
                onChange={(e) => {
                  console.log(e)
                  setSwitchBool({ ...switchBool, [`${week[i]}_end`]: e })
                }}
              />
            </Form.Item>
          </Col> */}
        </Row>
      )
    })
  }

  useEffect(() => {
    console.log('data >>>>>>>>>.', props.dataObj)
    const week = [
      'all_day', 'holiday', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'
    ]
    week.map((item, index) => {
      // console.log(index, ':', props.dataObj[`${item}_start_end`])
      if (_.isNil(props.dataObj[`${item}_start`])) {
        return;
      }
      props.dataObj[`${item}_start_end`] = [props.dataObj[`${item}_start`], props.dataObj[`${item}_end`]]
    })
    props.dataObj.effective_start_end = [props.dataObj.effective_start, props.dataObj.effective_end]
    if (props.dataObj.effective_all_time === 1) {
      props.dataObj.effective_start_end = undefined
    }
    form.resetFields()
    setSwitchBool(props.dataObj)
    form.setFieldsValue(props.dataObj)
  }, [props.dataObj])

  // useEffect(() => {
  //   // if (switchBool.all_full_day === 1) {
  //   //   return setSwitchBool({
  //   //     ...switchBool,
  //   //     holiday_full_day: 1,
  //   //     mon_full_day: 1,
  //   //     tue_full_day: 1,
  //   //     wed_full_day: 1,
  //   //     thu_full_day: 1,
  //   //     fri_full_day: 1,
  //   //     sat_full_day: 1,
  //   //     sun_full_day: 1,
  //   //   })
  //   // };
  // }, [switchBool])
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Spin spinning={loading}>
      <Form
        {...layout}
        form={form}
        name="RuleSetForm"
        layout="horizontal"
        // initialValues={props.dataObj}
        onFinish={onFinish}
      >
        <Row justify="space-around">
          <Col>
            <Form.Item label={t("name")} name="name" rules={[{ required: true, message: 'Please input name' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t("priority")} name="priority" rules={[{ required: true, message: 'Please input priority number' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t("remarks")} name="remarks">
              <Input />
            </Form.Item>
          </Col>
        </Row>


        <Divider />
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label={t('effective_all_time')} name="effective_all_time" key="effective_all_time">
              <Switch
                checked={switchBool.effective_all_time}
                onChange={(bool) => {
                  let result = 0
                  if (bool === true) {
                    result = 1;
                    form.setFieldsValue({ effective_start_end: [null, null] })
                  }
                  setSwitchBool({ ...switchBool, effective_all_time: result })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("effective_start_end")}
              name="effective_start_end"
              key="effective_start_end"
              rules={[{ required: Boolean(!switchBool.effective_all_time), message: 'Please select Date.' }]}
            >
              <RangePicker
                onChange={(e) => {
                  setSwitchBool({ ...switchBool, effective_start_end: e })
                }}
                disabled={switchBool.effective_all_time}
                disabledDate={(current) => {
                  return moment().add(-1, 'days') >= current
                  // || moment().add(1, 'month') <= current;
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
          <Form.Item label={t("effective_end")} name="effective_end" key="effective_end">
            <DatePicker
              onChange={(e) => {
                setSwitchBool({ ...switchBool, effective_end: e })
              }}
              disabledDate={(current) => {
                return moment().add(-1, 'days') >= current
                // || moment().add(1, 'month') <= current;
              }}
            />
          </Form.Item>
        </Col> */}
        </Row>
        <Divider />
        <div style={{
          display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', fontSize: 16, flex: 1, margin: 15
        }}
        >
          <div style={{ alignSelf: 'center' }}>
            {t('full_day_available')}
          </div>
          <div style={{ paddingRight: 15 }}>
            {t('partial_available')}
          </div>
        </div>
        {/* <Col span={8}>
            <Form.Item label=c style={{ fontWeight: 'bold' }}>
            </Form.Item>
          </Col> */}

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item style={{ textAlign: 'center' }} label={t('all_full_day')} name="all_full_day" key="all_full_day">
              <Switch
                checked={switchBool.all_full_day}
                onChange={(bool) => {
                  let result = 0
                  if (bool === true) {
                    result = 1;
                  }
                  setSwitchBool({
                    ...switchBool,
                    all_full_day: result,
                    holiday_full_day: result,
                    mon_full_day: result,
                    tue_full_day: result,
                    wed_full_day: result,
                    thu_full_day: result,
                    fri_full_day: result,
                    sat_full_day: result,
                    sun_full_day: result,
                  })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item style={{ textAlign: 'center' }} name="all_day_start_end" key="all_day_start_end">
              <TimePicker.RangePicker
                disabled={switchBool.all_full_day}
                format="HH:mm"
                onChange={(e) => {
                  // setSwitchBool({ ...switchBool, all_day_start: e })
                  // console.log('time ranger >>>>>>', e)
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
          <Form.Item label={t("all_day_end")} name="all_day_end" key="all_day_end">
            <TimePicker
              disabled={switchBool.all_full_day}
              format="HH:mm"
              onChange={(e) => {
                console.log(e)
                setSwitchBool({ ...switchBool, all_day_end: e })
              }}
            />
          </Form.Item>
        </Col> */}
        </Row>
        {renderingRuleSetOption()}
        {/* <Col>
          <Form.Item name="all_full_day">
            <TimePicker.RangePicker />
          </Form.Item>
        </Col> */}
        {/* <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('all_full_day')} name="all_full_day">
            <Switch
              checked={switchBool.all_full_day}
              onChange={(value) => {
                console.log(value)
                let result = 0
                if (value === true) {
                  result = 1;
                }

                setSwitchBool({ all_full_day: result })
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="all_full_day">
            <TimePicker.RangePicker onChange={(value) => console.log(value)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('holiday_full_day')} name="holiday_full_day">
            <Switch checked={switchBool.holiday_full_day} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="holiday_full_day">
            <TimePicker.RangePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('mon_full_day')} name="mon_full_day">
            <Switch checked={switchBool.mon_full_day} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="mon_full_day">
            <TimePicker.RangePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('tue_full_day')} name="tue_full_day">
            <Switch checked={switchBool.tue_full_day} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="tue_full_day">
            <TimePicker.RangePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('wed_full_day')} name="wed_full_day">
            <Switch checked={switchBool.wed_full_day} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="wed_full_day">
            <TimePicker.RangePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('thu_full_day')} name="thu_full_day">
            <Switch checked={switchBool.tue_full_day} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="thu_full_day">
            <TimePicker.RangePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('fri_full_day')} name="fri_full_day">
            <Switch checked={switchBool.fri_full_day} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="fri_full_day">
            <TimePicker.RangePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('sat_full_day')} name="sat_full_day">
            <Switch checked={switchBool.sat_full_day} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="sat_full_day">
            <TimePicker.RangePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('sun_full_day')} name="sun_full_day">
            <Switch checked={switchBool.sun_full_day} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="sun_full_day">
            <TimePicker.RangePicker />
          </Form.Item>
        </Col>
      </Row> */}
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default ItemRuleSetForm;
