import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Modal
} from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'antd/dist/antd.css';
import _ from 'lodash';
import * as Service from '../core/Service';
import NavAndSideFrame from '../components/NavAndSideFrame';
import CompanyAdminForm from '../components/CompanyAdminForm';
import CompanyAdminTable from '../components/CompanyAdminTable';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

const involvedModelName = "company/admin";
// const title = "Company Admin";
const selectedKey = "admin";
const openKey = "admin";
const tableIDName = "company_admin_id";

const CompanyAdminList = (props) => {
  const {t} = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [dataList, setDataList] = useState([]);
  const [adminGroupList, setAdminGroupList] = useState([]);
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_admin_w')
  );

  useEffect(() => {
    getAllData();
    getAdminGroupList();
  }, []);

  const getAllData = async () => {
    try {
      let url = `/api/admin/list`;
      let data = await Service.call('get', url);
      // let { companyAdmin, companies } = data;
      // dataList = Main.mergeByKey(companyAdmin, companies, 'company_id');
      let newData = _.map(data, (item) => {
        let {
          first_name, last_name, nickname
        } = item;
        return { ...item, fullname: `${first_name} ${last_name} (${nickname})`}
      })
      setDataList(newData);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const getAdminGroupList = async () => {
    try {
      let url = `/api/admin/groupList`;
      let mData = await Service.call("get", url);

      setAdminGroupList(mData);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };

  return (
    <NavAndSideFrame {...props} title={t("company_admin")} selectedKey={selectedKey} openKey={openKey}>
      {
        disableEdit ? '' : (
          <>
            <Button
              type="primary"
              onClick={() => {
                setSelectedRecord({ company_admin_id: 0, createNewAdmin: true });
                setModalVisible(true);
              }}
            >
              {t("create_new")}
            </Button>
            <Divider />
          </>
        )
      }
      {/* <Tablex
        rowKey={tableIDName}
        scroll={{x: 'max-content'}}
        dataSource={dataList}
        columns={setTableHeader()}
      /> */}
      <CompanyAdminTable
        dataObj={
          dataList
        }
        adminGroupList={adminGroupList}
        adminChangeData={getAllData}
        disableEdit={disableEdit}
      />

      <Modal
        destroyOnClose
        title="Edit"
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        onCancel={() => {
          setModalVisible(false)
        }}
      >
        <CompanyAdminForm
          dataObj={
            selectedRecord
          }
          openModal={
            (value) => {
              setModalVisible(value)
              getAllData();
            }
          }
          adminGroupList={adminGroupList}
        />
      </Modal>
    </NavAndSideFrame>
  )
}

export default CompanyAdminList;
