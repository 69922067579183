import React, { Component } from 'react'
import { Layout, Menu } from 'antd';
import {
  Redirect,
  Link
} from "react-router-dom";
import {
  DashboardOutlined,
  UserOutlined,
  AppstoreOutlined,
  TeamOutlined,
  MinusOutlined,
  DollarCircleOutlined,
  BookOutlined,
  FolderOpenOutlined,
  NotificationOutlined,
  HomeOutlined
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { ActionCreators } from '../redux/actions';
import * as Service from '../core/Service';
import { checkPermission } from '../utils';

const { Sider } = Layout;
const { SubMenu } = Menu;

let dataList = [
  {
    key: 'home', title: 'home', path: '/admin/home', icon: <HomeOutlined />, display: 'block', privilege: 'all'
  },
  // { key: 'company', title: 'Company List', path: '/admin/company/list', icon: <BankOutlined />, display: 'block', className: ['p_company'], privilege: 'admin' },
  // { key: 'company_admin', title: 'Company Admin', path: '/admin/company/admin', icon: <LockOutlined />, display: 'block', className: ['p_company'], privilege: 'all' },
  // {key: 'company_user_role', title: 'Company User Role', path: '/company/user_role', icon: <BankOutlined />, display: 'block', className: ['p_company']},
  // { key: 'company_door', title: 'Company Door', path: '/admin/company/door', icon: <HistoryOutlined />, display: 'block', className: ['p_company'], privilege: 'all' },
  // { key: 'company_user', title: 'Company User', path: '/admin/user', icon: <TeamOutlined />, display: 'block', privilege: 'all' },
  // { key: 'user', title: 'User', path: '/admin/user', icon: <TeamOutlined />, display: 'block', privilege: 'all' },
  {
    key: 'dashboard', title: 'dashboard', path: '/admin/dashboard', icon: <DashboardOutlined />, display: 'block', privilege: 'all', permissions: ['ui_dashboard_r'],
  },
  {
    key: 'user',
    title: 'user',
    path: '/',
    icon: <TeamOutlined />,
    display: 'block',
    privilege: 'admin',
    submenu: [
      {
        key: 'user', title: 'user', path: '/admin/user', display: 'block', privilege: 'all', permissions: ['ui_user_r'],
      },
      {
        key: 'approve', title: 'approve', path: '/admin/approve', display: 'block', privilege: 'all', permissions: ['ui_user_approve_r'],
      },
    ],
  },
  {
    key: 'order',
    title: 'order',
    path: '/',
    icon: <FolderOpenOutlined />,
    display: 'block',
    privilege: 'admin',
    submenu: [
      {
        key: 'order_list', title: 'order_list', path: '/admin/order/list', display: 'block', privilege: 'all', permissions: ['ui_order_list_r']
      },
      {
        key: 'common_area_list', title: 'common_area_list', path: '/admin/order/common_area_list', display: 'block', privilege: 'all', permissions: ['ui_common_area_list_r']
      },
      {
        key: 'parking_list', title: 'parking_list', path: '/admin/order/parking_list', display: 'block', privilege: 'all', permissions: ['ui_parking_list_r']
      },
      {
        key: 'transaction_log', title: 'transaction_log', path: '/admin/payment/transaction/log', display: 'block', privilege: 'all', permissions: ['ui_transaction_log_r']
      },
    ],
  },
  {
    key: 'token',
    title: 'token',
    path: '/',
    icon: <DollarCircleOutlined />,
    display: 'block',
    privilege: 'admin',
    submenu: [
      {
        key: 'token_log', title: 'token_log', path: '/admin/token/log', display: 'block', privilege: 'all', permissions: ['ui_token_log_r']
      },
      {
        key: 'token_package', title: 'token_package', path: '/admin/token/package', display: 'block', privilege: 'all', permissions: ['ui_token_package_r']
      },
    ],
  },
  {
    key: 'booking',
    title: 'booking',
    path: '/',
    icon: <BookOutlined />,
    display: 'block',
    privilege: 'admin',
    submenu: [
      {
        key: 'expired_locker_booking', title: 'expired_locker_booking', path: '/admin/booking/expired_locker_booking', display: 'block', privilege: 'all', permissions: ['ui_expired_locker_booking_r']
      },
      {
        key: 'book_now', title: 'book_now', path: '/admin/booking/book_now', display: 'block', privilege: 'all', permissions: ['ui_book_now_r']
      },
      {
        key: 'booking_list', title: 'booking_calendar', path: '/admin/booking/list', display: 'block', privilege: 'all', permissions: ['ui_booking_list_r']
      },
      {
        key: 'booking_record_list', title: 'booking_list', path: '/admin/booking/record/list', display: 'block', privilege: 'all', permissions: ['ui_booking_record_list_r']
      },
      {
        key: 'item_list', title: 'item_list', path: '/admin/item/list', display: 'block', privilege: 'all', permissions: ['ui_item_list_r']
      },
      {
        key: 'item_config', title: 'item_config', path: '/admin/item/config', display: 'block', privilege: 'all', permissions: ['ui_item_config_r']
      },
    ],
  },
  {
    key: "discount",
    title: "discount",
    path: "/",
    icon: <DollarCircleOutlined />,
    display: "block",
    privilege: "admin",
    submenu: [
      {
        key: "discount_info", title: "discount_info", path: "/admin/discountinfo", icon: <DollarCircleOutlined />, display: "block", privilege: "all", permissions: ['ui_discount_info_r'],
      },
      {
        key: "discount_info_room", title: "discount_info_room", path: "/admin/discountinforoom", icon: <DollarCircleOutlined />, display: "block", privilege: "all", permissions: ['ui_discount_room_r'],
      },
    ],
  },
  {
    key: 'notification',
    title: 'notification',
    path: '/admin/notification',
    icon: <NotificationOutlined />,
    display: 'block',
    privilege: 'all',
    permissions: ['ui_notification_r'],
  },
  {
    key: 'app_config',
    title: 'app_config',
    path: '/',
    icon: <AppstoreOutlined />,
    display: 'block',
    privilege: 'admin',
    submenu: [
      {
        key: 'posts_list', title: 'posts_list', path: '/admin/posts/list', display: 'block', privilege: 'all', permissions: ['ui_posts_list_r']
      },
      {
        key: 'faq', title: 'faq', path: '/admin/faq', display: 'block', privilege: 'all', permissions: ['ui_faq_r']
      },
      {
        key: 'general', title: 'general', path: '/admin/app_config', display: 'block', privilege: 'all', permissions: ['ui_meta_r']
      },
    ],
  },
  {
    key: 'sys_config',
    title: 'sys_config',
    path: '/',
    icon: <AppstoreOutlined />,
    display: 'block',
    privilege: 'admin',
    submenu: [
      {
        key: 'sys_config_general', title: 'general', path: '/admin/sys_config', display: 'block', privilege: 'all', permissions: 'ui_sys_config_r'
      },
    ],
  },
  {
    key: 'admin',
    title: 'admin',
    path: '/',
    icon: <UserOutlined />,
    display: 'block',
    privilege: 'admin',
    submenu: [
      {
        key: 'admin', title: 'admin_list', path: '/admin/company_admin', display: 'block', privilege: 'all', permissions: ['ui_admin_r']
      },
      {
        key: 'admin_group', title: 'admin_group', path: '/admin/company_admin_group', display: 'block', privilege: 'all', permissions: ['ui_admin_group_r']
      },
    ],
  },

  // { key: 'token', title: 'Token ', path: '/admin/token', icon: <TeamOutlined />, display: 'block', privilege: 'all' },

  // { key: 'token_log', title: 'token_log', path: '/admin/token/log', icon: <TeamOutlined />, display: 'block', privilege: 'all' },
  // { key: 'access_log', title: 'Access log', path: '/admin/access_log', icon: <TeamOutlined />, display: 'block', privilege: 'all' },
  // { key: 'company_door_qrcode', title: 'Company Door QRCode', path: '', icon: <QrcodeOutlined />, display: 'block', privilege: 'company' },
  // {key: 'company_user_access_log_id', title: 'Client Access Log', path: '/client/access_log', icon: <HistoryOutlined />, display: 'block'},
  // { key: 'controller_device', title: 'Controller Device', path: '/admin/controller_device_list', icon: <HistoryOutlined />, display: 'block', privilege: 'admin' },
  // { key: 'controller_passcode', title: 'Controller Passcode', path: '/admin/controller_passcode_list', icon: <TeamOutlined />, display: 'block', privilege: 'admin' },
  // {key: 'controller_access_log', title: 'Controller Access Log', path: '/controller_access_log', icon: <HistoryOutlined />, display: 'block'},
  // { key: 'controller_access_log', title: 'Controller Access Log', path: '/admin/controller_access_log', icon: <HistoryOutlined />, display: 'block', privilege: 'admin' },
  // { key: 'company_user_access_log', title: 'Company User Access Log', path: '/admin/controller_access_log', icon: <HistoryOutlined />, display: 'block', privilege: 'company' },
  // {key: 'website_enquiry', title: 'Website Enquiry', path: '/contact-form/list', icon: <MailOutlined />, display: 'block'},
  // {
  //   key: 'sales',
  //   title: 'Sales(DEV)',
  //   path: '/',
  //   icon: <DollarOutlined />,
  //   display: 'block',
  //   submenu: [
  //     {key: 'ballpark', title: 'Ballpark(DEV)', path: '/ballpark', display: 'block'},
  //   ]
  // },
  // {
  //   key: 'project_management',
  //   title: 'Project Management',
  //   path: '/',
  //   icon: <ProjectOutlined />,
  //   display: 'block',
  //   submenu: [
  //     {key: 'project', title: 'Project List(DEV)', path: '/project/list', display: 'block'},
  //     {key: 'timesheet', title: 'Timesheet', path: '/timesheet/list', display: 'block'},
  //   ]
  // },
  // {key: 'admin', title: 'Admin List', path: '/admin/list', icon: <UserOutlined /> },
  // {
  //   key: 'setting',
  //   title: 'Settings',
  //   path: '/',
  //   icon: <AppstoreOutlined />,
  //   display: 'block',
  //   submenu: [
  //     { key: 'mobile_app', title: 'Mobile App', path: '/admin/setting/mobile/app', display: 'block', privilege: 'admin' },
  //     { key: 'system', title: 'System', path: '/admin/setting/system', display: 'block', privilege: 'admin' },
  //   ],
  //   privilege: 'admin',
  // },
]
let adminRoute = [
  '/admin/home',
  '/admin/user',
  '/admin/approve',
  '/admin/posts/list',
  '/admin/faq',
  '/admin/booking',
  '/admin/payment',
  '/admin/notification',
  '/admin/token/log',
  '/admin/payment/transaction/log',
  '/admin/access_log',
  '/admin/app_config',
  '/admin/sys_config',
  '/admin/company_admin',
  // '/admin/controller_device_list',
  // '/admin/controller_passcode_list',
  // '/controller_access_log',
  // '/admin/controller_access_log',
]
// let companyAdminRoute = [
//   '/company/home',
//   '',
//   '/company/company/admin',
//   // '/company/company/user_role',
//   '/company/company/door',
//   '/company/company/user',
//   // '/client/access_log',
//   '/company/company/door/qrcode',
//   '',
//   '',
//   '',
//   '/company/company/all/user/accesslog',
//   // '/company/controller_device_list',
//   // '/company/controller_passcode_list',
//   // '/controller_access_log',
//   // '/company/controller_access_log',
// ]

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      logout: false,
    };
  }

  async toggle() {
    let { collapsed } = this.state
    this.setState({ collapsed: !collapsed });
  }

  async logout() {
    const {
      app, setLogoutWay, setAdmin, setCompanyAdmin, setAdminPermissions, setAdminUIPermissions, setAuth
    } = this.props;

    let admin = _.isEmpty(app.admin);
    if (admin) {
      setLogoutWay('company')
    } else {
      setLogoutWay('admin')
    }
    await Service.logout();
    setAdmin({})
    setCompanyAdmin({})
    setAdminPermissions([]);
    setAdminUIPermissions([]);
    setAuth(false)
    this.setState({ logout: true });
  }

  renderMenuItem() {
    const { t, app } = this.props;
    let admin = _.isEmpty(app.company_admin);
    return dataList.map((pathData) => {
      let hasPermission = checkPermission(pathData.permissions, app.adminUIPermissions);
      if (pathData.submenu && admin) {
        let anySubHasPermission = _.includes(pathData.submenu.map((subPathData) => {
          return checkPermission(subPathData.permissions, app.adminUIPermissions);
        }), true);
        if (hasPermission && anySubHasPermission) {
          return (
            <SubMenu
              key={pathData.key}
              icon={pathData.icon}
              title={t(pathData.title)}
            >
              {pathData.submenu.map((subPathData, i) => {
                let hasPermissionSub = checkPermission(subPathData.permissions, this.props.app.adminUIPermissions);
                if (hasPermissionSub) {
                  return (
                    <Menu.Item
                      key={subPathData.key}
                      style={{
                        height: "25px",
                        lineHeight: "25px"
                      }}
                      icon={<MinusOutlined />}
                    >
                      <Link to={subPathData.path}>
                        {subPathData.icon}
                        {t(subPathData.title)}
                      </Link>
                    </Menu.Item>
                  )
                }
              })}
              <div style={{margin: 25}} />
            </SubMenu>
          )
        }
        return null;
      }

      if ((pathData.privilege === "all"
      || (admin && pathData.privilege === 'admin')
      || (!admin && pathData.privilege === 'company')
      ) && hasPermission) {
        return (
          <Menu.Item
            key={pathData.key}
            onClick={this.handleClick}
            className={
              pathData.className ? pathData.className.join(' ') : ''
            }
          >
            <Link to={pathData.path}>
              {pathData.icon}
              <span>{t(pathData.title)}</span>
            </Link>
          </Menu.Item>
        )
      }
      return null;
    })
  }

  render() {
    const { t } = this.props;
    const { logout } = this.state;

    let { defaultSelectedKeys, defaultOpenKeys } = this.props;

    return (
      <Sider
        breakpoint="sm"
        collapsedWidth="0"
        width={250}
        style={{
          height: '100%', opacity: 1, position: 'relative', backgroundColor: 'white'
        }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[defaultSelectedKeys]}
          defaultOpenKeys={[defaultOpenKeys]}
          style={{ paddingBottom: '50px', paddingTop: '20px' }}
        >
          {this.renderMenuItem()}
        </Menu>
        <Menu mode="inline" style={{ opacity: 1, position: 'relative' }}>
          {/* <Divider /> */}
          <Menu.Item key="99" onClick={() => { this.logout(); }}>
            <span>{t("logout")}</span>
          </Menu.Item>
        </Menu>

        {
          logout === true
            ? <Redirect to="/admin/login" />
            : null
        }
      </Sider>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Sidebar));
