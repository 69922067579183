const LOCKER_DURATION_TYPE = {
  FULL_HOUR: 0,
  HALF_DAY: 1,
  FULL_DAY: 2,
  FULL_WEEK: 3,
  FULL_MONTH: 4,
  HALF_YEAR: 5,
  FULL_YEAR: 6,
};

export default LOCKER_DURATION_TYPE;
