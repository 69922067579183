import _ from 'lodash';
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import moment from 'moment';
import { notification } from 'antd';
import * as Service from './Service';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { ActionCreators } from '../redux/actions';
import { getStore } from '../redux/store/configureStore';

export const TABLE_CONFIG = {
  item_info: 'ii',
  item_facility: 'if',
  item_category: 'ic',
  post: 'post',
  faq: 'faq'
}
const ID_CONFIG = {
  ii: 'item_id',
  if: 'item_facility_id',
  ic: 'item_category_id',
  post: 'post_id',
  faq: 'faq_id'
}

const COLUMN_CONFIG = {
  ii: ['item_desc', 'item_name'],
  if: ['name'],
  ic: ['category_desc', 'category_name'],
  post: ['post_title', 'post_excerpt', 'post_content'],
  faq: ['faq_title', 'faq_content'],
}

const LanguageList = ['zh_hant'];

// export function getUser() {
//   return getStore().getState().app.user;
// }

// export function getCompany() {
//   return getStore().getState().app.company;
// }

// export function signOut() {
//   localStorage.setItem('user');
//   localStorage.setItem('auth', JSON.stringify(false));
//   localStorage.removeItem('company');
// }
export function convertAllCompanyUserAccessLogToDisplayData(dataList) {
  try {
    dataList.map((item) => {
      if (!_.isUndefined(item.ctime)) item.ctime = moment.unix(item.ctime).format('YYYY-MM-DD HH:mm:ss');
      if (!_.isUndefined(item.access_time)) item.access_time = moment.unix(item.access_time).format('YYYY-MM-DD HH:mm:ss');
      if (!_.isNil(item.utime)) delete item.utime;
      if (!_.isNil(item.passcode)) delete item.passcode;
      if (!_.isNil(item.passcode_type)) delete item.passcode_type;
      if (!_.isNil(item.passcode_time_start)) delete item.passcode_time_start;
      if (!_.isNil(item.passcode_time_end)) delete item.passcode_time_end;
      if (!_.isNil(item.access_token)) delete item.access_token;
      if (!_.isNil(item._id)) delete item._id;
      if (!_.isNil(item.version)) delete item.version;
      if (!_.isNil(item.browser)) delete item.browser;
      if (!_.isNil(item.signature)) delete item.signature;
      if (!_.isNil(item.os)) delete item.os;
      if (!_.isNil(item.platform)) delete item.platform;
      if (!_.isNil(item.client_ip)) delete item.client_ip;
      if (!_.isNil(item.controller_access_log_id)) delete item.controller_access_log_id;
      if (!_.isNil(item.controller_device_id)) delete item.controller_device_id;
      if (!_.isNil(item.controller_ip_internet)) delete item.controller_ip_internet;
      if (!_.isNil(item.controller_ip_local)) delete item.controller_ip_local;
      if (!_.isNil(item.controller_name)) delete item.controller_name;
      if (item.is_active === 1) {
        item.is_active = "actice"
      } else {
        item.is_active = "inactive"
      }
    })
    return dataList;
  } catch (error) {
    console.log(error)
  }
}

export async function exportToCSV(dataList, fileName) {
  const header = [
    [
      'Create Time',
      'Access Time',
      'Status',
      'Usage Count',
      'Active Status',
      'Direction',
      'Action',
      'Reader',
      'Last Name',
      'First Name',
      'User ID',
      'Door Name',
    ]
  ]
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  let ws = XLSX.utils.book_new()
  XLSX.utils.sheet_add_aoa(ws, header)
  XLSX.utils.sheet_add_json(ws, dataList, { skipHeader: true, origin: "A2" });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export async function exportTableToCSV(dataList, fileName) {
  const header = dataList.length >= 0
    ? [_.map(dataList[0], (value, key) => { return key; })]
    : [[]];

  let processedDataList = _.map(dataList, (data) => {
    let mData = { ...data };
    if (mData.ctime) { mData.ctime = moment(mData.ctime, 'X').format('YYYY-MM-DD HH:mm:ss'); }
    if (mData.utime) { mData.utime = moment(mData.utime, 'X').format('YYYY-MM-DD HH:mm:ss'); }
    if (mData.is_active) { mData.is_active = mData.is_active === 1; }
    return mData;
  });

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  let ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, header);
  XLSX.utils.sheet_add_json(ws, processedDataList, { skipHeader: true, origin: "A2" });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export function mergeByKey(arr, subArr, key) {
  return _.each(arr, (item) => {
    _.find(subArr, (subItem) => {
      return item[key] === subItem[key] ? _.assign(item, subItem) : null;
    })
  })
}

export function fetchProductCategoryOption(dataList, item_category_id = 0) {
  // let result = await Service.call('get', `/api/item/category/list/current`);
  // console.log('result >>>>', dataList)

  // if (result.status !== 1) {
  //   return notification.error({ message: '錯誤', description: result.errorMessage });
  // }

  let allowPick = _.filter(dataList, function (o) { // filter options
    //  Filter out self record
    if (o.item_category_id === item_category_id) {
      return false;
    }

    let value = o.parent_index.split('-');
    let length = value.length;
    if (value[0] === "") { // correct the length of root categories
      length = 0
    }
    if (length >= 4) { // Filter out max layer record
      return false;
    }
    return true;
  })

  // console.log('allowPick', allowPick)

  let categoryMap = _.groupBy(allowPick, 'parent_index');

  const getChildren = (itemCategoryId, index) => {
    let childParentIndex = index.length > 0 ? `${index}-${itemCategoryId}` : _.toString(itemCategoryId);
    let childNodes = categoryMap[childParentIndex];
    // console.log(`childNodes >> `, childNodes);
    if (!childNodes) {
      return null;
    }

    return childNodes.map((rc) => {
      return {
        value: rc.item_category_id,
        label: rc.category_name,
        children: getChildren(rc.item_category_id, rc.parent_index)
      };
    });
  };

  const cascaderOptions = [];
  const rootNodes = categoryMap[""];

  _.forEach(rootNodes, (rc) => {
    cascaderOptions.push({
      value: rc.item_category_id,
      label: rc.category_name,
      children: getChildren(rc.item_category_id, rc.parent_index)
    });
  });

  return cascaderOptions;
}

export function mergeTranslationList(tableName, dataList, translationList) {
  let keyByTransList = _.keyBy(translationList, 'ref_key')
  _.map(dataList, (itemRc) => {
    _.map(COLUMN_CONFIG[tableName], (columnName) => {
      // debug(${tableName}_${itemRc[TABLE_NAME_CONFIG[tableName]]}_${columnName}_zh_hant)
      _.map(LanguageList, (language) => {
        let translationRc = keyByTransList[`${tableName}_${itemRc[ID_CONFIG[tableName]]}_${columnName}_${language}`]
        if (translationRc) {
          itemRc[`${columnName}_${language}`] = translationRc.value
        }
      })
    })
  })
  return dataList;
}
