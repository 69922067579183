import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import {
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis
} from "recharts";
import {
  Spin, Empty, Select, Button
} from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import ExportToPDFButton from "../ExportToPDFButton";

const TrendDataRoomChart = () => {
  const id = "room_booking_trend_chart";
  const { t } = useTranslation();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [roomData, setRoomData] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [selectedItemID, setSelectedItemID] = useState(null);
  const [selectedDay, setSelectedDay] = useState(1);

  useEffect(() => {
    if (!_.isNull(selectedItemID)) {
      getTrendData(selectedItemID);
    }
  }, [selectedItemID]);

  useEffect(() => {
    getRoomData();
  }, []);

  const getTrendData = async (item_id) => {
    setLoading(true);
    try {
      let url = `/api/dashboard/trend_data_room_cart?item_id=${item_id}`;
      let result = await Service.call("get", url);

      let finalResult = processResult(result);

      setTrendData(finalResult);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getRoomData = async () => {
    setLoading(true);
    try {
      let url = `/api/dashboard/trend_data_room_cart_room_list`;
      let result = await Service.call("get", url);

      setRoomData(_.orderBy(result, "item_name"));
      if (_.isNull(selectedItemID) && result.length > 0) {
        setSelectedItemID(result[0].item_id);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const processResult = (data) => {
    const doDayData = (thatDayData) => {
      let timeCount = {};
      for (let i = 0; i < 24; i += 1) {
        timeCount[`${i.toString().padStart(2, 0)}:00`] = 0;
      }
      _.each(thatDayData, (d) => {
        timeCount[moment(d.time, "X").format("HH:00")] += d.count;
      });
      return _.map(timeCount, (count, name) => {
        return { name, count };
      });
    };

    let grouped = _.groupBy(data, (d) => {
      return moment(d.time, "X").day();
    });
    for (let i = 0; i < 7; i += 1) {
      if (_.isUndefined(grouped[i.toString()])) {
        grouped[i.toString()] = [];
      }
    }
    return _.map(grouped, (g) => {
      return doDayData(g);
    });
  };

  const onItemChange = (value) => {
    setSelectedItemID(value);
  };

  const renderChart = () => {
    if (_.isEmpty(trendData)) {
      return (
        <div style={{ padding: "10% 3%" }}>
          <Empty />
        </div>
      );
    }

    let dataToRender = trendData[selectedDay];

    return (
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={500}
          height={500}
          data={dataToRender}
          margin={{
            top: 5,
            right: 10,
            left: 10,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" interval={0} />
          <YAxis domain={[0, 8]} hide />
          <Bar dataKey="count" fill="#8884d8" tickCount={1} />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const onDayChange = (value) => {
    setSelectedDay(value);
  };

  const increaseDay = () => {
    let day = selectedDay + 1;
    if (day >= 7) {
      day = 0;
    }
    setSelectedDay(day);
  };

  const decreaseDay = () => {
    let day = selectedDay - 1;
    if (day < 0) {
      day = 6;
    }
    setSelectedDay(day);
  };

  return (
    <Spin id={id} spinning={loading}>
      <ExportToPDFButton targetID={id} />
      <h2>{t("room_booking_trend_chart")}</h2>
      <Button.Group>
        <Button onClick={decreaseDay} icon={<LeftOutlined />} />
        <Select
          style={{ width: 200 }}
          onChange={onDayChange}
          value={selectedDay}
        >
          <Option value={0}>{t("sun_full_day")}</Option>
          <Option value={1}>{t("mon_full_day")}</Option>
          <Option value={2}>{t("tue_full_day")}</Option>
          <Option value={3}>{t("wed_full_day")}</Option>
          <Option value={4}>{t("thu_full_day")}</Option>
          <Option value={5}>{t("fri_full_day")}</Option>
          <Option value={6}>{t("sat_full_day")}</Option>
        </Select>
        <Button onClick={increaseDay} icon={<RightOutlined />} />
      </Button.Group>
      <Select style={{ width: 200, marginLeft: 10 }} onChange={onItemChange} value={selectedItemID}>
        {_.map(roomData, (data) => {
          return <Option value={data.item_id}>{data.item_name}</Option>;
        })}
      </Select>
      {renderChart()}
    </Spin>
  );
};

export default TrendDataRoomChart;
