import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';

const debug = require('debug')('app:admin:client:src:pages:company:UserQuotaListTable');

const PastBookingTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "booking_id";

  const [dataList, setDataList] = useState([]);
  const [itemFilterList, setItemFilterList] = useState([]);
  const [categoryFilterList, setCategoryFilterList] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const {
    setLoading, setModalVisible, setEventObj, loading, callRefresh
  } = props;

  useEffect(() => {
    getDataList();
  }, [callRefresh])

  useEffect(() => {
    getItemFilterList();
  }, [categoryFilter])

  const getItemFilterList = async () => {
    let itemArr = []
    let resp = await Service.call('get', "/api/item/list/current");
    if (resp.status === 1) {
      let { data, translationList } = resp;
      itemArr = Main.mergeTranslationList(Main.TABLE_CONFIG.item_info, data, translationList)
    }
    let categoryArr = await Service.call('get', "/api/item/category/list/current");
    let categoryArrMap = _.keyBy(categoryArr, "item_category_id");

    _.each(itemArr, (item, i) => {
      itemArr[i].category_name = categoryArrMap[item.item_category_id].category_name
    })

    if (!_.isEmpty(categoryFilter)) {
      setItemFilterList(_.uniq(_.map(itemArr, (item) => {
        if (_.includes(categoryFilter, item.category_name)) {
          return item.item_name
        }
      })))
    } else {
      setItemFilterList(_.uniq(_.map(itemArr, 'item_name')));
    }
  }

  const getDataList = async () => {
    setLoading(true)
    let bookingData = []
    let orderedBookingData = []
    let categoryArr = []
    let itemArr = []

    try {
      // Get & format calander data
      let resp = await Service.call('get', "/api/item/list/current");
      if (resp.status === 1) {
        let { data, translationList } = resp;
        itemArr = Main.mergeTranslationList(Main.TABLE_CONFIG.item_info, data, translationList)
      }
      let itemArrMap = _.keyBy(itemArr, "item_id")
      let userArr = await Service.call('get', "/api/user/list/current");
      let userArrMap = _.keyBy(userArr, "user_id");
      let guestUserArr = await Service.call('get', "/api/user/guest/list/current");
      let guestUserArrMap = _.keyBy(guestUserArr, "user_guest_id");
      categoryArr = await Service.call('get', "/api/item/category/list/current");
      let categoryArrMap = _.keyBy(categoryArr, "item_category_id")
      let bookingItemArr = await Service.call('get', `/api/booking_item/list/bydate/0000000000/${moment().unix()}`);

      bookingData = _.filter(bookingItemArr, ['status', 10]);

      // Mapping Data
      _.each(bookingData, (bookingItem, i) => {
        bookingData[i].item_name = itemArrMap[bookingItem.item_id].item_name
        if (bookingItem.user_id !== 0) {
          bookingData[i].first_name = userArrMap[bookingItem.user_id].first_name
          bookingData[i].last_name = userArrMap[bookingItem.user_id].last_name
          bookingData[i].user_type = "user"
        } else if (bookingItem.user_guest_id !== 0) {
          bookingData[i].first_name = guestUserArrMap[bookingItem.user_guest_id].first_name
          bookingData[i].last_name = guestUserArrMap[bookingItem.user_guest_id].last_name
          bookingData[i].nickname = guestUserArrMap[bookingItem.user_guest_id].nickname
          bookingData[i].email = guestUserArrMap[bookingItem.user_guest_id].email
          bookingData[i].mobile = guestUserArrMap[bookingItem.user_guest_id].mobile
          bookingData[i].remarks = guestUserArrMap[bookingItem.user_guest_id].remarks
          bookingData[i].user_type = "guest"
        } else {
          bookingData[i].first_name = ""
          bookingData[i].last_name = ""
        }
        bookingData[i].id = bookingItem.user_id || bookingItem.user_guest_id || 0;
        bookingData[i].item_category_id = itemArrMap[bookingItem.item_id].item_category_id
        bookingData[i].category_name = categoryArrMap[bookingItem.item_category_id].category_name
      })

      orderedBookingData = _.orderBy(bookingData, ["start_time"], ["desc"])
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setCategoryFilterList(_.map(categoryArr, 'category_name'));
      setItemFilterList(_.uniq(_.map(itemArr, 'item_name')));
      setDataList(orderedBookingData);
    }
    setLoading(false)
  }
  const displayUserType = (userType) => {
    let displayStr = '';
    let tagColor = 'green';
    switch (userType) {
      case "user":
        displayStr = t('user');
        tagColor = 'green';
        break;
      case "guest":
        displayStr = t("guest");
        tagColor = 'blue';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('view')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EyeOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setEventObj(record)
                  }}
                />
              </Tooltip>
            </span>
          )
        }
      },
      {
        title: t('user_id'),
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id
      },
      {
        title: t('user_type'),
        dataIndex: 'user_type',
        sorter: (a, b) => a.user_type.localeCompare(b.user_type),
        render: (value) => displayUserType(value)
      },
      // {
      //   title: t('first_name'),
      //   dataIndex: 'first_name',
      //   sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      // },
      // {
      //   title: t('last_name'),
      //   dataIndex: 'last_name',
      //   sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      // },
      {
        title: t('item_name'),
        dataIndex: 'item_name',
        filters: _.map(itemFilterList, (value) => {
          return { text: value, value };
        }),
        onFilter: (value, record) => {
          return record.item_name === value;
        },
        sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      },
      {
        title: t('category_name'),
        dataIndex: 'category_name',
        filters: _.map(categoryFilterList, (value) => {
          return { text: value, value };
        }),
        onFilter: (value, record) => {
          return record.category_name === value;
        },
        sorter: (a, b) => a.category_name.localeCompare(b.category_name),
      },
      {
        title: t('start_time'),
        dataIndex: 'start_time',
        sorter: (a, b) => a.start_time - b.start_time,
        render: (start_time) => {
          return moment.unix(start_time).format('YYYY-MM-DD HH:mm')
        },
      },
      {
        title: t('end_time'),
        dataIndex: 'end_time',
        sorter: (a, b) => a.end_time - b.end_time,
        render: (end_time) => {
          return moment.unix(end_time).format('YYYY-MM-DD HH:mm')
        },
      },
      {
        title: t('section_duration'),
        dataIndex: 'section_duration',
        sorter: (a, b) => a.section_duration - b.section_duration,
        render: (value) => {
          return Math.round(value / 60) + t('mins')
        },
      },
      {
        title: t('section_price'),
        dataIndex: 'section_price',
        sorter: (a, b) => a.section_price - b.section_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format('YYYY-MM-DD HH:mm')
        },
      }
    ];
    return columns;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        loading={loading}
        pagination={{ pageSize }}
        onChange={(pagination, filters, sorter, extra) => {
          if (categoryFilter !== filters.category_name) {
            if (filters.category_name !== null) {
              setCategoryFilter(filters.category_name)
            } else {
              setCategoryFilter([])
            }
          }
          if (pageSize !== pagination.pageSize) {
            setPageSize(pagination.pageSize)
          }
        }}
      />
    </Layout>
  )
}
export default PastBookingTable;
