import React, { useState, useEffect } from 'react';
import {
  Button, Layout, Table, Tag, Tooltip, Row, Col, Select
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  FileImageOutlined,
  LaptopOutlined,
  ControlOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Main from '../../core/Main';

const ItemInfoListTable = (props) => {
  const { t } = useTranslation()
  const tableIDName = "item_id";
  let {
    dataObj, categoryList, setModalVisible, setGalleryModalVisible, setFacilityModalVisible, setRulesetModalVisible, setSelectedRecord, loading, isActiveChangeCallBack, disableEdit
  } = props

  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [isActive, setIsActive] = useState(1);
  const [filteredItemCategory, setFilteredItemCategory] = useState([]);
  const {Option} = Select;

  useEffect(() => {
    // console.log(dataObj);
    let filteredDataList = dataObj.filter((item) => item.type !== 1);
    // console.log('filtered :>', filteredDataList)

    setDataList(filteredDataList)
    setFilterDataList(filteredDataList);
  }, [dataObj]);

  useEffect(() => {
    filterData();
  }, [
    dataList,
    filteredItemCategory,
    isActive
  ])

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const filterData = () => {
    debounce(() => {
      let searchedList = dataList;

      searchedList = _.compact(searchedList);
      searchedList = _.map(searchedList, (rowData) => {
        if (_.isUndefined(rowData)) return;
        if (rowData.is_active === isActive) return rowData;
      });
      // console.log(`searchedList >> isActive ${isActive} >> `, searchedList)

      // console.log('filteredItemCategory', filteredItemCategory)
      if (filteredItemCategory.length > 0) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          let isFound = false;
          _.each(filteredItemCategory, (item_category_id) => {
            if (rowData.item_category_id === item_category_id) isFound = true;
          })
          if (isFound) return rowData;
        });
        // console.log(`searchedList >> filteredItemCategory ${filteredItemCategory} >> `, searchedList)
      }

      searchedList = _.compact(searchedList);
      // console.log(`searchedList >> setFilterDataList >> `, searchedList)
      setFilterDataList(searchedList);
    }, 400);
  }

  const setTableHeader = () => {
    let columns = [
      {
        title: t('name'),
        dataIndex: 'item_name',
        sorter: (a, b) => a.item_name.localeCompare(b.item_name)
      },
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('sorting'),
        dataIndex: 'sorting',
        sorter: (a, b) => a.sorting - b.sorting,
      },
      {
        title: t('category'),
        dataIndex: 'item_category_id',
        render: (value) => {
          return categoryList.map(
            (category) => {
              if (category.item_category_id === value) {
                return category.category_name
              }
            }
          )
        },
        sorter: (a, b) => a.item_category_id - b.item_category_id,
      },
      {
        title: t('section_price'),
        dataIndex: 'section_price',
        sorter: (a, b) => a.section_price - b.section_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('hourly_price'),
        dataIndex: 'hourly_price',
        sorter: (a, b) => a.hourly_price - b.hourly_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('half_day_price'),
        dataIndex: 'half_day_price',
        sorter: (a, b) => a.half_day_price - b.half_day_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('daily_price'),
        dataIndex: 'daily_price',
        sorter: (a, b) => a.daily_price - b.daily_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('weekly_price'),
        dataIndex: 'weekly_price',
        sorter: (a, b) => a.weekly_price - b.weekly_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('monthly_price'),
        dataIndex: 'monthly_price',
        sorter: (a, b) => a.monthly_price - b.monthly_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('half_year_price'),
        dataIndex: 'half_year_price',
        sorter: (a, b) => a.half_year_price - b.half_year_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('yearly_price'),
        dataIndex: 'yearly_price',
        sorter: (a, b) => a.yearly_price - b.yearly_price,
        render: (value) => {
          return '$' + value
        },
      },
      {
        title: t('section_duration'),
        dataIndex: 'section_duration',
        sorter: (a, b) => a.section_duration - b.section_duration,
        render: (value) => {
          return Math.round(value / 60) + t('mins')
        },
      },
      // {
      //   title: t('buffer'),
      //   dataIndex: 'buffer',
      //   sorter: (a, b) => a.buffer - b.buffer,
      //   render: (value) => {
      //     return Math.round(value / 60) + t('mins')
      //   },
      // },
      // {
      //   title: t('start_time'),
      //   dataIndex: 'start_time',
      //   sorter: (a, b) => a.start_time - b.start_time,
      //   render: (value) => {
      //     return moment(value, "HH:mm").format("HH:mm")
      //   },
      // },
      // {
      //   title: t('end_time'),
      //   dataIndex: 'end_time',
      //   sorter: (a, b) => a.end_time - b.end_time,
      //   render: (value) => {
      //     return moment(value, "HH:mm").format("HH:mm")
      //   },
      // },
      {
        title: t('start_booking_day'),
        dataIndex: 'start_day',
        sorter: (a, b) => a.start_day - b.start_day,
        render: (value) => {
          return moment.unix(value).format('YYYY-MM-DD')
        },
      },
      {
        title: t('end_booking_day'),
        dataIndex: 'end_day',
        sorter: (a, b) => a.end_day - b.end_day,
        render: (value, record) => {
          return record.no_end_day === 1 ? t("with_no_end_day") : moment.unix(value).format('YYYY-MM-DD')
        },
      },
      {
        title: t('prebooking_type'),
        dataIndex: 'prebooking_type',
        sorter: (a, b) => a.prebooking_type - b.prebooking_type,
        render: (value) => {
          return displayPrebookingType(value)
        },
      },
      {
        title: t('utime'),
        dataIndex: 'utime',
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record)
                  }}
                />
              </Tooltip>
              <Tooltip title={t('gallery')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<FileImageOutlined />}
                  onClick={() => {
                    setGalleryModalVisible(true);
                    setSelectedRecord(record)
                  }}
                />
              </Tooltip>
              <Tooltip title={t('facility')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<LaptopOutlined />}
                  onClick={() => {
                    setFacilityModalVisible(true);
                    setSelectedRecord(record)
                  }}
                />
              </Tooltip>
              <Tooltip title={t('ruleset')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<ControlOutlined />}
                  onClick={() => {
                    setRulesetModalVisible(true);
                    setSelectedRecord(record)
                  }}
                // disabled={record.is_active}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={() => {
                    let is_active = record.is_active ? 0 : 1
                    isActiveChangeCallBack(record.item_id, is_active)
                  }}
                />
              </Tooltip>
              {/* <Popconfirm
                title={t('confirm_delete') + "?"}
                onConfirm={() => deleteRecord(record.item_id)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <Button
                    shape="circle"
                    // style={{ marginRight: 8}}
                    icon={<DeleteOutlined />}
                    // onClick={() => {
                    //   setSelectedRecord(record)
                    // }}
                  />
                </Tooltip>
              </Popconfirm> */}
            </span>
          )
        }
      });
    }
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayPrebookingType = (value) => {
    let displayStr = '';
    switch (value) {
      case 1:
        displayStr = t("one_week_before");
        break;
      case 2:
        displayStr = t("one_month_before");
        break;
      case 3:
        displayStr = t("two_week_before");
        break;
      case 0:
        displayStr = t("na");
        break;
      default:
        displayStr = t("error")
        break;
    }
    return displayStr;
  }

  const handleItemCategoryFilterChange = (value) => {
    setFilteredItemCategory(value);
  }

  const handleIsActiveFilterChange = (value) => {
    setIsActive(value);
  }

  const onExportTable = () => {
    const processedDataList = _.map(dataList, (data) => {
      return {
        ...data,
        start_day: data.start_day === 0 ? '-' : moment(data.start_day, 'X').format('YYYY-MM-DD'),
        end_day: data.end_day === 0 ? '-' : moment(data.end_day, 'X').format('YYYY-MM-DD'),
        section_duration: `${data.section_duration / 60}Mins`,
      }
    });

    Main.exportTableToCSV(processedDataList, 'export');
  };

  return (
    <Layout>
      <Row style={{ marginTop: 20 }}>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`category`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Select
            mode="multiple"
            style={{ width: "100%", minWidth: 400 }}
            placeholder={t('category')}
            onChange={(value) => handleItemCategoryFilterChange(value)}
          >
            {
              _.map(categoryList, (eachItem) => {
                // console.log(categoryList)
                // console.log('setting item', eachItem.item_category_id, t(eachItem.category_name))
                return <Option value={eachItem.item_category_id}>{t(eachItem.category_name)}</Option>
              })
            }
          </Select>
        </Col>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`is_active`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Select
            defaultValue={1}
            style={{ width: "100%", minWidth: 100 }}
            placeholder={t('is_active')}
            onChange={(value) => handleIsActiveFilterChange(value)}
          >
            <Option value={1} key={1}>{t('true')}</Option>
            <Option value={0} key={0}>{t('false')}</Option>
          </Select>
        </Col>
        <Col>
          <Button
            style={{
              float: 'right',
              marginBottom: 10,
              maxHeight: 32,
            }}
            icon={<FileExcelOutlined />}
            onClick={onExportTable}
          >
            {t('export_to_excel_file')}
          </Button>
        </Col>
      </Row>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={filterDataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
      />
    </Layout>
  )
}
export default ItemInfoListTable;
