import React, { useState, useEffect } from "react";
import _, { set } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  Spin,
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "querystring";
import * as Main from "../core/Main";
import * as Service from '../core/Service';

const { Option } = Select;

const DiscountInfoFormModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible
  } = props;

  const [initialFormValues, setInitialFormValues] = useState({});
  const [mType, setMType] = useState(null);
  const [limitType, setLimitType] = useState(null);
  const [DiscountInfoForm] = Form.useForm();

  useEffect(() => {
    let initialValues = {};
    initialValues = selectedRecord;
    if (initialValues.discount_info_id > 0) {
      initialValues.start = moment.unix(selectedRecord.start);
      initialValues.expire = moment.unix(selectedRecord.expire);
      setMType(initialValues.type);
    }
    setInitialFormValues(initialValues);
  }, [modalVisible]);

  useEffect(() => {
    DiscountInfoForm.resetFields();
  }, [initialFormValues])

  const handleFormSubmit = (formData) => {
    formData.start = moment(formData.start).unix();
    formData.expire = moment(formData.expire).unix();
    onFinish(formData);
  };

  const onFinish = async (formData) => {
    let url = '/api/discount';
    let discount_info_id = selectedRecord.discount_info_id;

    let params = formData;
    if (discount_info_id > 0) {
      params.discount_info_id = discount_info_id;
      params.status = selectedRecord.status;
    }

    let resp = {};
    resp = await Service.call(discount_info_id > 0 ? 'PATCH' : 'POST', url, params);

    if (!resp || resp.status < 1) {
      message.error(t('fail'));
      return;
    }
    message.success(t('Success'));
    setModalVisible(false);
  };

  return (
    <Modal
      title="Discount Info Form"
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={DiscountInfoForm}
        name={t("discount_info_form")}
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={(formData) => {
          handleFormSubmit(formData);
        }}
      >
        <Form.Item
          label={t("discount_name")}
          name="name"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("discount_code")}
          name="code"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("type")}
          name="type"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Select value={mType} onChange={(value) => setMType(value)}>
            <Option value={1}>{t('discount_type_actual_price')}</Option>
            <Option value={2}>{t('discount_type_percentage')}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t("discount_amount")}
          name="discount"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <InputNumber
            min={0}
            formatter={(value) => {
              switch (DiscountInfoForm.getFieldsValue().type) {
                case 1:
                  return `$${value}`;
                case 2:
                  return `${value}%`;
                default:
                  return value;
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label={t("limit_type")}
          name="limit_type"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Select value={limitType} onChange={(value) => setLimitType(value)}>
            <Option value={0}>{t('none')}</Option>
            <Option value={1}>{t('limit_by_token')}</Option>
            <Option value={2}>{t('limit_by_price')}</Option>
          </Select>
        </Form.Item>
        {limitType > 0 ? (
          <Form.Item
            label={t("limit_amount")}
            name="limit_amount"
            rules={[{ required: limitType > 0, message: t('input_required') }]}
          >
            <InputNumber
              min={0}
              formatter={(value) => {
                switch (DiscountInfoForm.getFieldsValue().limit_type) {
                  case 1:
                    return `${value}`;
                  case 2:
                    return `$${value}`;
                  default:
                    return value;
                }
              }}
            />
          </Form.Item>
        ) : null}
        <Row>
          <Col flex="auto">
            <Form.Item
              label={t("effective_start")}
              name="start"
              rules={[{ required: true, message: t('select_required') }]}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item
              label={t("effective_end")}
              name="expire"
              rules={[{ required: true, message: t('select_required') },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('start') <= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('invalid_end_day'));
                  },
                }),
              ]}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="auto">
            <Form.Item
              label={t("limit_per_user")}
              name="limit_per_user"
              rules={[{ required: true, message: t('input_required') }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item
              label={t("limit_total")}
              name="limit_total"
              rules={[{ required: true, message: t('input_required') }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        {selectedRecord.discount_info_id !== 0 ? (
          <Form.Item label={t("create_time")}>
            <p>{moment(selectedRecord.ctime * 1000).format('YYYY-MM-DD HH:mm')}</p>
          </Form.Item>
        ) : null}

        {selectedRecord.discount_info_id !== 0 ? (
          <Form.Item label={t("utime")}>
            <p>{moment(selectedRecord.utime * 1000).format('YYYY-MM-DD HH:mm')}</p>
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default DiscountInfoFormModal;
