import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button, Col, Form, Input, Layout, Modal,
  notification, Radio, Row, Select, Tabs, Upload, message, DatePicker, TimePicker, InputNumber
} from 'antd';
import * as Service from '../core/Service'
import * as Main from '../core/Main';

const AgreementDocuments = () => {
  const [content, setContent] = useState('')
  let { type, language } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    getContent();
  }, [])

  const getContent = async () => {
    let url = `/api/document/${type}/${language}`
    let result = await Service.call('get', url)
    let { data } = result
    if (result.status !== 1) {
      return notification.error({
        message: t('error'),
        description: result.errorMessage,
      });
    }
    setContent(data[0].meta_value)
    notification.success(`${t('success')}!`);
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  )
}

export default AgreementDocuments;
