import React, { useEffect, useState } from 'react';
import {
  Spin, Button, Table, Modal, Tooltip, Tag, Form, Input, notification, Switch, Row, Col, Tabs, message,
} from 'antd'
import _ from 'lodash';
import {
  EditOutlined,
  CheckOutlined,
  PlusOutlined,
  StopOutlined,
  SelectOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as Service from '../../core/Service';
import { formItemLayout, tailLayout } from "../ModalLayout";
import Fuse from 'fuse.js';
import * as Main from '../../core/Main';

const filterableFields = [
  `name`,
  `remarks`,
];

const ItemRuleSetGroup = (props) => {
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [rulesetOption, setRulesetOption] = useState([]);
  const [linkedRulesetOption, setLinkedRulesetOption] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [linkageModalVisible, setLinkageModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectTablKey, setSelectedTabKey] = useState(1);
  const [form] = Form.useForm();
  const [linkageForm] = Form.useForm();
  const { disableEdit } = props;

  const getAllData = async () => {
    setLoading(true);
    let result = await Service.call('get', '/api/item/ruleset_group');
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: t('error'),
        description: result.errorMessage,
      });
    }
    let { data } = result;
    setDataList(data);
    setFilterDataList(data);
    setLoading(false);
  }

  const getRuleSetOption = async (item_ruleset_group_id) => {
    let result = await Service.call('post', '/api/item/ruleset_group/ruleset_option', { item_ruleset_group_id })
    if (result.status !== 1) {
      return notification.error({
        message: t('error'),
        description: result.errorMessage,
      });
    }
    let { data } = result;
    setRulesetOption(data);
  }

  const getLinkedRuleSetOption = async (item_ruleset_group_id) => {
    // console.log('test >>>>>', item_ruleset_group_id)
    if (_.isUndefined(item_ruleset_group_id)) {
      item_ruleset_group_id = 0
    }
    let result = await Service.call('post', '/api/item/ruleset_group/linked_ruleset_option', { item_ruleset_group_id })
    if (result.status !== 1) {
      return notification.error({
        message: t('error'),
        description: result.errorMessage,
      });
    }
    let { data } = result;
    // console.log('linked data >>', data)
    setLinkedRulesetOption(data);
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const handleRulesetGroupIsActive = async (record) => {
    setLoading(true)
    let { item_ruleset_group_id, is_active } = record;
    let result = await Service.call('patch', '/api/item/ruleset_group/is_active', { item_ruleset_group_id, is_active });
    if (result.status !== 1) {
      message.error(t(result.errorMessage))
      return;
    }
    message.success(t('success'))
    getAllData()
    setLoading(false)
  }

  const getColumns = () => {
    let columns = [
      {
        title: t('name'),
        dataIndex: 'name',
      },
      {
        title: t('remarks'),
        dataIndex: 'remarks',
      },
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        align: 'center',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
    ];
    if (!disableEdit) {
      columns.unshift(    {
        title: t('operation'),
        dataIndex: 'item_ruleset_group_id',
        key: 'item_ruleset_group_id',
        fixed: 'left',
        render: (value, record, index) => {
          return (
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: '#000000' }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                    getAllData();
                  }}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t('disable') : t('enable')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: record.is_active ? 'red' : 'green' }}
                  icon={record.is_active ? (<StopOutlined />) : (<CheckOutlined />)}
                  onClick={() => handleRulesetGroupIsActive(record)}
                />
              </Tooltip>
              <Tooltip title={t("linkage")}>
                <Button
                  shape="circle"
                  style={{ color: '#000000' }}
                  icon={<SelectOutlined />}
                  onClick={async () => {
                    getRuleSetOption(record.item_ruleset_group_id);
                    getLinkedRuleSetOption(record.item_ruleset_group_id);
                    setLinkageModalVisible(true);
                    setSelectedRecord(record);
                  }}
                />
              </Tooltip>
            </span>
          )
        }
      });
    }
    return columns;
  }
  const onFinish = async (postObj) => {
    setLoading(true)
    let method = 'post'
    if (selectedRecord.item_ruleset_group_id > 0) {
      method = 'patch'
      postObj.item_ruleset_group_id = selectedRecord.item_ruleset_group_id
    }

    // post
    let result = await Service.call(method, '/api/item/ruleset_group', postObj)
    if (result.status !== 1) {
      return notification.error({
        message: t('error'),
        description: result.errorMessage,
      });
    }
    notification.success({
      message: t('Success'),
    });
    // notification.success('Succeed!')
    setModalVisible(false);
    getAllData();
    setLoading(false)
  }

  const LinkagePage = () => {
    return (
      <Tabs defaultActiveKey={selectTablKey} onChange={(e) => setSelectedTabKey(e)}>
        <TabPane tab={t('linked_ruleset')} key={2}>
          {handleLinkedRuleSetOption()}
        </TabPane>
        <TabPane tab={t('linkage_option')} key={1}>
          {handleRuleSetOption()}
        </TabPane>

      </Tabs>
      // {renderingRuleSetOption()}
    )
  }
  const handleRuleSetOption = () => {
    const rulesetColumns = [
      {
        title: t('name'),
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: t('remarks'),
        key: 'remarks',
        dataIndex: 'remarks',
      },
      {
        title: t('operation'),
        dataIndex: 'item_ruleset_id',
        key: 'item_ruleset_id',
        render: (value, record) => {
          return (
            <span>
              <Button
                type="primary"
                onClick={async () => {
                  let result = await Service.call('post', '/api/item/ruleset_group/link_ruleset', { item_ruleset_id: value, item_ruleset_group_id: selectedRecord.item_ruleset_group_id })
                  if (result.status !== 1) {
                    return notification.error({
                      message: t('error'),
                      description: result.errorMessage,
                    });
                  }
                  notification.success({ message: 'Linked!' })
                  getRuleSetOption(selectedRecord.item_ruleset_group_id);
                  getLinkedRuleSetOption(selectedRecord.item_ruleset_group_id)
                }}
              >
                {t("link")}
              </Button>
            </span>
          )
        }
      },
    ]

    return (
      <Table
        rowKey={(index) => index}
        scroll={{ x: true }}
        columns={rulesetColumns}
        dataSource={rulesetOption}
      />
    )
  }

  const handleLinkedRuleSetOption = () => {
    const rulesetColumns = [
      {
        title: t('name'),
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: t('remarks'),
        key: 'remarks',
        dataIndex: 'remarks',
      },
      {
        title: t('operation'),
        dataIndex: 'item_ruleset_ref_id',
        key: 'item_ruleset_ref_id',
        render: (value, record) => {
          return (
            <span>
              <Button
                type="primary"
                onClick={async () => {
                  let result = await Service.call('put', '/api/item/ruleset_group/delete_ruleset', { item_ruleset_ref_id: value })
                  if (result.status !== 1) {
                    return notification.error({
                      message: t('error'),
                      description: result.errorMessage,
                    });
                  }
                  notification.success({ message: `${t('unlinked')}!` })
                  getRuleSetOption(selectedRecord.item_ruleset_group_id);
                  getLinkedRuleSetOption(selectedRecord.item_ruleset_group_id);
                }}
              >
                {t('unlinked')}
              </Button>
            </span>
          )
        }
      },
    ]

    return (
      <Table
        rowKey={(index) => index}
        scroll={{ x: true }}
        columns={rulesetColumns}
        dataSource={linkedRulesetOption}
      />
    )
  }
  useEffect(() => {
    getAllData();
  }, [])

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(selectedRecord);
  }, [selectedRecord])

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      }
      const fuse = new Fuse(filterList, options)
      const result = fuse.search(filterObj);
      // console.log(result);
      filterList = _.map(result, 'item');
      // console.log(filterList);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setFilterDataList(filterList);
    }
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <Spin spinning={loading}>
      <Row>
        {
          disableEdit ? '' : (
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord({})
                  setModalVisible(true);
                }}
                style={{ marginBottom: 10 }}
              >
                {t('create')}
              </Button>
            </Col>
          )
        }
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
        <Col style={{ marginLeft: 10 }}>
          <Button
            icon={<FileExcelOutlined />}
            onClick={onExportTable}
          >
            {t('export_to_excel_file')}
          </Button>
        </Col>
      </Row>
      <Table
        rowKey={(index) => index}
        scroll={{ x: true }}
        columns={getColumns()}
        dataSource={filterDataList}
        bordered
        size="small"
      />
      <Modal
        title={selectedRecord.item_ruleset_group_id > 0 ? t('edit') : t('create')}
        footer={null}
        width={1200}
        visible={modalVisible}
        onCancel={() => {
          getAllData()
          setModalVisible(false);
        }}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="RuleSetGroupLForm"
          layout="horizontal"
          // initialValues={props.dataObj}
          onFinish={onFinish}
        >
          <Form.Item
            label={t("name")}
            name="name"
            rules={[{ required: true, message: 'Please input username.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("remarks")}
            name="remarks"
            rules={[{ required: true, message: 'Please input username.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              {t("submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t('linkage')}
        footer={null}
        visible={linkageModalVisible}
        width={1200}
        onCancel={() => {
          getAllData()
          setLinkageModalVisible(false);
        }}
      >
        <LinkagePage />
      </Modal>

    </Spin>
  )
}

export default ItemRuleSetGroup;
