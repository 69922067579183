import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "querystring";
import * as debugLib from "debug";
import NavAndSideFrame from "../NavAndSideFrame";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import FormUploadFile from "../form/FormUploadFile";

const {Option} = Select;
const debug = debugLib("app:src/admin/client/src/components/item/ItemLinkageModal");

const ItemLinkageModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    itemList,
  } = props;
  const [initialFormValues, setInitialFormValues] = useState({});
  const [ItemLinkageForm] = Form.useForm();

  useEffect(() => {
    setInitialFormValues(selectedRecord);
  }, [selectedRecord]);

  useEffect(() => {
    ItemLinkageForm.resetFields();
  }, [initialFormValues])

  const onFinish = async (formData) => {
    let endpoint = `/api/item/linkage`;
    let method = selectedRecord.item_linkage_id > 0 ? 'PUT' : 'POST';
    let {
      father_item_id,
      son_item_id,
    } = formData;

    if (father_item_id === son_item_id) {
      notification.error({
        message: `${t(`cannot_link_same_item`)}`,
      });
      return;
    }
    let params = {
      father_item_id,
      son_item_id,
    };

    if (selectedRecord.item_linkage_id > 0) params.item_linkage_id = selectedRecord.item_linkage_id;
    let resp = await Service.call(method, endpoint, params);
    // console.log('resp >>> ', resp);
    if (!resp || resp.status < 1) {
      message.error(resp.errorMessage);
      return;
    }
    notification.success({
      message: t('Success'),
    });
    // message.success(t('modal_success'));
    setModalVisible(false);
    // getData();
  }

  return (
    <Modal
      title={
        selectedRecord.item_linkage_id === 0 ? t("add_new_item_linkage") : t("edit_item_linkage")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{maxWidth: "95%"}}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={ItemLinkageForm}
        name="ItemLinkageForm"
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={onFinish}
      >
        <Form.Item
          label={t("father_item")}
          name="father_item_id"
        >
          <Select defaultValue={selectedRecord.father_item_id}>
            {_.map(itemList, (item) => {
              return (
                <Option value={item.item_id}>
                  {item.item_name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("son_item")}
          name="son_item_id"
        >
          <Select defaultValue={selectedRecord.son_item_id}>
            {_.map(itemList, (item) => {
              return (
                <Option value={item.item_id}>
                  {item.item_name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ItemLinkageModal;
