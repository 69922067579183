import React, { Component, useState } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Content,
  Input,
  Layout,
  Row,
  Select,
  Tabs,
  message,
  Form,
  Modal,
  notification
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { setAuth, setAdmin, setAdminPermissions, setAdminUIPermissions } from '../redux/actions/common'
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import * as Service from '../core/Service';
import logo from '../assets/Logo_Black.png';


const Login = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [forgetPasswordForm] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const config = useSelector((state) => state.app.config);
  const [modalVisible, setModalVisible] = useState(false)
  const [inputEmail, setInputEmail] = useState('')
  // console.log('Config>>>', config);
  const onFinish = async (formData) => {
    let { email, password } = formData;
    let data = await Service.call('post', `/api/login/admin`, {
      email, password
    });

    if (!data || data.errorMessage) return message.error(t("login_error"));

    let adminData = await Service.call('get', `/api/admin`);
    // console.log('admin data .>>>>>>>>>>', adminData)
    if (adminData.errorMessage) return dispatch(setAuth(false));
    if (_.isEmpty(adminData.userData)) return dispatch(setAuth(false));

    dispatch(setAdmin(adminData.userData[0]));
    dispatch(setAdminPermissions(adminData.permissions));
    dispatch(setAdminUIPermissions(adminData.uiPermissions));
    dispatch(setAuth(true));
    history.push('/admin/home')
  }

  const onSubmitResetPassword = async (postObj) => {
    let url = '/api/admin/forget_password/reset_password'
    let method = 'post'

    const {
      password,
      repeat_password,
    } = postObj;

    if (password !== repeat_password) {
      return message.error(t('repeat_password_error'))
    }

    let resp = await Service.call(method, url, postObj)
    if (!resp || resp.status !== 1) {
      return notification.error({
        message: t('reset_password_fail'),
        description: resp.errorMessage
      })
    }
    notification.success({
      message: t('reset_password_success'),
      description: t('reset_password_success_desc')

    });

    setModalVisible(false)
  }
  const displayLogin = () => {
    return (
      <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
        <Form
          className="login-form col-12 mt-2"
          style={{ width: '100%' }}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: t('please_input_email'), type: 'email' }]}
          >
            <Input
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={t('email')}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: t('please_input_password') }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={t('pwd')}
            />
          </Form.Item>
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t("login")}
          </Button>
          {/* <p className="forgot-password text-right">
                Login with <a href="#" onClick={(e) => this.changeLoginType(e)}>{this.state.isMobile ? 'email' : 'mobile'}</a>
            </p> */}
        </Form>
      </div>
    );
  }

  const displayForgetPw = () => {
    return (
      <div className="" style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <Form
          className="login-form col-12 mt-2"
          // style={{ width: '100%' }}
          form={forgetPasswordForm}
          onFinish={onSubmitResetPassword}
        >
          <Input.Group compact style={{ display: 'flex' }}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: t('please_input_email'), type: 'email' }]}
            >
              <Input
                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder={t('email')}
                onChange={(event) => setInputEmail(event.target.value)}
              />
            </Form.Item>
            <Button
              type="primary"
              className="login-form-button"
              onClick={() => getOTP()}
            >
              {t("get_otp")}
            </Button>
          </Input.Group>
          <Form.Item
            name="otp_code"
            rules={[{ required: true, message: t('please_input_otp_code') }]}
          >
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={t('otp_code')}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: t('please_input_password') }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={t('pwd')}
            />
          </Form.Item>
          <Form.Item
            name="repeat_password"
            rules={[{ required: true, message: t('please_input_repeat_password') }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={t('repeat_pwd')}
            />
          </Form.Item>
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t("submit")}
          </Button>
        </Form>
      </div>
    )
  };

  const validateEmail = function (email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const getOTP = async () => {
    if (!validateEmail(inputEmail)) {
      return message.error('invalid email');
    }
    let resp = await Service.call('get', `/api/admin/forget_password/opt?email=${inputEmail}`);
    if (resp.status !== 1) {
      return notification.error({
        message: t('otp_sent_fail'),
        description: resp.errorMessage
      })
    }
    return notification.success({
      message: t('otp_sent_success')
    })
  }

  return (
    <Layout>
      <Layout.Content style={{ padding: '50px', backgroundColor: 'white' }}>
        <Row type="flex" justify="center">
          <Col xs={20} sm={16} md={14} lg={10} xl={8} className="pt-5 pb-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <div>
                <img alt="" src={logo} style={{ width: '100%', maxWidth: '300px', marginBottom: '30px' }} />
              </div>
              <h2>Smart Access</h2>
              <h3>{t("admin_panel")}</h3>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="center" style={{ marginTop: '15px' }}>
          <Col
            xs={22}
            sm={16}
            md={14}
            lg={10}
            xl={8}
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            {
              displayLogin()
            }
          </Col>
        </Row>
        <Row type="flex" justify="center" style={{ marginTop: '15px' }}>
          <Col
            xs={22}
            sm={16}
            md={14}
            lg={10}
            xl={8}
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <Button
              style={{ width: "100%", marginTop: '10px' }}
              type="link"
              className="login-form-button"
              onClick={() => setModalVisible(true)}
            >
              {t("forget_password")}
            </Button>
          </Col>
        </Row>
        <Modal
          visible={modalVisible}
          destroyOnClose
          footer={false}
          width={800}
          onCancel={() => setModalVisible(false)}
        >
          {displayForgetPw()}
        </Modal>
        {/* <Row type="flex" justify="center">
            <Col xs={20} sm={16} md={14} lg={10} xl={8} className="pt-5 pb-3" style={{alignItems: 'center', justifyContent: 'center'}}>
              <Link to="/company/login">Company admin login</Link>
            </Col>
          </Row> */}
      </Layout.Content>
    </Layout>
  )
}

export default Login;

// function mapStateToProps(state) {
//   return {
//     app: state.app
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(ActionCreators, dispatch);
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Login);
