import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Form, Input, Button, message, Modal, Table
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  FileImageOutlined,
  PlusOutlined,
  StopOutlined,
} from '@ant-design/icons';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NotificationForm from '../../components/NotificationForm';

const selectedKey = 'notification';
const openKey = 'notification';

const Notification = (props) => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState();
  const [dataList, setDataList] = useState([]);
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_notification_w')
  );

  useEffect(() => {
    getData();
  }, [modalVisible])

  const getData = async () => {
    let result = await Service.call('get', '/api/notification/global')
    if (result.status !== 1) {
      return message.error(result.errorMessage)
    }
    let { data } = result
    setDataList(data)
    // message.success(t('success'))
  }
  const getColumns = () => {
    const columns = [
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
      {
        title: t('title'),
        dataIndex: 'title',
      },
      {
        title: t('content'),
        dataIndex: 'content',
      },
      {
        title: t('title_zh_hant'),
        dataIndex: 'title_zh_hant',
      },
      {
        title: t('content_zh_hant'),
        dataIndex: 'content_zh_hant',
      },
      {
        title: t('status'),
        dataIndex: 'status',
        render: (value) => displayStatus(value)
      },
    ]

    return columns;
  }

  const onExportTable = () => {
    // Main.exportTableToCSV(filterDataList, 'export');
    Main.exportTableToCSV(dataList, 'export');
  };

  const displayStatus = (value) => {
    switch (value) {
      case 0:
        return t("pending");
      case 1:
        return t("sent")
      case -1:
        return t("fail");
      case -2:
        return t("cancel")
      default:
        return t("error")
    }
  };

  return (
    <NavAndSideFrame {...props} title={t("notification")} selectedKey={selectedKey} openKey={openKey} onExportTable={onExportTable}>
      {
        disableEdit ? '' : (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setModalVisible(true);
            }}
            style={{ marginBottom: 10 }}
          >
            {t('create')}
          </Button>
        )
      }
      <Table
        rowKey={(index) => index}
        scroll={{ x: true }}
        columns={getColumns()}
        dataSource={dataList}
        bordered
        size="small"
      />
      <Modal
        title={t("notification")}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: "95%" }}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <NotificationForm setModalVisible={setModalVisible} />
      </Modal>

    </NavAndSideFrame>
  )
}

export default Notification;
