import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { Table, DatePicker, Spin } from "antd";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import ExportToPDFButton from "../ExportToPDFButton";

const TokenPurchaseData = () => {
  const id = "token_purchase_data";
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [tokenPurchaseData, setTokenPurchaseData] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').unix());
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    if (!_.isNull(startDate) && !_.isNull(endDate)) {
      getTokenPurchaseData(startDate, endDate);
    }
  }, [startDate, endDate]);

  const getTokenPurchaseData = async (start_date, end_date) => {
    setLoading(true);
    try {
      let url = `/api/dashboard/token_purchase_data?start_date=${start_date}&end_date=${end_date}`;
      let result = await Service.call('get', url);
      setTokenPurchaseData(result);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  const onCalendarChange = (dateArray) => {
    if (_.isNull(dateArray)) {
      return;
    }

    let start_date = dateArray[0] ? dateArray[0].unix() : null;
    let end_date = dateArray[1] ? dateArray[1].unix() : null;

    setStartDate(start_date);
    setEndDate(end_date);
  }

  const displayPrice = (value) => {
    return `$${value / 100}`;
  }

  const displayDiscount = (value) => {
    return `$${value / 100}`;
  }

  const renderTable = () => {
    const columns = [
      {
        title: t("total_original_price"),
        dataIndex: "original_price",
        render: (value) => displayPrice(value)
      },
      {
        title: t("total_final_price"),
        dataIndex: "final_price",
        render: (value) => displayPrice(value)
      },
      {
        title: t("total_discount"),
        dataIndex: "discount",
        render: (value) => displayDiscount(value)
      },
      {
        title: t("total_token_amount"),
        dataIndex: "token_amount",
      }
    ];

    return (
      <Table
        dataSource={tokenPurchaseData}
        columns={columns}
        bordered
        pagination={false}
      />
    );
  };

  return (
    <Spin id={id} spinning={loading}>
      <ExportToPDFButton targetID={id} />
      <h2>{t("token_purchase_data")}</h2>
      <RangePicker
        onCalendarChange={onCalendarChange}
        defaultValue={[moment(startDate, 'X'), moment(endDate, 'X')]}
        style={{marginBottom: "20px"}}
      />
      {renderTable()}
    </Spin>
  );
};

export default TokenPurchaseData;
