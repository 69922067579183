import React, { useState, useEffect } from 'react';
import {
  Spin, Button, Row, Col, Table, Tag, Tooltip, Input, Typography
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import moment from 'moment';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import PostsFormModal from '../../components/PostsFormModal';

const { Paragraph } = Typography;

// const title = "Post List";
const selectedKey = "posts_list";
const openKey = "app_config";
const tableIDName = "post_id";
const filterableFields = [
  // `post_status`,
  `post_date`,
  `post_title`,
  `post_excerpt`,
  `keywords`,
  `admin_id`,
];

const PostsList = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  // const [sortingList, setSortingList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_posts_list_w')
  );


  useEffect(() => {
    setLoading(true);
    getAllData();
  }, [modalVisible]);

  const getAllData = async () => {
    let endpoint = `/api/posts/list`;
    let resp = await Service.call('GET', endpoint);

    try {
      if (!resp || resp.status !== 1) {
        return;
      }
      const { data, translationList } = resp
      resp = Main.mergeTranslationList(Main.TABLE_CONFIG.post, data, translationList)
      let adminArr = await Service.call('GET', '/api/admin/list');
      console.log('adminArr>>', adminArr)
      let adminArrMap = _.keyBy(adminArr, 'company_user_id');
      _.each(resp, (post, i) => {
        resp[i].admin_name = adminArrMap[post.admin_id].nickname || "";
      })
    } catch (error) {
      // console.log('error>>>', error);
    }
    // console.log('test>>>', resp)

    setDataList(resp);
    setFilterDataList(resp);
    // setSortingList(resp);
    // let lastRecord = _.last(resp);
    setLoading(false);
  }

  // const moveUp = async(record) => {
  //   let target_sorting = record.sorting;
  //   let newSortingList = sortingList;
  //   _.each(sortingList, (obj) => {
  //     if (obj.sorting === target_sorting - 1) {
  //       obj.sorting += 1;
  //     } else if (obj.sorting === target_sorting) {
  //       obj.sorting -= 1;
  //     }
  //   })
  //   setSortingList(_.orderBy(newSortingList, ['sorting'], ['asc']));
  // }

  // const moveDown = async(record) => {
  //   let target_sorting = record.sorting;
  //   let newSortingList = sortingList;
  //   _.each(sortingList, (obj) => {
  //     if (obj.sorting === target_sorting) {
  //       obj.sorting += 1;
  //     } else if (obj.sorting === target_sorting + 1) {
  //       obj.sorting -= 1;
  //     }
  //   })
  //   setSortingList(_.orderBy(newSortingList, ['sorting'], ['asc']));
  // }

  // const updateSorting = () => {
  //   console.log(dataList);
  //   console.log(sortingList);
  //   let endpoint = '/api/posts/sorting';
  //   setLoading(true);
  //   _.map(dataList, async (data, i) => {
  //     if (data.post_id !== sortingList[i].post_id) {
  //       await Service.call('PUT', endpoint, {
  //         post_id: sortingList[i].post_id,
  //         sorting: sortingList[i].sorting,
  //       })
  //     }
  //   });

  //   getAllData();
  // }

  const setTableHeader = () => {
    let columns = [
      {
        title: t('active_status'),
        dataIndex: 'post_status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.post_status.localeCompare(b.post_status)
      },
      // {
      //   title: t('sorting'),
      //   dataIndex: 'sorting',
      //   render: (value, record) => {
      //     return (
      //       <Row>
      //         <Space>
      //           <Col>
      //             <Button
      //               size="small"
      //               onClick={() => moveUp(record)}
      //               disabled={record.sorting === 1}
      //             >
      //               <UpOutlined />
      //             </Button>
      //           </Col>
      //           <Col>
      //             {value}
      //           </Col>
      //           <Col>
      //             <Button
      //               size="small"
      //               onClick={() => moveDown(record)}
      //               disabled={record.sorting === sortingList.length}
      //             >
      //               <DownOutlined />
      //             </Button>
      //           </Col>
      //         </Space>
      //       </Row>
      //     );
      //   },
      //   sorter: (a, b) => a.sorting - b.sorting
      // },
      {
        title: t('post_date'),
        dataIndex: 'post_date',
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.post_date - b.post_date
      },
      {
        title: t('hide_post_date'),
        dataIndex: 'hide_post_date',
        render: (value) => displayHidePostDate(value),
        sorter: (a, b) => a.hide_post_date - b.hide_post_date
      },
      {
        title: t('hide_post_time'),
        dataIndex: 'hide_post_time',
        render: (value) => displayHidePostDate(value),
        sorter: (a, b) => a.hide_post_time - b.hide_post_time
      },
      {
        title: t('post_title'),
        dataIndex: 'post_title',
        render: (value) => {
          return <Paragraph ellipsis={{ rows: 2, expandable: false }} style={{ maxWidth: 200, marginBottom: 0 }}>{value}</Paragraph>
        }
        // sorter: (a, b) => a.post_title - b.post_title
      },
      {
        title: t('post_excerpt'),
        dataIndex: 'post_excerpt',
        render: (value) => {
          return <Paragraph ellipsis={{ rows: 2, expandable: false }} style={{ maxWidth: 200, marginBottom: 0 }}>{value}</Paragraph>
        }
        // sorter: (a, b) => a.post_excerpt - b.post_excerpt
      },
      // {
      //   title: t('post_content'),
      //   dataIndex: 'post_content',
      //   sorter: (a, b) => a.post_content - b.post_content
      // },
      {
        title: t('keywords'),
        dataIndex: 'keywords',
        // sorter: (a, b) => a.keywords - b.keywords
      },
      {
        title: t('on_landing'),
        dataIndex: 'on_landing',
        render: (value) => displayBoolean(value),
        sorter: (a, b) => a.on_landing - b.on_landing
      },
      {
        title: t('admin_user'),
        dataIndex: 'admin_name',
        sorter: (a, b) => a.admin_name.localeCompare(b.admin_name)
      },
      {
        title: t('ctime'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => displayMoment(value),
      },
      {
        title: t('utime'),
        dataIndex: 'utime',
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => displayMoment(value),
      },
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setSelectedRecord(record)
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              <Tooltip title={record.post_status === `public` ? t('post_hide') : t('post_publish')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={record.post_status === "public" ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={async () => {
                    if (isChangingIsActive) return;
                    setChangingIsActive(true);
                    let post_status = record.post_status === "public" ? "hide" : "public"
                    await Service.call('PATCH', '/api/posts/status',
                      {
                        post_id: record.post_id,
                        post_status
                      });
                    getAllData();
                    setChangingIsActive(false);
                  }}
                />
              </Tooltip>
            </span>
          )
        },
      });
    }
    return columns;
  }

  const displayBoolean = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let isTrue = _.toInteger(value);
    switch (isTrue) {
      case 1:
        displayStr = t("yes");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("no");
        tagColor = 'red';
        break;
      default:
        displayStr = t("fail");
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm:ss') => {
    if (unixTime !== 0) {
      return moment.unix(unixTime).format(outputFormat);
    }
    return '-';
  }

  const displayHidePostDate = (value) => {
    let displayStr = '';
    let tagColor = '';
    switch (value) {
      case 0:
        displayStr = t("visible");
        tagColor = 'green';
        break;
      case 1:
        displayStr = t("hide");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toString(value);
    switch (statusValue) {
      case `public`:
        displayStr = t("public");
        tagColor = 'green';
        break;
      case `hide`:
        displayStr = t("hide");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      }
      const fuse = new Fuse(filterList, options)
      const result = fuse.search(filterObj);
      console.log(result);
      filterList = _.map(result, 'item');
      console.log(filterList);
      filterList = _.orderBy(filterList, ['user_id'], ['desc']);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      filterList = _.orderBy(filterList, 'user_id');
      setFilterDataList(filterList);
    }
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <NavAndSideFrame {...props} title={t('posts_list')} selectedKey={selectedKey} openKey={openKey} onExportTable={onExportTable}>
      <Row>
        {
          disableEdit ? '' : (
            <Col>
              <Button
                icon={<PlusOutlined />}
                style={{ marginBottom: 10 }}
                type="primary"
                onClick={() => {
                  setSelectedRecord({ post_id: 0, sorting: 1 });
                  setModalVisible(true);
                }}
              >
                {t('create')}
              </Button>
            </Col>
          )
        }
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
      </Row>
      {/* <Divider /> */}
      <Spin spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          size="middle"
        />
        <PostsFormModal
          modalVisible={modalVisible}
          selectedRecord={selectedRecord}
          setModalVisible={setModalVisible}
        />
      </Spin>
      {/* <Row justify="end">
        <Button
          type="primary"
          // onClick={updateSorting}
        >
          {t('submit')}
        </Button>
      </Row> */}
    </NavAndSideFrame>
  );
}

export default PostsList;
