import React, { useState, useEffect } from 'react';
import {
  Spin, Button, Layout, Table, Tooltip
} from 'antd';
import {
  EditOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from "../core/Service";
import * as Main from '../core/Main';
import SystemConfigModal from './SystemConfigModal';

const SystemConfigTable = (props) => {
  const { t } = useTranslation()
  const tableIDName = "sys_config_id";
  const { disableEdit } = props;

  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  const getAllData = async () => {
    let configData = await Service.call('GET', '/api/sys_config/list/all');
    setDataList(configData);
    setFilterDataList(configData);
    setLoading(false);
  }
  useEffect(() => {
    setLoading(true)
    getAllData();
  }, [modalVisible]);

  const renderTitle = (value, record) => {
    if (_.isUndefined(value) || value === '') {
      return record.config_key;
    }
    return value;
  }

  const renderValue = (value, record) => {
    let { config_key } = record;

    if (config_key === 'booking_limitation_type_parking'
    || config_key === 'booking_limitation_type_room') {
      switch (value) {
        case '0': return `0 (${t('by_all_time')})`;
        case '1': return `1 (${t('by_week')})`;
        case '2': return `2 (${t('by_month')})`;
        default:
          return `2 (${t('unknown')})`;
      }
    }

    if (_.includes([
      'order_cancel_time',
      'cancel_booking_return_token_time',
      'booking_interval',
      'otp_expiry_interval'
    ], config_key)) {
      return `${value}${t('seconds')}`;
    }

    return value;
  }

  const setTableHeader = () => {
    let columns = [
      {
        title: t('title'),
        dataIndex: 'title',
        key: 'title',
        render: (value, record) => renderTitle(value, record),
        sorter: (a, b) => {
          const aTitle = a.title === '' ? a.config_key : a.title;
          const bTitle = b.title === '' ? b.config_key : b.title;
          return aTitle.localeCompare(bTitle);
        },
        defaultSortOrder: 'ascend',
      },
      {
        title: t('value'),
        dataIndex: 'config_value',
        key: 'config_value',
        render: (value, record) => renderValue(value, record),
      },
      {
        title: t('remark'),
        dataIndex: 'remark',
        key: 'remark',
      },
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: 'sys_config_id',
        key: 'sys_config_id',
        render: (value, record, index) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    console.log('record >>>>>>', record)
                    setSelectedRecord(record);
                    setModalVisible(true)
                  }}
                />
              </Tooltip>
              {/* <Tooltip title={t('delete')}>
                <Button
                  // hidden={createButtonHidden}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    console.log(record)
                    // onDelete(record.app_meta_id, record)
                  }}
                />
              </Tooltip> */}
            </span>
          )
        }
      });
    }
    return columns;
  }

  return (
    <Spin spinning={loading}>
      {/* <Row>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginBottom: 10 }}
            onClick={() => {
              setModalVisible(true)
              setSelectedRecord({item_holiday_id: 0})
            }}
          >
            {t('create')}
          </Button>
        </Col>
        <Col style={{marginTop: 5, marginLeft: 10}}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{marginLeft: 20}}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
      </Row> */}
      <Layout>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          bordered
          size="small"
          pagination={{pageSize: 100}}
          // loading={loading}
        />
        <SystemConfigModal
          selectedRecord={selectedRecord}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      </Layout>
    </Spin>
  )
}
export default SystemConfigTable;
