import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button, Form, Input, Checkbox, message
} from "antd";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import { tailLayout } from "../ModalLayout";
import PermissionSelect from "./PermissionSelect";

const CompanyAdminGroupForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dataObj } = props;

  const [initialFormValues, setInitialFormValues] = useState({});
  const permissionList = {
    dashboard: {pKey: 'ui_dashboard', hideWrite: true},

    user: {pKey: 'ui_user'},
    approve: {pKey: 'ui_user_approve'},

    order_list: {pKey: 'ui_order_list', hideWrite: true},
    common_area_list: {pKey: 'ui_common_area_list', hideWrite: true},
    parking_list: {pKey: 'ui_parking_list', hideWrite: true},
    transaction_log: {pKey: 'ui_transaction_log', hideWrite: true},

    token_log: {pKey: 'ui_token_log', hideWrite: true},
    token_package: {pKey: 'ui_token_package'},

    expired_locker_booking: {pKey: 'ui_expired_locker_booking'},
    book_now: {pKey: 'ui_book_now', readAlsoWrite: true},
    booking_calendar: {pKey: 'ui_booking_list', hideWrite: true},
    booking_list: {pKey: 'ui_booking_record_list', hideWrite: true},
    item_list: {pKey: 'ui_item_list'},
    item_config: {pKey: 'ui_item_config'},

    discount_info: {pKey: 'ui_discount_info'},
    discount_info_room: {pKey: 'ui_discount_room'},

    notification: {pKey: 'ui_notification'},

    posts_list: {pKey: 'ui_posts_list'},
    faq: {pKey: 'ui_faq'},
    app_config: {pKey: 'ui_meta'},

    sys_config: {pKey: 'ui_sys_config'},

    admin: {pKey: 'ui_admin'},
    admin_group: {pKey: 'ui_admin_group'},
  }
  const [uiPermissionObj, setUiPermissionObj] = useState({
    // user: {options: ['user_r', 'user_w'], checkedList: []},
  });
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    setAllChecked(_.includes(dataObj.uiPermissions, 'ui_all'));

    // Init Permission Obj
    let mPermissionObj = {};
    _.each(permissionList, (p, key) => {
      let options = [];
      if (!p.hideRead) {
        options.push({label: `${t(p.pKey + '_r')}`, value: `${p.pKey}_r`, disabled: false});
      }
      if (!p.hideWrite) {
        options.push({label: `${t(p.pKey + '_w')}`, value: `${p.pKey}_w`, disabled: false});
      }

      let checkedList = [];
      _.each(dataObj.uiPermissions, (pp) => {
        if (pp.substring(0, pp.length - 2) === p.pKey && !_.includes(checkedList, pp)) {
          checkedList.push(pp);
        }
      });

      mPermissionObj[key] = {
        options,
        checkedList,
      }
    });
    setUiPermissionObj(mPermissionObj);
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [initialFormValues]);

  useEffect(() => {
    if (dataObj) {
      setInitialFormValues(dataObj);
    }
  }, [dataObj]);

  const onFinish = async (postObj) => {
    let uiPermissions = '';
    if (allChecked) {
      uiPermissions = 'ui_all';
    } else {
      let uiPermissionsArr = [];
      _.each(uiPermissionObj, (p) => {
        uiPermissionsArr = [...uiPermissionsArr, ...p.checkedList];
      });
      uiPermissions = _.join(uiPermissionsArr, ",");
    }

    let company_user_group_id = dataObj.company_user_group_id > 0
      ? dataObj.company_user_group_id : null;

    let myPostObj = {
      ...postObj,
      company_user_group_id,
      uiPermissions,
    };
    myPostObj = _.pickBy(myPostObj, (value) => {
      return !_.isNull(value);
    });

    // Put
    if (!_.isUndefined(myPostObj.company_user_group_id)) {
      let data = await Service.call("put", "/api/company_user_group", myPostObj);
      if (data.errorMessage) {
        message.error(data.errorMessage);
        return props.openModal(true);
      }
      message.success(t("common.success"));
      window.location.reload();
      return props.openModal(false);
    }

    // POST
    let data = await Service.call("post", "/api/company_user_group", myPostObj);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      return props.openModal(true);
    }
    message.success("success");
    return props.openModal(false);
  };

  const onPermissionChange = (key, checkedList) => {
    let updatedPermissionObj = {...uiPermissionObj};
    let mCheckedList = [...checkedList];

    _.each(checkedList, (checked) => {
      if (permissionList[key].readAlsoWrite && checked.substring(checked.length - 2, checked.length) === '_r') {
        let r = checked.substring(0, checked.length - 2) + '_w';
        if (!_.includes(mCheckedList, r)) {
          mCheckedList.push(r);
        }
      }
    });
    updatedPermissionObj[key].checkedList = _.uniq(mCheckedList);
    console.log(key, mCheckedList);

    setUiPermissionObj(updatedPermissionObj);
  }

  const onAllChange = (e) => {
    setAllChecked(e.target.checked);
  }

  return (
    <Form
      // {...formItemLayout}
      labelCol={{
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 }
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 }
      }}
      form={form}
      name="time_related_controls"
      onFinish={onFinish}
      initialValues={initialFormValues}
    >
      <Form.Item label={t("title")} name="title" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t("permissions")} name="uiPermissions">
        <Checkbox checked={allChecked} style={{padding: 10}} onChange={onAllChange}>{t('ui_all')}</Checkbox>
        <PermissionSelect disabled={allChecked} permissionObj={uiPermissionObj} onChange={onPermissionChange} />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          {t("submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyAdminGroupForm;
