import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  LabelList,
  XAxis,
  YAxis,
} from "recharts";
import { DatePicker, Spin, Empty } from "antd";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import ExportToPDFButton from "../ExportToPDFButton";

const RoomBookingCountRankChart = () => {
  const id = "room_booking_count_rank_chart";
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [countRankData, setCountRankData] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(1, "month").unix());
  const [endDate, setEndDate] = useState(moment().unix());

  useEffect(() => {
    if (!_.isNull(startDate) && !_.isNull(endDate)) {
      getCountRankData(startDate, endDate);
    }
  }, [startDate, endDate]);

  const getCountRankData = async (start_date, end_date) => {
    setLoading(true);
    try {
      let url = `/api/dashboard/room_booking_count_rank?start_date=${start_date}&end_date=${end_date}`;
      let result = await Service.call("get", url);
      setCountRankData(result);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onCalendarChange = (dateArray) => {
    if (_.isNull(dateArray)) {
      return;
    }

    let start_date = dateArray[0] ? dateArray[0].unix() : null;
    let end_date = dateArray[1] ? dateArray[1].unix() : null;

    setStartDate(start_date);
    setEndDate(end_date);
  };

  const renderChart = () => {
    if (_.isEmpty(countRankData)) {
      return (
        <div style={{padding: "10% 3%"}}>
          <Empty />
        </div>
      );
    }

    return (
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          layout="vertical"
          width={500}
          height={500}
          data={countRankData}
          margin={{
            top: 5,
            right: 80,
            left: 50,
            bottom: 5,
          }}
        >
          <YAxis type="category" dataKey="item_name" hide />
          <XAxis type="number" dataKey="count" />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#8884d8">
            <LabelList dataKey="item_name" position="right" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Spin id={id} spinning={loading}>
      <ExportToPDFButton targetID={id} />
      <h2>{t('room_booking_count_rank_chart')}</h2>
      <RangePicker
        onCalendarChange={onCalendarChange}
        defaultValue={[
          moment(startDate, "X"),
          moment(endDate, "X"),
        ]}
      />
      {renderChart()}
    </Spin>
  );
};

export default RoomBookingCountRankChart;
