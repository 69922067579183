import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
    Button, Col, Form, Input, Layout, Modal,
    notification, Radio, Row, Select, Tabs, Upload, message
} from 'antd';
import moment from 'moment';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import { formItemLayout, tailLayout } from '../components/ModalLayout';
import { useSelector } from 'react-redux';

const { Option } = Select;

const AdminPassword = (props) => {
    const [form] = Form.useForm();
    const [roleList, setRoleList] = useState([]);
    const [companyList, setCompanyList] = useState([]);

    const admin = useSelector(state => state.app.admin);

    useEffect(() => {
        form.resetFields();
    }, [props.dataObj]);

    const onFinish = async (postObj) => {
        let url = `/api/admin_account/pw`;
        // postObj.password = postObj.passwords;
        if (postObj.password != postObj.re_password) {
            notification.error({
                message: 'Error',
                description:
                  'Password not same, please input again',
                },
              );
            return;
        }
        postObj.admin_id = admin.admin_id;
        postObj.email = admin.email;
        let data = await Service.call('patch', url, postObj);
        if(data.errorMessage) {
            message.error(data.errorMessage);
            return props.openModal(true);
        }
        message.success("Success");
        return props.openModal(false);
    };


    return (
        <Form
            {...formItemLayout}
            form={form}
            name="time_related_controls"
            onFinish={onFinish}
            initialValues={admin}
        >
            <Form.Item
                label="Username"
                name="email"
                rules={[{ required: true, message: 'Please input username.' }]}
            >
                <Input disabled={admin.email} />
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input password.' }]}
            >
                <Input type='password'/>
            </Form.Item>
            <Form.Item
                label="Retype password"
                name="re_password"
                rules={[{ required: true, message: 'Please reinput password' }]}
            >
                <Input type='password'/>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Submit
        </Button>
            </Form.Item>
        </Form>
    )
}

export default AdminPassword;
