import React, { Component, useState, useEffect } from 'react';
import {
  Layout, Menu, Avatar, Modal, Button
} from 'antd';
import 'antd/dist/antd.css';
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../redux/actions';
import { Redirect } from "react-router-dom";
import logo from '../assets/Logo_White.png';
import _ from 'lodash';
import AdminPassword from './AdminPassword';
import i18n from '../i18n';

const { Header } = Layout;

const Navbar = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [redirectHome, setRedirectHome] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [lang, setLang] = useState();

  const company_admin = useSelector(state => state.app.company_admin);
  const admin = useSelector(state => state.app.admin);

  const directHome = () => {
    setRedirectHome(true);
  }

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <Header className="header" style={{ background: 'linear-gradient(to right,#006ABF 0,#054f8a 100%)' }}>
      <Modal
        title="Edit"
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width={'90%'}
        onCancel={() => { setModalVisible(false) }}
      >
        <AdminPassword
          openModal={
            (value) => {
                setModalVisible(value);
            }
          }
        />
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{color: "white", fontSize: "22px", fontWeight: 600}}>SFEGROW | Admin Management System</span>
      {
        // <div style={{ width: 180 }}>
        //   <img onClick={() => { directHome(); }} style={{ width: 180, cursor: 'pointer' }} src={logo} alt="" />
        // </div>
      }
        <div>
          <Button
            type="text"
            onClick={() => setLang('en')}
            style={{ padding: 8, color: 'white' }}
          >
            Eng
          </Button>
          <Button
            type="text"
            onClick={() => setLang('zh_hant')}
            style={{ padding: 8, color: 'white' }}
          >
            繁中
          </Button>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ lineHeight: '64px', float: 'right', background: 'rgba(255, 0, 0, 0)' }}
          >
            {admin.admin_id !=null?<Menu.Item
              onClick={() => {
                setModalVisible(true);
              }}
            >
              Edit Password
            </Menu.Item>: ''}
            <Menu.Item>
              <Avatar style={{ margin: '0 10px' }}>{admin.nickname?.charAt(0)}</Avatar>
              {admin.admin_id != null ? admin.nickname : company_admin.nickname}
            </Menu.Item>
          </Menu>
        </div>
      </div>
      {
        redirectHome === true
          ? _.isEmpty(admin) ? <Redirect to="/company/home" /> : <Redirect to="/admin/home" />
          : null
      }
    </Header>
  )

}

export default Navbar;
