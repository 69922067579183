import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import {
  DatePicker,
  Spin,
  Empty,
} from 'antd';
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import debug from "debug";

const IncomeByItemLineChart = (props) => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').unix());
  const [endDate, setEndDate] = useState(moment().unix());
  const [incomeByItemData, setIncomeByItemData] = useState([]);
  const [incomeByItemModel, setIncomeByItemModel] = useState([]);
  const {itemName, colorTemplate} = props

  useEffect(() => {
    if (!_.isNull(startDate) && !_.isNull(endDate)) {
      getIncomeDataByDateByItem(startDate, endDate);
    }
  }, [startDate, endDate]);

  const getIncomeDataByDateByItem = async (start_date, end_date) => {
    console.log(itemName)
    console.log(colorTemplate)
    let incomeData = []
    let incomeModel = [];
    setLoading(true);
    try {
      let url = '';
      if (itemName === 'Summary') {
        url = `/api/dashboard/income_by_date?start_date=${start_date}&end_date=${end_date}`;
      } else {
        url = `/api/dashboard/income_by_item/${itemName}?start_date=${start_date}&end_date=${end_date}`;
      }
      let resp = await Service.call('get', url);
      incomeData = _.map(resp.data.list, (object) => {
        let cloneObject = _.cloneDeep(object);
        _.mapKeys(cloneObject, (values, key) => {
          if (key !== 'date') {
            cloneObject[key] /= 100; // need to /100
          }
        })
        return cloneObject;
      });
      let colorIndex = -1;
      incomeModel = _.map(resp.data.model, (name) => {
        colorIndex += 1;
        return {
          name,
          color: colorTemplate[colorIndex]
        }
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIncomeByItemModel(incomeModel)
      setIncomeByItemData(incomeData);
    }
  };

  const onCalendarChange = (dateArray) => {
    if (_.isNull(dateArray)) {
      return;
    }

    let start_date = dateArray[0] ? dateArray[0].unix() : null;
    let end_date = dateArray[1] ? dateArray[1].unix() : null;

    setStartDate(start_date);
    setEndDate(end_date);
  }

  return (
    <Spin spinning={loading}>
      <RangePicker
        onCalendarChange={onCalendarChange}
        defaultValue={[moment(startDate, 'X'), moment(endDate, 'X')]}
      />
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={500}
          height={500}
          data={incomeByItemData}
          margin={{
            top: 5,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          {
            _.map(incomeByItemModel, (eachModel) => {
              return (<Line type="monotone" dataKey={eachModel.name} stroke={eachModel.color} />)
            })
          }
        </LineChart>
      </ResponsiveContainer>
    </Spin>
  );
};

export default IncomeByItemLineChart;
