import React, { useState, useEffect } from "react";
import _, { set } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  Spin,
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "querystring";
import * as Main from "../core/Main";
import * as Service from '../core/Service';

const FAQFormModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible
  } = props;

  const [initialFormValues, setInitialFormValues] = useState({});
  const [FAQForm] = Form.useForm();

  useEffect(() => {
    setInitialFormValues(selectedRecord);
  }, [modalVisible]);

  useEffect(() => {
    FAQForm.resetFields();
  }, [initialFormValues])

  const onFinish = async (formData) => {
    let endpoint = '/api/faq';
    let faq_id = selectedRecord.faq_id;
    console.log(formData);

    let params = formData;
    if (faq_id > 0) params.faq_id = faq_id;

    let resp = await Service.call(faq_id > 0 ? 'PUT' : 'POST', endpoint, params);
    console.log(resp);
    if (!resp || resp.status < 1) {
      message.error(t('fail'));
      return;
    }
    message.success(t('Success'));
    setModalVisible(false);
  };

  return (
    <Modal
      title={t("faq_form")}
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={FAQForm}
        name={t("faq_form")}
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={onFinish}
      >
        <Form.Item
          label={t("title")}
          name="faq_title"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("title_zh_hant")}
          name="faq_title_zh_hant"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("content")}
          name="faq_content"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={t("content_zh_hant")}
          name="faq_content_zh_hant"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={t("sorting")}
          name="sorting"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <InputNumber
            min={1}
            formatter={(value) => _.floor(value)}
            parser={(value) => _.floor(value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FAQFormModal;
