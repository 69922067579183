import React, { Component, useState, useEffect } from 'react';
import {
  Row, Col, Input, Spin, Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, notification
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Service from "../../core/Service";
import ItemHolidayModal from './ItemHolidayModal';
import { setRefreshItemTime } from '../../redux/actions/common';
import * as Main from '../../core/Main';

const filterableFields = [
  `description`,
  `remarks`,
];

const ItemHolidayTable = (props) => {
  const { t } = useTranslation()
  // const involvedModelName = "company";
  const title = "Item Holiday";
  // const selectedKey = 3;
  const tableIDName = "item_holiday_id";
  const app = useSelector((state) => state.app);
  // let {
  // dataObj, setModalVisible, setSelectedRecord, getData
  // } = props

  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const { disableEdit } = props;
  const dispatch = useDispatch();
  const refreshItemTime = useSelector((state) => state.app.refreshItemTime);
  const [refreshModalVisible, setRefreshModalVisible] = useState(false)
  const getAllData = async () => {
    let holidayData = await Service.call('GET', '/api/item/holiday/list');
    // console.log('holidayData >>>> ', holidayData);
    let list = _.orderBy(holidayData, `utime`, `desc`);
    setDataList(list);
    setFilterDataList(list);
    setLoading(false);
  }
  useEffect(() => {
    setLoading(true)
    getAllData()
    // setDataList(dataObj)
  }, [modalVisible, isChangingIsActive]);

  const onClickRefresh = async () => {
    dispatch(setRefreshItemTime(moment().add(1, 'minute').unix()))
    let submit = await Service.call('patch', '/api/item/is_active/all')
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  };

  const setTableHeader = () => {
    const columns = [
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('description'),
        dataIndex: 'description',
        sorter: (a, b) => a.description.localeCompare(b.description)

      },
      {
        title: t('remarks'),
        dataIndex: 'remarks',
        sorter: (a, b) => a.remarks.localeCompare(b.remarks)

      },
      {
        title: t('start_date'),
        dataIndex: 'start_time',
        sorter: (a, b) => a.start_time - b.start_time,
        render: (value) => {
          return moment.unix(value).format('YYYY-MM-DD')
        },
      },
      {
        title: t('end_date'),
        dataIndex: 'end_time',
        sorter: (a, b) => a.end_time - b.end_time,
        render: (value) => {
          return moment.unix(value).format('YYYY-MM-DD')
        },
      },
      {
        title: t('utime'),
        dataIndex: 'utime',
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
      {
        title: t('ctime'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record)
                  }}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={async () => {
                    if (isChangingIsActive) return;
                    setChangingIsActive(true);
                    let is_active = record.is_active ? 0 : 1
                    await Service.call('PATCH', '/api/item/holiday/status',
                      {
                        item_holiday_id: record.item_holiday_id,
                        is_active
                      });
                    // getAllData();
                    setChangingIsActive(false);
                  }}
                />
              </Tooltip>
            </span>
          )
        }
      });
    }
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      }
      const fuse = new Fuse(filterList, options)
      const result = fuse.search(filterObj);
      // console.log(result);
      filterList = _.map(result, 'item');
      // console.log(filterList);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setFilterDataList(filterList);
    }
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <Spin spinning={loading}>
      <Row>
        {
          disableEdit ? '' : (
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 10 }}
                onClick={() => {
                  setModalVisible(true)
                  setSelectedRecord({ item_holiday_id: 0 })
                }}
              >
                {t('create')}
              </Button>
              <Button
                style={{ marginBottom: 10, marginLeft: 10 }}
                type="primary"
                onClick={() => setRefreshModalVisible(true)}
                loading={refreshItemTime > moment().unix()}
              >
                {t('refresh')}
              </Button>
            </Col>
          )
        }
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Button
            style={{ float: 'right' }}
            icon={<FileExcelOutlined />}
            onClick={onExportTable}
          >
            {t('export_to_excel_file')}
          </Button>
        </Col>
      </Row>
      <Layout>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          bordered
          size="small"
          pagination={{ pageSize: 100 }}
        // loading={loading}
        />
      </Layout>
      <ItemHolidayModal
        modalVisible={modalVisible}
        selectedRecord={selectedRecord}
        setModalVisible={setModalVisible}
        setRefreshModalVisible={setRefreshModalVisible}
      // getData={getAllData}
      />
      <Modal
        visible={refreshModalVisible}
        onCancel={() => setRefreshModalVisible(false)}
        onOk={() => {
          onClickRefresh()
          setRefreshModalVisible(false)
        }}
      >
        <p>{t('refresh_confirm')}</p>
        <p style={{fontWeight: 'bold'}}>{t('refresh_reminder')}</p>
      </Modal>
    </Spin>
  )
}
export default ItemHolidayTable;
