import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification, Calendar, Badge, Spin, Button, TreeSelect
} from 'antd';
import {
  LoadingOutlined,
  MinusSquareFilled
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as debugLib from 'debug';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../NavAndSideFrame';
import UserBookingListCalendar from './UserbookingListCalender';

// import BookingCalendar from '../../components/BookingCalendar';

const debug = debugLib('app:pages/UserbookingList');

const involvedModelName = "company";
const selectedKey = "booking_list";
const tableIDName = "item_category_id";

// let sampleText = "apple";

const UserbookingListCalendarView = (props) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const { Option } = Select;
  const [calendarEvents, setCalendarEvents] = useState([])
  const [itemCategory, setItemCategory] = useState([])
  const [itemCategoryFilter, setItemCategoryFilter] = useState(0)
  const [item, setItem] = useState([])
  const [itemFilter, setItemFilter] = useState([])
  const [viewStartDate, setViewStartDate] = useState(moment().startOf('week').unix())
  const [viewEndDate, setViewEndDate] = useState(moment().endOf('week').unix())

  // const [nextWeek, setnextWeek] = useState(0);
  // const [previousWeek, setpreviousWeek] = useState(0);

  // const [bookingItemStatus, setBookingItemStatus] = useState(0)
  const [bookingItemStatusFilter, setBookingItemStatusFilter] = useState(10); // default show booked room

  // For if user choose "All" catergory (First Select button)
  // if isFiltered == 0 , the item select button (second select button) disabled , else , abled

  useEffect(() => {
    setLoading(true)
    getItemCategoryList();
    getItemList();
    getBookingItem(); // Booking infor
    // setBookingItemStatusFilter(10)
    getViewDate();
    setLoading(false)
  }, []);

  useEffect(() => {
    getItemList()
  }, [itemCategoryFilter])

  useEffect(() => {
    getBookingItem();
    // getItemList();
  }, [itemCategoryFilter, itemFilter, bookingItemStatusFilter, viewStartDate, viewEndDate])

  const getItemCategoryList = async () => {
    let itemCategoryArr = [];
    let response = await Service.call('get', "/api/item/category/list/current");
    if (response) {
      itemCategoryArr = Main.mergeTranslationList(
        Main.TABLE_CONFIG.item_category,
        response.data,
        response.translationList
      )
    }
    let data = Main.fetchProductCategoryOption(itemCategoryArr);
    setItemCategory(data)
    // setItemCategory(itemCategoryArr)
  }

  const getItemList = async () => {
    let resp = await Service.call('get', "/api/item/list/current");
    if (!resp || resp.status !== 1) {
      return;
    }
    let { data, translationList } = resp;
    let itemArr = Main.mergeTranslationList(Main.TABLE_CONFIG.item_info, data, translationList)
    // Filter Items
    if (itemCategoryFilter !== 0) {
      itemArr = _.filter(itemArr, ['item_category_id', itemCategoryFilter]);
    }
    setItem(itemArr)
  }

  const handleItemCategoryFilterChange = (value) => {
    setItemFilter([])
    if (value === 0) {
      setItemCategoryFilter(0)
    }
    if (value > 0) {
      setItemCategoryFilter(value)
    }
    // console.log('categorychange', itemFilter)
  }

  const handleItemFilterChange = (value) => {
    if (value.length > 0) {
      setItemFilter(value)
    }
    // console.log('itemchange', itemFilter)
  }

  const handleStatusFilterChange = (value) => {
    setBookingItemStatusFilter(value)
  }

  const getViewDate = () => {
    setViewStartDate(moment().startOf('week').unix())
    setViewEndDate(moment().endOf('week').unix())
  }

  const selectDate = (prevOrNext, focusWeek) => {
    let nextWeek = moment(focusWeek).endOf('week').unix()
    let previousWeek = moment(focusWeek).startOf('week').unix()

    setViewStartDate(previousWeek)
    setViewEndDate(nextWeek)

    getBookingItem()
  }

  const getBookingItem = async () => {
    setLoading(true)
    let calendarData = []
    let orderedCalendarData = []

    try {
      // Get & format calander data
      let itemArr = await Service.call('get', "/api/item/list");
      // debug('itemArr>>>', itemArr)
      let itemArrMap = _.keyBy(itemArr, "item_id")
      let userArr = await Service.call('get', "/api/user/list");
      // debug('userArr>>>', userArr)
      let userArrMap = _.keyBy(userArr, "user_id");
      // let guestArr = await Service.call('get', "/api/user/guest/list");
      // let guestArrMap = _.keyBy(guestArr, "user_guest_id");

      let bookingItemArr = await Service.call('get', `/api/booking_item/list/bydate/${viewStartDate}/${viewEndDate}`)
      // debug('bookingItemArr>>>', bookingItemArr)

      calendarData = _.filter(bookingItemArr, 'status')

      // Mapping Data
      _.each(calendarData, (bookingItem, i) => {
        calendarData[i].item_name = itemArrMap[bookingItem.item_id].item_name
        calendarData[i].title = itemArrMap[bookingItem.item_id].item_name
        calendarData[i].item_feature_image = itemArrMap[bookingItem.item_id].item_feature_image
        if (_.indexOf(_.keys(userArrMap), bookingItem.user_id.toString()) !== -1) {
          // calendarData[i].title += " - " + userArrMap[bookingItem.user_id].first_name + ' ' + userArrMap[bookingItem.user_id].last_name
          calendarData[i].title += " - " + userArrMap[bookingItem.user_id].nickname;
        }
        // else if (_.indexOf(_.keys(guestArrMap), bookingItem.user_guest_id.toString()) !== -1) {
        //   calendarData[i].title += " - [GUEST] " + guestArrMap[bookingItem.user_guest_id].first_name + ' ' + guestArrMap[bookingItem.user_guest_id].last_name
        //   calendarData[i].first_name = guestArrMap[bookingItem.user_guest_id].first_name
        //   calendarData[i].last_name = guestArrMap[bookingItem.user_guest_id].last_name
        //   calendarData[i].nickname = guestArrMap[bookingItem.user_guest_id].nickname
        //   calendarData[i].email = guestArrMap[bookingItem.user_guest_id].email
        //   calendarData[i].mobile = guestArrMap[bookingItem.user_guest_id].mobile
        //   calendarData[i].remarks = guestArrMap[bookingItem.user_guest_id].remarks
        // }
        calendarData[i].item_category_id = itemArrMap[bookingItem.item_id].item_category_id
        calendarData[i].start = moment.unix(bookingItem.start_time).toDate()
        calendarData[i].end = moment.unix(bookingItem.end_time).toDate()
      })

      // Filter Items Category
      if (itemCategoryFilter !== 0) {
        calendarData = _.filter(calendarData, ['item_category_id', itemCategoryFilter]);
      }

      // Filter Items
      if (itemFilter.length > 0) {
        let calendarDataFiltered = []
        _.each(itemFilter, (itemFilterRc) => {
          calendarDataFiltered.push(_.filter(calendarData, ['item_id', _.toInteger(itemFilterRc)]))
          calendarDataFiltered = _.flatten(calendarDataFiltered)
        })
        calendarData = calendarDataFiltered
      }

      // Filter Status
      if (bookingItemStatusFilter !== 0) {
        calendarData = _.filter(calendarData, ['status', bookingItemStatusFilter]);
      }

      orderedCalendarData = _.orderBy(calendarData, ["item_id"], ["asc"])
    } catch (error) {
      // console.error('error >>> ', error);
    } finally {
      setCalendarEvents(orderedCalendarData)
    }
    setLoading(false)
    // console.log('orderedCalendarData>>>', orderedCalendarData)
  }

  return (
    <div>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <p>{t("category")}</p>
          <TreeSelect
            treeDefaultExpandAll
            style={{ width: "100%" }}
            treeData={itemCategory}
            placeholder={t('search_category')}
            onChange={(value) => handleItemCategoryFilterChange(value)}
          // onChange={(e) => console.log('value >>>>>>>>', e)}
          />
          {/* <Select
            defaultValue={0}
            showSearch
            style={{ width: 200 }}
            placeholder={t('search_category')}
            optionFilterProp="children"
            onChange={(value) => handleItemCategoryFilterChange(value)}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option key={0} value={0}>{t('all')}</Option>
            {
              _.map(itemCategory, (itemCategoryRc) => <Option key={itemCategoryRc.item_category_id} value={itemCategoryRc.item_category_id}>{itemCategoryRc.category_name}</Option>)
            }
          </Select> */}
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <p>{t("item")}</p>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder={t('search_item')}
            disabled={itemCategoryFilter === 0}
            value={itemFilter}
            // value={(isFiltered) ? SelectedItemList : []}
            onChange={(value) => handleItemFilterChange(value)}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {
              _.map(item, (itemRc) => <Option key={itemRc.item_id}>{itemRc.item_name}</Option>)
            }
          </Select>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <p>{t("status")}</p>
          <Select
            defaultValue={10}
            style={{ width: "100%" }}
            placeholder={t('search_status')}
            onChange={(value) => handleStatusFilterChange(value)}
          >
            {/* <Option value={0} key={0}>{t('all')}</Option> */}
            <Option value={0} key={0}>{t('all')}</Option>
            <Option value={10} key={10}>{t('booked')}</Option>
            <Option value={1} key={1}>{t('available')}</Option>
            <Option value={2} key={2}>{t('hold')}</Option>
            <Option value={9} key={9}>{t('expired')}</Option>

          </Select>
        </Col>
      </Row>
      <Divider />
      <Row gutter={40} align="middle">
        <Col>
          <p>
            <MinusSquareFilled style={{ fontSize: '20px', color: '#3174ad' }} />
            {' '}
            {t("available")}
          </p>
        </Col>
        <Col>
          <p>
            <MinusSquareFilled style={{ fontSize: '20px', color: '#AAAAAA' }} />
            {' '}
            {t("hold")}
          </p>

        </Col>
        <Col>
          <p>
            <MinusSquareFilled style={{ fontSize: '20px', color: 'red' }} />
            {' '}
            {t("booked")}
          </p>
        </Col>
        <Col>
          <p>
            <MinusSquareFilled style={{ fontSize: '20px', color: '#333333' }} />
            {' '}
            {t("expired")}
          </p>
        </Col>
      </Row>
      <Spin
        indicator={<LoadingOutlined />}
        spinning={loading}
      >
        <UserBookingListCalendar
          BookingItem={calendarEvents}
          setLoading={setLoading}
          callBack={() => getBookingItem()}
          selectDateCallBack={(prevOrNext, focusWeek) => selectDate(prevOrNext, focusWeek)}
        />
      </Spin>
      <Divider />
    </div>
  )
}

export default UserbookingListCalendarView;
