import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  message,
  Radio,
} from "antd";
import {
  EditOutlined,
  BackwardOutlined,
  WifiOutlined,
  BankOutlined,
  QrcodeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import FormUploadFile from "../../components/form/FormUploadFile";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;
const debug = require("debug")("app:admin:client:src:CompanyUserForm");

// const involvedModelName = "company";
const selectedKey = 4;
// const tableIDName = "company_user_access_log_id";

const UserForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const [formValues, setFormValues] = useState({});
  const {
    dataObj,
    modalVisible,
    setModalVisible
  } = props;

  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  useEffect(() => {
    setFormValues(dataObj);
    if (dataObj.photo) {
      setImageURL(`${STATIC_SERVER_URL}/${dataObj.photo}`);
    } else {
      setImageURL("");
    }
    // console.log(dataObj);
  }, [dataObj]);

  useEffect(() => {
    form.resetFields();
  }, [formValues]);

  const uploadOnChange = (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("uploaded"));
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        };
        let path = "../../" + info.file.response.filepath;
        setImageURL(path);
        setFileinfo(data);
      } else {
        message.error(t("fail"));
      }
    }
  };

  const onFinish = async (patchObj) => {
    let url = `/api/admin/user/profile`;

    patchObj.photo = fileinfo.filepath
      ? fileinfo.filepath
      : null;
    // patchObj.user_id = dataObj.user_id;
    // console.log(patchObj);

    // Patch
    if (formValues.user_id > 0) {
      patchObj.user_id = formValues.user_id;
      let data = await Service.call('PATCH', url, patchObj);
      // console.log('api resp >>>>', data);
      if (!data) {
        message.error('Failed');
        return;
      }
      if (data.errorMessage) {
        message.error(data.errorMessage);
        return;
      }
    }
    message.success('success');
    setModalVisible(false);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="time_related_controls"
      onFinish={onFinish}
      initialValues={formValues}
    >
      <Form.Item
        label={t('sf_id')}
        name="sf_id"
        rules={[{ required: true, message: t('input_required') }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label={t('name')}
        name="nickname"
        rules={[{ required: true, message: t('input_required') }]}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        label="First Name"
        name="first_name"
        rules={[{ required: true, message: t('input_required')ame.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[{ required: true, message: t('input_required')ame.' }]}
      >
        <Input />
      </Form.Item> */}
      <Form.Item
        label={t('company_name')}
        name="company_name"
        rules={[{ required: true, message: t('input_required') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('mobile')}
        name="mobile"
        rules={[{ required: true, message: t('input_required') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('email')}
        name="email"
        rules={[{ required: true, message: t('input_required'), type: 'email' }]}
      >
        <Input disabled />
      </Form.Item>
      {/* <Form.Item
        label="Password"
        name="password"
      >
        <Input disabled={location.state.dataObj.password} />
      </Form.Item> */}
      {/* <Form.Item
        label="Reference ID"
        name="ref_id"
      rules={[{ required: true, message: 'Please input reference id. If no, input `-`'}]}
      >
        <Input />
      </Form.Item> */}
      {/* <Form.Item
        label="Gender"
        name="gender"
        rules={[{ required: false, message: 'Please input gender.' }]}
      >
        <Radio.Group>
          <Radio value="M">{t('male')}</Radio>
          <Radio value="F">{ t('female')}</Radio>
        </Radio.Group>
      </Form.Item> */}
      {/* <Form.Item label={t("image")} name="photo">
        <FormUploadFile
          data={{ scope: "private" }}
          onChange={uploadOnChange}
          imageURL={imageURL}
        />
      </Form.Item> */}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
