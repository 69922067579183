import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, Input, Button, Tag, Spin, Table, Modal, notification, Tooltip
} from 'antd';
import _ from 'lodash';
import Fuse from 'fuse.js'
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  PlusOutlined,
  StopOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import * as Service from '../../core/Service';
import ItemRuleSetForm from './ItemRuleSetForm';
import * as Main from '../../core/Main';

const filterableFields = [
  `name`,
  `remarks`,
];

const ItemRuleSet = (props) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const { disableEdit } = props;

  const getAllData = async () => {
    let result = await Service.call('get', '/api/item/ruleset')
    // console.log('rule set >>>>>>>', result)
    let { data } = result;
    if (result.status !== 1) {
      setLoading(false);
      notification.error({
        message: t('error'),
        description: result.errorMessage,
      });
      return;
    }
    setDataList(data)
    setFilterDataList(data);
    setLoading(false)
  };

  useEffect(() => {
    setLoading(true)
    getAllData()
  }, [modalVisible]);

  const displayIsTrue = (value) => {
    if (value === 1) {
      return <CheckCircleOutlined style={{ color: 'green' }} />
    }
    return <span style={{ textAlign: 'center' }}><CloseCircleOutlined style={{ color: 'red' }} /></span>
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const getColumns = () => {
    let options = [
      'all_full_day',
      'holiday_full_day',
      'mon_full_day',
      'tue_full_day',
      'wed_full_day',
      'thu_full_day',
      'fri_full_day',
      'sat_full_day',
      'sun_full_day'
    ]
    let timeOptions = [
      'all_day_start',
      'all_day_end',
      'holiday_start',
      'holiday_end',
      'mon_start',
      'mon_end',
      'tue_start',
      'tue_end',
      'wed_start',
      'wed_end',
      'thu_start',
      'thu_end',
      'fri_start',
      'fri_end',
      'sat_start',
      'sat_end',
      'sun_start',
      'sun_end',
    ]

    let columns = [
      {
        title: t('name'),
        dataIndex: 'name',
        fixed: 'left',
      },
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        align: 'center',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('priority'),
        dataIndex: 'priority',
        sorter: (a, b) => a.priority - b.priority,
      },
      {
        title: t('effective_all_time'),
        dataIndex: 'effective_all_time',
        render: (value) => displayIsTrue(value),
      },
      {
        title: t('effective_start'),
        dataIndex: 'effective_start',
        sorter: (a, b) => a.effective_start - b.effective_start,
        render: (value) => {
          if (value === 0) return 'None';
          return moment.unix(value).format('YYYY-MM-DD')
        }
      },
      {
        title: t('effective_end'),
        dataIndex: 'effective_end',
        sorter: (a, b) => a.effective_end - b.effective_end,
        render: (value) => {
          if (value === 0) return 'None';
          return moment.unix(value).format('YYYY-MM-DD')
        }
      },
      // renderingColumns(),
      // {
      //   title: t('all_full_day'),
      //   dataIndex: 'all_full_day',
      //   render: (value) => displayIsTrue(value)
      // },
      // {
      //   title: t('holiday_full_day'),
      //   dataIndex: 'holiday_full_day',
      //   render: (value) => displayIsTrue(value)
      // },
      {
        title: t('remarks'),
        dataIndex: 'remarks',
      },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: 'item_ruleset_id',
        key: 'item_ruleset_id',
        fixed: 'left',
        render: (value, record, index) => {
          return (
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4, color: '#000000' }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    let timeConvertor = [
                      'all_day_start',
                      'all_day_end',
                      'holiday_start',
                      'holiday_end',
                      'mon_start',
                      'mon_end',
                      'tue_start',
                      'tue_end',
                      'wed_start',
                      'wed_end',
                      'thu_start',
                      'thu_end',
                      'fri_start',
                      'fri_end',
                      'sat_start',
                      'sat_end',
                      'sun_start',
                      'sun_end',
                    ]
                    // Handle Time before pass it to the form
                    let clonedData = { ...record };
                    timeConvertor.map((val) => {
                      if (record[val] == 0) {
                        delete clonedData[val];
                        return;
                      }
                      clonedData[val] = moment(clonedData[val], 'HH:mm')
                    })
                    if (clonedData.effective_start === 0) {
                      delete clonedData.effective_start
                    } else {
                      clonedData.effective_start = moment.unix(clonedData.effective_start)
                    }
                    if (clonedData.effective_end === 0) {
                      delete clonedData.effective_end
                    } else {
                      clonedData.effective_end = moment.unix(clonedData.effective_end)
                    }
                    // the end of Handle Time before pass it to the form
                    setSelectedRecord(clonedData);
                    setModalVisible(true);
                    getAllData();
                  }}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t('disable_rule_set') : t('enable_rule_set')}>
                <Button
                  shape="circle"
                  style={{ color: record.is_active ? 'red' : 'green' }}
                  icon={record.is_active ? (<StopOutlined />) : (<CheckOutlined />)}
                  onClick={async () => {
                    let { item_ruleset_id, is_active } = record;
                    await Service.call('patch', '/api/item/ruleset/is_active', { item_ruleset_id, is_active });
                    getAllData();
                  }}
                />
              </Tooltip>
            </span>
          )
        }
      });
    }
    // options.forEach((value) => {
    //   let column = {
    //     title: t(value),
    //     dataIndex: value,
    //     render: (e) => displayIsTrue(e)
    //   }
    //   columns.push(column);
    // })
    // timeOptions.forEach((value) => {
    //   let column = {
    //     title: t(value),
    //     dataIndex: value,
    //   }
    //   columns.push(column);
    // })
    return columns;
  };

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      }
      const fuse = new Fuse(filterList, options)
      const result = fuse.search(filterObj);
      // console.log(result);
      filterList = _.map(result, 'item');
      // console.log(filterList);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setFilterDataList(filterList);
    }
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <Spin spinning={loading}>
      <Row>
        {
          disableEdit ? '' : (
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord({})
                  setModalVisible(true);
                }}
                style={{ marginBottom: 10 }}
              >
                {t('create')}
              </Button>
            </Col>
          )
        }
        <Col style={{marginTop: 5, marginLeft: 10}}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{marginLeft: 20}}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
        <Col style={{marginLeft: 10}}>
          <Button
            icon={<FileExcelOutlined />}
            onClick={onExportTable}
          >
            {t('export_to_excel_file')}
          </Button>
        </Col>
      </Row>
      <Table
        rowKey={(index) => index}
        scroll={{ x: true }}
        columns={getColumns()}
        dataSource={filterDataList}
        bordered
        size="small"
      />
      <Modal
        title={selectedRecord.item_ruleset_id > 0 ? t('edit') : t('create')}
        footer={null}
        width={1200}
        visible={modalVisible}
        onCancel={() => {
          getAllData()
          setModalVisible(false);
        }}
      >
        <ItemRuleSetForm
          dataObj={selectedRecord}
          onClick={(val) => setModalVisible(val)}
        />
      </Modal>
    </Spin>
  )
}

export default ItemRuleSet;
