export const SET_ADMIN = 'SET_ADMIN';
export const SET_COMPANY_ADMIN = 'SET_COMPANY_ADMIN';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_AUTH = 'SET_AUTH';
export const SET_ORIGIN = 'SET_ORIGIN';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOGOUTWAY = 'SET_LOGOUTWAY';
export const SET_ADMIN_PERMISSIONS = 'SET_ADMIN_PERMISSIONS';
export const SET_ADMIN_UI_PERMISSIONS = 'SET_ADMIN_UI_PERMISSIONS';
export const SET_REFRESH_ITEM_TIME = 'SET_REFRESH_ITEM_TIME';
