import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import {
  Spin,
  Button,
  Input,
  Divider,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Row,
  Col,
  message,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";
import * as Service from "../../core/Service";
import * as Main from '../../core/Main';

import NavAndSideFrame from "../../components/NavAndSideFrame";
import DiscountInfoRoomFormModal from "../../components/DiscountInfoRoomFormModal";

const debug = require("debug")("app:pages/DiscountInfoRoom");

const selectedKey = "discount_info_room";
const tableIDName = "discount_info_room_id";

const filterableFields = [
  "discount_info_room_id",
  "remarks"
];

const DiscountInfoRoom = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [roomList, setRoomList] = useState([]);
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_discount_room_w')
  );

  const title = t("discount_info_room");

  useEffect(() => {
    getRoomList();
    getAllData();
  }, []);

  useEffect(() => {
    getAllData();
  }, [modalVisible]);

  const getAllData = async () => {
    setLoading(true);
    let data = [];
    try {
      let url = "/api/discount_room/list";
      data = await Service.call("get", url);
    } catch (error) {
      console.error("error >>> ", error);
      setLoading(false);
    } finally {
      setDataList(data);
      setFilterDataList(data);
      setLoading(false);
    }
  };

  const getRoomList = async () => {
    setLoading(true);
    try {
      let mRoomList = await Service.call(
        "get",
        "/api/discount_room/room_list"
      );

      setRoomList(mRoomList);
    } catch (error) {
      console.error("error >>> ", error);
      setLoading(false);
    }
  };

  const onPressDelete = async (discount_info_room_id) => {
    let url = `/api/discount_room?discount_info_room_id=${discount_info_room_id}`;
    await Service.call("DELETE", url);
    getAllData();
  };

  const setTableHeader = () => {
    let columns = [
      {
        title: "Room",
        dataIndex: "item_id_array",
        render: (value) => displayRoomName(value),
        filters: _.map(roomList, (room) => {
          return { text: `${room.item_name} (#${room.item_id})`, value: room.item_id}
        }),
        onFilter: (value, record) => {
          return _.includes(record.item_id_array, value);
        }
        // sorter: (a, b) => a.item_id - b.item_id,
      },
      {
        title: t("active_status"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      {
        title: t("effective_start"),
        dataIndex: "start",
        render: (value) => moment.unix(value, "X").format("YYYY-MM-DD HH:mm"),
        sorter: (a, b) => a.start - b.start,
      },
      {
        title: t("effective_end"),
        dataIndex: "expire",
        render: (value) => moment.unix(value, "X").format("YYYY-MM-DD HH:mm"),
        sorter: (a, b) => a.expire - b.expire,
      },
      {
        title: t("buy_count"),
        dataIndex: "buy_count",
        sorter: (a, b) => a.buy_count - b.buy_count,
      },
      {
        title: t("free_count"),
        dataIndex: "free_count",
        sorter: (a, b) => a.free_count - b.free_count,
      },
      {
        title: t("create_time"),
        dataIndex: "ctime",
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
        sorter: (a, b) => a.ctime - b.ctime,
      },
      {
        title: t("utime"),
        dataIndex: "utime",
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm"),
        sorter: (a, b) => a.utime - b.utime,
      },
      {
        title: t("remarks"),
        dataIndex: "remarks",
      },
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={record.is_active === 1 ? t("deactivate") : t("activate")}
              >
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={
                    record.is_active ? (
                      <CloseCircleOutlined />
                    ) : (
                      <CheckCircleOutlined />
                    )
                  }
                  onClick={async () => {
                    if (isChangingIsActive) return;
                    setChangingIsActive(true);
                    let resp = await Service.call(
                      "PATCH",
                      "/api/discount_room/active",
                      {
                        discount_info_room_id: record.discount_info_room_id,
                        is_active: record.is_active === 0 ? 1 : 0,
                      }
                    );
                    if (!resp || resp.status < 1) {
                      let errorMessage = resp.errorMessage || "";
                      message.error(`${t("fail")}: ${errorMessage}`);
                    }
                    getAllData();
                    setChangingIsActive(false);
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={t("confirm_delete") + "?"}
                onConfirm={() => onPressDelete(record.discount_info_room_id)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Tooltip title={t("delete")}>
                  <Button shape="circle" icon={<DeleteOutlined />} />
                </Tooltip>
              </Popconfirm>
            </span>
          );
        },
      });
    }
    return columns;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "blue";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = "green";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("error");
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayRoomName = (item_id_array) => {
    return (
      <div style={{maxWidth: 150, display: "flex", flexWrap: "wrap"}}>
        {_.map(item_id_array, (item_id) => {
          let roomFound = _.find(roomList, (room) => {
            return room.item_id === item_id;
          });

          if (_.isUndefined(roomFound)) {
            return <Tag>{`??? (#${item_id})`}</Tag>;
          }

          return <Tag>{`${roomFound.item_name} (#${item_id})`}</Tag>;
        })}
      </div>
    );
  };

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      };
      const fuse = new Fuse(filterList, options);
      const result = fuse.search(filterObj);
      filterList = _.map(result, "item");
      filterList = _.orderBy(filterList, ["discount_info_room_id"], ["desc"]);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      filterList = _.orderBy(filterList, "discount_info_room_id");
      setFilterDataList(filterList);
    }
  };

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  const onExportTable = () => {
    let exportDataList = _.map(filterDataList, (d) => {
      return {
        ...d,
        item_id_array: d.item_id_array.join(','),
        start: moment(d.start, 'X').format('YYYY-MM-DD HH:mm:ss'),
        expire: moment(d.expire, 'X').format('YYYY-MM-DD HH:mm:ss'),
        item: _.map(d.item_id_array, (item_id) => {
          let roomFound = _.find(roomList, (room) => {
            return room.item_id === item_id;
          });

          if (_.isUndefined(roomFound)) {
            return `??? (#${item_id})`;
          }

          return `${roomFound.item_name} (#${item_id})`;
        }).join(',')
      }
    });

    Main.exportTableToCSV(exportDataList, 'export');
  };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} onExportTable={onExportTable}>
      <Row>
        {
          disableEdit ? '' : (
            <Col>
              <Button
                icon={<PlusOutlined />}
                style={{ marginBottom: 10 }}
                type="primary"
                onClick={() => {
                  setSelectedRecord({ discount_info_room_id: 0 });
                  setModalVisible(true);
                }}
              >
                {t("create")}
              </Button>
            </Col>
          )
        }
        <Col style={{ marginTop: 5, marginLeft: 10 }}>{t(`search`) + `:`}</Col>
        <Col style={{ marginLeft: 20 }}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: "50%" }}
            onChange={(values) => {
              onChange(values.target.value);
            }}
          />
        </Col>
      </Row>
      <Divider />
      <Spin spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: "max-content" }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          size="middle"
        />
        <DiscountInfoRoomFormModal
          modalVisible={modalVisible}
          selectedRecord={selectedRecord}
          roomList={roomList}
          setModalVisible={setModalVisible}
        />
      </Spin>
    </NavAndSideFrame>
  );
};

export default DiscountInfoRoom;
