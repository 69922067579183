import React, { useEffect, useState } from "react";
import _ from "lodash";
import * as debugLib from "debug";
import { useTranslation } from "react-i18next";
import { Select, Button, Modal, Card, message } from "antd";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as Enums from "../../enums";
import BookNowRoomForm from "../../components/booking/BookNowRoomForm";
import BookNowLockerForm from "../../components/booking/BookNowLockerForm";
import BookNowCarParkForm from "../../components/booking/BookNowCarParkForm";

const { Option } = Select;

const debug = debugLib("app:pages/BookNow");

const selectedKey = "book_now";
const openKey = "book_now";

// let sampleText = "apple";

const BookNow = (props) => {
  const { t } = useTranslation();
  const title = t("book_now");

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryList, setCategoryList] = useState([]);

  const getItemCategoryList = async () => {
    let resp = await Service.call("get", "/api/item/category/list/child");
    setCategoryList(resp.data);
  };

  const onSelectedCategory = (item_category_id, type) => {
    if (type === Enums.ITEM_TYPE.COMMON_ROOM) {
      message.warning('Not yet support common room booking');
      return;
    }

    setSelectedCategory({ item_category_id, type });
    setModalVisible(true);
  };

  const renderForm = (item_category_id, type) => {
    switch (type) {
      case Enums.ITEM_TYPE.ROOM:
        return (
          <BookNowRoomForm
            item_category_id={item_category_id}
            openModal={(value) => {
              setModalVisible(value);
            }}
          />
        );
      case Enums.ITEM_TYPE.PARKING:
        return (
          <BookNowCarParkForm
            item_category_id={item_category_id}
            openModal={(value) => {
              setModalVisible(value);
            }}
          />
        );
      case Enums.ITEM_TYPE.COMMON_ROOM:
        break;
      case Enums.ITEM_TYPE.LOCKER:
        return (
          <BookNowLockerForm
            item_category_id={item_category_id}
            openModal={(value) => {
              setModalVisible(value);
            }}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    getItemCategoryList();
  }, []);

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKey={openKey}>
      <div
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        {_.map(categoryList, (category) => {
          let { item_category_id, type, is_active, category_name, category_desc, banner } = category;

          let title = is_active
            ? category_name
            : `${category_name} (${t("inactive")})`;
          let cardStyle = is_active
            ? {}
            : {
                color: "#00000040",
                backgroundColor: "#f5f5f5",
                borderColor: "#d9d9d9",
                cursor: "not-allowed",
              };

          return (
            <Card
              title={title}
              key={item_category_id}
              headStyle={{ ...cardStyle }}
              style={{ width: 300, margin: 10, ...cardStyle }}
              onClick={() => {
                if (!is_active) {
                  return;
                }
                onSelectedCategory(item_category_id, type);
              }}
              hoverable={is_active}
              // disabled={!is_active}
            >
              <p>{`${_.findKey(Enums.ITEM_TYPE, (v)=>v===type) || ' - '}`}</p>
              <p>{`${category_desc}`}</p>
            </Card>
          );
        })}
      </div>
      <Modal
        title={t("book_now_title_new_booking")}
        footer={null}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        {renderForm(selectedCategory?.item_category_id, selectedCategory?.type)}
      </Modal>
    </NavAndSideFrame>
  );
};

export default BookNow;
