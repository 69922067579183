import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import {
  Spin,
  Button,
  Divider,
  Modal,
  Table,
  Tag,
  Tooltip,
  Row,
  Col,
  Input,
  message,
  Space,
  InputNumber,
  Select
} from "antd";
import {
  EditOutlined,
  CheckCircleOutlined,
  StopOutlined,
  CheckOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
// import 'antd/dist/antd.css';
import Fuse from "fuse.js";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import * as Main from '../../core/Main';
import NavAndSideFrame from "../../components/NavAndSideFrame";
import CompanyUserForm from "./CompanyUserForm";
import UserAddTokenForm from "../../components/UserAddTokenForm";

const { Option } = Select;
const debug = require("debug")("app:admin:client:src:AdvertisementList");

// const involvedModelName = "company";
// const title = "User";
const selectedKey = "user";
const openKey = "user";
const tableIDName = "user_id";
const filterableFields = [
  "user_id",
  "nickname",
  "first_name",
  "sf_id",
  "approve_status",
  "company_name",
  "mobile",
  "email",
  "own_token",
  "total_token",
  "ref_id",
];

const CompanyUserList = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [unChangeData, setUnChangeData] = useState([]);
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [isChangingAllowParking, setChangingAllowParking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addTokenModalVisible, setAddTokenModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [minOwnToken, setMinOwnToken] = useState(Number.MIN_SAFE_INTEGER);
  const [maxOwnToken, setMaxOwnToken] = useState(Number.MAX_SAFE_INTEGER);
  const [showAllowParking, setShowAllowParking] = useState('All');
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_user_w')
  );

  useEffect(() => {
    setLoading(true);
    getAllData();
  }, [modalVisible]);

  const getAllData = async () => {
    let dataList = [];
    try {
      let url = `/api/user/list/approved`;
      let data = await Service.call("get", url);

      dataList = _.orderBy(data, ["user_id"], ["desc"]);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setUnChangeData(dataList);
      setDataList(dataList);
      setLoading(false);
    }
  };

  const setTableHeader = () => {
    let columns = [
      {
        title: t("user_id"),
        dataIndex: `user_id`,
        sorter: (a, b) => a.user_id - b.user_id,
      },
      {
        title: t("name"),
        dataIndex: `nickname`,
        sorter: (a, b) => a.nickname.localeCompare(b.nickname),
      },
      {
        title: t("sf_id"),
        dataIndex: "sf_id",
        sorter: (a, b) => a.sf_id - b.sf_id,
      },
      {
        title: t("active"),
        dataIndex: "is_active",
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      {
        title: t("allow_parking"),
        dataIndex: "allow_parking",
        render: (value, record) => displayAllowParking(value, record),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      {
        title: t(`company_name`),
        dataIndex: `company_name`,
        sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      },
      // {title: 'Username', dataIndex: 'username', align: 'center'},
      // {title: 'Password', dataIndex: 'password'},
      // {
      //     title: 'Nickname',
      //     dataIndex: 'nickname',
      //     sorter: (a, b) => a.
      // },
      // {
      //     title: 'Role',
      //     dataIndex: 'company_user_role',
      //     sorter: (a, b) => a.company_user_role.localeCompare(b.company_user_role)
      // },
      // {
      //     title: 'Admin Permission',
      //     dataIndex: 'is_admin',
      //     render: (value) => displayStatus(value),
      //     sorter: (a, b) => a.is_admin - b.is_admin,
      //     align: 'center'
      // },
      // {
      //     title: 'QR Ccode Permission',
      //     dataIndex: 'privilege_scan_qrcode',
      //     render: (value) => displayStatus(value),
      //     sorter: (a, b) => a.privilege_scan_qrcode - b.privilege_scan_qrcode,
      //     align: 'center'
      // },
      // {
      //   title: 'Gender',
      //   dataIndex: 'gender',
      //   sorter: (a, b) => a.gender.localeCompare(b.gender),
      //   align: 'center'
      // },
      {
        title: t("mobile"),
        dataIndex: "mobile",
        render: (value, record) => (
          <span>
            {displayVerified(record.is_verified_mobile)}
            {value}
          </span>
        ),
        sorter: (a, b) => a.mobile.localeCompare(b.mobile),
      },
      {
        title: t("email"),
        dataIndex: "email",
        render: (value, record) => (
          <span>
            {displayVerified(record.is_verified_email)}
            {value}
          </span>
        ),
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      // {
      //   title: t('is_verified_mobile'),
      //   dataIndex: 'is_verified_mobile',
      //   render: (value) => displayBoolean(value),
      //   sorter: (a, b) => a.is_verified_mobile.localeCompare(b.is_verified_mobile)
      // },
      // {
      //   title: t('is_verified_email'),
      //   dataIndex: 'is_verified_email',
      //   render: (value) => displayBoolean(value),
      //   sorter: (a, b) => a.is_verified_email.localeCompare(b.is_verified_email)
      // },
      {
        title: t("own_token"),
        dataIndex: "own_token",
        sorter: (a, b) => a.own_token - b.own_token,
      },
      {
        title: t("total_token"),
        dataIndex: "total_token",
        sorter: (a, b) => a.total_token - b.total_token,
      },
      // {
      //   title: t('approve_status'),
      //   dataIndex: 'status',
      //   render: (value) => displayStatus(value),
      //   // sorter: (a, b) => a.status - b.status
      // },
      {
        title: t(`ref_id`),
        dataIndex: `ref_id`,
        // sorter: (a, b) => a.ref_id.localeCompare(b.ref_id)
      },
      // {
      //     title: 'Email Verify',
      //     dataIndex: 'is_verified_email',
      //     render: (value) => displayStatus(value),
      //     sorter: (a, b) => a.is_verified_email - b.is_verified_email,
      //     align: 'center'
      // },
      // {title: 'Is active', dataIndex: 'is_active', render: (value) => displayStatus(value)},
    ];

    if (!disableEdit) {
      columns.unshift({
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: "#000000" }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              <Tooltip title={t("add_token")}>
                <Button
                  disabled={!record.is_active}
                  shape="circle"
                  style={{ marginRight: 8, color: "#000000" }}
                  icon={<DollarCircleOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setAddTokenModalVisible(true);
                  }}
                />
              </Tooltip>
              {/* <Tooltip title="Reset password">
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: '#000000' }}
                  icon={<KeyOutlined />}
                  onClick={() => {
                    setPasswordVisible(true);
                    setPasswordRecord(record);
                  }}
                />
              </Tooltip> */}
              {/* <Link to={{
                pathname: admin.admin_id != null ? adminPath[0] : companyAdminPath[0],
                state: {
                  company_user_id: record.company_user_id,
                  company_name: record.company_name,
                  company_id: record.company_id,
                  first_name: record.first_name,
                  last_name: record.last_name,
                }
              }}
              >
                <Tooltip title="Access Log">
                  <Button
                    style={{ marginRight: 8, color: '#000000' }}
                    shape="circle"
                    icon={<FileTextOutlined />}
                  />
                </Tooltip>
              </Link> */}
              {/* <Link to={{
                pathname: admin.admin_id != null ? adminPath[1] : companyAdminPath[1],
                state: {
                  company_user_id: record.company_user_id,
                  company_id: record.company_id,
                  company_name: record.company_name,
                  first_name: record.first_name,
                  last_name: record.last_name,
                }
              }}
              >
                <Tooltip title="User Doors">
                  <Button
                    style={{ marginRight: 8, color: '#000000' }}
                    shape="circle"
                    icon={<BankOutlined />}
                  />
                </Tooltip>
              </Link> */}
              {/* <Link to={{
                pathname: admin.admin_id != null ? adminPath[2] : companyAdminPath[2],
                state: {
                  company_user_id: record.company_user_id,
                  company_name: record.company_name,
                  company_id: record.company_id,
                  first_name: record.first_name,
                  last_name: record.last_name,
                }
              }}
              >
                <Tooltip title="User QR Codes">
                  <Button
                    style={{ marginRight: 8, color: '#000000' }}
                    shape="circle"
                    icon={<QrcodeOutlined />}
                  />
                </Tooltip>
              </Link> */}
              <Tooltip
                title={record.is_active ? t("disable_user") : t("enable_user")}
              >
                <Button
                  shape="circle"
                  style={{ color: record.is_active ? "red" : "green" }}
                  icon={record.is_active ? <StopOutlined /> : <CheckOutlined />}
                  onClick={async () => {
                    if (isChangingIsActive) return; // avoid multiple attempts

                    setChangingIsActive(true);
                    let { user_id, is_active } = record;

                    // change in route, no need to pass a changed value
                    let result = await Service.call(
                      "patch",
                      "/api/user/status",
                      { user_id, is_active }
                    );
                    setChangingIsActive(false);
                    getAllData();
                    if (_.isEmpty(result) || result.status < 1) {
                      return message.error(t("fail"));
                    }

                    // value of is_active is changed
                    return message.success(t("modal_success"));
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      });
    }
    return columns;
  };

  const displayVerified = (value) => {
    if (value === 1) {
      return <CheckCircleOutlined style={{ color: "blue" }} />;
    }
  };

  const displayBoolean = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let isTrue = _.toInteger(value);
    switch (isTrue) {
      case 1:
        displayStr = t(`yes`);
        tagColor = "green";
        break;
      case 0:
        displayStr = t(`no`);
        tagColor = "red";
        break;
      default:
        displayStr = t(`fail`);
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayIsActive = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let isActiveValue = _.toInteger(value);
    switch (isActiveValue) {
      case 1:
        displayStr = t("active");
        tagColor = "green";
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = "red";
        break;
      default:
        displayStr = t("fail");
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const displayAllowParking = (value, record) => {
    let displayStr = "";
    let tagColor = "green";
    let isActiveValue = _.toInteger(value);
    switch (isActiveValue) {
      case 1:
        displayStr = t("allow");
        tagColor = "green";
        break;
      case 0:
        displayStr = t("not_allow");
        tagColor = "red";
        break;
      default:
        displayStr = t("fail");
        tagColor = "#f50";
        break;
    }
    return (
      <>
        <Tag color={tagColor}>{displayStr}</Tag>
        {
          disableEdit ? '' : (
            <span>
              <Tooltip title={record.allow_parking ? t("not_allow") : t("allow")}>
                <Button
                  shape="circle"
                  style={{ color: record.allow_parking ? "red" : "green" }}
                  icon={record.allow_parking ? <StopOutlined /> : <CheckOutlined />}
                  onClick={async () => {
                    if (isChangingAllowParking) return; // avoid multiple attempts
                    setChangingAllowParking(true);
                    let { user_id, allow_parking } = record;
                    // change in route, no need to pass a changed value
                    let result = await Service.call("patch", "/api/user/parking", {
                      user_id,
                      allow_parking,
                    });
                    setChangingAllowParking(false);
                    getAllData();
                    if (_.isEmpty(result) || result.status < 1) {
                      return message.error(t("fail"));
                    }
                    // value of is_active is changed
                    return message.success(t("modal_success"));
                  }}
                />
              </Tooltip>
            </span>
          )
        }
      </>
    );
  };

  const displayStatus = (value) => {
    let displayStr = "";
    let tagColor = "green";
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("approved");
        tagColor = "green";
        break;
      case 0:
        displayStr = t("wait_for_approve");
        tagColor = "red";
        break;
      default:
        displayStr = t("rejected");
        tagColor = "#f50";
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  };

  const getDataBySearchKeyword = (keywords) => {
    let searchedList = [];
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      };
      const fuse = new Fuse(unChangeData, options);
      const result = fuse.search(keywords);
      searchedList = _.map(result, "item");
      searchedList = _.orderBy(searchedList, ["user_id"], ["desc"]);
      return searchedList
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      // setDataList(dataList);
    }
  };

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onKeywordChange = (keyword) => {
    setSearchKeyword(keyword);
  };
  const onMinOwnTokenChange = (value) => {
    if (_.isNull(value)) value = Number.MIN_SAFE_INTEGER
    setMinOwnToken(value);
  };
  const onMaxOwnTokenChange = (value) => {
    if (_.isNull(value)) value = Number.MAX_SAFE_INTEGER
    setMaxOwnToken(value);
  };
  const onAllowParkingChange = (value) => {
    console.log('onAllowParkingChange',value)
    setShowAllowParking(value);
  }

  useEffect(() => {
    debounce(() => {
      console.log('filtering with', searchKeyword, minOwnToken, maxOwnToken, showAllowParking)
      let searchedList = unChangeData;
      if (_.isEmpty(unChangeData)) return;
      console.log('unChangeData', unChangeData);
      if (!_.isEmpty(searchKeyword)) {
        searchedList = getDataBySearchKeyword(searchKeyword);
        console.log(`searchedList >> keyword ${searchKeyword} >> `, searchedList)
      }

      if (minOwnToken !== Number.MIN_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.own_token > minOwnToken) return rowData;
        });
        console.log(`searchedList >> minOwnToken ${minOwnToken} >> `, searchedList)
      }

      if (maxOwnToken !== Number.MAX_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.own_token < maxOwnToken) return rowData;
        });
        console.log(`searchedList >> maxOwnToken ${maxOwnToken} >> `, searchedList)
      }

      if (showAllowParking !== 'All') {
        if (showAllowParking === 'Yes') {
          searchedList = _.map(searchedList, (rowData) => {
            if (_.isUndefined(rowData)) return;
            if (rowData.allow_parking === 1) return rowData;
          });
          console.log(`searchedList >> showAllowParking ${showAllowParking} >> `, searchedList)
        } else {
          searchedList = _.map(searchedList, (rowData) => {
            if (_.isUndefined(rowData)) return;
            if (rowData.allow_parking === 0) return rowData;
          });
          console.log(`searchedList >> showAllowParking ${showAllowParking} >> `, searchedList)
        }
      }

      searchedList = _.compact(searchedList);
      setDataList(searchedList);
    }, 400);
  }, [searchKeyword, minOwnToken, maxOwnToken, showAllowParking]);

  const onExportTable = () => {
    Main.exportTableToCSV(dataList, 'export');
  };

  return (
    <NavAndSideFrame
      {...props}
      title={t("user")}
      selectedKey={selectedKey}
      openKey={openKey}
      onExportTable={onExportTable}
    >
      {/* <Button
        type="primary"
        onClick={() => {
          setSelectedRecord({ company_user_id: 0 });
          setModalVisible(true);
        }}
      >
        Create New User
      </Button> */}
      {/* <Divider /> */}
      <Row style={{ marginTop: 5 }}>
        {/* <Col style={{ marginTop: 5 }}>{t(`search`) + `:`}</Col> */}
        <Col style={{ marginLeft: 20 }}>
          <>{t(`search`) + `:`}</>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: "50%" }}
            onChange={(values) => {
              onKeywordChange(values.target.value);
            }}
          />
        </Col>
        <Col style={{ marginLeft: 40 }}>
          <InputNumber
            style={{ minWidth: 30, width: "20%" }}
            onChange={(values) => {
              onMinOwnTokenChange(values);
            }}
          />
          <>{` < ${t('own_token')} < `}</>
          <InputNumber
            style={{ minWidth: 30, width: "20%" }}
            onChange={(values) => {
              onMaxOwnTokenChange(values);
            }}
          />
        </Col>
        <Col>
          <>{t('allow_parking') + ':'}</>
          <Select defaultValue="All" style={{ width: 100 }} onChange={onAllowParkingChange}>
            <Option value="All">All</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: "max-content" }}
          dataSource={dataList}
          columns={setTableHeader()}
        />
        <Modal
          destroyOnClose
          title={t("user_profile")}
          visible={modalVisible}
          footer={null}
          width={1280}
          style={{ maxWidth: "95%" }}
          onCancel={() => {
            setModalVisible(false);
          }}
        >
          <CompanyUserForm
            dataObj={selectedRecord}
            setModalVisible={setModalVisible}
          />
        </Modal>
        <Modal
          destroyOnClose
          title={t("add_token")}
          visible={addTokenModalVisible}
          footer={null}
          onCancel={() => {
            setAddTokenModalVisible(false);
          }}
        >
          <UserAddTokenForm
            dataObj={selectedRecord}
            setAddTokenModalVisible={setAddTokenModalVisible}
          />
        </Modal>
      </Spin>
    </NavAndSideFrame>
  );
};

export default CompanyUserList;
