import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "querystring";
import * as debugLib from "debug";
import NavAndSideFrame from "../NavAndSideFrame";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import FormUploadFile from "../form/FormUploadFile";

const debug = debugLib("app:pages/company/item/list/Modal");

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ItemHolidayModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    // categoryList,
    modalVisible,
    setModalVisible,
    setRefreshModalVisible
    // getData,
    // handleFormSubmit,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  const [ItemHolidayForm] = Form.useForm();

  useEffect(() => {
    let initialValues = {}
    if (selectedRecord) {
      // console.log('selectedRecord >>> ', selectedRecord);
      initialValues = selectedRecord
      initialValues.utime = moment.unix(selectedRecord.utime).format("YYYY-MM-DD HH:mm");
      initialValues.ctime = moment.unix(selectedRecord.ctime).format("YYYY-MM-DD HH:mm");

      let start_time = selectedRecord.start_time ? moment.unix(selectedRecord.start_time) : null;
      let end_time = selectedRecord.end_time ? moment.unix(selectedRecord.end_time) : null;
      initialValues.start_end_holiday_time = [start_time, end_time];
    }
    if (selectedRecord.item_holiday_id !== 0) {
      setIsActive(selectedRecord.is_active)
    } else {
      setIsActive(true)
    }
    setInitialFormValues(initialValues)
  }, [selectedRecord]);

  useEffect(() => {
    ItemHolidayForm.resetFields();
  }, [initialFormValues])

  const onFinish = async (formData) => {
    let url = '/api/item/holiday';
    // console.log(formData);
    let params = {
      description: formData.description,
      remarks: formData.remarks,
      start_time: formData.start_end_holiday_time[0].startOf('day').unix(),
      end_time: formData.start_end_holiday_time[1].endOf('day').unix(),
    };

    if (selectedRecord.item_holiday_id > 0) params.item_holiday_id = selectedRecord.item_holiday_id;

    // console.log(params);
    let resp = await Service.call(selectedRecord.item_holiday_id > 0 ? 'PUT' : 'POST', url, params);
    // console.log('resp >>> ', resp);
    if (!resp || resp.status < 1) {
      message.error(resp.errorMessage);
      return;
    }
    notification.success({
      message: t('Success'),
    });
    // message.success(t('modal_success'));
    setModalVisible(false);
    setRefreshModalVisible(true);
    // getData();
  }
  return (
    <Modal
      title={
        selectedRecord.item_holiday_id === 0 ? t("add_new_holiday") : t("edit_holiday")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{maxWidth: "95%"}}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={ItemHolidayForm}
        name="ItemHolidayForm"
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={onFinish}
      >
        <Form.Item
          label={t("description")}
          name="description"
          // rules={[{ required: true, message: t('input_required') }]}
        >
          <Input
            disabled={!isActive}
          />
        </Form.Item>
        <Form.Item
          label={t("remarks")}
          name="remarks"
          // rules={[{ required: true, message: t('input_required') }]}
        >
          <Input
            disabled={!isActive}
          />
        </Form.Item>
        <Form.Item
          label={t("start_end_holiday_time")}
          name="start_end_holiday_time"
          rules={[{ required: true, message: t('select_required') }]}
        >
          <DatePicker.RangePicker
            disabled={!isActive}
            format="YYYY-MM-DD"
            onChange={(value) => console.log(value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default ItemHolidayModal;
