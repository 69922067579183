import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Spin, Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Input, message, Select, DatePicker, InputNumber
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
  StopOutlined,
  CheckOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import Fuse from 'fuse.js';
import _ from 'lodash';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import CompanyUserForm from './CompanyUserForm';
import CanpanyUserPasswordForm from '../../components/CompanyUserPasswordForm';
import { useTranslation } from 'react-i18next';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

// const involvedModelName = "company";

const selectedKey = 'transaction_log';
const openKey = 'order';
const tableIDName = "ptx_key";
const { Option } = Select;
const { RangePicker } = DatePicker;
const now = moment().unix();

const filterableFields = [
  `ptx_key`,
  `order_key`,
  `email`,
  `ptx_pay_method`,
];


const CompanyPaymentTransaction = (props) => {
  const { t } = useTranslation()
  const title = t("transaction_log");
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchStatus, setSearchStatus] = useState('all');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [minCreateTime, setMinCreateTime] = useState(0);
  const [maxCreateTime, setMaxCreateTime] = useState(now);
  const [minCompleteTime, setMinCompleteTime] = useState(0);
  const [maxCompleteTime, setMaxCompleteTime] = useState(now);
  const [minAmount, setMinAmount] = useState(Number.MIN_SAFE_INTEGER);
  const [maxAmount, setMaxAmount] = useState(Number.MAX_SAFE_INTEGER);
  const [payMethod, setPayMethod] = useState('all');

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getAllData();
  }, [modalVisible]);

  useEffect(() => {
    filterData();
  }, [
    searchKeyword,
    searchStatus,
    minCreateTime,
    maxCreateTime,
    minCompleteTime,
    maxCompleteTime,
    minAmount,
    maxAmount,
    payMethod
  ]);

  ///api/token/log

  const getAllData = async () => {
    let dataList = [];
    try {
      let url = `/api/transaction/log`;
      let data = await Service.call('get', url);
      // dataList = data
      dataList = _.orderBy(data, ['ptx_create_time'], ['desc']);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setFilterDataList(dataList);
      setDataList(dataList);
      setLoading(false);
    }
  }

  const getDataBySearchKeyword = (keywords) => {
    let searchedList = [];
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      };
      const fuse = new Fuse(dataList, options);
      const result = fuse.search(keywords);
      searchedList = _.map(result, "item");
      searchedList = _.orderBy(searchedList, ["user_id"], ["desc"]);
      return searchedList
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      // setDataList(dataList);
    }
  };

  const setTableHeader = () => {
    const columns = [
      // {
      //   title: 'Operation',
      //   dataIndex: tableIDName,
      //   render: (value, record) => {
      //     let status = (record.status === 1);
      //     let color = '#000000';
      //     let icon = '';
      //     let wordings = '';
      //     if (status) {
      //       color = '#AA0000'
      //       icon = 'stop';
      //       wordings = 'Disable';
      //     } else {
      //       color = '#00AA00'
      //       icon = 'check';
      //       wordings = 'Enable';
      //     }
      //     return (
      //       <span>
      //         <Tooltip title="Edit">
      //           <Button
      //             shape="circle"
      //             style={{ marginRight: 8, color: '#000000' }}
      //             icon={<EditOutlined />}
      //             onClick={() => {
      //               setSelectedRecord(record);
      //               setModalVisible(true);
      //             }}
      //           />
      //         </Tooltip>
      //         {/* <Tooltip title="Reset password">
      //           <Button
      //             shape="circle"
      //             style={{ marginRight: 8, color: '#000000' }}
      //             icon={<KeyOutlined />}
      //             onClick={() => {
      //               setPasswordVisible(true);
      //               setPasswordRecord(record);
      //             }}
      //           />
      //         </Tooltip> */}
      //         {/* <Link to={{
      //           pathname: admin.admin_id != null ? adminPath[0] : companyAdminPath[0],
      //           state: {
      //             company_user_id: record.company_user_id,
      //             company_name: record.company_name,
      //             company_id: record.company_id,
      //             first_name: record.first_name,
      //             last_name: record.last_name,
      //           }
      //         }}
      //         >
      //           <Tooltip title="Access Log">
      //             <Button
      //               style={{ marginRight: 8, color: '#000000' }}
      //               shape="circle"
      //               icon={<FileTextOutlined />}
      //             />
      //           </Tooltip>
      //         </Link> */}
      //         {/* <Link to={{
      //           pathname: admin.admin_id != null ? adminPath[1] : companyAdminPath[1],
      //           state: {
      //             company_user_id: record.company_user_id,
      //             company_id: record.company_id,
      //             company_name: record.company_name,
      //             first_name: record.first_name,
      //             last_name: record.last_name,
      //           }
      //         }}
      //         >
      //           <Tooltip title="User Doors">
      //             <Button
      //               style={{ marginRight: 8, color: '#000000' }}
      //               shape="circle"
      //               icon={<BankOutlined />}
      //             />
      //           </Tooltip>
      //         </Link> */}
      //         {/* <Link to={{
      //           pathname: admin.admin_id != null ? adminPath[2] : companyAdminPath[2],
      //           state: {
      //             company_user_id: record.company_user_id,
      //             company_name: record.company_name,
      //             company_id: record.company_id,
      //             first_name: record.first_name,
      //             last_name: record.last_name,
      //           }
      //         }}
      //         >
      //           <Tooltip title="User QR Codes">
      //             <Button
      //               style={{ marginRight: 8, color: '#000000' }}
      //               shape="circle"
      //               icon={<QrcodeOutlined />}
      //             />
      //           </Tooltip>
      //         </Link> */}
      //         <Tooltip title={record.is_active ? 'Disable User' : 'Enable User'}>
      //           <Button
      //             shape="circle"
      //             style={{ color: record.is_active ? 'red' : 'green' }}
      //             icon={record.is_active ? (<StopOutlined />) : (<CheckOutlined />)}
      //             onClick={async () => {
      //               if (isChangingIsActive) return; // avoid multiple attempts

      //               setChangingIsActive(true);
      //               let { user_id, is_active } = record;

      //               // change in route, no need to pass a changed value
      //               let result = await Service.call('patch', '/api/user/status', { user_id, is_active });
      //               setChangingIsActive(false);
      //               getAllData();
      //               if (_.isEmpty(result) || result.status < 1) {
      //                 return message.error(is_active ? 'Fail to disable user' : 'Fail to enable user');
      //               }

      //               // value of is_active is changed
      //               return message.success(is_active ? 'User enabled' : 'User disabled');
      //             }}
      //           />
      //         </Tooltip>
      //       </span>
      //     )
      //   }
      // },
   
    
      // {
      //     title: 'Mobile Verify',
      //     dataIndex: 'is_verified_mobile',
      //     render: (value) => displayStatus(value),
      //     sorter: (a, b) => a.is_verified_mobile - b.is_verified_mobile,
      //     align: 'center'
      // },
      {
        title: t('key'),
        dataIndex: `ptx_key`,
        sorter: (a, b) => a.ptx_key.localeCompare(b.ptx_key)
      },
      {
        title: t('order'),
        dataIndex: `order_key`,
        render: (value) => { return value || '-'; },
        // sorter: (a, b) => a.order_key.localeCompare(b.order_key)
      },
      {
        title: t('email'),
        dataIndex: 'email',
        // sorter: (a, b) => a.email.localeCompare(b.email)
      },

      {
        title: t('amount'),
        dataIndex: 'ptx_amount',
        render: (value) => { return _.toInteger(value) / 100 },
        // render: (value) => displayBoolean(value),
        // sorter: (a, b) => a.ptx_amount.localeCompare(b.ptx_amount)
        sorter: (a, b) => a.ptx_amount - b.ptx_amount,
      },
      {
        title: t('currency'),
        dataIndex: 'ptx_currency',
        // render: (value) => displayBoolean(value),
        sorter: (a, b) => a.ptx_currency.localeCompare(b.ptx_currency)
      },
      {
        title: t('token_used'),
        dataIndex: 'token_used',
        sorter: (a, b) => a.token_used - b.token_used
      },
      {
        title: t('ptx_pay_method'),
        dataIndex: 'ptx_pay_method',
        sorter: (a, b) => a.ptx_pay_method.localeCompare(b.ptx_pay_method)
      },
      {
        title: t('create_time'),
        dataIndex: 'ptx_create_time',
        sorter: (a, b) => a.ptx_create_time - b.ptx_create_time,
        render: (ptx_create_time) => {
          return moment.unix(ptx_create_time).format('YYYY-MM-DD hh:mm')
        },
      },
      {
        title: t('complete_time'),
        dataIndex: 'ptx_complete_time',

        sorter: (a, b) => a.ptx_complete_time - b.ptx_complete_time,
        render: (ptx_complete_time, record) => {
          if (record.ptx_status === 0) {
            return '-';
          }
          return moment.unix(ptx_complete_time).format('YYYY-MM-DD hh:mm')
        },
      },

      {
        title: t('status'),
        dataIndex: 'ptx_status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.ptx_status - b.ptx_status
      },
    ];
    return columns;
  }

  const displayBoolean = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let isTrue = _.toInteger(value);
    switch (isTrue) {
      case 1:
        displayStr = "Yes";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "No";
        tagColor = 'red';
        break;
      default:
        displayStr = "Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let isActiveValue = _.toInteger(value);
    switch (isActiveValue) {
      case 9:
        displayStr = t('completed');
        tagColor = 'green';
        break;
      case 0:
        displayStr = t('pending');
        tagColor = 'orange';
        break;
      case 1:
        displayStr = t('refund');
        tagColor = 'grey';
        break;
      case -2:
        displayStr = t('fail');
        tagColor = 'red';
        break;
      default:
        displayStr = t('error')
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayIsType = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let isActiveValue = _.toInteger(value);
    switch (value) {
      case "order":
        displayStr = "order";
        tagColor = 'green';
        break;
      case "order_cancel":
        displayStr = "order_cancel";
        tagColor = 'red';
        break;
      case "topup":
        displayStr = "topup";
        tagColor = 'blue';
        break;
      default:
        displayStr = "Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  // const displayStatus = (value) => {
  //   let displayStr = '';
  //   let tagColor = 'green';
  //   let statusValue = _.toInteger(value);
  //   switch (statusValue) {
  //     case 1:
  //       displayStr = "Approved";
  //       tagColor = 'green';
  //       break;
  //     case 0:
  //       displayStr = "Waiting For Approve";
  //       tagColor = 'red';
  //       break;
  //     default:
  //       displayStr = "Rejected"
  //       tagColor = '#f50';
  //       break;
  //   }
  //   return <Tag color={tagColor}>{displayStr}</Tag>;
  // }


  const onStatusChange = (status) => {
    setSearchStatus(status);
  };

  const renderStatusSelectBox = () => {
    // case "placed": wording = t("placed"); break;
    // case "paid": wording = t("paid"); break;
    // case "payment_confirmed": wording = t("payment_confirmed"); break;
    // case "payment_failed": wording = t("payment_failed"); break;
    // case "payment_refund": wording = t("payment_refund"); break;
    // case "in_delivery": wording = t("in_delivery"); break;
    // case "cancelled": wording = t("cancelled"); break;
    // case "expired": wording = t("expired"); break;
    return (
      <Select defaultValue="all" onChange={onStatusChange} style={{minWidth: 160}}>
        <Option value="all">All</Option>
        <Option value={9}>{t("completed")}</Option>
        <Option value={0}>{t("pending")}</Option>
        <Option value={1}>{t("refund")}</Option>
        <Option value={-2}>{t("fail")}</Option>
      </Select>
    )
  }

  const filterData = () => {
    debounce(() => {
      let searchedList = dataList;
      if (_.isEmpty(dataList)) return;
      console.log('unChangeData', dataList);
      if (!_.isEmpty(searchKeyword)) {
        searchedList = getDataBySearchKeyword(searchKeyword);
        console.log(`searchedList >> keyword ${searchKeyword} >> `, searchedList)
      }

      if (searchStatus !== 'all') {
        searchedList = _.map(searchedList, (obj) => {
          if (_.isUndefined(obj)) return;
          if (obj.ptx_status === searchStatus) return obj;
        })
        console.log(`searchedList >> searchStatus ${searchStatus} >> `, searchedList)
      }

      if (minCreateTime >= 0) {
        searchedList = _.map(searchedList, (obj) => {
          if (_.isUndefined(obj)) return;
          if (minCreateTime < obj.ptx_create_time) return obj;
        })
        console.log(`searchedList >> minCreateTime ${minCreateTime} >> `, searchedList)
      }

      if (minCreateTime !== now) {
        searchedList = _.map(searchedList, (obj) => {
          if (_.isUndefined(obj)) return;
          if (obj.ptx_create_time < maxCreateTime) return obj;
        })
        console.log(`searchedList >> maxCreateTime ${maxCreateTime} >> `, searchedList)
      }

      if (minCompleteTime >= 0) {
        searchedList = _.map(searchedList, (obj) => {
          if (_.isUndefined(obj)) return;
          if (minCompleteTime < obj.ptx_complete_time) return obj;
        })
        console.log(`searchedList >> minCreateTime ${minCreateTime} >> `, searchedList)
      }

      if (maxCompleteTime !== now) {
        searchedList = _.map(searchedList, (obj) => {
          if (_.isUndefined(obj)) return;
          if (obj.ptx_complete_time < maxCompleteTime) return obj;
        })
        console.log(`searchedList >> maxCreateTime ${maxCreateTime} >> `, searchedList)
      }

      if (minAmount !== Number.MIN_SAFE_INTEGER) {
        searchedList = _.map(searchedList, (obj) => {
          if (_.isUndefined(obj)) return;
          if (minAmount <= obj.ptx_amount) return obj;
        })
        searchedList = _.compact(searchedList);
        console.log(`searchedList >> minAmount ${minAmount} >> `, searchedList)
      }

      if (maxAmount !== Number.MAX_SAFE_INTEGER) {
        searchedList = _.map(searchedList, (obj) => {
          if (_.isUndefined(obj)) return;
          if (obj.ptx_amount <= maxAmount) return obj;
        })
        console.log(`searchedList >> maxAmount ${maxAmount} >> `, searchedList)
      }

      if (payMethod.toLocaleUpperCase() !== 'ALL') {
        searchedList = _.map(searchedList, (obj) => {
          if (_.isUndefined(obj)) return;
          if (obj.ptx_pay_method === payMethod) return obj;
        })
        console.log(`searchedList >> payMethod ${payMethod} >> `, searchedList)
      }


      searchedList = _.compact(searchedList);
      console.log(`searchedList >> setFilterDataList >> `, searchedList)
      setFilterDataList(searchedList);
    }, 400);
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onKeywordChange = (keyword) => {
    setSearchKeyword(keyword);
  }

  const onCreateTimeChange = (moments) => {
    let start_date
    let end_date;
    console.log(moments);
    if (!_.isNull(moments)) {
      start_date = moments[0].unix();
      end_date = moments[1].unix();
    }
    if (_.isUndefined(start_date)) start_date = 0
    if (_.isUndefined(end_date)) end_date = now;

    console.log('start end', start_date, end_date)
    setMinCreateTime(start_date);
    setMaxCreateTime(end_date);
  };

  const onCompleteTimeChange = (moments) => {
    let start_date
    let end_date;
    console.log(moments);
    if (!_.isNull(moments)) {
      start_date = moments[0].unix();
      end_date = moments[1].unix();
    }
    if (_.isUndefined(start_date)) start_date = 0
    if (_.isUndefined(end_date)) end_date = now;

    console.log('start end', start_date, end_date)
    setMinCompleteTime(start_date);
    setMaxCompleteTime(end_date);
  };

  const onMinAmountChange = (value) => {
    if (_.isNull(value)) value = Number.MIN_SAFE_INTEGER
    setMinAmount(value*100);
  };
  const onMaxAmountChange = (value) => {
    if (_.isNull(value)) value = Number.MAX_SAFE_INTEGER
    setMaxAmount(value*100);
  };

  const onPaymentMethodChange = (value) => {
    setPayMethod(value);
  };

  const onExportTable = () => {
    const processedDataList = _.map(filterDataList, (data) => {
      return {
        ...data,
        ptx_create_time: data.ptx_create_time === 0 ? '-' : moment(data.ptx_create_time, 'X').format('YYYY-MM-DD HH:mm:ss'),
        ptx_complete_time: data.ptx_complete_time === 0 ? '-' : moment(data.ptx_complete_time, 'X').format('YYYY-MM-DD HH:mm:ss'),
      }
    });

    Main.exportTableToCSV(processedDataList, 'export');
  };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKey={openKey} onExportTable={onExportTable}>
      {/* <Button
        type="primary"
        onClick={() => {
          setSelectedRecord({ company_user_id: 0 });
          setModalVisible(true);
        }}
      >
        Create New User
      </Button> */}
      {/* <Divider /> */}
      <Row>
        <Col style={{marginTop: 5}}>
          {t("search_keywords")}
        </Col>
        <Col style={{marginLeft: 20}}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onKeywordChange(values.target.value);
              }
            }
          />
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <>{`${t('status')} `}</>
          {renderStatusSelectBox()}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <>{`${t('create_time')} `}</>
          <RangePicker
            showTime
            style={{ minWidth: 270, width: '50%' }}
            onChange={onCreateTimeChange}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{marginTop: 5}}>
          <>{`${t('complete_time')} `}</>
          <RangePicker
            showTime
            style={{ minWidth: 270}}
            onChange={onCompleteTimeChange}
          />
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <>{`${t('ptx_pay_method')} `}</>
          <Select defaultValue="all" onChange={onPaymentMethodChange} style={{minWidth: 160}}>
            <Option value="all">All</Option>
            <Option value="fiserv">{t("fiserv")}</Option>
            <Option value="token">{t("token")}</Option>
          </Select>
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <InputNumber
            style={{ minWidth: 30}}
            onChange={(values) => {
              onMinAmountChange(values);
            }}
          />
          <>{` < ${t('amount')} < `}</>
          <InputNumber
            style={{ minWidth: 30}}
            onChange={(values) => {
              onMaxAmountChange(values);
            }}
          />
        </Col>
      </Row>
      <Divider />
      <Spin spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
        />
        {/* <Modal
          destroyOnClose
          title="User Profile"
          visible={modalVisible}
          footer={null}
          width={1280}
          style={{maxWidth: "95%"}}
          onCancel={() => {
            setModalVisible(false);
          }}
        >
          <CompanyUserForm
            dataObj={selectedRecord}
            setModalVisible={setModalVisible}
          />
        </Modal> */}
      </Spin>
    </NavAndSideFrame>
  )
}

export default CompanyPaymentTransaction;