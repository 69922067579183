import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Col, Form, Input, Layout, Modal,
  notification, Radio, Row, Select, Tabs, Upload, message
} from 'antd';
import moment from 'moment';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import { formItemLayout, tailLayout } from '../components/ModalLayout';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CompanyAdminPasswordForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  useEffect(() => {
    delete props.dataObj.password
    form.resetFields();
  }, [props.dataObj]);

  const onFinish = async (postObj) => {
    let url = `/api/admin/pw`;
    // postObj.password = postObj.passwords;
    if (postObj.password !== postObj.re_password) {
      notification.error({
        message: 'Error',
        description:
          'Password not same, please input again',
      });
      return;
    }
    let postData = { ...props.dataObj, ...postObj }
    let data = await Service.call('patch', url, postData);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      return props.openModal(true);
    }
    message.success("Success");
    return props.openModal(false);
  };


  return (
    <Form
      {...formItemLayout}
      form={form}
      name="time_related_controls"
      onFinish={onFinish}
      initialValues={props.dataObj}
    >
      <Form.Item
        label={t('operation')}
        name="email"
        rules={[{ required: true, message: 'Please input username.' }]}
      >
        <Input disabled={props.dataObj.email} />
      </Form.Item>
      <Form.Item
        label={t('pwd')}
        name="password"
        rules={[{ required: true, message: 'Please input password.' }]}
      >
        <Input type='password' />
      </Form.Item>
      <Form.Item
        label={t('retype_pwd')}
        name="re_password"
        rules={[{ required: true, message: 'Please reinput password' }]}
      >
        <Input type='password' />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CompanyAdminPasswordForm;
