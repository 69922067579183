import React from 'react';
import {
  BrowserRouter, Redirect, Switch, Route
} from "react-router-dom";
import 'antd/dist/antd.css';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from './redux/actions';
import Login from './pages/Login';
import Home from './pages/admin/Home';
import Approve from './pages/Approve';
import AppMeta from './pages/AppMeta';
import SystemConfig from './pages/SystemConfig';
import AgreementDocuments from './pages/AgreementDocuments';
import CommonAreaList from './pages/admin/CommonAreaList';
import CompanyUserList from './pages/admin/CompanyUserList';
import CompanyTokenLog from './pages/admin/CompanyTokenLog';
import CompanyTokenPackage from './pages/admin/CompanyTokenPackage';
import CompanyPaymentTransaction from './pages/admin/CompanyPaymentTransaction';
import CompanyAdminList from './pages/CompanyAdminList';
import CompanyAdminGroup from './pages/CompanyAdminGroup';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import NotFound from './components/NotFound';
import BookingList from './pages/admin/BookingList';
import ExpiredLockerBooking from './pages/admin/ExpiredLockerBooking';
import BookNow from './pages/admin/BookNow';
import ItemList from './pages/admin/ItemList';
import ItemConfig from './pages/admin/ItemConfig';
import OrderList from './pages/OrderList';
import ParkingList from './pages/ParkingList';
import Dashboard from './pages/admin/Dashboard';

import PostsList from './pages/admin/PostsList';
import FAQList from './pages/FAQList';
import BookingRecordList from './pages/admin/BookingRecordList';
import Notification from './pages/admin/Notification';
import DiscountInfo from './pages/admin/DiscountInfo';
import DiscountInfoRoom from './pages/admin/DiscountInfoRoom';

class AppRoute extends React.PureComponent {
  render() {
    // console.log('Pure component AppRoute');
    return (
      <BrowserRouter>
        <Switch>
          <PublicRoute restricted path="/admin/login" component={Login} exact />

          <PublicRoute restricted path="/document/:type/:language" component={AgreementDocuments} exact />
          <PrivateRoute restricted path="/admin/home" component={Home} privilege="admin" exact />
          <PrivateRoute restricted path="/admin/dashboard" component={Dashboard} permissions={['ui_dashboard_r']} privilege="admin" exact />
          <PrivateRoute restricted path="/admin/notification" component={Notification} permissions={['ui_notification_r']} privilege="admin" exact />

          <PrivateRoute restricted path="/admin/discountinfo" component={DiscountInfo} privilege="admin" permissions={['ui_discount_info_r']} exact />
          <PrivateRoute restricted path="/admin/discountinforoom" component={DiscountInfoRoom} privilege="admin" permissions={['ui_discount_room_r']} exact />

          <PrivateRoute restricted path="/admin/user" component={CompanyUserList} privilege="admin" permissions={['ui_user_r']} exact />
          <PrivateRoute restricted path="/admin/approve" component={Approve} privilege="admin" permissions={['ui_user_approve_r']} exact />

          <PrivateRoute restricted path="/admin/booking/expired_locker_booking" component={ExpiredLockerBooking} privilege="admin" permissions={['ui_expired_locker_booking_r']} exact />
          <PrivateRoute restricted path="/admin/booking/book_now" component={BookNow} privilege="admin" permissions={['ui_book_now_r']} exact />
          <PrivateRoute restricted path="/admin/booking/list" component={BookingList} privilege="admin" permissions={['ui_booking_list_r']} exact />
          <PrivateRoute restricted path="/admin/booking/record/list" component={BookingRecordList} privilege="admin" permissions={['ui_booking_record_list_r']} exact />
          <PrivateRoute restricted path="/admin/item/list" component={ItemList} privilege="admin" permissions={['ui_item_list_r']} exact />
          <PrivateRoute restricted path="/admin/item/config" component={ItemConfig} privilege="admin" permissions={['ui_item_config_r']} exact />

          <PrivateRoute restricted path="/admin/order/list" component={OrderList} privilege="admin" permissions={['ui_order_list_r']} exact />
          <PrivateRoute restricted path="/admin/order/common_area_list" component={CommonAreaList} privilege="admin" permissions={['ui_common_area_list_r']} exact />
          <PrivateRoute restricted path="/admin/order/parking_list" component={ParkingList} privilege="admin" permissions={['ui_parking_list_r']} exact />
          <PrivateRoute restricted path="/admin/payment/transaction/log" component={CompanyPaymentTransaction} privilege="admin" permissions={['ui_transaction_log_r']} exact />

          <PrivateRoute restricted path="/admin/posts/list" component={PostsList} privilege="admin" permissions={['ui_posts_list_r']} exact />
          <PrivateRoute restricted path="/admin/faq" component={FAQList} privilege="admin" permissions={['ui_faq_r']} exact />
          <PrivateRoute restricted path="/admin/app_config" component={AppMeta} privilege="admin" permissions={['ui_meta_r']} exact />

          <PrivateRoute restricted path="/admin/token/log" component={CompanyTokenLog} privilege="admin" permissions={['ui_token_log_r']} exact />
          <PrivateRoute restricted path="/admin/token/package" component={CompanyTokenPackage} privilege="admin" permissions={['ui_token_package_r']} exact />

          <PrivateRoute restricted path="/admin/sys_config" component={SystemConfig} privilege="admin" permissions={['ui_sys_config_r']} exact />

          <PrivateRoute restricted path="/admin/company_admin" component={CompanyAdminList} privilege="admin" permissions={['ui_admin_r']} exact />
          <PrivateRoute restricted path="/admin/company_admin_group" component={CompanyAdminGroup} privilege="admin" permissions={['ui_admin_group_r']} exact />
          <Route restricted path="/404" component={NotFound} exact />
          <Redirect exact from="/*" to="/404" />
        </Switch>
      </BrowserRouter>
    )
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoute);
