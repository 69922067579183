import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "querystring";
import * as debugLib from "debug";
import * as Service from "../core/Service";

const debug = debugLib("app:src/admin/src/components/SystemConfigModal");

const {Option} = Select;
const SystemConfigModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
  } = props;
  const [initialFormValues, setInitialFormValues] = useState({});
  const [type, setType] = useState("int");
  let [SystemConfigForm] = Form.useForm();

  useEffect(() => {
    setInitialFormValues(selectedRecord);
    setType(selectedRecord.config_type);
  }, [selectedRecord]);

  useEffect(() => {
    SystemConfigForm.resetFields();
  }, [initialFormValues]);

  const renderValueInput = () => {
    let { config_key } = selectedRecord;
    if (config_key === 'booking_limitation_type_parking'
    || config_key === 'booking_limitation_type_room') {
      return (
        <Select onChange={(value) => setType(value)}>
          <Option value="0">{`0 (${t('by_all_time')})`}</Option>
          <Option value="1">{`1 (${t('by_week')})`}</Option>
          <Option value="2">{`2 (${t('by_month')})`}</Option>
        </Select>
      )
    }

    switch (type) {
      case 'int':
      case 'number':
        if (_.includes([
          'order_cancel_time',
          'cancel_booking_return_token_time',
          'booking_interval',
          'otp_expiry_interval'
        ], config_key)) {
          return (
            <InputNumber
              formatter={(value) => { return type === 'int' ? `${_.floor(value)}${t('seconds')}` : `${value}${t('seconds')}` }}
              parser={(value) => { return type === 'int' ? _.floor(value) : value }}
            />
          );
        }

        return (
          <InputNumber
            formatter={(value) => { return type === 'int' ? _.floor(value) : value }}
            parser={(value) => { return type === 'int' ? _.floor(value) : value }}
          />
        );
      default: return <Input />
    }
  }

  const onFinish = async (formData) => {
    let endpoint = '/api/sys_config';
    console.log(formData);
    let params = {
      config_value: formData.config_value,
      config_type: formData.config_type,
      title: formData.title,
      remark: formData.remark,
    };

    if (params.config_type === 'int') params.config_value = _.floor(params.config_value);
    params.config_key = selectedRecord.config_key;

    let resp = await Service.call('PUT', endpoint, params);
    console.log('resp >>> ', resp);
    if (!resp || resp.status < 1) {
      message.error(resp.errorMessage);
      return;
    }
    notification.success({
      message: t('Success'),
    });
    // message.success(t('modal_success'));
    setModalVisible(false);
    // getData();
  }
  return (
    <Modal
      title={t('edit')}
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{maxWidth: "95%"}}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={SystemConfigForm}
        name="SystemConfigForm"
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={onFinish}
      >
        <Form.Item
          label={t("key")}
          name="config_key"
          // rules={[{ required: true, message: t('input_required') }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={t("title")}
          name="title"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("value")}
          name="config_value"
          rules={[{ required: true, message: t('input_required') }]}
        >
          {renderValueInput()}
        </Form.Item>
        <Form.Item
          label={t("remark")}
          name="remark"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("type")}
          name="config_type"
          rules={[{ required: true, message: t('select_required') }]}
        >
          <Select onChange={(value) => setType(value)} disabled>
            <Option value="int">int</Option>
            <Option value="string">string</Option>
            <Option value="json">json</Option>
            <Option value="number">number</Option>
            <Option value="comma-separated">comma-separated</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default SystemConfigModal;
