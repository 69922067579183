import React, {
  Component, useState, useEffect, useRef
} from 'react';
import {
  Spin, Row, Col, Divider, Badge, Button, Radio, Form, Icon, Layout, Menu, Modal, Checkbox, Table, Tag, Tabs, Tooltip, Select, Input, Space, Drawer, Switch, Typography, Card, InputNumber, DatePicker
} from 'antd';
import {
  UserOutlined,
  ScheduleOutlined,
  EditOutlined,
  EyeOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import moment from 'moment';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import debug from 'debug';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import NavAndSideFrame from '../components/NavAndSideFrame';
import OrderFormModal from '../components/OrderFormModal';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;
// const title = "Order List";
const selectedKey = 'order_list';
const openKey = 'order';
const filterableFields = [
  `order_key`,
  `user_id`,
  `nickname`,
  `email`,
  `date_completed`,
  `currency`,
  `original_price`,
  `final_price`,
  `discount`,
  `token_used`,
  `totken_earned`,
  // `ptx_key`,

];

const OrderList = (props) => {
  const { t } = useTranslation();
  const title = t("order_list");
  const now = moment().unix();
  const momentNow = moment();

  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchStatus, setSearchStatus] = useState('all');
  const [minCompleteDate, setMinCompleteDate] = useState(0);
  const [maxCompleteDate, setMaxCompleteDate] = useState(now);

  const [minOriginalPrice, setMinOriginalPrice] = useState(Number.MIN_SAFE_INTEGER);
  const [maxOriginalPrice, setMaxOriginalPrice] = useState(Number.MAX_SAFE_INTEGER);
  const [minDiscount, setMinDiscount] = useState(Number.MIN_SAFE_INTEGER);
  const [maxDiscount, setMaxDiscount] = useState(Number.MAX_SAFE_INTEGER);
  const [minFinalPrice, setMinFinalPrice] = useState(Number.MIN_SAFE_INTEGER);
  const [maxFinalPrice, setMaxFinalPrice] = useState(Number.MAX_SAFE_INTEGER);

  const tableIDName = "order_table";

  useEffect(() => {
    setLoading(true);
    getAllData();
  }, [modalVisible]);

  const getAllData = async () => {
    let result = await Service.call('GET', '/api/order/list');
    result = _.orderBy(result, ['utime'], ['desc'])
    // console.log('datalist >>>>>', result)
    // Handle price for display
    result.map((item) => {
      item.original_price /= 100;
      item.final_price /= 100;
      item.discount /= 100;
    })
    setDataList(result);
    setFilterDataList(result);
    setLoading(false);
  }

  const setTableHeader = () => {
    let columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={<EyeOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setSelectedRecord(record)
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              {/* <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={async () => {
                    if (isChangingIsActive) return;
                    setChangingIsActive(true);
                    let is_active = record.is_active ? 0 : 1
                    await Service.call('PATCH', '/api/item/holiday/status',
                      {
                        item_holiday_id: record.item_holiday_id,
                        is_active
                      });
                    // getAllData();
                    setChangingIsActive(false);
                  }}
                />
              </Tooltip> */}
            </span>
          )
        }
      },
      {
        title: t('order_key'),
        dataIndex: 'order_key',
        // sorter: (a, b) => a.order_key.localeCompare(b.order_key)
      },
      // {
      //   title: t('active_status'),
      //   dataIndex: 'is_active',
      //   render: (value) => displayIsActive(value),
      //   sorter: (a, b) => a.is_active - b.is_active
      // },
      {
        title: t('status'),
        dataIndex: 'status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.status.localeCompare(b.status)
      },
      {
        title: t('name'),
        dataIndex: 'nickname',
        sorter: (a, b) => a.nickname.localeCompare(b.nickname)
      },
      {
        title: t('email'),
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email)
      },
      {
        title: t('date_completed'),
        dataIndex: 'date_completed',
        sorter: (a, b) => a.date_completed - b.date_completed,
        render: (value) => displayMoment(value),
      },
      // {
      //   title: t('currency'),
      //   dataIndex: 'currency',
      //   sorter: (a, b) => a.currency - b.currency
      // },
      {
        title: t('original_price'),
        dataIndex: 'original_price',
        sorter: (a, b) => a.original_price - b.original_price,
        render: (value) => displayCurrency(value),
      },
      {
        title: t('discount'),
        dataIndex: 'discount',
        sorter: (a, b) => a.discount - b.discount,
        render: (value) => displayCurrency(value),
      },
      {
        title: t('discount_info_id'),
        dataIndex: 'discount_info_id',
        sorter: (a, b) => a.discount_info_id - b.discount_info_id,
      },
      {
        title: t('token_used'),
        dataIndex: 'token_used',
        sorter: (a, b) => a.token_used - b.token_used
      },
      {
        title: t('final_price'),
        dataIndex: 'final_price',
        sorter: (a, b) => a.final_price - b.final_price,
        render: (value) => displayCurrency(value),
      },
      {
        title: t('token_earned'),
        dataIndex: 'token_earned',
        sorter: (a, b) => a.token_earned - b.token_earned
      },
      // {
      //   title: t('ptx_key'),
      //   dataIndex: 'ptx_key',
      //   sorter: (a, b) => a.ptx_key - b.ptx_key
      // },
    ]

    return columns;
  };

  const displayStatus = (value) => {
    let status = _.toString(value);
    let wording = ``;
    switch (status) {
      case "placed": wording = t("placed"); break;
      case "paid": wording = t("paid"); break;
      case "payment_confirmed": wording = t("payment_confirmed"); break;
      case "payment_failed": wording = t("payment_failed"); break;
      case "payment_refund": wording = t("payment_refund"); break;
      case "in_delivery": wording = t("in_delivery"); break;
      case "cancelled": wording = t("cancelled"); break;
      case "expired": wording = t("expired"); break;
      default: wording = t('error');
    }
    return wording;
  }

  const displayCurrency = (value) => {
    let amount = Intl.NumberFormat().format(value);
    return "$" + amount
  }

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
    let displayTime = '';
    if (unixTime !== 0) {
      displayTime = moment.unix(unixTime).format(outputFormat);
    } else {
      displayTime = '-'
    }
    return displayTime;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const getDataBySearchKeyword = (keywords) => {
    let searchedList = [];
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      };
      const fuse = new Fuse(dataList, options);
      const result = fuse.search(keywords);
      searchedList = _.map(result, "item");
      searchedList = _.orderBy(searchedList, ["user_id"], ["desc"]);
      return searchedList
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      // setDataList(dataList);
    }
  };

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };


  useEffect(() => {
    debounce(() => {
      let searchedList = dataList;
      if (_.isEmpty(dataList)) return;
      console.log('unChangeData', dataList);
      if (!_.isEmpty(searchKeyword)) {
        searchedList = getDataBySearchKeyword(searchKeyword);
        console.log(`searchedList >> keyword ${searchKeyword} >> `, searchedList)
      }

      if (!_.isEmpty(searchStatus) && searchStatus !== 'all') {
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.status === searchStatus) return rowData;
        })
      }

      if (minDiscount !== Number.MIN_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.discount > minDiscount) return rowData;
        });
        console.log(`searchedList >> minOwnToken ${minDiscount} >> `, searchedList)
      }

      if (maxDiscount !== Number.MAX_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.discount < maxDiscount) return rowData;
        });
        console.log(`searchedList >> maxOwnToken ${maxDiscount} >> `, searchedList)
      }

      if (minCompleteDate !== 0) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.date_completed > minCompleteDate) return rowData;
        });
        console.log(`searchedList >> minCompleteDate ${minCompleteDate} >> `, searchedList)
      }

      if (maxCompleteDate !== now) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.date_completed < maxCompleteDate) return rowData;
        });
        console.log(`searchedList >> maxCompleteDate ${maxCompleteDate} >> `, searchedList)
      }

      if (minOriginalPrice !== Number.MIN_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.original_price > minOriginalPrice) return rowData;
        });
        console.log(`searchedList >> minOwnToken ${minOriginalPrice} >> `, searchedList)
      }

      if (maxOriginalPrice !== Number.MAX_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.original_price < maxOriginalPrice) return rowData;
        });
        console.log(`searchedList >> maxOwnToken ${maxOriginalPrice} >> `, searchedList)
      }

      if (minFinalPrice !== Number.MIN_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.final_price > minFinalPrice) return rowData;
        });
        console.log(`searchedList >> minFinalPrice ${minFinalPrice} >> `, searchedList)
      }

      if (maxFinalPrice !== Number.MAX_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.final_price < maxFinalPrice) return rowData;
        });
        console.log(`searchedList >> maxFinalPrice ${maxFinalPrice} >> `, searchedList)
      }

      searchedList = _.compact(searchedList);
      setFilterDataList(searchedList);
    }, 400);
  }, [
    searchKeyword,
    searchStatus,
    minCompleteDate,
    maxCompleteDate,
    minDiscount,
    maxDiscount,
    minOriginalPrice,
    maxOriginalPrice,
    minFinalPrice,
    maxFinalPrice
  ]);


  const onKeywordChange = (keyword) => {
    setSearchKeyword(keyword);
  };

  const onStatusChange = (status) => {
    setSearchStatus(status);
  };

  const onCompleteDateChange = (moments) => {
    let start_date
    let end_date;
    console.log(moments);
    if (!_.isNull(moments)) {
      start_date = moments[0].unix();
      end_date = moments[1].unix();
    }
    if (_.isUndefined(start_date)) start_date = 0
    if (_.isUndefined(end_date)) end_date = now;

    console.log('start end',start_date,end_date)
    setMinCompleteDate(start_date);
    setMaxCompleteDate(end_date);
  };

  const onMinOriginalPriceChange = (value) => {
    if (_.isNull(value)) value = Number.MIN_SAFE_INTEGER
    setMinOriginalPrice(value);
  };
  const onMaxOriginalPriceChange = (value) => {
    if (_.isNull(value)) value = Number.MAX_SAFE_INTEGER
    setMaxOriginalPrice(value);
  };

  const onMinDiscountChange = (value) => {
    if (_.isNull(value)) value = Number.MIN_SAFE_INTEGER
    setMinDiscount(value);
  };
  const onMaxDiscountChange = (value) => {
    if (_.isNull(value)) value = Number.MAX_SAFE_INTEGER
    setMaxDiscount(value);
  };

  const onMinFinalPriceChange = (value) => {
    if (_.isNull(value)) value = Number.MIN_SAFE_INTEGER
    setMinFinalPrice(value);
  };
  const onMaxFinalPriceChange = (value) => {
    if (_.isNull(value)) value = Number.MAX_SAFE_INTEGER
    setMaxFinalPrice(value);
  };

  const renderStatusSelectBox = () => {
    // case "placed": wording = t("placed"); break;
    // case "paid": wording = t("paid"); break;
    // case "payment_confirmed": wording = t("payment_confirmed"); break;
    // case "payment_failed": wording = t("payment_failed"); break;
    // case "payment_refund": wording = t("payment_refund"); break;
    // case "in_delivery": wording = t("in_delivery"); break;
    // case "cancelled": wording = t("cancelled"); break;
    // case "expired": wording = t("expired"); break;
    return (
      <Select defaultValue="all" onChange={onStatusChange} style={{minWidth: 160}}>
        <Option value="all">All</Option>
        <Option value="placed">{t("placed")}</Option>
        <Option value="paid">{t("paid")}</Option>
        <Option value="payment_confirmed">{t("payment_confirmed")}</Option>
        <Option value="payment_failed">{t("payment_failed")}</Option>
        <Option value="payment_refund">{t("payment_refund")}</Option>
        <Option value="in_delivery">{t("in_delivery")}</Option>
        <Option value="cancelled">{t("cancelled")}</Option>
        <Option value="expired">{t("expired")}</Option>
      </Select>
    )
  }

  const onExportTable = () => {
    const processedDataList = _.map(filterDataList, (data) => {
      return {
        ...data,
        date_completed: data.date_completed === 0 ? '-' : moment(data.date_completed, 'X').format('YYYY-MM-DD HH:mm:ss'),
      }
    });

    Main.exportTableToCSV(processedDataList, 'export');
  };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKey={openKey} onExportTable={onExportTable}>
      <Row>
        <Col style={{marginLeft: 20}}>
          <>{`${t('search')} `}</>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onKeywordChange(values.target.value);
              }
            }
          />
        </Col>
        <Col style={{marginLeft: 20}}>
          <>{t('date_completed')}</>
          <RangePicker
            // defaultValue={[moment().subtract(1, 'month'), momentNow]}
            style={{ minWidth: 270, width: '50%' }}
            onChange={onCompleteDateChange}
          />
        </Col>
      </Row>
      <Row style={{marginTop: 10}}>
        <Col style={{ marginLeft: 20 }}>
          <InputNumber
            style={{ minWidth: 30, width: "20%" }}
            onChange={(values) => {
              onMinFinalPriceChange(values);
            }}
          />
          <>{` < ${t('final_price')} < `}</>
          <InputNumber
            style={{ minWidth: 30, width: "20%" }}
            onChange={(values) => {
              onMaxFinalPriceChange(values);
            }}
          />
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <InputNumber
            style={{ minWidth: 30, width: "20%" }}
            onChange={(values) => {
              onMinOriginalPriceChange(values);
            }}
          />
          <>{` < ${t('original_price')} < `}</>
          <InputNumber
            style={{ minWidth: 30, width: "20%" }}
            onChange={(values) => {
              onMaxOriginalPriceChange(values);
            }}
          />
        </Col>
      </Row>
      <Row style={{marginTop: 10}}>
        <Col style={{ marginLeft: 20 }}>
          <InputNumber
            style={{ minWidth: 30, width: "20%" }}
            onChange={(values) => {
              onMinDiscountChange(values);
            }}
          />
          <>{` < ${t('discount')} < `}</>
          <InputNumber
            style={{ minWidth: 30, width: "20%" }}
            onChange={(values) => {
              onMaxDiscountChange(values);
            }}
          />
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <>{`${t('status')} `}</>
          {renderStatusSelectBox()}
        </Col>
      </Row>
      <Divider />
      <Spin spinning={loading}>
        <Table
          bordered
          size="small"
          pagination={{
            defaultPageSize: 25,
            showSizeChanger: true,
            pageSizeOptions: [25, 50, 100, 200, 500]
          }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
        />
        <OrderFormModal
          modalVisible={modalVisible}
          selectedOrder={selectedRecord}
          setModalVisible={setModalVisible}
        />
      </Spin>
    </NavAndSideFrame>
  )
}

export default OrderList;
