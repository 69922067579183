import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Spin, Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Tabs
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import CompanyUserForm from '../../components/CompanyUserForm';
import CanpanyUserPasswordForm from '../../components/CompanyUserPasswordForm';
import CompanyUserTable from '../../components/CompanyUserTable';
import ItemRuleSet from '../../components/item/ItemRuleSet';
import ItemHolidayTable from '../../components/item/ItemHolidayTable';
import ItemLinkageList from '../../components/item/ItemLinkageList';
import ItemRuleSetGroup from '../../components/item/ItemRuleSetGroup';
import CategoryInfoList from '../../components/item/CategoryInfoList'

const debug = require('debug')('app:pages/admin/ItemConfig');

// const title = "Item Config";
const selectedKey = "item_config";
const openKey = "booking";
const { TabPane } = Tabs;

const ItemConfig = (props) => {
  const { t } = useTranslation()
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_item_config_w')
  );

  return (
    <NavAndSideFrame {...props} title={t("item_config")} selectedKey={selectedKey} openKey={openKey}>
      <Tabs defaultActiveKey={1}>
        <TabPane tab={t('item_category')} key="1">
          <CategoryInfoList disableEdit={disableEdit} />
        </TabPane>
        <TabPane tab={t('holiday')} key="2">
          <ItemHolidayTable disableEdit={disableEdit} />
        </TabPane>
        <TabPane tab={t('ruleset_group')} key="3">
          <ItemRuleSetGroup disableEdit={disableEdit} />
        </TabPane>
        <TabPane tab={t('ruleset')} key="4">
          <ItemRuleSet disableEdit={disableEdit} />
        </TabPane>
        <TabPane tab={t('linkage')} key="5">
          <ItemLinkageList disableEdit={disableEdit} />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

export default ItemConfig;
