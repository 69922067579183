import React, {
  Component, useState, useEffect, useRef
} from 'react';
import {
  Spin, Badge, Button, Row, Col, Table, Tag, Tooltip, Popconfirm, message, Select, Input, Space, Drawer, Switch, Typography, Card, Divider
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  ScheduleOutlined,
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import moment from 'moment';
import Fuse from 'fuse.js';
import _ from 'lodash';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import { useTranslation } from 'react-i18next';
import NavAndSideFrame from '../components/NavAndSideFrame';
import FAQFormModal from '../components/FAQFormModal';

const { Paragraph } = Typography;

// const title = "FAQ List";
const selectedKey = "faq";
const openKey = "app_config";
const tableIDName = "faq_id";
const filterableFields = [
  `faq_title`,
  `faq_content`
];

const FAQList = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [sortingList, setSortingList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [maxSorting, setMaxSorting] = useState(1);
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_faq_w')
  );

  useEffect(() => {
    getAllData();
  }, [modalVisible]);

  const getAllData = async () => {
    setLoading(true);
    let resp = await Service.call('GET', '/api/faq/list');
    if (!resp || resp.status !== 1) {
      return;
    }
    let {data, translationList} = resp;
    resp = Main.mergeTranslationList(Main.TABLE_CONFIG.faq, data, translationList)
    // console.log(resp);
    setDataList(resp);
    setSortingList(resp);
    setFilterDataList(resp);
    let lastRecord = _.last(resp);
    if (!_.isEmpty(lastRecord)) setMaxSorting(lastRecord.sorting + 1);
    setLoading(false);
  }

  const moveUp = async(record) => {
    let target_sorting = record.sorting;
    let newSortingList = sortingList;
    _.each(sortingList, (obj) => {
      if (obj.sorting === target_sorting - 1) {
        obj.sorting += 1;
      } else if (obj.sorting === target_sorting) {
        obj.sorting -= 1;
      }
    })
    setSortingList(_.orderBy(newSortingList, ['sorting'], ['asc']));
  }

  const moveDown = async(record) => {
    let target_sorting = record.sorting;
    let newSortingList = sortingList;
    _.each(sortingList, (obj) => {
      if (obj.sorting === target_sorting) {
        obj.sorting += 1;
      } else if (obj.sorting === target_sorting + 1) {
        obj.sorting -= 1;
      }
    })
    setSortingList(_.orderBy(newSortingList, ['sorting'], ['asc']));
  }

  const updateSorting = () => {
    // console.log(dataList);
    // console.log(sortingList);
    let endpoint = '/api/faq/sorting';

    _.map(dataList, async (data, i) => {
      if (data.faq_id !== sortingList[i].faq_id) {
        await Service.call('PUT', endpoint, {
          faq_id: sortingList[i].faq_id,
          sorting: sortingList[i].sorting,
        })
      }
    });

    getAllData();
  }

  const deleteFAQ = async (faq_id) => {
    let endpoint = '/api/faq/delete';
    console.log(faq_id);
    let result = await Service.call('PUT', endpoint, {faq_id});
    console.log(result);
    getAllData();
  }

  const setTableHeader = () => {
    let columns = [
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('title'),
        dataIndex: 'faq_title',
        render: (value) => {
          return <Paragraph ellipsis={{rows: 2, expandable: false}} style={{maxWidth: 200, marginBottom: 0}}>{value}</Paragraph>
        }
        // sorter: (a, b) => a.faq_title - b.faq_title
      },
      {
        title: t('content'),
        dataIndex: 'faq_content',
        render: (value) => {
          return <Paragraph ellipsis={{rows: 2, expandable: false}} style={{maxWidth: 200, marginBottom: 0}}>{value}</Paragraph>
        }
        // sorter: (a, b) => a.faq_content - b.faq_content
      },
      {
        title: t('sorting'),
        dataIndex: 'sorting',
        render: (value, record) => {
          return (
            <Row>
              <Space>
                <Col>
                  <Button
                    size="small"
                    onClick={() => moveUp(record)}
                    disabled={record.sorting === 1}
                  >
                    <UpOutlined />
                  </Button>
                </Col>
                <Col>
                  {value}
                </Col>
                <Col>
                  <Button
                    size="small"
                    onClick={() => moveDown(record)}
                    disabled={record.sorting === sortingList.length}
                  >
                    <DownOutlined />
                  </Button>
                </Col>
              </Space>
            </Row>
          );
        },
        sorter: (a, b) => a.sorting - b.sorting
      },
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setSelectedRecord(record)
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={async () => {
                    if (isChangingIsActive) return;
                    setChangingIsActive(true);
                    let is_active = record.is_active ? 0 : 1
                    await Service.call('PATCH', '/api/faq/status',
                      {
                        faq_id: record.faq_id,
                        is_active
                      });
                    getAllData();
                    setChangingIsActive(false);
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={t('confirm_delete') + "?"}
                onConfirm={() => deleteFAQ(record.faq_id)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          )
        }
      });
    }
    return columns;
}

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      }
      const fuse = new Fuse(filterList, options)
      const result = fuse.search(filterObj);
      // console.log(result);
      filterList = _.map(result, 'item');
      // console.log(filterList);
      filterList = _.orderBy(filterList, ['user_id'], ['desc']);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      filterList = _.orderBy(filterList, 'user_id');
      setFilterDataList(filterList);
    }
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <NavAndSideFrame {...props} title={t("faq_list")} selectedKey={selectedKey} openKey={openKey} onExportTable={onExportTable}>
      <Row>
        {
          disableEdit ? '' : (
            <Col>
              <Button
                icon={<PlusOutlined />}
                style={{marginBottom: 10}}
                type="primary"
                onClick={() => {
                  setSelectedRecord({ faq_id: 0, sorting: maxSorting});
                  setModalVisible(true);
                }}
              >
                {t('create')}
              </Button>
            </Col>
          )
        }
        <Col style={{marginTop: 5, marginLeft: 10}}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{marginLeft: 20}}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
      </Row>
      {/* <Divider /> */}
      <Spin spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          size="middle"
        />
        <FAQFormModal
          modalVisible={modalVisible}
          selectedRecord={selectedRecord}
          setModalVisible={setModalVisible}
        />
      </Spin>
      <Row justify="end">
        <Button
          type="primary"
          onClick={updateSorting}
        >
          {t('submit')}
        </Button>
      </Row>
    </NavAndSideFrame>
  );
}

export default FAQList;