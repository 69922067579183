import React, {
  Component, useState, useEffect, useRef
} from 'react';
import {
  Spin, Row, Col, Divider, Badge, Button, Radio, Form, Icon, Layout, Menu, Modal, Checkbox, Table, Tag, Tabs, Tooltip, Select, Input, Space, Drawer, Switch, Typography, Card, DatePicker
} from 'antd';
import {
  UserOutlined,
  ScheduleOutlined,
  EditOutlined,
  EyeOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import moment from 'moment';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import NavAndSideFrame from '../components/NavAndSideFrame';
import ParkingFormModal from '../components/ParkingFormModal';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
// const title = "Order List";
const selectedKey = 'parking_list';
const openKey = 'order';
const filterableFields = [
  `name`,
  `license_plate`,
  `phone_number`,
  `nickname`,
];

const ParkingList = (props) => {
  const { t } = useTranslation();
  const title = t("parking_list");
  const now = moment().unix();

  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [minStartTime, setMinStartTime] = useState(0);
  const [maxStartTime, setMaxStartTime] = useState(now);
  const [minEndTime, setMinEndTime] = useState(0);
  const [maxEndTime, setMaxEndTime] = useState(now);

  const tableIDName = "Order Parking";

  useEffect(() => {
    setLoading(true);
    getAllData();
  }, [modalVisible]);

  useEffect(() => {
    filterData();
  }, [
    searchKeyword,
    minStartTime,
    maxStartTime,
    minEndTime,
    maxEndTime
  ]);

  const filterData = () => {
    debounce(() => {
      let searchedList = dataList;
      if (_.isEmpty(dataList)) return;
      console.log('unChangeData', dataList);
      if (!_.isEmpty(searchKeyword)) {
        searchedList = getDataBySearchKeyword(searchKeyword);
        console.log(`searchedList >> keyword ${searchKeyword} >> `, searchedList)
      }

      if (minStartTime !== 0) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.start_time > minStartTime) return rowData;
        });
        console.log(`searchedList >> minStartTime ${minStartTime} >> `, searchedList)
      }

      if (maxStartTime !== now) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.start_time < maxStartTime) return rowData;
        });
        console.log(`searchedList >> maxStartTime ${maxStartTime} >> `, searchedList)
      }

      if (minEndTime !== 0) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.end_time > minEndTime) return rowData;
        });
        console.log(`searchedList >> minEndTime ${minEndTime} >> `, searchedList)
      }

      if (maxEndTime !== now) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.end_time < maxEndTime) return rowData;
        });
        console.log(`searchedList >> maxEndTime ${maxEndTime} >> `, searchedList)
      }

      searchedList = _.compact(searchedList);
      console.log(`searchedList >> setFilterDataList >> `, searchedList)
      setFilterDataList(searchedList);
    }, 400);
  }


  const getAllData = async () => {
    let result = await Service.call('GET', '/api/order/parking/list');
    // console.log('order parking >>>', result);
    result = _.orderBy(result, ['ctime'], ['desc'])
    setDataList(result);
    setFilterDataList(result);
    setLoading(false);
  }

  const setTableHeader = () => {
    let columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={<EyeOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setSelectedRecord(record)
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              {/* <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={async () => {
                    if (isChangingIsActive) return;
                    setChangingIsActive(true);
                    let is_active = record.is_active ? 0 : 1
                    await Service.call('PATCH', '/api/item/holiday/status',
                      {
                        item_holiday_id: record.item_holiday_id,
                        is_active
                      });
                    // getAllData();
                    setChangingIsActive(false);
                  }}
                />
              </Tooltip> */}
            </span>
          )
        }
      },
      {
        title: t('name'),
        dataIndex: 'name',
        // sorter: (a, b) => a.order_key.localeCompare(b.order_key)
      },
      {
        title: t('mobile'),
        dataIndex: 'phone_number',
        // sorter: (a, b) => a.order_key.localeCompare(b.order_key)
      },
      {
        title: t('license_plate'),
        dataIndex: 'license_plate',
        // sorter: (a, b) => a.order_key.localeCompare(b.order_key)
      },
      {
        title: t('start_time'),
        dataIndex: 'start_time',
        sorter: (a, b) => a.start_time - b.start_time,
        render: (value) => moment.unix(value).format('YYYY/MM/DD HH:mm:ss'),
      },
      {
        title: t('end_time'),
        dataIndex: 'end_time',
        sorter: (a, b) => a.end_time - b.end_time,
        render: (value) => moment.unix(value).format('YYYY/MM/DD HH:mm:ss'),
      },
      {
        title: t('ctime'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format('YYYY/MM/DD HH:mm:ss'),
      },
      // {
      //   title: t('ptx_key'),
      //   dataIndex: 'ptx_key',
      //   sorter: (a, b) => a.ptx_key - b.ptx_key
      // },
    ]

    return columns;
  };

  const displayStatus = (value) => {
    let status = _.toString(value);
    let wording = ``;
    switch (status) {
      case "placed": wording = t("placed"); break;
      case "paid": wording = t("paid"); break;
      case "payment_confirmed": wording = t("payment_confirmed"); break;
      case "payment_failed": wording = t("payment_failed"); break;
      case "payment_refund": wording = t("payment_refund"); break;
      case "in_delivery": wording = t("in_delivery"); break;
      case "cancelled": wording = t("cancelled"); break;
      case "expired": wording = t("expired"); break;
      default: wording = t('error');
    }
    return wording;
  }

  const displayCurrency = (value) => {
    let amount = Intl.NumberFormat().format(value);
    return "$" + amount
  }

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
    let displayTime = '';
    if (unixTime !== 0) {
      displayTime = moment.unix(unixTime).format(outputFormat);
    } else {
      displayTime = '-'
    }
    return displayTime;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const getDataBySearchKeyword = (keywords) => {
    let searchedList = [];
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      };
      const fuse = new Fuse(dataList, options);
      const result = fuse.search(keywords);
      searchedList = _.map(result, "item");
      searchedList = _.orderBy(searchedList, ["user_id"], ["desc"]);
      return searchedList
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      // setDataList(dataList);
    }
  };

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    setSearchKeyword(keyword);
  };

  const onStartTimeChange = (moments) => {
    let start_date
    let end_date;
    console.log(moments);
    if (!_.isNull(moments)) {
      start_date = moments[0].unix();
      end_date = moments[1].unix();
    }
    if (_.isUndefined(start_date)) start_date = 0
    if (_.isUndefined(end_date)) end_date = now;

    console.log('start end', start_date, end_date)
    setMinStartTime(start_date);
    setMaxStartTime(end_date);
  };

  const onEndTimeChange = (moments) => {
    let start_date
    let end_date;
    console.log(moments);
    if (!_.isNull(moments)) {
      start_date = moments[0].unix();
      end_date = moments[1].unix();
    }
    if (_.isUndefined(start_date)) start_date = 0
    if (_.isUndefined(end_date)) end_date = now;

    console.log('start end', start_date, end_date)
    setMinEndTime(start_date);
    setMaxEndTime(end_date);
  };

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKey={openKey} onExportTable={onExportTable}>
      <Row>
        <Col style={{marginTop: 5, marginLeft: 10}}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{marginLeft: 20}}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
      </Row>
      <Row style={{marginTop: 10}}>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`start_time`) + `:`}
        </Col>
        <Col style={{marginLeft: 20}}>
          <RangePicker
            // defaultValue={[moment().subtract(1, 'month'), momentNow]}
            style={{ minWidth: 270, width: '50%' }}
            onChange={onStartTimeChange}
          />
        </Col>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`end_time`) + `:`}
        </Col>
        <Col style={{marginLeft: 20}}>
          <RangePicker
            // defaultValue={[moment().subtract(1, 'month'), momentNow]}
            style={{ minWidth: 270, width: '50%' }}
            onChange={onEndTimeChange}
          />
        </Col>
      </Row>
      <Divider />
      <Spin spinning={loading}>
        <Table
          bordered
          size="small"
          pagination={{
            defaultPageSize: 25,
            showSizeChanger: true,
            pageSizeOptions: [25, 50, 100, 200, 500]
          }}
          rowKey={(value) => value.order_parking_id}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
        />
        <ParkingFormModal
          modalVisible={modalVisible}
          selectedOrder={selectedRecord}
          setModalVisible={setModalVisible}
        />
      </Spin>
    </NavAndSideFrame>
  )
}

export default ParkingList;
