import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, message, notification
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  ControlOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import ParkingInfoForm from './ParkingInfoForm';

const debug = require('debug')('app:admin:client:src:pages:company:UserQuotaListTable');

const ParkingInfoTable = (props) => {
  const { t } = useTranslation();
  const tableIDName = "item_id";

  const [dataList, setDataList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [itemFilterList, setItemFilterList] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [modalVisible, setModalVisible] = useState(false);
  const {
    setLoading, setEventObj, loading, callRefresh, setRulesetModalVisible, setRulesetRecord, disableEdit
  } = props;

  useEffect(() => {
    getData()
  }, [])
  const displayUserType = (userType) => {
    let displayStr = '';
    let tagColor = 'green';
    switch (userType) {
      case "user":
        displayStr = t('user');
        tagColor = 'green';
        break;
      case "guest":
        displayStr = t("guest");
        tagColor = 'blue';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }
  const getData = async () => {
    let result = await Service.call('get', '/api/item/car_park/list')
    if (result.status !== 1) {
      return message.error(result.errorMessage);
    }
    // console.log('data >>>>', result)
    setDataList(result.data);
    setItemFilterList(result.data);
  }

  const handleIsActiveChange = async (item_id, value) => {
    let postData = {
      item_id,
      is_active: value
    }
    let submit = await Service.call('patch', '/api/item/car_park/is_active', postData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
    } else {
      notification.success({
        message: t('success'),
      });
      getData();
    }
  }
  const setTableHeader = () => {
    let columns = [
      // {
      //   title: t('name'),
      //   dataIndex: 'name',
      //   sorter: (a, b) => a.id - b.id
      // },
      // {
      //   title: t('user_type'),
      //   dataIndex: 'user_type',
      //   sorter: (a, b) => a.user_type.localeCompare(b.user_type),
      //   render: (value) => displayUserType(value)
      // },
      // // {
      // //   title: t('first_name'),
      // //   dataIndex: 'first_name',
      // //   sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      // // },
      // // {
      // //   title: t('last_name'),
      // //   dataIndex: 'last_name',
      // //   sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      // // },
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('item_name'),
        dataIndex: 'item_name',
        filters: _.map(itemFilterList, (value) => {
          return { text: value, value };
        }),
        onFilter: (value, record) => {
          return record.item_name === value;
        },
        sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      },
      {
        title: t('parking_slot_number'),
        dataIndex: 'quota',
        sorter: (a, b) => a.id - b.id
      },
      // {
      //   title: t('category_name'),
      //   dataIndex: 'category_name',
      //   filters: _.map(categoryFilterList, (value) => {
      //     return { text: value, value };
      //   }),
      //   onFilter: (value, record) => {
      //     return record.category_name === value;
      //   },
      //   sorter: (a, b) => a.category_name.localeCompare(b.category_name),
      // },
      // {
      //   title: t('start_time'),
      //   dataIndex: 'start_time',
      //   sorter: (a, b) => a.start_time - b.start_time,
      //   render: (start_time) => {
      //     return moment.unix(start_time).format('HH:mm')
      //   },
      // },
      // {
      //   title: t('end_time'),
      //   dataIndex: 'end_time',
      //   sorter: (a, b) => a.end_time - b.end_time,
      //   render: (end_time) => {
      //     return moment.unix(end_time).format('HH:mm')
      //   },
      // },
      // {
      //   title: t('section_duration'),
      //   dataIndex: 'section_duration',
      //   sorter: (a, b) => a.section_duration - b.section_duration,
      //   render: (value) => {
      //     return Math.round(value / 60) + t('mins')
      //   },
      // },
      // {
      //   title: t('section_price'),
      //   dataIndex: 'section_price',
      //   sorter: (a, b) => a.section_price - b.section_price,
      //   render: (value) => {
      //     return '$' + value
      //   },
      // },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format('YYYY-MM-DD HH:mm')
        },
      }
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    console.log('v >', value, 'R >>', record);
                    setModalVisible(true);
                    setSelectedRecord(record);
                  }}
                />
              </Tooltip>
              <Tooltip title={t('ruleset')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={<ControlOutlined />}
                  onClick={() => {
                    setRulesetModalVisible(true);
                    setRulesetRecord(record)
                  }}
                // disabled={record.is_active}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={() => {
                    let is_active = record.is_active ? 0 : 1
                    handleIsActiveChange(record.item_id, is_active)
                  }}
                />
              </Tooltip>
            </span>
          )
        }
      });
    }
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const onExportTable = () => {
    Main.exportTableToCSV(dataList, 'export');
  };

  return (
    <>
      {
        disableEdit ? '' : (
          <Button
            icon={<PlusOutlined />}
            style={{ marginBottom: 10 }}
            type="primary"
            onClick={() => {
              console.log('inside')
              setSelectedRecord({ item_id: 0 })
              setModalVisible(true)
            }}
          >
            {t('create')}
          </Button>
        )
      }
      <Button
        style={{
          float: 'right',
          marginBottom: 10,
          maxHeight: 32,
        }}
        icon={<FileExcelOutlined />}
        onClick={onExportTable}
      >
        {t('export_to_excel_file')}
      </Button>
      <Layout>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setTableHeader()}
          bordered
          size="small"
          loading={loading}
          pagination={{ pageSize }}
          onChange={(pagination, filters, sorter, extra) => {
            if (categoryFilter !== filters.category_name) {
              if (filters.category_name !== null) {
                setCategoryFilter(filters.category_name)
              } else {
                setCategoryFilter([])
              }
            }
            if (pageSize !== pagination.pageSize) {
              setPageSize(pagination.pageSize)
            }
          }}
        />
      </Layout>
      <Modal
        title={selectedRecord.item_id > 0 ? t('edit') : t('create')}
        footer={null}
        width={1200}
        visible={modalVisible}
        onCancel={() => {
          // getAllData()
          setModalVisible(false);
        }}
      >
        <ParkingInfoForm
          selectedRecord={selectedRecord}
          onClick={(val) => setModalVisible(val)}
          getData={() => getData()}
        />
      </Modal>
    </>
  )
}
export default ParkingInfoTable;
