import React, { Component } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Layout, Menu, Divider, PageHeader } from 'antd';
import 'antd/dist/antd.css';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../redux/actions';
// import * as Service from './core/Service';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const { Content } = Layout;

class AppLayout extends React.PureComponent {
  render() {
    const {
      children,
      noSidebar,
      title
    } = this.props;
    return (
      <Layout style={{width: '100%'}}>
        <Navbar />
        <Layout>
          {
            noSidebar
              ? null
              : <Sidebar defaultSelectedKeys="0" />
          }
          <Layout style={{width: '100%'}}>
            <PageHeader
              title={title}
            />
            <Content
              style={{
                background: '#fff', margin: 0, minHeight: 280, padding: 24
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {...state.app};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppLayout);
