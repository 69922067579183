import React, {
  Component, useState, useEffect, useRef
} from 'react';
import {
  Badge, Button, Radio, Form, Icon, Layout, Menu, Modal, Checkbox, Table, Tag, Tabs, Tooltip, Select, Input, Space, Drawer, Switch, Typography, Card
} from 'antd';
import {
  UserOutlined,
  ScheduleOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'antd/dist/antd.css';
import moment from 'moment';
import Fuse from 'fuse.js';
import _ from 'lodash';
import * as Service from '../core/Service';

import NavAndSideFrame from '../components/NavAndSideFrame';
import UserApprove from '../components/UserApprove';

const { TabPane } = Tabs;
// const title = "Approve";
const selectedKey = 'approve';
const openKey = 'user';

const Approve = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    // console.log("Page Title", props);
  }, [props]);

  return (
    <NavAndSideFrame {...props} title={t("approve")} selectedKey={selectedKey} openKey={openKey}>
      <UserApprove />
    </NavAndSideFrame>
  )
};

export default Approve;
