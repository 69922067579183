import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';
import { ActionCreators } from './redux/actions';
import { checkPermission } from './utils';

// export default function PrivateRoute(props) {
//   const app = useSelector((state) => state.app);

//   return (
//     <Route
//       render={() => {
//         return (
//           app.auth === true
//             ? <Component />
//             : <Redirect to="/login" />
//         )}}
//     />
//   );
// }

class PrivateRoute extends React.PureComponent {
  render() {
    // const {component: Component, app, ...rest} = this.props;
    const {
      component: AppComponent,
      privilege,
      permissions,
      app,
    } = this.props;

    if (app.auth === null) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return (
        <div style={{position: 'absolute', top: '50%', left: '50%'}}>
          <Spin indicator={antIcon} />
        </div>
      )
    }
    let logoutWay = app.logoutWay;

    return (
      <Route
        render={(props) => {
          if (app.auth) {
            let hasPermission = checkPermission(permissions, app.adminUIPermissions);
            if (!hasPermission) {
              return <Redirect to="/admin/home" />
            }

            if (privilege === 'admin' && app.admin.company_user_id) {
              return <AppComponent />
            }
            if (privilege === 'company' && app.company_admin.company_admin_id) {
              return <AppComponent />
            }
            if (privilege === 'admin' && !app.admin.company_user_id) {
              return <Redirect to="/company/home" />
            }
            if (privilege === 'company' && !app.company_admin.company_admin_id) {
              return <Redirect to="/admin/home" />
            }
          }
          if (logoutWay === 'admin') {
            return <Redirect to="/admin/login" />
          }
          return <Redirect to="/company/login" />
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
