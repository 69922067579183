import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Select,
  Spin,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";

const { Option } = Select;
const debug = require("debug")("app:admin:client:src:BookNowRoomForm");

const BookNowRoomForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [timeSlotList, setTimeSlotList] = useState([]);

  const [userID, setUserID] = useState(null);
  const [selectedItemCategoryID, setSelectedItemCategoryID] = useState(null);
  const [selectedDateUnix, setSelectedDateUnix] = useState(null);
  const [selectedItemID, setSelectedItemID] = useState(null);

  const [userList, setUserList] = useState(null);

  const { t } = useTranslation();
  const { item_category_id: parent_category_id } = props;

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    getCategoryList();
  }, [props]);

  useEffect(() => {
    getItemDetail();
  }, [selectedDateUnix, selectedItemID]);

  const getUserList = async () => {
    setLoading(true);
    let result = await Service.call("get", '/api/user/list/approved?user_id');

    setUserList(result);
    setLoading(false);
  };

  const getCategoryList = async () => {
    setLoading(true);
    let req = await Service.call(
      "get",
      `/api/item/category/list/sub_category?parent_category_id=${parent_category_id}`
    );

    let { data } = req;
    let sortedData = _.sortBy(data, "sorting");
    setCategoryList(sortedData);
    setLoading(false);
  };

  const getItemList = async (mySelectedItemCategoryID) => {
    if (_.isNull(mySelectedItemCategoryID)) {
      return;
    }

    setLoading(true);
    let req = await Service.call(
      "get",
      `/api/item/list?item_category_id=${mySelectedItemCategoryID}`
    );

    let sortedItems = _.sortBy(req, "sorting");
    setItemList(sortedItems);
    setLoading(false);
  };

  const getItemDetail = async () => {
    if (_.isNull(selectedItemID) || _.isNull(selectedDateUnix)) {
      return;
    }

    setLoading(true);
    let req = await Service.call(
      "get",
      `/api/item/detail?item_id=${selectedItemID}&ts=${selectedDateUnix}`
    );

    let { bookingArr } = req;
    setTimeSlotList(bookingArr);
    setLoading(false);
  };

  const onCategoryOptionChange = (value) => {
    setItemList([]);
    setTimeSlotList([]);
    form.setFieldsValue({
      item_id: null,
      ts: null,
      bookingIDArr: [],
    });

    setSelectedItemCategoryID(value);
    getItemList(value);
  };

  const onSelectedItemChange = (value) => {
    setTimeSlotList([]);
    form.setFieldsValue({
      bookingIDArr: [],
    });

    setSelectedItemID(value);
  };

  const onSelectedDateChange = (value) => {
    setTimeSlotList([]);
    form.setFieldsValue({
      bookingIDArr: [],
    });

    setSelectedDateUnix(value.unix());
  };

  const onFinish = async (putObj) => {
    setLoading(true);
    let { bookingIDArr, user_id } = putObj;
    let remarks = "";

    let finalPutObj = { bookingIDArr, user_id, remarks };
    let data = await Service.call("put", `/api/order`, finalPutObj);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      setLoading(false);
      return;
    }
    message.success(t("Success"));
    form.resetFields();
    props.openModal(false);

    setCategoryList([]);
    setItemList([]);
    setTimeSlotList([]);
    setSelectedItemCategoryID(null);
    setSelectedDateUnix(null);
    setSelectedItemID(null);
    setUserList(null);

    setLoading(false);
  };

  const userOptionMap = () => {
    return (
      _.map(userList, (u) => {
        const { user_id, nickname, email } = u;
        return <Option value={user_id}>{`${nickname}(${email}) #${user_id}`}</Option>
      })
    )
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="BookNowRoomForm"
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label={t("book_now_form_user_id")}
          name="user_id"
          rules={[{ required: true }]}
        >
          <Select
            value={userID}
            onChange={(value) => { setUserID(value) }}
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) => optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())}
          >
            {userOptionMap()}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("book_now_form_category")}
          name="item_category_id"
          rules={[{ required: true }]}
        >
          <Select onChange={onCategoryOptionChange}>
            {_.map(categoryList, (room) => {
              let { item_category_id, category_name, is_active } = room;
              return (
                <Option
                  value={item_category_id}
                  disabled={!is_active}
                  key={item_category_id}
                >
                  {category_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("book_now_form_item_room")}
          name="item_id"
          rules={[{ required: true }]}
        >
          <Select
            onChange={onSelectedItemChange}
            disabled={_.isNull(selectedItemCategoryID)}
          >
            {_.map(itemList, (item) => {
              let { item_id, item_name, is_active } = item;
              return (
                <Option value={item_id} disabled={!is_active} key={item_name}>
                  {item_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("book_now_form_date")}
          name="ts"
          rules={[{ required: true, message: "Please select date." }]}
        >
          <DatePicker
            onChange={onSelectedDateChange}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label={t("book_now_form_time")}
          name="bookingIDArr"
          rules={[{ required: true }]}
        >
          <Select
            mode="multiple"
            allowClear
            disabled={timeSlotList.length === 0}
          >
            {_.map(timeSlotList, (timeslot) => {
              let {
                booking_id,
                start_time,
                section_duration,
                section_price,
                is_active,
                status,
              } = timeslot;
              return (
                <Option
                  value={booking_id}
                  disabled={!is_active || status !== 1}
                  key={start_time}
                >
                  {`${moment(start_time, "X").format("HH:mm")} - ${moment(
                    start_time + section_duration,
                    "X"
                  ).format("HH:mm")}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default BookNowRoomForm;
