import React, { useEffect } from 'react';
import {
  Form, Input, Button, message
} from 'antd'
import { useTranslation } from 'react-i18next';
import * as Service from '../core/Service';
import { formItemLayout, tailLayout } from "./ModalLayout";

const { TextArea } = Input;

const NotificationForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { setModalVisible } = props;

  const onFinish = async (postObj) => {
    let url = '/api/notification/global'
    let method = 'post'
    let result = await Service.call(method, url, postObj)
    if (result.status !== 1) {
      message.error(result.errorMessage)
    }
    message.success(t('success'))
    setModalVisible(false)
  }

  useEffect(() => {
    form.resetFields()
  }, [])

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Form
      {...formItemLayout}
      form={form}
      name="notification_form"
      onFinish={onFinish}
    // initialValues={props.dataObj}
    >
      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ fontWeight: 'bold' }}>To All Customer</div>
      </div> */}
      <Form.Item
        label={t('title')}
        name="title"
        rules={[{ required: true, message: t('please_input_title') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('title_zh_hant')}
        name="title_zh_hant"
        rules={[{ required: true, message: t('please_input_title') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('message')}
        name="content"
        rules={[{ required: true, message: t('please_input_content') }]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        label={t('message_zh_hant')}
        name="content_zh_hant"
        rules={[{ required: true, message: t('please_input_content') }]}
      >
        <TextArea />
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ fontWeight: 'bold' }}>{t('message_will_be_sent_after_1_min')}</div>
      </div>
      <Form.Item {...tailLayout} style={{ marginTop: 15 }}>
        <Button
          type="primary"
          htmlType="submit"
        >
          {t("submit")}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default NotificationForm;
