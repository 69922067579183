import React, { useState } from "react";
import _ from "lodash";
import moment from 'moment';
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import { FilePdfOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const ExportToPDFButton = (props) => {
  const { t } = useTranslation();
  const { targetID, paddingRight } = props;
  const [loading, setLoading] = useState(false);

  const exportToPdf = async () => {
    setLoading(true);
    const domElement = document.getElementById(targetID);
    const imgWidth = domElement.offsetWidth;
    const imgHeight = domElement.offsetHeight;
    const ratio = imgHeight / imgWidth;

    const canvas = await html2canvas(domElement, {
      dpi: 300,
      scale: 3,
      onclone: (document) => {
        _.each(document.getElementsByClassName("print-button"), (buttonElement) => {
          buttonElement.style.visibility = "hidden";
        });
      },
    });

    // Save as img instead
    // const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    // const a = document.createElement('a')
    // a.setAttribute('download', `${targetID}_${moment().format('YYYY_MM_DD_HHmmss')}.png`);
    // a.setAttribute('href', image);
    // a.click();

    const img = canvas.toDataURL("image/jpeg", 1);
    const doc = new JsPDF('l');
    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();
    const marginTop = 10;
    height = ratio * width - marginTop;
    width = height / ratio;
    doc.addImage(img, 'JPEG', (doc.internal.pageSize.getWidth() - width) / 2, marginTop, width, height);
    doc.save(`${targetID}_${moment().format('YYYY_MM_DD_HHmmss')}.pdf`);
    setLoading(false);
  };

  return (
    <div className="print-button" id="print-button" style={{position: 'absolute', right: (paddingRight || 0)}}>
      <Button
        icon={<FilePdfOutlined />}
        loading={loading}
        onClick={exportToPdf}
      >
        {t("export_to_pdf_file")}
      </Button>
    </div>
  );
};

export default ExportToPDFButton;
