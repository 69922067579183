import React, { useState, useEffect } from "react";
import _, { set } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  Spin
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "querystring";
import * as debugLib from "debug";
import { title } from "process";
import NavAndSideFrame from "./NavAndSideFrame";
import * as Main from "../core/Main";
import * as Service from '../core/Service';
import OrderItemList from '../pages/OrderItemList';

const OrderFormModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedOrder,
    modalVisible,
    setModalVisible
  } = props;
  const [isActive, setIsActive] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [orderItemDataList, setOrderItemDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [OrderForm] = Form.useForm();


  useEffect(() => {
    selectedOrder.final_price = '$' + selectedOrder.final_price;
    selectedOrder.original_price = '$' + selectedOrder.original_price;
    setInitialFormValues(selectedOrder)
  }, [modalVisible]);

  useEffect(() => {
    OrderForm.resetFields();
    setLoading(true);
    getOrderItemData();
  }, [initialFormValues])

  const getOrderItemData = async () => {
    let { order_id } = selectedOrder;
    let url = `/api/order_item?order_id=${order_id}`;
    let resp = await Service.call('GET', url);
    setOrderItemDataList(resp);
    setLoading(false);
  };
  const onFinish = async (formData) => {
    console.log('finish');
  }
  return (
    <Modal
      title={t("order_form")}
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={OrderForm}
        name={t("order_form")}
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={onFinish}
      >
        <Row justify="space-around">
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="user_id"
              label={t("user_id")}
            // rule={[required: true]}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="nickname"
              label={t("name")}
            // rule={[required: true]}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="status"
              label={t("status")}
              rule={[]}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around">
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="currency"
              label={t("currency")}
              rule={[]}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="original_price"
              label={t("original_price")}
              rule={[]}
            >
              <InputNumber min={0} disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="final_price"
              label={t("final_price")}
              rule={[]}
            >
              <InputNumber min={0} disabled={!isActive} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around">
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="discount"
              label={t("discount")}
              rule={[]}
            >
              <InputNumber min={0} disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="token_used"
              label={t("token_used")}
              rule={[]}
            >
              <InputNumber min={0} disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="token_earned"
              label={t("token_earned")}
              rule={[]}
            >
              <InputNumber min={0} disabled={!isActive} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around">
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="date_completed"
              label={t("date_completed")}
              rule={[]}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            {/* */}
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            {/* */}
          </Col>
        </Row>
        <Row justify="space-around">
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            {/* <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item> */}
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            {/* */}
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            {/* */}
          </Col>
        </Row>
        {/* <Form.Item
          name="ptx_key"
          label={t("ptx_key")}
          rule={[]}
        >
          <Input disabled="true" />
        </Form.Item> */}
      </Form>
      <Spin spinning={loading}>
        <OrderItemList orderItemDataList={orderItemDataList} />
      </Spin>
    </Modal>
  )
}
export default OrderFormModal;
