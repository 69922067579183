import React, {
  Component, useState, useEffect, useRef
} from 'react';
import {
  Badge, Button, Radio, Form, Icon, Layout, Menu, Modal, Checkbox, Table, Tag, Tabs, Tooltip, Select, Input, Space, Drawer, Switch, Typography, Card
} from 'antd';
import {
  UserOutlined,
  ScheduleOutlined,
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import moment from 'moment';
import Fuse from 'fuse.js';
import _ from 'lodash';
import * as Service from '../core/Service';
import { useTranslation } from 'react-i18next';

const title = "Order Item List";
const tableIDName = "order_item_id"
// const selectedKey = 5;

const OrderItemList = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);

  let {
    orderItemDataList
  } = props;
  useEffect(() => {
    updateDataList();
  }, [orderItemDataList]);

  const updateDataList = () => {
    // group by hash key
    let bookingMap = _.groupBy(orderItemDataList, (booking) => { return booking.hashkey });

    let displayBookingList = [];
    _.map(bookingMap, (group) => {
      // adding info accroding to hashkey group

      let displayBooking = {};
      _.map(group, (booking) => {
        if (_.isEmpty(displayBooking)) {
          displayBooking = booking;
          displayBooking.price = booking.price / 100
          return;
        }
        displayBooking.start_time = Math.min(displayBooking.start_time, booking.start_time);
        displayBooking.end_time = Math.max(displayBooking.end_time, booking.end_time);
        displayBooking.duration += booking.duration;
        displayBooking.price += booking.price / 100;
        displayBooking.remarks += ';\n' + booking.remarks;
      });
      displayBookingList.push(displayBooking);
    })
    setDataList(displayBookingList);
  }

  const setTableHeader = () => {
    let columns = [
      {
        title: t('category_name'),
        dataIndex: 'category_name',
        sorter: (a, b) => a.category_name.localeCompare(b.category_name)
      },
      {
        title: t('item_name'),
        dataIndex: 'item_name',
        sorter: (a, b) => a.item_name.localeCompare(b.item_name)
      },
      {
        title: t('start_time'),
        dataIndex: 'start_time',
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.start_time - b.start_time
      },
      {
        title: t('end_time'),
        dataIndex: 'end_time',
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.end_time - b.end_time
      },
      {
        title: t('duration'),
        dataIndex: 'duration',
        render: (value) => `${_.toInteger(value) / 60}Mins`,
        sorter: (a, b) => a.duration - b.duration
      },
      {
        title: t('currency'),
        dataIndex: 'currency',
        sorter: (a, b) => a.currency.localeCompare(b.currency)
      },
      {
        title: t('price'),
        dataIndex: 'price',
        sorter: (a, b) => a.price - b.price
      },
      {
        title: t('remarks'),
        dataIndex: 'remarks',
        // sorter: (a, b) => a.remarks - b.remarks
      }
      // {
      //   title: t('hashkey'),
      //   dataIndex: 'hashkey',
      //   sorter: (a, b) => a.hashkey - b.hashkey
      // },
    ]
    return columns;
  }

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
    let displayTime = '';
    if (unixTime !== 0) {
      displayTime = moment.unix(unixTime).format(outputFormat);
    } else {
      displayTime = '-'
    }
    return displayTime;
  }
  return (
    <Table
      rowKey={tableIDName}
      scroll={{ x: 'max-content' }}
      dataSource={dataList}
      columns={setTableHeader()}
    />
  );
};

export default OrderItemList;