import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  AutoComplete,
  Modal,
  Button,
  Label,
  Spin
} from "antd";
import * as Service from '../../core/Service';
import moment from 'moment';
import { tailLayout } from '../ModalLayout';


const { Option, OptGroup } = Select;

const ParkingInfoForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false)
  const [initialData, setInitialData] = useState({});
  const { t } = useTranslation();
  const [parkingForm] = Form.useForm();
  const { selectedRecord, onClick, getData } = props;

  useEffect(() => {
    console.log('inside form >>>>', props)
  }, [])
  useEffect(() => {
    setInitialData(selectedRecord);
  }, [selectedRecord]);

  useEffect(() => {
    parkingForm.resetFields();
  }, [initialData])

  const onFinish = async (postObj) => {
    setLoading(true);
    console.log(postObj);
    let method = 'post'
    if (selectedRecord.item_id > 0) {
      console.log('selectedRecord', selectedRecord)
      method = 'patch'
      postObj.item_id = selectedRecord.item_id;
    }
    // postObj.start_time = moment(postObj.start_time).unix();
    // postObj.end_time = moment(postObj.end_time).unix();
    let result = await Service.call(method, '/api/item/car_park', postObj)
    if (result.status !== 1) {
      return notification.error({
        message: t('error'),
        description: result.errorMessage,
      });
    }
    notification.success({
      message: t('Success'),
    });
    getData()
    setLoading(false)
    onClick(false)
  }
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Spin spinning={loading}>
      <Form
        {...layout}
        form={parkingForm}
        name="RuleSetForm"
        layout="horizontal"
        initialValues={initialData}
        onFinish={onFinish}
      >
        <Form.Item label={t('name')} name="item_name" rules={[{ required: true, message: t('input_required') }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t('parking_slot_number')} name="quota" rules={[{ required: true, message: t('input_required') }]}>
          <Input />
        </Form.Item>
        {/* <Form.Item label={t('start_time')} name="start_time" rules={[{ required: true, message: t('input_required') }]}>
          <TimePicker format="HH:mm" />
        </Form.Item>
        <Form.Item label={t('end_time')} name="end_time" rules={[{ required: true, message: t('input_required') }]}>
          <TimePicker format="HH:mm" />
        </Form.Item> */}
        <Form.Item
          label={t("prebooking_type")}
          name="prebooking_type"
          rules={[{ required: true, message: t('select_required') }]}
        >
          <Select
            disabled={isActive}
          >
            <Option value={1}>{t("one_week_before")}</Option>
            <Option value={3}>{t("two_week_before")}</Option>
            <Option value={2}>{t("one_month_before")}</Option>
          </Select>
        </Form.Item>
        {selectedRecord.item_id !== 0 ? (
          <Form.Item label={t("create_time")}>
            <p>{moment.unix(selectedRecord.ctime).format("YYYY-MM-DD HH:mm:ss")}</p>
          </Form.Item>
        ) : null}

        {selectedRecord.item_id !== 0 ? (
          <Form.Item label={t("utime")}>
            <p>{moment.unix(selectedRecord.utime).format("YYYY-MM-DD HH:mm:ss")}</p>
          </Form.Item>
        ) : null}
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default ParkingInfoForm;
