import React, { useState, useEffect } from "react";
import {
  Spin,
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Input,
  Modal,
  DatePicker,
  InputNumber
} from "antd";
import { DollarCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import moment from "moment";
import Fuse from "fuse.js";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import NavAndSideFrame from "../../components/NavAndSideFrame";
import PostsFormModal from "../../components/PostsFormModal";
import UserAddTokenForm from "../../components/UserAddTokenForm";


const { RangePicker } = DatePicker;
const tableIDName = "booking_id";
const selectedKey = "expired_locker_booking";
const openKey = "expired_locker_booking";
const filterableFields = [
  'booking_id',
  'item_name',
  'email',
  'mobile',
  'nickname',
  'sf_id'
];

const ExpiredLockerBooking = (props) => {
  const now = moment().unix();
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [addTokenModalVisible, setAddTokenModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [minStartDate, setMinStartDate] = useState(0);
  const [maxStartDate, setMaxStartDate] = useState(now);

  const [minEndDate, setMinEndDate] = useState(0);
  const [maxEndDate, setMaxEndDate] = useState(now);

  const [minOverTime, setMinOverTime] = useState(Number.MIN_SAFE_INTEGER);
  const [maxOverTime, setMaxOverTime] = useState(Number.MAX_SAFE_INTEGER);
  const [loading, setLoading] = useState(false);
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, "ui_all")
      && !_.includes(state.app.adminUIPermissions, "ui_expired_locker_booking_w")
  );

  useEffect(() => {
    setLoading(true);
    getAllData();
  }, [modalVisible]);

  const getAllData = async () => {
    try {
      let endpoint = `/api/booking_item/locker/expire_list`;
      let resp = await Service.call("GET", endpoint);
      setDataList(resp);
      setFilterDataList(resp);
    } catch (error) {
      // console.log('error>>>', error);
    }
    setLoading(false);
  };

  const setTableHeader = () => {
    let columns = [
      {
        title: t("booking_id"),
        dataIndex: "booking_id",
        sorter: (a, b) => a.booking_id - b.booking_id,
      },
      {
        title: t("start_time"),
        dataIndex: "start_time",
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.start_time - b.start_time,
      },
      {
        title: t("end_time"),
        dataIndex: "end_time",
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.end_time - b.end_time,
      },
      {
        title: t("overtime"),
        dataIndex: "overtimeHour",
        render: (value) => displayOvertime(value),
        sorter: (a, b) => a.overtime - b.overtime,
      },
      // {
      //   title: t('item_id'),
      //   dataIndex: 'item_id',
      //   sorter: (a, b) => a.item_id - b.item_id,
      // },
      {
        title: t("item_name"),
        dataIndex: "item_name",
        sorter: (a, b) => a.item_name - b.item_name,
      },
      {
        title: t("sf_id"),
        dataIndex: "sf_id",
        sorter: (a, b) => a.sf_id - b.sf_id,
      },
      {
        title: t("nickname"),
        dataIndex: "nickname",
        sorter: (a, b) => a.nickname - b.nickname,
      },
      {
        title: t("email"),
        dataIndex: "email",
        sorter: (a, b) => a.email - b.email,
      },
      {
        title: t("mobile"),
        dataIndex: "mobile",
        sorter: (a, b) => a.mobile - b.mobile,
      },
    ];

    if (!disableEdit) {
      columns.unshift({
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t("waive")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: "#000000" }}
                  icon={<DollarCircleOutlined />}
                  onClick={() => {
                    const mRecord = {
                      ...record,
                      quantity: record.token_required,
                      remarks: 'Waive locker expire'
                    }
                    setSelectedRecord(mRecord);
                    setAddTokenModalVisible(true);
                  }}
                />
              </Tooltip>
            </span>
          );
        },
      });
    }
    return columns;
  };

  const displayMoment = (unixTime, outputFormat = "YYYY/MM/DD HH:mm:ss") => {
    if (unixTime !== 0) {
      return moment.unix(unixTime).format(outputFormat);
    }
    return "-";
  };

  const displayOvertime = (overtimeHour) => {
    return `${overtimeHour} ${t('hour')}`;
  }

  const getDataBySearchKeyword = (keywords) => {
    let searchedList = [];
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      };
      const fuse = new Fuse(dataList, options);
      const result = fuse.search(keywords);
      searchedList = _.map(result, "item");
      searchedList = _.orderBy(searchedList, ["user_id"], ["desc"]);
      return searchedList
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      // setDataList(dataList);
    }
  };

  useEffect(() => {
    debounce(() => {
      let searchedList = dataList;
      if (_.isEmpty(dataList)) return;
      console.log('unChangeData', dataList);
      if (!_.isEmpty(searchKeyword)) {
        searchedList = getDataBySearchKeyword(searchKeyword);
        console.log(`searchedList >> keyword ${searchKeyword} >> `, searchedList)
      }

      if (minStartDate !== 0) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.start_time > minStartDate) return rowData;
        });
        console.log(`searchedList >> minStartDate ${minStartDate} >> `, searchedList)
      }

      if (maxStartDate !== now) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.start_time < maxStartDate) return rowData;
        });
        console.log(`searchedList >> maxStartDate ${maxStartDate} >> `, searchedList)
      }

      if (minEndDate !== 0) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.end_time > minEndDate) return rowData;
        });
        console.log(`searchedList >> minEndDate ${minEndDate} >> `, searchedList)
      }

      if (maxEndDate !== now) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.end_time < maxEndDate) return rowData;
        });
        console.log(`searchedList >> maxEndDate ${maxEndDate} >> `, searchedList)
      }

      if (minOverTime !== Number.MIN_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.overtime > minOverTime) return rowData;
        });
        console.log(`searchedList >> minOverTime ${minOverTime} >> `, searchedList)
      }

      if (maxOverTime !== Number.MAX_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.overtime < maxOverTime) return rowData;
        });
        console.log(`searchedList >> maxOverTime ${maxOverTime} >> `, searchedList)
      }

      searchedList = _.compact(searchedList);
      setFilterDataList(searchedList);
    }, 400);
  }, [
    searchKeyword,
    minStartDate,
    maxStartDate,
    minEndDate,
    maxEndDate,
    minOverTime,
    maxOverTime,
  ]);

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    setSearchKeyword(keyword);
  };

  const onStartTimeChange = (moments) => {
    let start_date
    let end_date;
    console.log(moments);
    if (!_.isNull(moments)) {
      start_date = moments[0].unix();
      end_date = moments[1].unix();
    }
    if (_.isUndefined(start_date)) start_date = 0
    if (_.isUndefined(end_date)) end_date = now;

    console.log('start end', start_date, end_date)
    setMinStartDate(start_date);
    setMaxStartDate(end_date);
  };

  const onEndTimeChange = (moments) => {
    let start_date
    let end_date;
    console.log(moments);
    if (!_.isNull(moments)) {
      start_date = moments[0].unix();
      end_date = moments[1].unix();
    }
    if (_.isUndefined(start_date)) start_date = 0
    if (_.isUndefined(end_date)) end_date = now;

    console.log('start end', start_date, end_date)
    setMinEndDate(start_date);
    setMaxEndDate(end_date);
  };

  const onMinOverTimeChange = (value) => {
    if (_.isNull(value)) value = Number.MIN_SAFE_INTEGER
    setMinOverTime(value);
  };
  const onMaxOverTimeChange = (value) => {
    if (_.isNull(value)) value = Number.MAX_SAFE_INTEGER
    setMaxOverTime(value);
  };

  return (
    <NavAndSideFrame
      {...props}
      title={t("expired_locker_booking")}
      selectedKey={selectedKey}
      openKey={openKey}
    >
      <Row>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>{t(`search`) + `:`}</Col>
        <Col style={{ marginLeft: 20 }}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: "20%" }}
            onChange={(values) => {
              onChange(values.target.value);
            }}
          />
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <InputNumber
            style={{ minWidth: 30}}
            onChange={(values) => {
              onMinOverTimeChange(values);
            }}
          />
          <>{` < ${t('overtime')} < `}</>
          <InputNumber
            style={{ minWidth: 30}}
            onChange={(values) => {
              onMaxOverTimeChange(values);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 5}}>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>{t(`start_time`) + `:`}</Col>
        <Col style={{ marginLeft: 20 }}>
          <RangePicker
            // defaultValue={[moment().subtract(1, 'month'), momentNow]}
            style={{ minWidth: 270, width: '50%' }}
            onChange={onStartTimeChange}
          />
        </Col>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>{t(`end_time`) + `:`}</Col>
        <Col style={{ marginLeft: 20 }}>
          <RangePicker
            // defaultValue={[moment().subtract(1, 'month'), momentNow]}
            style={{ minWidth: 270, width: '50%' }}
            onChange={onEndTimeChange}
          />
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: "max-content" }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          size="middle"
        />
        <PostsFormModal
          modalVisible={modalVisible}
          selectedRecord={selectedRecord}
          setModalVisible={setModalVisible}
        />
        <Modal
          destroyOnClose
          title={t("waive")}
          visible={addTokenModalVisible}
          footer={null}
          onCancel={() => {
            setAddTokenModalVisible(false);
          }}
        >
          <UserAddTokenForm
            dataObj={selectedRecord}
            setAddTokenModalVisible={setAddTokenModalVisible}
            waiveLocker
          />
        </Modal>
      </Spin>
    </NavAndSideFrame>
  );
};

export default ExpiredLockerBooking;
