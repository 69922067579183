import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import {
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip
} from "recharts";
import {
  Spin, Empty, Button, DatePicker
} from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import ExportToPDFButton from "../ExportToPDFButton";

const AccessLogChart = () => {
  const id = "access_log_chart";
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [accessLogData, setAccessLogData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day'));

  useEffect(() => {
    if (!_.isNull(selectedDate)) {
      getAccessLogData(selectedDate);
    }
  }, [selectedDate]);

  const getAccessLogData = async (mDate) => {
    setLoading(true);
    try {
      let start_time = moment(mDate).startOf('day').unix();
      let end_time = moment(mDate).endOf('day').unix();

      let url = `/api/dashboard/access_log_data?start_time=${start_time}&end_time=${end_time}`;
      let result = await Service.call("get", url);

      let finalResult = processResult(result);

      setAccessLogData(finalResult);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const processResult = (data) => {
    return _.map(data, (mData) => {
      return {
        time: moment(mData.time, 'X').format('HH:mm'),
        count: mData.count,
      }
    });
  };

  const renderChart = () => {
    if (_.isEmpty(accessLogData)) {
      return (
        <div style={{ padding: "10% 3%" }}>
          <Empty />
        </div>
      );
    }

    return (
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={500}
          height={500}
          data={accessLogData}
          margin={{
            top: 5,
            right: 10,
            left: 10,
            bottom: 5,
          }}
        >
          <XAxis dataKey="time" interval={0} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" tickCount={1} />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const onDateChange = (value) => {
    setSelectedDate(value);
  };

  const increaseDate = () => {
    if (_.isNull(selectedDate)) {
      setSelectedDate(moment());
    } else {
      setSelectedDate(moment(selectedDate).add(1, 'day'));
    }
  };

  const decreaseDate = () => {
    if (_.isNull(selectedDate)) {
      setSelectedDate(moment());
    } else {
      setSelectedDate(moment(selectedDate).subtract(1, 'day'));
    }
  };

  const disabledDate = (current) => {
    return dateIsDisabled(current);
  };
  const increaseButtonDisabled = () => {
    return dateIsDisabled(moment(selectedDate).add(1, 'day'));
  }
  const dateIsDisabled = (date) => {
    return date.diff(moment().startOf('day'), 'day') > 0;
  }

  return (
    <Spin id={id} spinning={loading}>
      <ExportToPDFButton targetID={id} />
      <h2>{t("access_log_chart")}</h2>
      <Button.Group>
        <Button onClick={decreaseDate} icon={<LeftOutlined />} />
        <DatePicker value={selectedDate} disabledDate={disabledDate} onChange={onDateChange} />
        <Button onClick={increaseDate} icon={<RightOutlined />} disabled={increaseButtonDisabled()} />
      </Button.Group>
      {renderChart()}
    </Spin>
  );
};

export default AccessLogChart;
