import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Form,
  Input,
  message,
  DatePicker,
  InputNumber,
  Modal,
  Button,
  Select,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Service from '../core/Service';

const { Option } = Select;

const DiscountInfoRoomFormModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    roomList,
    setModalVisible
  } = props;

  const [initialFormValues, setInitialFormValues] = useState({});
  const [DiscountInfoForm] = Form.useForm();
  const [roomOptions, setRoomOptions] = useState(null);

  useEffect(() => {
    let initialValues = {};
    initialValues = selectedRecord;
    if (initialValues.discount_info_room_id > 0) {
      initialValues.start = moment.unix(selectedRecord.start);
      initialValues.expire = moment.unix(selectedRecord.expire);
    }
    setInitialFormValues(initialValues);
  }, [modalVisible]);

  useEffect(() => {
    DiscountInfoForm.resetFields();
  }, [initialFormValues]);

  useEffect(() => {
    getRoomOptions();
  }, [roomList]);

  const handleFormSubmit = (formData) => {
    let myFormData = {
      ...formData,
      start: moment(formData.start).unix(),
      expire: moment(formData.expire).unix(),
    }

    onFinish(myFormData);
  };

  const getRoomOptions = () => {
    let options = _.map(roomList, (room) => {
      let displayStr = `${room.item_name} (${room.item_id})`;

      if (room.is_active === 0) {
        displayStr += ` (${t('inactive')})`;
      }

      return <Option value={room.item_id}>{displayStr}</Option>
    });
    setRoomOptions(options);
  }

  const onFinish = async (formData) => {
    let url = '/api/discount_room';
    let discount_info_room_id = selectedRecord.discount_info_room_id;

    let params = formData;
    if (discount_info_room_id > 0) {
      params.discount_info_room_id = discount_info_room_id;
    }

    let resp = {};
    resp = await Service.call(discount_info_room_id > 0 ? 'PUT' : 'POST', url, params);

    if (!resp || resp.status < 1) {
      console.log(resp)
      let errorMessage = resp.errorMessage || '';
      message.error(`${t('fail')}: ${errorMessage}`);
      return;
    }
    message.success(t('Success'));
    setModalVisible(false);
  };

  return (
    <Modal
      title="Discount Info Form"
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{maxWidth: "95%"}}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={DiscountInfoForm}
        name={t("discount_info_room_form")}
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={(formData) => {
          handleFormSubmit(formData);
        }}
      >
        <Form.Item
          label={t("room")}
          name="item_id_array"
        >
          <Select
            mode="multiple"
            allowClear
            filterOption={
              (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {roomOptions}
          </Select>
        </Form.Item>
        <Row>
          <Col flex="auto">
            <Form.Item
              label={t("buy_count")}
              name="buy_count"
              rules={[{ required: true, message: t('select_required') }]}
            >
              <InputNumber min={1} />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item
              label={t("free_count")}
              name="free_count"
              rules={[{ required: true, message: t('select_required') },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('buy_count') >= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('invalid_buy_count'));
                  },
                }),
              ]}
            >
              <InputNumber min={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="auto">
            <Form.Item
              label={t("effective_start")}
              name="start"
              rules={[{ required: true, message: t('select_required') }]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime={{
                  format: 'HH:mm',
                  defaultValue: moment('00:00', 'HH:mm')
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item
              label={t("effective_end")}
              name="expire"
              rules={[{ required: true, message: t('select_required') },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('start') <= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('invalid_end_day'));
                  },
                }),
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime={{
                  format: 'HH:mm',
                  defaultValue: moment('00:00', 'HH:mm')
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t("remarks")}
          name="remarks"
        >
          <Input allowClear />
        </Form.Item>
        {selectedRecord.discount_info_room_id !== 0 ? (
          <Form.Item label={t("create_time")}>
            <p>{moment(selectedRecord.ctime * 1000).format('YYYY-MM-DD HH:mm')}</p>
          </Form.Item>
        ) : null}

        {selectedRecord.discount_info_room_id !== 0 ? (
          <Form.Item label={t("utime")}>
            <p>{moment(selectedRecord.utime * 1000).format('YYYY-MM-DD HH:mm')}</p>
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default DiscountInfoRoomFormModal;
