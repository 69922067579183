import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Spin, Button, Input, Divider, Typography, Popconfirm, Table, Tag, Tooltip, Row, Col, Tabs
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import Fuse from 'fuse.js';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';

import NavAndSideFrame from '../../components/NavAndSideFrame';
import DiscountInfoFormModal from '../../components/DiscountInfoFormModal';

const debug = require('debug')('app:pages/admin/ItemConfig');

const { Paragraph } = Typography;

// const title = "Item Config";
const selectedKey = "discount_info";
const tableIDName = "discount_info_id";
const { TabPane } = Tabs;

const filterableFields = [
  "discount_info_id",
  "code",
  "deleted",
  "discount",
  "expire",
  "limit_per_user",
  "limit_total",
  "name",
  "start",
  "status",
  "type",
  "ctime",
  "utime"
];

const DiscountInfo = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortingList, setSortingList] = useState([]);
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [maxSorting, setMaxSorting] = useState(1);
  const title = t('discount_info');
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_discount_info_w')
  );

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    getAllData();
  }, [modalVisible]);

  const getAllData = async () => {
    setLoading(true)
    let data = [];
    try {
      let url = '/api/discount/list';
      data = await Service.call('get', url);
      // console.log("data>>>>>", data);
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setDataList(data);
      setFilterDataList(data);
      setLoading(false)
    }
  };

  const onPressDelete = async (discount_info_id) => {
    let url = '/api/discount/delete';
    let result = await Service.call('PUT', url, { discount_info_id });
    getAllData();
  };

  const setTableHeader = () => {
    let columns = [
      {
        title: t('name'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: t('code'),
        dataIndex: 'code',
        sorter: (a, b) => a.code.localeCompare(b.code)
      },
      {
        title: t('discount'),
        dataIndex: 'discount',
        sorter: (a, b) => a.discount - b.discount,
        render: (value, record) => renderDiscount(value, record),
      },
      {
        title: t('discount_type'),
        dataIndex: 'type',
        sorter: (a, b) => a.type - b.type,
        render: (value) => renderDiscountType(value),
      },
      {
        title: t('limit_type'),
        dataIndex: 'limit_type',
        sorter: (a, b) => a.limit_type - b.limit_type,
        render: (value) => renderDiscountLimitType(value),
      },
      {
        title: t('limit_amount'),
        dataIndex: 'limit_amount',
        sorter: (a, b) => a.limit_amount - b.limit_amount,
      },
      {
        title: t('active_status'),
        dataIndex: 'status',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.status - b.status
      },
      {
        title: t('effective_start'),
        dataIndex: 'start',
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm'),
        sorter: (a, b) => a.start - b.start
      },
      {
        title: t('effective_end'),
        dataIndex: 'expire',
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm'),
        sorter: (a, b) => a.expire - b.expire
      },
      {
        title: t('limit_per_user'),
        dataIndex: 'limit_per_user',
        sorter: (a, b) => a.limit_per_user - b.limit_per_user
      },
      {
        title: t('limit_total'),
        dataIndex: 'limit_total',
        sorter: (a, b) => a.limit_total - b.limit_total
      },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm'),
        sorter: (a, b) => a.ctime - b.ctime
      },
      {
        title: t('utime'),
        dataIndex: 'utime',
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm'),
        sorter: (a, b) => a.utime - b.utime
      },
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              <Tooltip title={record.status ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={record.status
                    ? <CloseCircleOutlined />
                    : <CheckCircleOutlined />}
                  onClick={async () => {
                    if (isChangingIsActive) return;
                    setChangingIsActive(true);
                    await Service.call('PATCH', '/api/discount/status',
                      {
                        discount_info_id: record.discount_info_id,
                        status: !record.status,
                      });
                    getAllData();
                    setChangingIsActive(false);
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={t('confirm_delete') + "?"}
                onConfirm={() => onPressDelete(record.discount_info_id)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          )
        }
      });
    }
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      }
      const fuse = new Fuse(filterList, options)
      const result = fuse.search(filterObj);
      // console.log(result);
      filterList = _.map(result, 'item');
      // console.log(filterList);
      filterList = _.orderBy(filterList, ['discount_info_id'], ['desc']);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      filterList = _.orderBy(filterList, 'discount_info_id');
      setFilterDataList(filterList);
    }
  }

  const renderDiscount = (value, record) => {
    switch (record.type) {
      case 1:
        return `$${value}`;
      case 2:
        return `${value}%`;
      default:
        return value;
    }
  }

  const renderDiscountType = (value) => {
    switch (value) {
      case 1:
        return t('discount_type_actual_price');
      case 2:
        return t('discount_type_percentage');
      default:
        return t('error');
    }
  }

  const renderDiscountLimitType = (value) => {
    switch (value) {
      case 0:
        return t('none');
      case 1:
        return t('limit_by_token');
      case 2:
        return t('limit_by_price');
      default:
        return t('error');
    }
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} onExportTable={onExportTable}>
      <Row>
        {
          disableEdit ? '' : (
            <Col>
              <Button
                icon={<PlusOutlined />}
                style={{ marginBottom: 10 }}
                type="primary"
                onClick={() => {
                  setSelectedRecord({ discount_info_id: 0 });
                  setModalVisible(true);
                }}
              >
                {t('create')}
              </Button>
            </Col>
          )
        }
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
      </Row>
      <Divider />
      <Spin spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          size="middle"
        />
        <DiscountInfoFormModal
          modalVisible={modalVisible}
          selectedRecord={selectedRecord}
          setModalVisible={setModalVisible}
        />
      </Spin>
    </NavAndSideFrame>
  );
}

export default DiscountInfo;
