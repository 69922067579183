import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Input, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification, Calendar, Badge, Spin, Card, Button, TreeSelect, InputNumber
} from 'antd';
import {
  LoadingOutlined,
  EyeOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import Fuse from 'fuse.js';
import * as debugLib from 'debug';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import OrderFormModal from '../../components/OrderFormModal';


const debug = debugLib('app:pages/BookingRecordList');

const tableIDName = "booking_item_id";
const selectedKey = 'booking_record_list';
const openKey = 'booking';
const filterableFields = [
  `order_key`,
  `nickname`,
  `order_id`,
  `category_name`,
  `item_name`,
  `duration`,
  `currency`,
  `remarks`,
];

const BookingRecordList = (props) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [itemCategory, setItemCategory] = useState([])
  // const [item, setItem] = useState([]); // item list
  // const [itemFilter, setItemFilter] = useState([])
  const [itemCategoryFilter, setItemCategoryFilter] = useState([])
  const [selectedOrder, setSelectedOrder] = useState({});
  const [searchKeyword, setSearchKeyword] = useState('');
  const [minPrice, setMinPrice] = useState(Number.MIN_SAFE_INTEGER)
  const [maxPrice, setMaxPrice] = useState(Number.MAX_SAFE_INTEGER)
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookingItemStatusFilter, setBookingItemStatusFilter] = useState('all');

  const title = t('booking_list');
  useEffect(() => {
    setLoading(true);
    // getItemCategoryList();
    // getItemList();
    getAllData();
  }, []);

  // useEffect(() => {
  //   console.log('itemFilter', itemFilter)
  //   getItemList()
  // }, [itemCategoryFilter])

  useEffect(() => {
    // do filter data
    filterData();
  }, [searchKeyword, itemCategoryFilter, minPrice, maxPrice, bookingItemStatusFilter])

  const getAllData = async () => {
    let resp = await Service.call('GET', '/api/order_item/list');
    if (!resp || resp.status < 1) {
      setLoading(false);
      return;
    }
    // filter out inactive record
    resp = _.filter(resp, (item) => { return item.is_active !== 0; });

    let categoryFullList = _.map(resp, (obj) => {
      return obj.category_name
    })
    let categoryList = _.uniq(categoryFullList);
    // handle price
    resp.map((item) => {
      item.price /= 100
    })
    // group by hash key
    let bookingMap = _.groupBy(resp, (booking) => { return booking.hashkey });
    let displayBookingList = [];

    _.map(bookingMap, (group) => {
      // adding info accroding to hashkey group

      let displayBooking = {};
      _.map(group, (booking) => {
        if (_.isEmpty(displayBooking)) {
          displayBooking = booking;
          return;
        }
        displayBooking.start_time = Math.min(displayBooking.start_time, booking.start_time);
        displayBooking.end_time = Math.max(displayBooking.end_time, booking.end_time);
        displayBooking.duration += booking.duration;
        displayBooking.price += booking.price / 100;
        displayBooking.remarks += ';\n' + booking.remarks;
      });
      displayBookingList.push(displayBooking);
    });

    displayBookingList = _.orderBy(displayBookingList, ['start_time'], ['desc']);

    console.log(displayBookingList);
    setDataList(displayBookingList);
    setFilterDataList(displayBookingList);
    setLoading(false);

    setItemCategory(categoryList);
  }

  // const getItemCategoryList = async () => {
  //   let itemCategoryArr = await Service.call('get', "/api/item/category/list/current");
  //   let data = Main.fetchProductCategoryOption(itemCategoryArr);
  //   console.log('getItemCategoryList', data);
  //   setItemCategory(data)
  //   // setItemCategory(itemCategoryArr)
  // }

  // const getItemList = async () => {
  //   let itemArr = await Service.call('get', "/api/item/list/current");
  //   // Filter Items
  //   if (itemCategoryFilter !== 0) {
  //     itemArr = _.filter(itemArr, ['item_category_id', itemCategoryFilter]);
  //   }
  //   setItem(itemArr)
  // }

  const displayOrder = async (order_id) => {
    setLoading(true);
    let orderData = {}
    try {
      orderData = await Service.call('GET', `/api/order/${order_id}`);
    } catch (error) {
      // console.log("error>>>", error);
    }
    setSelectedOrder(orderData);
    setLoading(false);
    setModalVisible(true);
  }

  const setTableHeader = () => {
    let columns = [
      {
        title: t('operation'),
        dataIndex: "order_id",
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('view')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={<EyeOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    displayOrder(value);
                  }}
                />
              </Tooltip>
            </span>
          )
        },
      },
      // {
      //   title: t('id'),
      //   dataIndex: 'order_id',
      //   sorter: (a, b) => a.order_id - b.order_id
      // },
      {
        title: t('order_key'),
        dataIndex: 'order_key',
        sorter: (a, b) => a.order_key.localeCompare(b.order_key)
      },
      {
        title: t('user'),
        dataIndex: 'nickname',
        sorter: (a, b) => a.nickname.localeCompare(b.nickname)
      },
      // {
      //   title: t('email'),
      //   dataIndex: 'email',
      //   sorter: (a, b) => a.email.localeCompare(b.email)
      // },
      // {
      //   title: t('mobile'),
      //   dataIndex: 'mobile',
      //   sorter: (a, b) => a.mobile.localeCompare(b.mobile)
      // },
      {
        title: t('category_name'),
        dataIndex: 'category_name',
        sorter: (a, b) => a.category_name.localeCompare(b.category_name)
      },
      {
        title: t('item_name'),
        dataIndex: 'item_name',
        sorter: (a, b) => a.item_name.localeCompare(b.item_name)
      },
      // {
      //   title: t('active_status'),
      //   dataIndex: 'is_active',
      //   render: (value) => displayIsActive(value),
      //   sorter: (a, b) => a.is_active - b.is_active
      // },
      {
        title: t("status"),
        dataIndex: "status",
        render: (value) => displayStatus(value),
      },
      {
        title: t('start_time'),
        dataIndex: 'start_time',
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.start_time - b.start_time
      },
      {
        title: t('end_time'),
        dataIndex: 'end_time',
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.end_time - b.end_time
      },
      {
        title: t('duration'),
        dataIndex: 'duration',
        render: (value) => { return `${value / 60}Mins` },
        sorter: (a, b) => a.duration - b.duration
      },
      {
        title: t('currency'),
        dataIndex: 'currency',
        sorter: (a, b) => a.currency.localeCompare(b.currency)
      },
      {
        title: t('price'),
        dataIndex: 'price',
        render: (value) => { return `$${value}` },
        sorter: (a, b) => a.price - b.price
      },
      {
        title: t('remarks'),
        dataIndex: 'remarks',
        // sorter: (a, b) => a.remarks - b.remarks
      }
    ];

    return columns;
  }

  const displayStatus = (value) => {
    let status = _.toString(value);
    let wording = ``;
    switch (status) {
      case "placed": wording = t("placed"); break;
      case "paid": wording = t("paid"); break;
      case "payment_confirmed": wording = t("payment_confirmed"); break;
      case "payment_failed": wording = t("payment_failed"); break;
      case "payment_refund": wording = t("payment_refund"); break;
      case "in_delivery": wording = t("in_delivery"); break;
      case "cancelled": wording = t("cancelled"); break;
      case "expired": wording = t("expired"); break;
      case "cancel_after_payment": wording = t("cancel_after_payment"); break;
      default: wording = t('error');
    }
    return wording;
  }

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
    let displayTime = '';
    if (unixTime !== 0) {
      displayTime = moment.unix(unixTime).format(outputFormat);
    } else {
      displayTime = '-'
    }
    return displayTime;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const getDataBySearchKeyword = (keywords) => {
    let searchedList = [];
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      };
      const fuse = new Fuse(dataList, options);
      const result = fuse.search(keywords);
      searchedList = _.map(result, "item");
      searchedList = _.orderBy(searchedList, ["user_id"], ["desc"]);
      return searchedList
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      // setDataList(dataList);
    }
  };

  const filterData = () => {
    debounce(() => {
      let searchedList = dataList;
      if (_.isEmpty(dataList)) return;
      console.log('unChangeData', dataList);
      if (!_.isEmpty(searchKeyword)) {
        searchedList = getDataBySearchKeyword(searchKeyword);
        console.log(`searchedList >> keyword ${searchKeyword} >> `, searchedList)
      }

      if (minPrice !== Number.MIN_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.price > minPrice) return rowData;
        });
        console.log(`searchedList >> minPrice ${minPrice} >> `, searchedList)
      }

      if (maxPrice !== Number.MAX_SAFE_INTEGER) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.price < maxPrice) return rowData;
        });
        console.log(`searchedList >> maxStartTime ${maxPrice} >> `, searchedList)
      }

      if (bookingItemStatusFilter !== 'all') {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          if (rowData.status === bookingItemStatusFilter) return rowData;
        });
        console.log(`searchedList >> bookingItemStatusFilter ${bookingItemStatusFilter} >> `, searchedList)
      }

      if (itemCategoryFilter.length > 0) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          let isFound = false;
          _.each(itemCategoryFilter, (selected_category_name) => {
            if (rowData.category_name === selected_category_name) isFound = true;
          })
          if (isFound) return rowData;
        });
        console.log(`searchedList >> itemCategoryFilter ${itemCategoryFilter} >> `, searchedList)
      }

      searchedList = _.compact(searchedList);
      console.log(`searchedList >> setFilterDataList >> `, searchedList)
      setFilterDataList(searchedList);
    }, 400);
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    setSearchKeyword(keyword);
  };


  const handleItemCategoryFilterChange = (value) => {
    setItemCategoryFilter(value)
  }

  const handleStatusFilterChange = (value) => {
    setBookingItemStatusFilter(value)
  }


  const onMinPriceChange = (value) => {
    if (_.isNull(value)) value = Number.MIN_SAFE_INTEGER
    setMinPrice(value);
  };
  const onMaxPriceChange = (value) => {
    if (_.isNull(value)) value = Number.MAX_SAFE_INTEGER
    setMaxPrice(value);
  };

  const onExportTable = () => {
    const processedDataList = _.map(filterDataList, (data) => {
      return {
        ...data,
        start_time: data.start_time === 0 ? '-' : moment(data.start_time, 'X').format('YYYY-MM-DD HH:mm:ss'),
        end_time: data.end_time === 0 ? '-' : moment(data.end_time, 'X').format('YYYY-MM-DD HH:mm:ss'),
        duration: `${data.duration / 60}Mins`,
      }
    });

    Main.exportTableToCSV(processedDataList, 'export');
  };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKey={openKey} onExportTable={onExportTable}>
      <Row>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`status`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Select
            defaultValue="all"
            style={{ width: "100%", minWidth: 200 }}
            placeholder={t('search_status')}
            onChange={(value) => handleStatusFilterChange(value)}
          >
            <Option value="all" key={0}>{t('all')}</Option>
            <Option value="placed" key={0}>{t('placed')}</Option>
            <Option value="paid" key={0}>{t('paid')}</Option>
            <Option value="payment_confirmed" key={0}>{t('payment_confirmed')}</Option>
            <Option value="payment_failed" key={0}>{t('payment_failed')}</Option>
            <Option value="payment_refund" key={0}>{t('payment_refund')}</Option>
            <Option value="in_delivery" key={0}>{t('in_delivery')}</Option>
            <Option value="cancelled" key={0}>{t('cancelled')}</Option>
            <Option value="expired" key={0}>{t('expired')}</Option>
            <Option value="cancel_after_payment" key={0}>{t('cancel_after_payment')}</Option>
          </Select>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`category`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Select
            mode="multiple"
            style={{ width: "100%", minWidth: 400 }}
            placeholder={t('search_status')}
            onChange={(value) => handleItemCategoryFilterChange(value)}
          >
            {
              _.map(itemCategory, (eachItem) => {
                return <Option value={eachItem} key={0}>{t(eachItem)}</Option>
              })
            }
          </Select>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col style={{ marginLeft: 20 }}>
          <InputNumber
            style={{ minWidth: 30, width: "20%" }}
            onChange={(values) => {
              onMinPriceChange(values);
            }}
          />
          <>{` < ${t('price')} < `}</>
          <InputNumber
            style={{ minWidth: 30, width: "20%" }}
            onChange={(values) => {
              onMaxPriceChange(values);
            }}
          />
        </Col>
      </Row>
      <Divider />
      <Spin spinning={loading}>
        <Table
          bordered
          size="small"
          pagination={{
            defaultPageSize: 25,
            showSizeChanger: true,
            pageSizeOptions: [25, 50, 100, 200, 500]
          }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
        />
        <OrderFormModal
          modalVisible={modalVisible}
          selectedOrder={selectedOrder}
          setModalVisible={setModalVisible}
        />
      </Spin>
    </NavAndSideFrame>
  );
}

export default BookingRecordList;
