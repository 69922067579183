import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Col, Form, Input, Layout, Modal,
  notification, Radio, Row, Select, Tabs, Upload, message
} from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import * as Service from '../core/Service';
import { formItemLayout, tailLayout } from './ModalLayout'
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CompanyAdminForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [companyList, setCompanyList] = useState([]);
  const [createNewAdmin, setCreateNewAdmin] = useState(false);
  const [companyUserGroupOptions, setCompanyUserGroupOptions] = useState(null);
  const admin = useSelector((state) => state.app.admin);
  const { adminGroupList } = props;

  useEffect(() => {
    let options = _.map(adminGroupList, (group) => {
      return (
        <Option value={group.company_user_group_id} key={group.company_user_group_id}>
          {group.title}
        </Option>
      )
    })

    setCompanyUserGroupOptions(options);
  }, [adminGroupList])

  useEffect(() => {
    form.resetFields();
    setCreateNewAdmin(props.dataObj.createNewAdmin)
  }, [props.dataObj]);

  // useEffect(() => {
  //   getAllData();
  // }, []);

  // const getAllData = async () => {
  //   let companyList = [];
  //   try {
  //     let companyData = await Service.call('get', `/api/company/list`);
  //     companyList = _.orderBy(companyData, ['company_id']);
  //   } catch (error) {
  //     console.error('error >>> ', error);
  //   } finally {
  //     setCompanyList(companyList);
  //   }
  // }

  const onFinish = async (postObj) => {
    if (_.isEmpty(postObj.nickname)) {
      postObj.nickname = '-';
    }
    console.log('props >>>>>>>>', props)
    console.log('postObj >>>>>>>>', postObj)
    // Patch
    if (props.dataObj.company_user_id > 0) {
      postObj.company_user_id = props.dataObj.company_user_id;
      let data = await Service.call('post', '/api/admin/patchAdmin', postObj);
      if (data.errorMessage) {
        message.error(data.errorMessage);
        return props.openModal(true);
      }
      message.success('success');
      return props.openModal(false);
    }

    // POST
    postObj.create_time = moment().unix();
    let data = await Service.call('post', `/api/admin/postAdmin`, postObj);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      return props.openModal(true);
    }
    message.success('success');
    return props.openModal(false);
  };


  return (
    <Form
      {...formItemLayout}
      form={form}
      name="time_related_controls"
      onFinish={onFinish}
      initialValues={props.dataObj}
    >
      <Form.Item
        label={t("company_name")}
        name="company_name"
        rules={[{ required: true, message: 'Please input company name.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("first_name")}
        name="first_name"
        rules={[{ required: true, message: 'Please input first name.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("last_name")}
        name="last_name"
        rules={[{ required: true, message: 'Please input last name.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("nickname")}
        name="nickname"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("email")}
        name="email"
        rules={[{ required: true, message: 'Please input email.', type: 'email' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("mobile")}
        name="mobile"
        rules={[{ required: true, message: 'Please input phone number.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("company_user_group")}
        name="company_user_group_id"
        rules={[{ required: true }]}
      >
        <Select>{companyUserGroupOptions}</Select>
      </Form.Item>
      <Form.Item
        label={t("pwd")}
        name="password"
        hidden={!createNewAdmin}
        rules={[
          {
            required: createNewAdmin,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label={t("confirm_pwd")}
        name="confirm"
        dependencies={['password']}
        hasFeedback
        hidden={!createNewAdmin}
        rules={[
          {
            required: createNewAdmin,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
        >
          {t("submit")}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CompanyAdminForm;
