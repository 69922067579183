import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import _ from 'lodash';
import NavAndSideFrame from '../components/NavAndSideFrame';
import SystemConfigTable from '../components/SystemConfigTable';
import LightingControlTable from '../components/LightingControlTable';
import 'react-quill/dist/quill.snow.css';

const { TabPane } = Tabs;
const selectedKey = "sys_config_general";
const openKey = "sys_config";

const SystemConfig = (props) => {
  const { t } = useTranslation();
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_sys_config_w')
  );

  return (
    <NavAndSideFrame {...props} title={t('sys_config')} selectedKey={selectedKey} openKey={openKey}>
      <Tabs type="card">
        <TabPane tab={t('general')} key="general">
          <SystemConfigTable disableEdit={disableEdit} />
        </TabPane>
        <TabPane tab={t('lighting_control')} key="light_control">
          <LightingControlTable disableEdit={disableEdit} />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}


export default SystemConfig;
