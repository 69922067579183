import React, { Component, useState, useEffect, useRef } from 'react';
import {
  Spin, Row, Col, Divider, Badge, Button, Radio, Form, Icon, Layout, Menu, Modal, Checkbox, Table, Tag, Tabs, Tooltip, Select, Input, Space, Drawer, Switch, Typography, Card, message
} from 'antd';
import {
  EditOutlined,
  CheckCircleOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import { useTranslation } from 'react-i18next';
import Fuse from 'fuse.js';
import UserApproveForm from './UserApproveForm';

const { TabPane } = Tabs;
const { Option } = Select;
const filterableFields = [
  `nickname`,
  `sf_id`,
  `mobile`,
  `email`,
  `reject_reasons`
];

// let searchWord = '';
// let fuse = [];

const UserApprove = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const [dataList, setDataList] = useState([]);
  // const [unChangeData, setUnChangeData] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showStatus, setShowStatus] = useState('All');
  const [loading, setLoading] = useState(false);
  const tableIDName = "user_approve_table";
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_user_approve_w')
  );

  useEffect(() => {
    setLoading(true);
    getAllData();
  }, []);

  const getAllData = async () => {
    let result = await Service.call('get', '/api/user/approve_list')
    // console.log('result >>>>>>>', result)
    if (result.status !== 1) {
      setLoading(false);
      message.error(result.errorMessage)
      return
    }
    let { data } = result;
    let list = _.orderBy(data, `ctime`, `desc`);

    setDataList(list);
    setFilterDataList(list);
    setLoading(false);
  }

  const setTableHeader = () => {
    let columns = [
      // {
      //   title: "",
      //   key: "approve_status",
      //   dataIndex: "approve_status",
      //   width: 50,
      //   fixed: 'left',
      //   render: (approve_status) => <Tag color={approve_status === 0 ? "green" : "orange"}>{approve_status === 0 ? "First" : "Retry"}</Tag>,
      // },
      {
        title: t("status"),
        key: "user_id",
        dataIndex: "status",
        sorter: (a, b) => a.status - b.status,
        render: (value) => {
          // console.log('check value', value)
          return (
            <Tag color={value !== -1 ? "orange" : "red"}>
              {t(value !== -1 ? "not_approve_yet" : "rejected")}
            </Tag>
          )
        }
      },
      {
        title: t('name'),
        key: 'nickname',
        dataIndex: 'nickname',
        sorter: (a, b) => a.nickname.localeCompare(b.nickname),
        // fixed: 'left',
      },
      {
        title: t('sf_id'),
        key: 'sf_id',
        dataIndex: 'sf_id',
        sorter: (a, b) => a.sf_id - b.sf_id,
      },
      {
        title: t('reject_reasons_label'),
        key: 'reject_reasons',
        dataIndex: 'reject_reasons',
      },
      // {
      //   title: t('last_name'),
      //   key: 'last_name',
      //   dataIndex: 'last_name',
      //   // fixed: 'left',
      // },
      // {
      //   title: t('first_name'),
      //   key: 'first_name',
      //   dataIndex: 'first_name',
      //   // fixed: 'left',
      // },
      // {
      //   title: t('age'),
      //   key: 'age',
      //   dataIndex: 'age',
      // },
      {
        title: t('mobile'),
        key: 'mobile',
        dataIndex: 'mobile',
        render: (mobile, value) => (
          <span>
            {displayApproved(value.is_verified_mobile)}
            {mobile}
          </span>
        ),
        sorter: (a, b) => a.mobile.localeCompare(b.mobile),
      },
      {
        title: t('email'),
        key: 'email',
        dataIndex: 'email',
        render: (email, value) => (
          <span>
            {displayApproved(value.is_verified_email)}
            {email}
          </span>
        ),
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      // {
      //   title: t('referral_code'),
      //   dataIndex: 'referral_code',
      // },
      {
        title: t('ctime'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => <span>{moment.unix(ctime).format('YYYY-MM-DD')}</span>,
      },
    ];

    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        key: 'user_id',
        dataIndex: 'user_id',
        width: 75,
        fixed: 'left',
        render: (user_id, value) => (
          <Space>
            <Tooltip title={t(value.status === -1 ? 'rejected' : 'edit')}>
              <Button
                disabled={value.status === -1}
                shape="circle"
                style={{ marginRight: 4}}
                icon={<EditOutlined />}
                // disabled={record.is_active ? true : false}
                onClick={() => {
                  if (value.status !== -1) {
                    setSelectedRecord(value)
                    setShow(true);
                  }
                }}
              />
            </Tooltip>
          </Space>
        ),
        // filters: approvedOptions(t),
        // onFilter: (value, record) => record.approve_status == value,
      });
    }
    return columns;
  }

  const displayApproved = (value) => {
    if (value === 1) {
      return <CheckCircleOutlined style={{ color: "blue" }} />
    }
  }

  useEffect(() => {
    debounce(() => {
      console.log('filtering with', searchKeyword, showStatus)
      let searchedList = dataList;
      if (_.isEmpty(dataList)) return;
      console.log('unChangeData', dataList);
      if (!_.isEmpty(searchKeyword)) {
        searchedList = getDataBySearchKeyword(searchKeyword);
        console.log(`searchedList >> keyword ${searchKeyword} >> `, searchedList)
      }

      if (showStatus !== 'All') {
        if (showStatus === 'Pending') {
          searchedList = _.map(searchedList, (rowData) => {
            if (_.isUndefined(rowData)) return;
            if (rowData.status === 0) return rowData;
          });
          console.log(`searchedList >> showStatus ${showStatus} >> `, searchedList)
        } else {
          searchedList = _.map(searchedList, (rowData) => {
            if (_.isUndefined(rowData)) return;
            if (rowData.status === -1) return rowData;
          });
          console.log(`searchedList >> showStatus ${showStatus} >> `, searchedList)
        }
      }

      searchedList = _.compact(searchedList);
      setFilterDataList(searchedList);
    }, 400);
  }, [searchKeyword, showStatus]);

  const getDataBySearchKeyword = (keywords) => {
    let searchedList = [];
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      };
      const fuse = new Fuse(dataList, options);
      const result = fuse.search(keywords);
      searchedList = _.map(result, "item");
      searchedList = _.orderBy(searchedList, ["user_id"], ["desc"]);
      return searchedList
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      // setDataList(dataList);
    }
  };

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onKeywordChange = (keyword) => {
    setSearchKeyword(keyword);
  };

  const onStatusFilterChange = (status) => {
    setShowStatus(status);
  }

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
        <Row>
          <Col style={{ marginLeft: 20 }}>
            {t(`search`) + `:`}
            <Input
              placeholder={t(`please_input_keyword`)}
              style={{ minWidth: 270, width: '50%' }}
              onChange={
                (values) => {
                  onKeywordChange(values.target.value);
                }
              }
            />
          </Col>
          <Col style={{ marginLeft: 20 }}>
            {t(`Status`) + `:`}
            <Select defaultValue="All" style={{ width: 100 }} onChange={onStatusFilterChange}>
              <Option value="All">All</Option>
              <Option value="Pending">Pending</Option>
              <Option value="Rejected">Rejected</Option>
            </Select>
          </Col>
        </Row>
        <Button
          style={{float: 'right'}}
          icon={<FileExcelOutlined />}
          onClick={onExportTable}
        >
          {t('export_to_excel_file')}
        </Button>
      </div>
      <Divider />
      <Spin spinning={loading}>
        <Table
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: [25, 50, 100, 200, 500]
          }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          size="middle"
        />
      </Spin>

      <Drawer
        title={t('user') + t('info')}
        width="100%"
        onClose={() => {
          // getAllData()
          setShow(false)
        }}
        visible={show}
      // bodyStyle={{ paddingBottom: 80 }}
      >
        <UserApproveForm
          data={selectedRecord}
          refresh={getAllData}
          openDrawer={(bool) => {
            setShow(bool);
          }}
        />
      </Drawer>
    </>
  )
};

export default UserApprove;
