import React, { useState, useEffect } from 'react';
import {
  Spin, Button, Layout, Table, Tooltip, Popconfirm, message
} from 'antd';
import {
  EditOutlined,
  AlertOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from "../core/Service";
import * as Main from '../core/Main';
import SystemConfigModal from './SystemConfigModal';

const SystemConfigTable = (props) => {
  const { t } = useTranslation()
  const tableIDName = "lighting_info_id";
  const { disableEdit } = props;

  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [zoneID, setZoneID] = useState(0);
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  const lightingType = {
    light_on: 'LIGHT_ON',
    light_off: 'LIGHT_OFF'
  }
  const data = [
    {
      lighting_info_id: 1,
      name: t('all_rooms'),
      zone_id: 13,
    }
  ]

  const lightingControl = async () => {
    if (!_.includes(lightingType, type) || zoneID <= 0) {
      return message.error(t('lighting_control_error_type'))
    }
    let resp = await Service.call('post', '/api/sys_config/lighting/control', { queue_type: type, zone_id: zoneID });
    if (!resp || resp.status !== 1) {
      return message.error(t('lighting_control_error'))
    }
    message.success(t('lighting_control_success'))
  }
  const setTableHeader = () => {
    let columns = [
      {
        title: t('description'),
        dataIndex: 'name',
        key: 'zone_id',
      }
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('light_on'),
        dataIndex: 'zone_id',
        key: 'zone_id',
        render: (value, record, index) => {
          return (
            <span>
              <Tooltip title={t('light_on')}>
                <Popconfirm
                  title={t('light_on_warning')}
                  onConfirm={() => lightingControl()}
                  okText={t('yes')}
                  cancelText={t('no')}
                >
                  <Button
                    shape="circle"
                    icon={<AlertOutlined />}
                    style={{ marginRight: 8 }}
                    onClick={() => {
                      setType(lightingType.light_on);
                      setZoneID(record.zone_id);
                    }}
                  />
                </Popconfirm>
              </Tooltip>
              {/* <Tooltip title={t('delete')}>
                <Button
                  // hidden={createButtonHidden}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    console.log(record)
                    // onDelete(record.app_meta_id, record)
                  }}
                />
              </Tooltip> */}
            </span>
          )
        }
      }, {
        title: t('light_off'),
        dataIndex: 'zone_id',
        key: 'zone_id',
        render: (value, record, index) => {
          return (
            <span>
              <Tooltip title={t('light_off')}>
                <Popconfirm
                  title={t('light_off_warning')}
                  onConfirm={() => lightingControl()}
                  okText={t('yes')}
                  cancelText={t('no')}
                >
                  <Button
                    shape="circle"
                    icon={<PoweroffOutlined />}
                    style={{ marginRight: 8 }}
                    onClick={() => {
                      setType(lightingType.light_off);
                      setZoneID(record.zone_id);
                    }}
                  />
                </Popconfirm>
              </Tooltip>
              {/* <Tooltip title={t('delete')}>
                <Button
                  // hidden={createButtonHidden}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    console.log(record)
                    // onDelete(record.app_meta_id, record)
                  }}
                />
              </Tooltip> */}
            </span>
          )
        }
      });
    }
    return columns;
  }

  return (
    <Spin spinning={loading}>
      {/* <Row>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginBottom: 10 }}
            onClick={() => {
              setModalVisible(true)
              setSelectedRecord({item_holiday_id: 0})
            }}
          >
            {t('create')}
          </Button>
        </Col>
        <Col style={{marginTop: 5, marginLeft: 10}}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{marginLeft: 20}}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
      </Row> */}
      <Layout>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={data}
          columns={setTableHeader()}
          bordered
          size="small"
          pagination={{ pageSize: 100 }}
        // loading={loading}
        />
      </Layout>
    </Spin>
  )
}
export default SystemConfigTable;
