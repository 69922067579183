import React, { Component, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Spin, Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Input, message
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
  StopOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/util';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import CompanyUserForm from './CompanyUserForm';
import CanpanyUserPasswordForm from '../../components/CompanyUserPasswordForm';
import TokenPackageForm from '../../components/TokenPackageForm';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

// const involvedModelName = "company";

const selectedKey = 'token_package';
const openKey = 'token';
const tableIDName = "token_package";

const CompanyTokenPackage = (props) => {
  const { t } = useTranslation();
  const title = t("token_package");
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [unChangeData, setUnChangeData] = useState([]);
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_token_package_w')
  );

  useEffect(() => {
    setLoading(true);
    getAllData();
  }, [modalVisible]);

  const getAllData = async () => {
    // let dataList = [];
    try {
      let url = `/api/token/package`;
      let result = await Service.call('get', url);
      if (result.status !== 1) {
        return;
      }
      let {data} = result
      // dataList = data
      setUnChangeData(data);
      setDataList(data);
      setLoading(false);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  const setTableHeader = () => {
    let columns = [
      {
        title: t('status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('token_value'),
        dataIndex: 'token_value',
        render: (value) => { return value || '-'; },
        // sorter: (a, b) => a.email.localeCompare(b.email)
      },
      {
        title: t('ctime'),
        dataIndex: 'ctime',

        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format('YYYY-MM-DD hh:mm')
        },
      },
      {
        title: t('utime'),
        dataIndex: 'utime',

        sorter: (a, b) => a.utime - b.utime,
        render: (utime) => {
          return moment.unix(utime).format('YYYY-MM-DD hh:mm')
        },
      },
    ];

    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setSelectedRecord(record)
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={async () => {
                    if (isChangingIsActive) return;
                    setChangingIsActive(true);
                    // console.log('record >>>', record)
                    let is_active = record.is_active;
                    await Service.call('PATCH', '/api/token/package/is_active',
                      {
                        token_package_id: record.token_package_id,
                        is_active
                      });
                    getAllData();
                    setChangingIsActive(false);
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={t('confirm_delete') + "?"}
                onConfirm={() => deletePackage(record.token_package_id)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          )
        }
      });
    }
    return columns;
  }

  const displayBoolean = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let isTrue = _.toInteger(value);
    switch (isTrue) {
      case 1:
        displayStr = t("yes");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("no");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayIsType = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let isActiveValue = _.toInteger(value);
    switch (value) {
      case "order":
      case "order_create":
        displayStr = t('order_create');
        tagColor = 'green';
        break;
      case "order_cancel":
        displayStr = t('order_cancel');
        tagColor = 'red';
        break;
      case "topup":
        displayStr = t('topup');
        tagColor = 'blue';
        break;
      case "add_by_admin":
        displayStr = t('add_by_admin');
        tagColor = 'yellow';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  // const displayStatus = (value) => {
  //   let displayStr = '';
  //   let tagColor = 'green';
  //   let statusValue = _.toInteger(value);
  //   switch (statusValue) {
  //     case 1:
  //       displayStr = "Approved";
  //       tagColor = 'green';
  //       break;
  //     case 0:
  //       displayStr = "Waiting For Approve";
  //       tagColor = 'red';
  //       break;
  //     default:
  //       displayStr = "Rejected"
  //       tagColor = '#f50';
  //       break;
  //   }
  //   return <Tag color={tagColor}>{displayStr}</Tag>;
  // }

  // const getDataBySearchKeyword = (keywords) => {
  //   let dataList = []
  //   try {
  //     const options = {
  //       includeScore: true,
  //       findAllMatches: true,
  //       threshold: 0.15,
  //       keys: ['email', 'order_key', 'token_before', 'token_after', 'token_change', 'type', 'change_time', 'status']
  //     }
  //     const fuse = new Fuse(unChangeData, options)
  //     const result = fuse.search(keywords);
  //     dataList = _.map(result, 'item');
  //     dataList = _.orderBy(dataList, ['user_id'], ['desc']);
  //   } catch (error) {
  //     console.error('error >>> ', error);
  //   } finally {
  //     setDataList(dataList);
  //   }
  // }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const deletePackage = async (token_package_id) => {
    let endpoint = '/api/token/package/delete';
    // console.log(token_package_id);
    let result = await Service.call('PUT', endpoint, {token_package_id});
    // console.log(result);
    getAllData();
  }
  // const onChange = (keyword) => {
  //   debounce(() => {
  //     getDataBySearchKeyword(keyword);
  //     if (_.isEmpty(keyword)) setDataList(unChangeData);
  //   }, 400);
  // };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKey={openKey}>
      {
        disableEdit ? '' : (
          <>
            <Button
              type="primary"
              onClick={() => {
                setSelectedRecord({ company_user_id: 0 });
                setModalVisible(true);
              }}
            >
              {t("create_new_package")}
            </Button>
            <Divider />
          </>
        )
      }
      {/* <Row>
        <Col style={{ marginTop: 5 }}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20, marginBottom: 10 }}>
          <Input
            placeholder={t('please_input_keyword')}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
      </Row> */}
      {/* <Divider /> */}
      <Spin spinning={loading}>
        <Table
          rowKey={(item) => item.token_package_id}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setTableHeader()}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: [25, 50, 100, 200, 500]
          }}
        />
        <Modal
          destroyOnClose
          title={t("token_package")}
          visible={modalVisible}
          footer={null}
          width={1280}
          style={{ maxWidth: "95%" }}
          onCancel={() => {
            setModalVisible(false);
          }}
        >
          <TokenPackageForm
            setModalVisible={setModalVisible}
            dataObj={selectedRecord}
            getAllData={getAllData}
          />
        </Modal>
      </Spin>
    </NavAndSideFrame>
  )
}

export default CompanyTokenPackage;
