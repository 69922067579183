import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import {
  Layout, Row, Col, DatePicker, Tabs
} from 'antd';
import 'antd/dist/antd.css';
import {
  LabelList, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, Label
} from 'recharts';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import moment from 'moment';
import AppLayout from '../../components/AppLayout';


const Home = () => {
  const { t } = useTranslation();
  return (
    <AppLayout title={t("home")} />
  )
}

export default Home;
