import _ from 'lodash';

export function checkPermission(requiredPermissions, userPermissions) {
  if (!_.isEmpty(requiredPermissions) && !_.isArray(requiredPermissions)) {
    requiredPermissions = [requiredPermissions];
  }

  // If require NO permission
  if (_.isEmpty(requiredPermissions)) {
    return true;
  }
  // If user has ALL permission
  if (_.includes(userPermissions, "ui_all")) {
    return true;
  }
  let missingPermission = _.difference(requiredPermissions, userPermissions);
  if (_.isEmpty(missingPermission)) {
    return true;
  }

  return false;
}
