import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification, Calendar, Badge, Spin, Card, Button
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as debugLib from 'debug';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import UserBookingListTable from '../../components/booking/UserbookingListCalender';
import UserbookingListCalendarView from '../../components/booking/UserbookingListCalendarView';
import UserbookingListTableView from '../../components/booking/UserbookingListTableView';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// import BookingCalendar from '../../components/BookingCalendar';

const debug = debugLib('app:pages/BookingList');

const selectedKey = "booking_list";
const openKey = "booking";

// let sampleText = "apple";

const UserbookingContainer = (props) => {
  const { t } = useTranslation()
  const title = t('booking_calender');

  const [view, setView] = useState(1);

  const renderView = () => {
    if (view === 1) {
      return <UserbookingListCalendarView />
    }
    // if (view === 2) {
    //   return <UserbookingListTableView />
    // }
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKey={openKey}>
      {
        // <Select
        //   defaultValue={1}
        //   onChange={(value) => setView(value)}
        // >
        //   <Option key={1} value={1}>{t("calendar_view")}</Option>
        //   <Option key={2} value={2}>{t("table_view")}</Option>
        // </Select>
      }
      {renderView()}
    </NavAndSideFrame>
  )
}

export default UserbookingContainer;
