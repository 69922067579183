import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  Typography,
  Tag,
  Table,
  Popconfirm,
  Tooltip,
  Checkbox,
} from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as debugLib from "debug";
import NavAndSideFrame from "../NavAndSideFrame";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import FormUploadFile from "../form/FormUploadFile";

const debug = debugLib("app:pages/company/item/list/Modal");

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ItemRulesetModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    rulesetModalVisible,
    setRulesetModalVisible,
    setRefreshModalVisible,
  } = props;
  const [selectTablKey, setSelectedTabKey] = useState(1);
  const [rulesetGroupList, setRulesetGroupList] = useState([]);
  const [linkedRulesetGroupList, setLinkedRulesetGroupList] = useState([]);
  const [itemRulesetList, setItemRulesetList] = useState([]);
  const [loading, setLoading] = useState(false);
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  const [ItemRulesetForm] = Form.useForm();

  useEffect(() => {
    // console.log('selected Record >>>>', selectedRecord)
    if (_.isEmpty(selectedRecord)) {
      return;
    }
    getRuleSetGroupOption(selectedRecord.item_id);
    getLinkedRuleSetGroupOption(selectedRecord.item_id);
  }, [selectedRecord, rulesetModalVisible]);

  const getRuleSetGroupOption = async (item_id) => {
    let result = await Service.call('post', '/api/item/not_link_ruleset_group_option', { item_id })
    if (result.status !== 1) {
      return notification.error({
        message: t('error'),
        description: result.errorMessage,
      });
    }
    let { data } = result;
    setRulesetGroupList(data);
  }

  const getLinkedRuleSetGroupOption = async (item_id) => {
    let result = await Service.call('post', '/api/item/linked_ruleset_group_option', { item_id })
    if (result.status !== 1) {
      return notification.error({
        message: t('error'),
        description: result.errorMessage,
      });
    }
    let { data } = result;
    setLinkedRulesetGroupList(data);
  }

  const ruleSetGroupTable = () => {
    const rulesetColumns = [
      {
        title: t('name'),
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: t('remarks'),
        key: 'remarks',
        dataIndex: 'remarks',
      },
      {
        title: t('operation'),
        dataIndex: 'item_ruleset_group_id',
        key: 'item_ruleset_group_id',
        render: (value, record) => {
          return (
            <span>
              <Button
                type="primary"
                onClick={async () => {
                  // value = item_ruleset_group_id
                  // console.log('value .>>>', value, 'record >>>', record)
                  let result = await Service.call('post', '/api/item/link_ruleset_group', { item_ruleset_group_id: value, item_id: selectedRecord.item_id })
                  if (result.status !== 1) {
                    return notification.error({
                      message: t('error'),
                      description: result.errorMessage,
                    });
                  }
                  notification.success({ description: 'Linked!' })
                  getRuleSetGroupOption(selectedRecord.item_id);
                  getLinkedRuleSetGroupOption(selectedRecord.item_id)
                }}
              >
                {t("link")}
              </Button>
            </span>
          )
        }
      },
    ]
    return (
      <Table
        rowKey={(index) => index}
        scroll={{ x: true }}
        columns={rulesetColumns}
        dataSource={rulesetGroupList}
      />
    )
  }

  const LinkedRuleSetGroupTable = () => {
    const rulesetColumns = [
      {
        title: t('name'),
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: t('remarks'),
        key: 'remarks',
        dataIndex: 'remarks',
      },
      {
        title: t('operation'),
        dataIndex: 'item_ruleset_group_ref_id',
        key: 'item_ruleset_group_ref_id',
        render: (value, record) => {
          return (
            <span>
              <Button
                type="primary"
                onClick={async () => {
                  // console.log(value)
                  let result = await Service.call('put', '/api/item/delete_ruleset_group', { item_ruleset_group_ref_id: value })
                  if (result.status !== 1) {
                    return notification.error({
                      message: t('error'),
                      description: result.errorMessage,
                    });
                  }
                  notification.success({ description: `${t('Unlinked')}!` })
                  getRuleSetGroupOption(selectedRecord.item_id);
                  getLinkedRuleSetGroupOption(selectedRecord.item_id)
                }}
              >
                {t("unlink")}
              </Button>
            </span>
          )
        }
      },
    ]
    return (
      <Table
        rowKey={(index) => index}
        scroll={{ x: true }}
        columns={rulesetColumns}
        dataSource={linkedRulesetGroupList}
      />
    )
  }

  return (
    <Modal
      title={t("edit_ruleset")}
      visible={rulesetModalVisible}
      footer={null}
      width={1200}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setRulesetModalVisible(false);
        setRefreshModalVisible(true);
      }}
    >
      <Tabs defaultActiveKey={selectTablKey} onChange={(e) => setSelectedTabKey(e)}>
       
        <TabPane tab={t('linked_ruleset_group')} key={2}>
          {LinkedRuleSetGroupTable()}
        </TabPane>
        <TabPane tab={t('linkage_option')} key={1}>
          {ruleSetGroupTable()}
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ItemRulesetModal;
