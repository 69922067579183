import React, { useState, useEffect } from "react";
import _, { set } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  Checkbox,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  Spin,
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "querystring";
import TextArea from "antd/lib/input/TextArea";
import { T } from "antd/lib/upload/utils";
import * as Main from "../core/Main";
import * as Service from '../core/Service';
import FormUploadFile from './form/FormUploadFile';

const PostsFormModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible
  } = props;

  const [initialFormValues, setInitialFormValues] = useState({});
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const [inserting, setInserting] = useState(false);
  const [PostsForm] = Form.useForm();

  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  useEffect(() => {
    if (selectedRecord) {
      if (selectedRecord.post_date > 0) {
        selectedRecord.postDate = moment.unix(selectedRecord.post_date);
      }
      if (selectedRecord.post_cover_image) {
        setImageURL(`${STATIC_SERVER_URL}/${selectedRecord.post_cover_image}`);
      } else {
        setImageURL("");
      }

      if (selectedRecord.on_landing === 1) {
        selectedRecord.onLanding = true;
      }
      selectedRecord.onLanding = selectedRecord.on_landing === 1

      console.log('!!!!!!', selectedRecord.hide_post_date)
      selectedRecord.hidePostDate = selectedRecord.hide_post_date === 1;
      selectedRecord.hidePostTime = selectedRecord.hide_post_time === 1;
      setInitialFormValues(selectedRecord);
    }
  }, [selectedRecord]);

  useEffect(() => {
    PostsForm.resetFields();
  }, [initialFormValues])

  const uploadOnChange = (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("uploaded"));
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        };

        let path = "../../" + info.file.response.filepath;
        setImageURL(path);
        setFileinfo(data);
      } else {
        message.error(t("fail"));
      }
    }
  };

  const onFinish = async (formData) => {
    if (inserting) return;
    setInserting(true);
    let endpoint = '/api/posts';
    let post_id = selectedRecord.post_id;
    console.log(formData);

    let params = formData;
    params.post_cover_image = fileinfo.filepath ? fileinfo.filepath : imageURL;
    params.post_date = formData.postDate.unix();
    params.on_landing = formData.onLanding ? 1 : 0;
    params.post_type = "news"
    params.hide_post_date = formData.hidePostDate ? 1 : 0;
    params.hide_post_time = formData.hidePostTime ? 1 : 0;

    // params.post_cover_image = imageURL;
    delete params.postDate;
    delete params.hidePostDate;

    if (post_id > 0) params.post_id = post_id;
    console.log(params);
    let resp = await Service.call(post_id > 0 ? 'PUT' : 'POST', endpoint, params);
    setInserting(false);
    console.log(resp);
    if (!resp || resp.status < 1) {
      message.error(t('fail'));
      return;
    }
    setInserting(false);
    message.success(t('modal_success'));
    setModalVisible(false);
  };

  return (
    <Modal
      title="Posts Form"
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{maxWidth: "95%"}}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={PostsForm}
        name={t("posts_form")}
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={onFinish}
      >
        <Form.Item
          label={t("title")}
          name="post_title"
          rules={[{required: true, message: t('input_required')}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("title_zh_hant")}
          name="post_title_zh_hant"
          rules={[{required: true, message: t('input_required')}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("image")}
          name="post_cover_image"
          rules={[{required: true, message: t('upload_required')}]}
        >
          {_.isEmpty(imageURL) ? (
            <FormUploadFile
              data={{ scope: "private" }}
              onChange={uploadOnChange}
            />
          ) : (
            <Row style={{ alignItems: 'flex-end' }}>
              <img
                alt=""
                src={imageURL}
                style={{
                  width: '275px',
                  height: '155px',
                  objectFit: 'cover',
                }}
              />
              <Col style={{ marginLeft: '10px' }}>
                <p style={{ marginBottom: '5px' }}>275 x 155</p>
                <Button
                  type="default"
                  onClick={() => setImageURL()}
                >
                  Remove Current Image
                </Button>
              </Col>
            </Row>
          )}
        </Form.Item>
        <Form.Item
          label={t("excerpt")}
          name="post_excerpt"
          rules={[{required: true, message: t('input_required')}]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t("post_excerpt_zh_hant")}
          name="post_excerpt_zh_hant"
          rules={[{required: true, message: t('input_required')}]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t("content")}
          name="post_content"
          rules={[{required: true, message: t('input_required')}]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t("content_zh_hant")}
          name="post_content_zh_hant"
          rules={[{required: true, message: t('input_required')}]}
        >
          <TextArea />
        </Form.Item>
        {/* <Form.Item
          label={t("sorting")}
          name="sorting"
          rules={[{required: true, message: t('input_required')}]}
        >
          <InputNumber
            min={1}
            defaultValue="1"
            formatter={(value) => _.floor(value)}
            parser={(value) => _.floor(value)}
          />
        </Form.Item> */}
        <Form.Item
          label={t("post_date")}
          name="postDate"
          rules={[{required: true, message: t('input_required')}]}
        >
          <DatePicker
            showTime
          />
        </Form.Item>
        <Form.Item name="hidePostDate" label={t("hide_post_date")} valuePropName="checked">
          <Checkbox>{t("hide_post_date")}</Checkbox>
        </Form.Item>
        <Form.Item name="hidePostTime" label={t("hide_post_time")} valuePropName="checked">
          <Checkbox>{t("hide_post_time")}</Checkbox>
        </Form.Item>
        <Form.Item
          label={t("keywords")}
          name="keywords"
          rules={[{required: true, message: t('input_required')}]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="onLanding" valuePropName="checked">
          <Checkbox>{t('on_landing')}</Checkbox>
        </Form.Item>
        {/* <Form.Item
          // label={t("onLanding")}
          name="onLanding"
          rules={[{required: true, message: t('select_required')}]}
        >
          <Checkbox
            onChange={(e) => { selectedRecord.on_landing = e.target.checked ? 1 : 0; }}>
            {t('onLanding')}
          </Checkbox>
        </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PostsFormModal;
