import React, { useState, useEffect } from 'react';
import {
  Row, Col, Input, Button, Divider, Layout, Modal, Table, Tag, Tooltip
} from 'antd';
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined,
  KeyOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import 'antd/dist/antd.css';
import _ from 'lodash';
import Fuse from 'fuse.js';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import CompanyAdminForm from './CompanyAdminForm';
import CompanyAdminPasswordForm from './CompanyAdminPasswordForm';


const filterableFields = [
  `is_active`,
  `company_name`,
  `fullname`,
  `email`,
  `mobile`,
];
const CompanyAdminTable = (props) => {
  const [dataList, setDataList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordRecord, setPasswordRecord] = useState({});
  const [filterDataList, setFilterDataList] = useState([]);

  const tableIDName = "company_admin_id";
  const admin = useSelector((state) => state.app.admin);
  const company_admin = useSelector((state) => state.app.admin);
  const { t } = useTranslation();
  const { dataObj, adminGroupList, disableEdit } = props;

  useEffect(() => {
    setDataList(props.dataObj);
    setFilterDataList(props.dataObj);
  }, [dataObj]);

  const setTableHeader = () => {
    let columns = [
      {
        title: t("status"),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active,
      },
      checkDisplayPermission({
        title: t("company_name"),
        dataIndex: `company_name`,
        // sorter: (a, b) => a.company_name - b.company_name,
      }),
      {
        title: t("name"),
        dataIndex: 'fullname',
        // render: (value, record) => `${record.first_name} ${record.last_name} (${record.nickname})`,
        // sorter: (a, b) => a.first_name.localeCompare(b.first_name)
      },
      {
        title: t("email"),
        dataIndex: 'email',
        // render: (data, record) => `(${record.controller_device_id}) ${record.controller_name}`,
        // sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: t("mobile"),
        dataIndex: 'mobile',
        // sorter: (a, b) => a.mobile.localeCompare(b.mobile),
      },
      {
        title: t("company_user_group"),
        dataIndex: 'company_user_group_id',
        render: (value) => displayUserGroup(value),
      }
    ];

    if (!disableEdit) {
      columns.unshift( {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                  }}
                  style={{ marginRight: 8, color: '#000000' }}
                />
              </Tooltip>
              {
                record.company_user_id === company_admin.company_user_id ? '' : (
                  <Tooltip title={record.is_active ? t("disable_user") : t("enable_user")}>
                    <Button
                      shape="circle"
                      style={{ color: record.is_active ? 'red' : 'green', marginRight: 8}}
                      icon={record.is_active ? (<StopOutlined />) : (<CheckOutlined />)}
                      onClick={async () => {
                        let { company_user_id, is_active } = record;
                        is_active = is_active === 1 ? 0 : 1;
                        await Service.call('patch', '/api/admin/status', { company_user_id, is_active });
                        // if (admin.admin_id == null) {
                        //   props.companyChangeData();
                        // } else {
                        //   props.adminChangeData();
                        // }
                        //
                        props.adminChangeData();
                      }}
                    />
                  </Tooltip>
                )
              }
              <Tooltip title={t("reset_pwd")}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: '#000000' }}
                  icon={<KeyOutlined />}
                  onClick={() => {
                    setPasswordVisible(true);
                    setPasswordRecord(record);
                  }}
                />
              </Tooltip>
            </span>
          )
        }
      });
    }
    return columns;
  }

  const checkDisplayPermission = (value) => {
    if (admin.company_user_id == null) return {}
    return value
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "active";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "inactive";
        tagColor = 'red';
        break;
      case -1:
        displayStr = "disable";
        tagColor = '#9A9A9A';
        break;
      default:
        displayStr = "error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{t(displayStr)}</Tag>;
  }

  const displayUserGroup = (value) => {
    let group = _.find(adminGroupList, (o) => {
      return o.company_user_group_id === value;
    });
    if (!_.isUndefined(group)) {
      return `${group.title}(#${value})`;
    }
    return `${t('unknown')}(#${value})`;
  }

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0,
        keys: filterableFields,
        ignoreLocation: true,
      }
      const fuse = new Fuse(filterList, options)
      const result = fuse.search(filterObj);
      console.log(result);
      filterList = _.map(result, 'item');
      console.log(filterList);
      filterList = _.orderBy(filterList, ['admin_id'], ['asc']);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      filterList = _.orderBy(filterList, 'admin_id');
      setFilterDataList(filterList);
    }
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Row>
          <Col style={{marginTop: 5}}>
            {t(`search`) + `:`}
          </Col>
          <Col style={{marginLeft: 20}}>
            <Input
              placeholder={t(`please_input_keyword`)}
              style={{ minWidth: 270, width: '50%' }}
              onChange={
                (values) => {
                  onChange(values.target.value);
                }
              }
            />
          </Col>
        </Row>
        <Button
          style={{float: 'right'}}
          icon={<FileExcelOutlined />}
          onClick={onExportTable}
        >
          {t('export_to_excel_file')}
        </Button>
      </div>
      <Divider />
      <Layout>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
        />

        <Modal
          destroyOnClose
          title={t('edit')}
          visible={modalVisible}
          footer={null}
          style={{ maxWidth: 800 }}
          width="90%"
          onCancel={() => { setModalVisible(false) }}
        >
          <CompanyAdminForm
            dataObj={
              selectedRecord
            }
            openModal={
              (value) => {
                // if (admin.admin_id == null) {
                //   props.companyChangeData();
                // } else {
                //   props.adminChangeData();
                // }
                props.adminChangeData();
                setModalVisible(value)
              }
            }
            adminGroupList={adminGroupList}
          />
        </Modal>
        <Modal
          destroyOnClose
          title="Edit"
          visible={passwordVisible}
          footer={null}
          style={{ maxWidth: 800 }}
          width="90%"
          onCancel={() => { setPasswordVisible(false) }}
        >
          <CompanyAdminPasswordForm
            dataObj={passwordRecord}
            openModal={
              (value) => {
                // getAllData();
                setPasswordVisible(value)
                // if (admin.admin_id != null) props.adminChangeData()
                // else props.companyChangeData()
                props.adminChangeData()
              }
            }
          />
        </Modal>
      </Layout>
    </>
  )
}

export default CompanyAdminTable;
