import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { withTranslation } from 'react-i18next';
import { Button, Divider, Layout } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const { Header, Content, Sider } = Layout;

class NavAndSideFrame extends React.PureComponent {
  render() {
    let {
      title, children, selectedKey, openKey, onExportTable, t
    } = this.props;
    return (
      <Layout style={{width: '100%'}}>
        <Navbar {...this.props} />
        <Layout>
          <Sidebar {...this.props} defaultSelectedKeys={selectedKey} defaultOpenKeys={openKey} />
          <Layout style={{width: '100%'}}>
            <Content
              style={{
                background: '#fff', margin: 0, minHeight: 280, padding: 24
              }}
            >
              <h3 style={{display: 'contents'}}>{title}</h3>
              {
                onExportTable === undefined ? ''
                  : (
                    <Button
                      style={{float: 'right'}}
                      icon={<FileExcelOutlined />}
                      onClick={onExportTable}
                    >
                      {t('export_to_excel_file')}
                    </Button>
                  )
              }
              <Divider />
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

export default withTranslation()(NavAndSideFrame);

NavAndSideFrame.defaultProps = {
  title: "Title",
  children: null
}
