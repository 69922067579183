import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import {
  DatePicker,
  Spin,
  Empty,
} from 'antd';
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import ExportToPDFButton from "../ExportToPDFButton";

const IncomeByCatPieChart = () => {
  const id = "income_by_cat_pie_chart";
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').unix());
  const [endDate, setEndDate] = useState(moment().unix());
  const [incomeByCatData, setIncomeByCatData] = useState([]);

  useEffect(() => {
    if (!_.isNull(startDate) && !_.isNull(endDate)) {
      getIncomeByCatBarChart(startDate, endDate);
    }
  }, [startDate, endDate]);

  const getIncomeByCatBarChart = async (start_date, end_date) => {
    setLoading(true);
    try {
      let url = `/api/dashboard/income_by_category?start_date=${start_date}&end_date=${end_date}`;
      let result = await Service.call("get", url);
      setIncomeByCatData(result);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const renderPieChart = () => {
    if (_.isEmpty(incomeByCatData)) {
      return (
        <div style={{padding: "10% 3%"}}>
          <Empty />
        </div>
      );
    }

    const COLORS = [
      "#96D9FF",
      "#C658D0",
      "#9C46D0",
      "#E01D84",
      "#FF7200",
      "#FFAE00",
      "#FFEC00",
      "#52D726",
      "#1BA92E",
      "#2CCB75",
      "#96D9FF",
    ];

    let data0 = _.map(incomeByCatData, (data) => {
      let returnObj = {
        name: data.category_name,
        value: data.income / 100,
      };
      return returnObj;
    });

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = (obj) => {
      let {
        cx, cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
      } = obj;

      const radius = innerRadius + (outerRadius - innerRadius) * 0.75;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      const displayStr = percent <= 0.05 ? '...' : `${(percent * 100).toFixed(0)}%`;

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {displayStr}
        </text>
      );
    };

    return (
      <ResponsiveContainer width="100%" height={400}>
        <PieChart width={400} height={400}>
          <Legend verticalAlign="top" height={36} />
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data0}
            cx="50%"
            cy="50%"
            outerRadius={150}
            label={renderCustomizedLabel}
            labelLine={false}
          >
            {data0.map((entry, index) => (
              <Cell
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const onCalendarChange = (dateArray) => {
    if (_.isNull(dateArray)) {
      return;
    }

    let start_date = dateArray[0] ? dateArray[0].unix() : null;
    let end_date = dateArray[1] ? dateArray[1].unix() : null;

    setStartDate(start_date);
    setEndDate(end_date);
  }

  return (
    <Spin id={id} spinning={loading}>
      <ExportToPDFButton targetID={id} />
      <h2>{t("income_by_cat_pie_chart")}</h2>
      <RangePicker
        onCalendarChange={onCalendarChange}
        defaultValue={[moment(startDate, 'X'), moment(endDate, 'X')]}
      />
      {renderPieChart()}
    </Spin>
  );
};

export default IncomeByCatPieChart;
