import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Space, Row, Col, Button, Form, Input, Select, message, Tabs, DatePicker, TimePicker, Switch, notification, Divider, InputNumber, Radio, AutoComplete, Modal
} from 'antd';
import moment from 'moment';
import * as debugLib from 'debug';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import * as Service from '../../core/Service';
import FormUploadFile from "../form/FormUploadFile";

const debug = debugLib('app:pages/company/webStatusModal');

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const CategoryInfoModal = (props) => {
  const { t } = useTranslation()
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [childCategoryOption, setChildCategoryOption] = useState([])
  const [formItemVisible, setFormItemVisible] = useState(false);
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );
  const {
    selectedRecord, categoryList, modalVisible, setModalVisible, handleFormSubmit
  } = props

  const [CategoryInfoForm] = Form.useForm();

  // console.log("selectedRecord", selectedRecord)
  useEffect(() => {
    getParentOption();
    // setWithBreak(Boolean(selectedRecord.is_with_break))
    if (selectedRecord) {
      // selectedRecord.utime = moment.unix(selectedRecord.utime).format('YYYY-MM-DD HH:mm')
      // selectedRecord.ctime = moment.unix(selectedRecord.ctime).format('YYYY-MM-DD HH:mm')
      if (selectedRecord.banner) {
        setImageURL(`${STATIC_SERVER_URL}/media/${selectedRecord.banner}`);
      } else {
        setImageURL("");
      }
      if (selectedRecord.item_category_id === 0) {
        setFormItemVisible(true);
      }
    }
    setIsActive(selectedRecord.is_active)
    CategoryInfoForm.resetFields()
  }, [selectedRecord])


  const uploadOnChange = (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("uploaded"));
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        };
        let path = `${STATIC_SERVER_URL}/${info.file.response.filepath}`;
        setImageURL(path);
        setFileinfo(data);
      } else {
        message.error(t("fail"));
      }
    }
  };

  const getParentOption = async () => {
    let result = await Service.call('get', '/api/item/category/list/child');
    let { data } = result;
    if (result.status !== 1) {
      return message.error(result.errorMessage)
    }
    setChildCategoryOption(data)
  }
  return (
    <Modal
      title={selectedRecord.item_category_id === 0 ? t('add_new_record') : t('edit_record')}
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        setModalVisible(false)
      }}
    >
      <Form
        form={CategoryInfoForm}
        name="CategoryInfoForm"
        layout="vertical"
        initialValues={selectedRecord}
        onFinish={(formData) => {
          formData.company_id = selectedRecord.company_id
          formData.banner = fileinfo.filepath
            ? fileinfo.filepath
            : null;
          handleFormSubmit(formData)
          setModalVisible(false);
        }}
      >
        <Form.Item
          label={t('name')}
          name="category_name"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Input maxLength={50} />
        </Form.Item>

        <Form.Item
          label={t('name_zh_hant')}
          name="category_name_zh_hant"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item
          label={t('active_status')}
          name="is_active"
          initialValue={1}
        >
          <Select>
            <Option value={1}>{t('active')}</Option>
            <Option value={0}>{t('inactive')}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t('description')}
          name="category_desc"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Input maxLength={65535} />
        </Form.Item>
        <Form.Item
          label={t('description_zh_hant')}
          name="category_desc_zh_hant"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Input maxLength={65535} />
        </Form.Item>

        {/* <Form.Item
          label={t('parent_type')}
          name="type"
        >
          <Select>
            {categoryList.map(
              (category)=> {
                return (<Option value={category.item_category_id}>{category.category_name}</Option>)
              }
            )}
          </Select>
        </Form.Item> */}

        <Form.Item
          label={t('sorting')}
          name="sorting"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <InputNumber maxLength={10} />
        </Form.Item>
        <Form.Item
          label={t('parent_id')}
          name="parent_category_id"
        >
          <Select
            defaultValue={0}
            onChange={(value) => {
              console.log('event >>>>', value)
              if (value === 0) {
                setFormItemVisible(true);
                return;
              }
              setFormItemVisible(false);
            }}
          >
            <Option value={0}>{t('no_parent')}</Option>
            {!_.isEmpty(childCategoryOption) && childCategoryOption.filter((item) => item.item_category_id !== selectedRecord.item_category_id).map((item) => {
              return (
                <Option value={item.item_category_id}>{item.category_name}</Option>
              )
            })}
          </Select>
        </Form.Item>
        {/* {formItemVisible && ( */}
        <Form.Item
          label={t('type')}
          name="type"
          rules={[{ required: formItemVisible, message: t('input_required') }]}
        >
          <Select
            defaultValue={1}
          >
            <Option value={1}>{t('room')}</Option>
            <Option value={2}>{t('car_park')}</Option>
            <Option value={3}>{t('special_room')}</Option>
            <Option value={4}>{t('locker')}</Option>
          </Select>
        </Form.Item>
        {/* )} */}
        {selectedRecord.item_category_id === 0
          ? null
          : (
            <div>
              <Form.Item
                label={t('create_time')}
              >
                <p>{moment.unix(selectedRecord.utime).format('YYYY-MM-DD HH:mm')}</p>
              </Form.Item>
              <Form.Item
                label={t('utime')}
              >
                <p>{moment.unix(selectedRecord.ctime).format('YYYY-MM-DD HH:mm')}</p>
              </Form.Item>
            </div>
          )}

        <Form.Item label={t("image")} name="item_feature_image">
          {_.isEmpty(imageURL) ? (
            <FormUploadFile
              data={{ scope: "private" }}
              onChange={uploadOnChange}
            />
          ) : (
            <Row style={{ alignItems: 'flex-end' }}>
              <img
                alt=""
                src={imageURL}
                style={{
                  width: '150px',
                  height: '150px',
                  objectFit: 'cover',
                }}
              />
              <Col style={{ marginLeft: '10px' }}>
                <p style={{ marginBottom: '5px' }}>150 X 150</p>
                <Button
                  type="default"
                  onClick={() => setImageURL()}
                >
                  Remove Current Image
                </Button>
              </Col>
            </Row>
          )}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CategoryInfoModal;
