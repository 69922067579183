import React, { useState, useEffect } from "react";
import _, { set } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  Spin
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "querystring";
import * as debugLib from "debug";
import { title } from "process";
import NavAndSideFrame from "./NavAndSideFrame";
import * as Main from "../core/Main";
import * as Service from '../core/Service';
import OrderItemList from '../pages/OrderItemList';

const CommonAreaFormModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedOrder,
    modalVisible,
    setModalVisible
  } = props;
  const [isActive, setIsActive] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [orderItemDataList, setOrderItemDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [OrderForm] = Form.useForm();


  useEffect(() => {
    selectedOrder.booking_date = moment.unix(selectedOrder.booking_date).format('YYYY/MM/DD')
    selectedOrder.start_time = moment.unix(selectedOrder.start_time).format('HH:mm')
    selectedOrder.end_time = moment.unix(selectedOrder.end_time).format('HH:mm')
    setInitialFormValues(selectedOrder)
  }, [modalVisible]);

  useEffect(() => {
    OrderForm.resetFields();
  }, [initialFormValues])

  const onFinish = async (formData) => {
    console.log('finish');
  }

  return (
    <Modal
      title={t("common_area_form")}
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={OrderForm}
        name={t("common_area_form")}
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={onFinish}
      >
        <Row justify="space-around">
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="name"
              label={t("name")}
            // rule={[required: true]}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="mobile"
              label={t("mobile")}
            // rule={[required: true]}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="email"
              label={t("email")}
              rule={[]}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around">
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="remarks"
              label={t('remarks')}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="item_name"
              label={t('room')}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7} />
        </Row>
        <Row justify="space-around">
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="booking_date"
              label={t('date')}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="start_time"
              label={t('start_time')}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
          <Col xs={7} sm={7} lg={7} xl={7} xxl={7}>
            <Form.Item
              name="end_time"
              label={t('end_time')}
            >
              <Input disabled={!isActive} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
export default CommonAreaFormModal;
