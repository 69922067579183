import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Space, Row, Col, Form, Input, Select, message, Tabs, DatePicker, TimePicker, Switch, notification, Divider, InputNumber, Radio, AutoComplete, Modal, Typography, Tooltip, Button
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import * as debugLib from 'debug';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { Title } = Typography;

const BookingInfoModal = (props) => {
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );
  // formType {1}: company, {2}: user
  const { t } = useTranslation();
  const {
    formType, userList, dataObj, modalVisible, setModalVisible, handleFormSubmit
  } = props
  const [targetUser, setTargetUser] = useState(0);
  const [quotaLeft, setQuotaLeft] = useState('N/a');
  const [userOption, setUserOption] = useState('user');
  const [number, setNumber] = useState("");
  const [isActive, setIsActive] = useState(false);
  // console.log('modalObj>>>', dataObj)
  // console.log('userList>>>', userList)
  const [form] = Form.useForm();
  const defaultFormData = {mobile_prefix: '+852', user_option: userOption}

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(defaultFormData)
    if (dataObj.user_guest_id === 0) {
      setTargetUser(dataObj.user_id);
      setUserOption('user');
    } else {
      setNumber(dataObj.mobile);
      setUserOption('guest');
    }
    if (dataObj.status === 1) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    // setTargetUser(dataObj.user_id)
  }, [dataObj]);

  // useEffect(() => {
  //   if (targetUser === 0) {
  //     setQuotaLeft('N/a');
  //   } else {
  //     getUserQuota(targetUser, dataObj.item_category_id);
  //   }
  // }, [targetUser])

  useEffect(() => {
    let formData = form.getFieldsValue();
    let newFormData = {...formData, user_option: userOption}
    form.setFieldsValue(newFormData);
  }, [userOption])

  // const getUserQuota = async (user_id, item_category_id) => {
  //   let { status, data } = await Service.call('get', `/api/user_quota/list/${user_id}/${item_category_id}`);
  //   if (!_.isUndefined(data)) {
  //     setQuotaLeft(data.quota_amount)
  //   } else {
  //     setQuotaLeft('N/a')
  //   }
  // }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  // console.log('dataObj.user_id', dataObj.user_id)
  // console.log('check', targetUser)

  const onNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!_.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setNumber(value);
      form.setFieldsValue({ mobile: value })
    }
  };

  const renderUserBookingForm = () => {
    return (
      <div>
        <Form.Item
          label={t('user')}
          name="user_id"
        >
          <Select
            onChange={(value) => {
              setTargetUser(value);
            }}
            disabled={true}
            // disabled={!isActive}
          >
            <Option value={0}> </Option>
            {userList}
          </Select>
        </Form.Item>
        {
          // <Form.Item
          //   wrapperCol={24}
          //   style={{
          //     textAlign: "right",
          //     fontSize: 10,
          //     color: '#8c8c8c',
          //     display: !isActive ? "None" : null
          //   }}
          // >
          //   <Typography.Text>
          //     {`${t('available_quota')}: ${quotaLeft}`}
          //   </Typography.Text>
          // </Form.Item>
        }
      </div>
    )
  };

  // const renderGuestBookingForm = () => {
  //   return (
  //     <div>
  //       <Form.Item
  //         label={t('first_name')}
  //         name="first_name"
  //       >
  //         <Input maxLength={64} disabled={!isActive} />
  //       </Form.Item>

  //       <Form.Item
  //         label={t('last_name')}
  //         name="last_name"
  //       >
  //         <Input maxLength={64} disabled={!isActive} />
  //       </Form.Item>

  //       <Form.Item
  //         label={t('nickname')}
  //         name="nickname"
  //       >
  //         <Input maxLength={50} disabled={!isActive} />
  //       </Form.Item>

  //       <Form.Item
  //         label={t('email')}
  //         name="email"
  //         rules={[{ type: 'email', message: t('invalid_email') }]}
  //       >
  //         <Input maxLength={128} disabled={!isActive} />
  //       </Form.Item>
  //       <Form.Item
  //         label={t('moblie')}
  //         name="mobile"
  //       >
  //         <Tooltip>
  //           <Input
  //             value={number}
  //             onChange={onNumberChange}
  //             addonBefore={(
  //               <Form.Item name="mobile_prefix" noStyle>
  //                 <Select style={{ width: 80 }} disabled={!isActive}>
  //                   <Option value="+852">+852</Option>
  //                 </Select>
  //               </Form.Item>
  //             )}
  //             maxLength={15}
  //             style={{ width: '100%' }}
  //             disabled={!isActive}
  //           />
  //         </Tooltip>
  //       </Form.Item>
  //       <Form.Item
  //         label={t('remarks')}
  //         name="remarks"
  //       >
  //         <Input maxLength={128} disabled={!isActive} />
  //       </Form.Item>
  //     </div>
  //   )
  // };

  const renderCompanyEditForm = () => {
    return (
      <div>
        {
          // <Form.Item
          //   label={t('user_option')}
          //   name="user_option"
          // >
          //   <Radio.Group
          //     onChange={(value) => {
          //       setUserOption(value.target.value);
          //     }}
          //     disabled={!isActive}
          //   >
          //     <Radio value="user">{t('user')}</Radio>
          //     <Radio value="guest">{t('guest')}</Radio>
          //   </Radio.Group>
          // </Form.Item>
          // {userOption === 'user' ? renderUserBookingForm() : renderGuestBookingForm()}
        }
        <Form.Item wrapperCol={16} style={{ textAlign: "right" }}>
          {/* <Button
            type="primary"
            htmlType="submit"
          >
            {dataObj.status === 1 ? t('confirm_booking') : t('cancel_booking')}
          </Button> */}
        </Form.Item>
      </div>
    )
  }

  const renderUserEditForm = () => {
    // if (dataObj.status === 1) {
    //   return (
    //     <Form.Item wrapperCol={16} style={{ textAlign: "right" }}>
    //       <Button
    //         type="primary"
    //         htmlType="submit"
    //       >
    //         {t('confirm_booking')}
    //       </Button>
    //     </Form.Item>
    //   )
    // }
    // if (dataObj.status === 10) {
    //   return (
    //     <Form.Item wrapperCol={16} style={{ textAlign: "right" }}>
    //       <Button
    //         type="danger"
    //         htmlType="submit"
    //       >
    //         {t('cancel_booking')}
    //       </Button>
    //     </Form.Item>
    //   )
    // }
    return (
      null
    )
  }

  return (
    <Modal
      title={t('details')}
      // title={dataObj.title}
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        form.resetFields()
        // setTargetUser(dataObj.user_id)
        form.setFieldsValue(defaultFormData)
        setModalVisible(false)
      }}
      bodyStyle={{ paddingBottom: "1px" }}
      style={{ width: '70vw', maxWidth: '410px' }}
      forceRender
    >
      <Form
        {...layout}
        form={form}
        name="BookingInfoForm"
        layout="horizontal"
        initialValues={dataObj}
        onFinish={(formData) => {
          let postObj = formData
          postObj.booking_id = dataObj.booking_id
          postObj.item_name = dataObj.item_name
          postObj.category_name = dataObj.category_name
          postObj.start_time = moment.unix(dataObj.start_time).format('YYYY-MM-DD HH:mm')
          postObj.end_time = moment.unix(dataObj.end_time).format('YYYY-MM-DD HH:mm')
          postObj.section_duration = dataObj.section_duration / 60
          postObj.section_price = dataObj.section_price
          postObj.item_feature_image = dataObj.item_feature_image
          postObj.booking_id = dataObj.booking_id
          postObj.status = dataObj.status
          // postObj.name = dataObj.first_name + "" + dataObj.last_name
          console.log('postObj>>>>', postObj)
          if (postObj.user_id === 0) {
            notification.error({ message: t('please_select_user') });
          } else {
            handleFormSubmit(postObj)
          }
        }}
      >

        <Form.Item label={t('name')}>
          {dataObj.item_name}
        </Form.Item>

        <Form.Item label={t('category')}>
          {dataObj.category_name}
        </Form.Item>

        {/* <Form.Item label={t('item')}>
          {dataObj.title}
        </Form.Item> */}


        <Form.Item label={t('start_time')}>
          {moment.unix(dataObj.start_time).format('YYYY-MM-DD HH:mm')}
        </Form.Item>

        <Form.Item label={t('end_time')}>
          {moment.unix(dataObj.end_time).format('YYYY-MM-DD HH:mm')}
        </Form.Item>

        <Form.Item label={t('section_duration')}>
          {dataObj.section_duration / 60 + " " + t("mins")}
        </Form.Item>

        {/* <Form.Item label={t('buffer')}>
          {dataObj.buffer / 60 + "mins"}
        </Form.Item> */}

        <Form.Item label={t('section_price')}>
          {'$' + dataObj.section_price / 100}
        </Form.Item>

        <Form.Item label={t('hourly_price')}>
          {'$' + dataObj.hourly_price / 100}
        </Form.Item>

        <Form.Item label={t('half_day_price')}>
          {'$' + dataObj.half_day_price / 100}
        </Form.Item>

        <Form.Item label={t('daily_price')}>
          {'$' + dataObj.daily_price / 100}
        </Form.Item>

        <Form.Item label={t('weekly_price')}>
          {'$' + dataObj.weekly_price / 100}
        </Form.Item>

        <Form.Item label={t('monthly_price')}>
          {'$' + dataObj.monthly_price / 100}
        </Form.Item>

        <Form.Item label={t('half_year_price')}>
          {'$' + dataObj.half_year_price / 100}
        </Form.Item>

        <Form.Item label={t('yearly_price')}>
          {'$' + dataObj.yearly_price / 100}
        </Form.Item>

        <Form.Item label={t('status')}>
          {renderBookingStatus(dataObj.status)}
        </Form.Item>

       
        {renderUserBookingForm()}
        
        <Form.Item label={t('remarks')}>
          {dataObj.remarks || "-"}
        </Form.Item>

        {renderUserEditForm()}
      </Form>
    </Modal>
  )
}

export default BookingInfoModal;

const renderBookingStatus = (status) => {
  let text = ""
  switch (status) {
    case 1:
      text = "Available"
      break;
    case 2:
      text = "Hold"
      break;
    case 9:
      text = "Expired"
      break;
    case 10:
      text = "Booked"
      break;
    default:
      text = "Error"
  }
  return text
}
