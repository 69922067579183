import React, { Component, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification, Button, Tabs
} from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import ItemInfoListTable from '../../components/item/ItemInfoListTable';
import ItemInfoModal from '../../components/item/ItemInfoModal';
import ItemGalleryModal from '../../components/item/ItemGalleryModal';
import ItemFacilityModal from '../../components/item/ItemFacilityModal';
import ItemRulesetModal from '../../components/item/ItemRulesetModal';
import ParkingInfoTable from '../../components/parking/ParkingInfoTable';
import { setRefreshItemTime } from '../../redux/actions/common';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

const involvedModelName = "company";
const selectedKey = "item_list";
const openKey = "booking";
const tableIDName = "company_user_id";

const ItemInfoList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [galleryModalVisible, setGalleryModalVisible] = useState(false);
  const [facilityModalVisible, setFacilityModalVisible] = useState(false);
  const [rulesetModalVisible, setRulesetModalVisible] = useState(false);
  const [refreshModalVisible, setRefreshModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_item_list_w')
  );
  const refreshItemTime = useSelector((state) => state.app.refreshItemTime);
  // const [RefreshLoading, setLoading] = useState(true)
  const title = t('item_list');
  const { TabPane } = Tabs;
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true)
    let dataList = [];
    let categoryList = [];
    try {
      let url = "/api/item/list/current";
      let response = await Service.call('get', url);
      if (!response || response.status !== 1) {
        setLoading(false);
        return;
      }
      let { data, translationList } = response;
      let translatedDataList = Main.mergeTranslationList(Main.TABLE_CONFIG.item_info, data, translationList)
      dataList = translatedDataList;
      // Handle section_price
      dataList = _.map(dataList, (data) => {
        return {
          ...data,
          section_price: data.section_price / 100,
          hourly_price: data.hourly_price / 100,
          daily_price: data.daily_price / 100,
          half_day_price: data.half_day_price / 100,
          weekly_price: data.weekly_price / 100,
          monthly_price: data.monthly_price / 100,
          half_year_price: data.half_year_price / 100,
          yearly_price: data.yearly_price / 100,
        }
      });
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      // console.log('after map data list >>>>>>>', dataList)
      setDataList(dataList);
      setLoading(false)
    }
    try {
      let url = "/api/item/category/list/current";
      let response = await Service.call('get', url);
      if (response) {
        const { data, translationList } = response
        response = Main.mergeTranslationList(Main.TABLE_CONFIG.item_category, data, translationList)
      }
      categoryList = response;
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      // console.log("categoryList>>", categoryList);
      setCategoryList(categoryList);
      setLoading(false)
    }
  }

  const handleFormSubmit = (submitRecord) => {
    // console.log('submitRecord before transform >>>>>', submitRecord)

    // transform
    submitRecord.repeat_type = 1;
    submitRecord.quota = 1;
    submitRecord.is_with_break = 0;
    submitRecord.break_start_time = '0'
    submitRecord.break_end_time = '0'
    submitRecord.buffer = 0;
    submitRecord.section_duration *= 60
    submitRecord.start_day = submitRecord.start_day.valueOf() / 1000;
    submitRecord.end_day = submitRecord.no_end_day ? 0 : submitRecord.end_day.valueOf() / 1000;
    submitRecord.start_time = 0;
    submitRecord.end_time = 0;
    submitRecord.section_price *= 100;
    submitRecord.hourly_price *= 100;
    submitRecord.half_day_price *= 100;
    submitRecord.daily_price *= 100;
    submitRecord.weekly_price *= 100;
    submitRecord.monthly_price *= 100;
    submitRecord.half_year *= 100;
    submitRecord.yearly_price *= 100;
    // delete submitRecord.start_end_booking_time
    // delete submitRecord.start_end_booking_day
    console.log(submitRecord)

    if (selectedRecord.item_id === 0) {
      handlePutRecord(submitRecord)
    }
    if (selectedRecord.item_id > 0) {
      handlePatchRecord(submitRecord)
    }
  }

  const handlePutRecord = async (submitRecord) => {
    setLoading(true)
    let submit = await Service.call('put', '/api/item', submitRecord)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handlePatchRecord = async (submitRecord) => {
    setLoading(true)
    let patchData = {
      item_id: selectedRecord.item_id,
      ...submitRecord
    }
    let submit = await Service.call('patch', '/api/item', patchData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleDeleteRecord = async (deleteRecord) => {
    setLoading(true)
    let postData = {
      item_id: deleteRecord,
    }
    let submit = await Service.call('post', '/api/item/delete', postData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleIsActiveChange = async (item_id, value) => {
    setLoading(true)
    let postData = {
      item_id,
      is_active: value
    }
    let submit = await Service.call('patch', '/api/item/is_active', postData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const onClickRefresh = async () => {
    dispatch(setRefreshItemTime(moment().add(1, 'minute').unix()))
    let submit = await Service.call('patch', '/api/item/is_active/all')
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKey={openKey}>
      <Tabs defaultActiveKey={1}>
        <TabPane key={1} tab={t('item_list')}>
          {
            disableEdit ? '' : (
              <Button
                icon={<PlusOutlined />}
                style={{ marginBottom: 10 }}
                type="primary"
                onClick={() => {
                  setModalVisible(true)
                  setSelectedRecord({ item_id: 0 })
                }}
              >
                {t('create')}
              </Button>
            )
          }
          {
            disableEdit ? '' : (
              <Button
                style={{ marginBottom: 10, marginLeft: 10 }}
                type="primary"
                onClick={() => setRefreshModalVisible(true)}
                loading={refreshItemTime > moment().unix()}
              >
                {t('refresh')}
              </Button>
            )
          }
          <ItemInfoListTable
            dataObj={dataList}
            categoryList={categoryList}
            adminChangeData={getAllData}
            setSelectedRecord={(record) => setSelectedRecord(record)}
            deleteRecord={(record) => handleDeleteRecord(record)}
            setModalVisible={(value) => setModalVisible(value)}
            setGalleryModalVisible={(value) => setGalleryModalVisible(value)}
            setFacilityModalVisible={(value) => setFacilityModalVisible(value)}
            setRulesetModalVisible={(value) => setRulesetModalVisible(value)}
            isActiveChangeCallBack={(item_id, value) => handleIsActiveChange(item_id, value)}
            loading={loading}
            disableEdit={disableEdit}
          />
          <ItemInfoModal
            selectedRecord={selectedRecord}
            categoryList={categoryList}
            modalVisible={modalVisible}
            setModalVisible={
              (value) => {
                setModalVisible(value)
                getAllData()
              }
            }
            handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
          />
          <ItemGalleryModal
            selectedRecord={selectedRecord}
            galleryModalVisible={galleryModalVisible}
            setGalleryModalVisible={
              (value) => {
                setGalleryModalVisible(value)
              }
            }
          />
          <ItemFacilityModal
            selectedRecord={selectedRecord}
            facilityModalVisible={facilityModalVisible}
            setFacilityModalVisible={
              (value) => {
                setFacilityModalVisible(value)
              }
            }
          />
          <ItemRulesetModal
            selectedRecord={selectedRecord}
            rulesetModalVisible={rulesetModalVisible}
            setRefreshModalVisible={setRefreshModalVisible}
            setRulesetModalVisible={
              (value) => {
                setRulesetModalVisible(value)
              }
            }
          />
          <Modal
            visible={refreshModalVisible}
            onCancel={() => setRefreshModalVisible(false)}
            onOk={() => {
              onClickRefresh()
              setRefreshModalVisible(false)
            }}
          >
            <p>{t('refresh_confirm')}</p>
            <p style={{ fontWeight: 'bold' }}>{t('refresh_reminder')}</p>
          </Modal>
        </TabPane>
        <TabPane key={2} tab={t('parking_list')}>
          <ParkingInfoTable
            setRulesetModalVisible={
              (value) => {
                setRulesetModalVisible(value)
              }
            }
            setRulesetRecord={
              (value) => [
                setSelectedRecord(value)
              ]
            }
            disableEdit={disableEdit}
          />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

export default ItemInfoList;
