import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Badge, Select, notification
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import * as Service from '../../core/Service';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as Main from '../../core/Main';
import BookingInfoModal from './BookingInfoModal';
// import BookingInfoModal from '../../components/BookingInfoModal';
import 'moment/locale/en-gb';

const { Option } = Select;

const localizer = momentLocalizer(moment)

const UserbookingListCalender = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Item Category";
  const selectedKey = 3;
  const tableIDName = "item_category_id";
  const [modalVisible, setModalVisible] = useState(false);
  const [eventObj, setEventObj] = useState([]);
  const [userList, setUserList] = useState([])
  const [bookStatus, setbookStatus] = useState([])
  const app = useSelector((state) => state.app);

  const [dataList, getCalendarData] = useState([]);
  const [eventList, setEventList] = useState([]);
  const {
    serverAction, BookingItem, callBack, setLoading, selectDateCallBack
  } = props
  const formType = 1

  useEffect(() => {
    setEventList(BookingItem)
  }, [BookingItem])

  // console.log('booking_item', props.BookingItem)

  const lookDetail = async (event) => {
    setLoading(true)
    let categoryArr = []
    let response = await Service.call('get', "/api/item/category/list/current");
    if (response) {
      categoryArr = Main.mergeTranslationList(
        Main.TABLE_CONFIG.item_category,
        response.data,
        response.translationList
      )
    }
    let categoryArrMap = _.keyBy(categoryArr, "item_category_id")
    event.category_name = categoryArrMap[event.item_category_id].category_name

    let userArr = await Service.call('get', "/api/user/list");
    let userArrMap = []
    _.forEach(userArr, (user) => {
      userArrMap.push(<Option key={user.user_id} value={user.user_id}>{user.nickname}</Option>)
    })

    setUserList(userArrMap)

    let getbookStatus = await Service.call('get', "/api/booking_item/status");
    // statusDataList = getbookStatus
    setEventObj(event)

    // setbookStatus()
    setLoading(false)
    setModalVisible(true)
  }

  const renderBackgroundColor = (status) => {
    let backgroundColor = ""
    switch (status) {
      case 1:
        backgroundColor = "#003E80"
        break;
      case 2:
        backgroundColor = "#AAAAAA"
        break;
      case 10:
        backgroundColor = "#FF1A53"
        break;
      case 9:
        backgroundColor = "#333333"
        break;
      default:
        backgroundColor = "#FF1A53"
    }
    return backgroundColor
  }

  const calendarStyle = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: renderBackgroundColor(event.status),
        borderColor: 'rgba(255, 255, 255, 0.3)'
      }
    }
  }

  const handleFormSubmit = async (submitRecord) => {
    // console.log('submitRecord>>>', submitRecord)
    // setLoading(true)
    let response
    let userRes
    let patchData
    // console.log("submitRecord", submitRecord)
    if (submitRecord.user_option === 'user') {
      patchData = {
        status: submitRecord.status,
        user_id: submitRecord.user_id,
        booking_id: submitRecord.booking_id
      }
      userRes = await Service.call('get', `/api/user/${submitRecord.user_id}`);

      if (patchData && patchData.status === 1) {
        response = await Service.call('patch', '/api/booking_item/confirm', patchData)
      }
      if (patchData && patchData.status === 10) {
        response = await Service.call('patch', '/api/booking_item/cancel', patchData)
      }
    } else {
      patchData = {
        status: submitRecord.status,
        email: submitRecord.email,
        mobile: submitRecord.mobile,
        first_name: submitRecord.first_name,
        last_name: submitRecord.last_name,
        nickname: submitRecord.nickname,
        remarks: submitRecord.remarks,
        booking_id: submitRecord.booking_id
      }

      if (patchData && patchData.status === 1) {
        response = await Service.call('patch', '/api/booking_item//guest/confirm', patchData)
      }
      if (patchData && patchData.status === 10) {
        response = await Service.call('patch', '/api/booking_item/guest/cancel', patchData)
      }
    }

    console.log('response>>>', response)
    if (response.status && response.errorCode === -80101) {
      notification.error({
        message: t('insufficient_quota'),
      });
      return
    }
    if (response.status && response.status < 1) {
      notification.error({
        message: t('fail'),
      });
      return
    }
    if (response.status && response.status > 0) {
      notification.success({
        message: t('success'),
      });
      if (!_.isUndefined(submitRecord) || !_.isUndefined(userRes)) {
        let emailResult = await Service.call('post', '/api/email/booking', {
          type: patchData.status === 1 ? "booking_confirmation" : "booking_cancellation",
          to: submitRecord.email || userRes.email,
          subject: patchData.status === 1 ? "Booking Confirmation" : "Booking Cancellation",
          data: {
            booking_id: submitRecord.booking_id,
            user_id: submitRecord.user_id || 'GUEST',
            booking_items: submitRecord,
          }
        });
        console.log('emailResult>>>', emailResult)
      }
      callBack()
      setModalVisible(false)
    }
  }

  return (
    <Layout>
      <Calendar
        // selectable
        scrollToTime={new Date(new Date().setHours(8, 1))}
        localizer={localizer}
        events={eventList}
        startAccessor="start"
        endAccessor="end"
        style={{
          height: "1000px",
          background: "white"
        }}
        defaultView={Views.WEEK}
        views={['day', 'week']}
        messages={{
          day: t('day'),
          week: t('week'),
          today: t('today'),
          next: t('next'),
          previous: t('previous')
        }}
        // onNavigate={(focusWeek, flipUnit, prevOrNext) => selectDate(prevOrNext,focusWeek)}
        onNavigate={(focusWeek, flipUnit, prevOrNext) => selectDateCallBack(prevOrNext, focusWeek)}
        // components={(value)=>{console.log('check', value)}}

        // view="week"
        // views={{ 'month': true}}
        onSelectEvent={(event) => lookDetail(event)}
        // onSelectSlot={(slotInfo) => addNewEvent(slotInfo)}
        eventPropGetter={(event) => calendarStyle(event)}
      // for one day change color
      // dayPropGetter={calendarStyle}
      // defaultDate={new Date(2015, 3, 1)}
      />

      <BookingInfoModal
        formType={formType}
        userList={userList}
        dataObj={eventObj}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />

    </Layout>
  )
}

export default UserbookingListCalender;
