import React, { useState, useEffect } from 'react';
import {
  Spin, Modal, Table, Tag, Row, Col, Input, Select
} from 'antd';
// import 'antd/dist/antd.css';
import moment from 'moment';
import Fuse from 'fuse.js';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import CompanyUserForm from './CompanyUserForm';
const debug = require('debug')('app:admin:client:src:AdvertisementList');

const filterableFields = ['email', 'order_key', 'token_before', 'token_after', 'token_change', 'type', 'change_time', 'status']
const selectedKey = 'token_log';
const openKey = 'token';
const tableIDName = "user_id";
const {Option} = Select;

const CompanyTokenLog = (props) => {
  const { t } = useTranslation();
  const title = t("token_log");

  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [filterDataList, setFilterDataList] = useState([]);
  const [searchStatus, setSearchStatus] = useState('all');
  const [searchType, setSearchType] = useState('all');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getAllData();
  }, [modalVisible]);

  useEffect(() => {
    console.log('hello')
    filterData();
  }, [
    searchKeyword,
    searchStatus,
    searchType
  ]);


  ///api/token/log

  const getAllData = async () => {
    let dataList = [];
    try {
      let url = `/api/token/log`;
      let data = await Service.call('get', url);
      // dataList = data
      dataList = _.orderBy(data, ['change_time'], ['desc']);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setDataList(dataList);
      setFilterDataList(dataList);
      setLoading(false);
    }
  }

  const setTableHeader = () => {
    const columns = [
      {
        title: t('email'),
        dataIndex: 'email',
        render: (value) => { return value || '-'; },
        // sorter: (a, b) => a.email.localeCompare(b.email)
      },
      {
        title: t('order_key'),
        dataIndex: `order_key`,
        render: (value) => { return value || '-'; },
        // sorter: (a, b) => a.ref_id.localeCompare(b.order_key)
      },
      {
        title: t('token_before'),
        dataIndex: 'token_before',
        // render: (value) => displayBoolean(value),
        sorter: (a, b) => a.token_before - b.token_before
      },
      {
        title: t('token_after'),
        dataIndex: 'token_after',
        // render: (value) => displayBoolean(value),
        sorter: (a, b) => a.token_after - b.token_after
      },
      {
        title: t('token_change'),
        dataIndex: 'token_change',
        sorter: (a, b) => (a.token_after - a.token_before) - (b.token_after - b.token_before),
        render: (value, record) => displayTokenChange(value, record),
      },
      {
        title: t('type'),
        dataIndex: 'type',
        render: (value) => displayIsType(value),
        sorter: (a, b) => a.type.localeCompare(b.type)
      },
      {
        title: t('change_time'),
        dataIndex: 'change_time',

        sorter: (a, b) => a.change_time - b.change_time,
        render: (change_time) => {
          return moment.unix(change_time).format('YYYY-MM-DD hh:mm')
        },
      },

      {
        title: t('status'),
        dataIndex: 'status',
        render: (value) => displayIsStatus(value),
        sorter: (a, b) => a.status.localeCompare(b.status)
      },
      {
        title: t('remarks'),
        dataIndex: 'remarks',
        // sorter: (a, b) => a.remarks.localeCompare(b.remarks)
      },
    ];
    return columns;
  }

  const displayIsStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    // let isActiveValue = _.toInteger(value);
    switch (value) {
      case "success":
        displayStr = t('success');
        tagColor = 'green';
        break;
      case "fail":
        displayStr = t('fail');
        tagColor = 'red';
        break;
      default:
        displayStr = t('error')
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayTokenChange = (value, record) => {
    const diff = record.token_after - record.token_before;

    if (diff < 0) return <div style={{color: 'red'}}>{`-${value}`}</div>;
    if (diff > 0) return <div style={{color: 'green'}}>{`+${value}`}</div>;
    return <div>{`${value}`}</div>;
  }

  const displayIsType = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let isActiveValue = _.toInteger(value);
    switch (value) {
      case "order":
      case "order_create":
        displayStr = t('order_create');
        tagColor = 'green';
        break;
      case "order_cancel":
        displayStr = t('order_cancel');
        tagColor = 'red';
        break;
      case "topup":
        displayStr = t('topup');
        tagColor = 'blue';
        break;
      case "add_by_admin":
        displayStr = t('add_by_admin');
        tagColor = 'yellow';
        break;
      case "buy_token":
        displayStr = t('buy_token');
        tagColor = 'purple';
        break;
      case "order_item_cancel":
        displayStr = t('order_item_cancel');
        tagColor = 'orange';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const getDataBySearchKeyword = (keywords) => {
    let searchedList = [];
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      };
      const fuse = new Fuse(dataList, options);
      const result = fuse.search(keywords);
      searchedList = _.map(result, "item");
      searchedList = _.orderBy(searchedList, ["user_id"], ["desc"]);
      return searchedList
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      // setDataList(dataList);
    }
  };

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onStatusChange = (status) => {
    setSearchStatus(status);
  };

  const onTypeChange = (type) => {
    setSearchType(type);
  };

  const renderStatusSelectBox = () => {
    // case "placed": wording = t("placed"); break;
    // case "paid": wording = t("paid"); break;
    // case "payment_confirmed": wording = t("payment_confirmed"); break;
    // case "payment_failed": wording = t("payment_failed"); break;
    // case "payment_refund": wording = t("payment_refund"); break;
    // case "in_delivery": wording = t("in_delivery"); break;
    // case "cancelled": wording = t("cancelled"); break;
    // case "expired": wording = t("expired"); break;
    return (
      <Select defaultValue="all" onChange={onStatusChange} style={{minWidth: 160}}>
        <Option value="all">{t('all')}</Option>
        <Option value="success">{t("success")}</Option>
        <Option value="fail">{t("fail")}</Option>
      </Select>
    )
  }

  const renderTypeSelectBox = () => {
    return (
      <Select defaultValue="all" onChange={onTypeChange} style={{minWidth: 160}}>
        <Option value="all">{t('all')}</Option>
        {/* <Option value="order">{t('order_create')}</Option> */}
        <Option value="order_create">{t('order_create')}</Option>
        <Option value="order_cancel">{t('order_cancel')}</Option>
        <Option value="topup">{t('topup')}</Option>
        <Option value="add_by_admin">{t('add_by_admin')}</Option>
        <Option value="buy_token">{t('buy_token')}</Option>
        <Option value="order_item_cancel">{t('order_item_cancel')}</Option>
      </Select>
    )
  }

  const filterData = () => {
    debounce(() => {
      let searchedList = dataList;
      if (_.isEmpty(dataList)) return;
      console.log('unChangeData', dataList);
      if (!_.isEmpty(searchKeyword)) {
        searchedList = getDataBySearchKeyword(searchKeyword);
        console.log(`searchedList >> keyword ${searchKeyword} >> `, searchedList)
      }

      if (searchStatus.toLocaleUpperCase() !== 'ALL') {
        searchedList = _.map(searchedList, (obj) => {
          if (obj.status === searchStatus) return obj;
        })
        console.log(`searchedList >> searchStatus ${searchStatus} >> `, searchedList)
      }
      if (searchType.toLocaleUpperCase() !== 'ALL') {
        searchedList = _.map(searchedList, (obj) => {
          if (obj.type === searchType) return obj;
          if (
            (obj.type === 'order' || obj.type === 'order_create')
            && (searchType === 'order' || searchType === 'order_create')
          ) return obj;
        });
        console.log(`searchedList >> searchType ${searchType} >> `, searchedList)
      }
      searchedList = _.compact(searchedList);
      console.log(`searchedList >> setFilterDataList >> `, searchedList)
      setFilterDataList(searchedList);
    }, 400);
  }

  const onKeywordChange = (keyword) => {
    setSearchKeyword(keyword);
  }

  const onExportTable = () => {
    const processedDataList = _.map(filterDataList, (data) => {
      return {
        ...data,
        change_time: data.change_time === 0 ? '-' : moment(data.change_time, 'X').format('YYYY-MM-DD HH:mm:ss'),
      }
    });

    Main.exportTableToCSV(processedDataList, 'export');
  };

  return (
    <NavAndSideFrame {...props} title={title} selectedKey={selectedKey} openKey={openKey} onExportTable={onExportTable}>
      {/* <Button
        type="primary"
        onClick={() => {
          setSelectedRecord({ company_user_id: 0 });
          setModalVisible(true);
        }}
      >
        Create New User
      </Button> */}
      {/* <Divider /> */}
      <Row>
        <Col style={{ marginTop: 5 }}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20, marginBottom: 10 }}>
          <Input
            placeholder={t('please_input_keyword')}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onKeywordChange(values.target.value);
              }
            }
          />
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <>{`${t('status')}: `}</>
          {renderStatusSelectBox()}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <>{`${t('type')}: `}</>
          {renderTypeSelectBox()}
        </Col>
      </Row>
      {/* <Divider /> */}
      <Spin spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: [25, 50, 100, 200, 500]
          }}
        />
        <Modal
          destroyOnClose
          title={t("user_profile")}
          visible={modalVisible}
          footer={null}
          width={1280}
          style={{ maxWidth: "95%" }}
          onCancel={() => {
            setModalVisible(false);
          }}
        >
          <CompanyUserForm
            dataObj={selectedRecord}
            setModalVisible={setModalVisible}
          />
        </Modal>
      </Spin>
    </NavAndSideFrame>
  )
}

export default CompanyTokenLog;
