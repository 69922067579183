import React, { Component, useState, useEffect } from 'react';
import {
  Row, Col, Input, Spin, Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip
} from 'antd';
import {
  FileExcelOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Service from "../../core/Service";
import ItemLinkageModal from "./ItemLinkageModal";
import * as Main from '../../core/Main';

const tableIDName = `item_linkage_id`;
const filterableFields = [
  `father_item`,
  `son_item`,
];

const ItemLinkageList = (props) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [itemList, setItemList] = useState([]);
  useEffect(() => {
    setLoading(true);
    getAllData();
  }, [modalVisible, isChangingIsActive])

  const getAllData = async () => {
    let endpoint = `/api/item/linkage/list`;
    let data = await Service.call('GET', endpoint);
    console.log('linkage', data);

    let newDataList = _.orderBy(data, [`is_active`, `utime`], [`desc`, `desc`]);
    setDataList(newDataList);
    setFilterDataList(newDataList);

    endpoint = `/api/item/list`;
    data = await Service.call('GET', endpoint);
    setItemList(data);
    setLoading(false);
  }

  const setTableHeader = () => {
    let columns = [
      // {
      //   title: t('operation'),
      //   dataIndex: tableIDName,
      //   render: (value, record, key) => {
      //     return (
      //       <span>
      //         <Tooltip title={t('edit')}>
      //           <Button
      //             shape="circle"
      //             style={{ marginRight: 4}}
      //             icon={<EditOutlined />}
      //             // disabled={record.is_active ? true : false}
      //             onClick={() => {
      //               setModalVisible(true);
      //               setSelectedRecord(record)
      //             }}
      //           />
      //         </Tooltip>
      //         <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
      //           <Button
      //             shape="circle"
      //             style={{ marginRight: 4}}
      //             icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
      //             onClick={async () => {
      //               if (isChangingIsActive) return;
      //               setChangingIsActive(true);
      //               let is_active = record.is_active ? 0 : 1
      //               await Service.call('PATCH', '/api/item/linkage/status',
      //                 {
      //                   item_linkage_id: record.item_linkage_id,
      //                   is_active
      //                 });
      //               // getAllData();
      //               setChangingIsActive(false);
      //             }}
      //           />
      //         </Tooltip>
      //       </span>
      //     )
      //   }
      // },
      {
        title: t('status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('father_item'),
        dataIndex: `father_item`,
        // dataIndex: 'father_item_id',
        // render: (value) => {
        //   let record = _.find(itemList, (item) => { return item.item_id === value; });
        //   return record ? record.item_name : value;
        // },
        sorter: (a, b) => a.father_item_id - b.father_item_id
      },
      {
        title: t('son_item'),
        dataIndex: 'son_item',
        // dataIndex: 'son_item_id',
        // render: (value) => {
        //   let record = _.find(itemList, (item) => { return item.item_id === value; });
        //   return record ? record.item_name : value;
        // },
        sorter: (a, b) => a.son_item_id - b.son_item_id
      },
      {
        title: t('utime'),
        dataIndex: 'utime',
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => displayMoment(value),
      },
      {
        title: t('ctime'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => displayMoment(value),
      },
    ];

    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm:ss') => {
    let displayTime = '';
    if (unixTime !== 0) {
      displayTime = moment.unix(unixTime).format(outputFormat);
    } else {
      displayTime = '-'
    }
    return displayTime;
  }

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      }
      const fuse = new Fuse(filterList, options)
      const result = fuse.search(filterObj);
      // console.log(result);
      filterList = _.map(result, 'item');
      // console.log(filterList);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      setFilterDataList(filterList);
    }
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <Spin spinning={loading}>
      <Row>
        <Col>
          {/* <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setSelectedRecord({item_linkage_id: 0, father_item_id: null, son_item_id: null})
              setModalVisible(true);
            }}
            style={{ marginBottom: 10 }}
          >
            {t('create')}
          </Button> */}
        </Col>
        <Col style={{marginTop: 5, marginLeft: 10}}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{marginLeft: 20}}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%', marginBottom: 10}}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
        <Col style={{marginLeft: 20}}>
          <Button
            icon={<FileExcelOutlined />}
            onClick={onExportTable}
          >
            {t('export_to_excel_file')}
          </Button>
        </Col>
      </Row>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={filterDataList}
        columns={setTableHeader()}
        bordered
        size="small"
      />
      <ItemLinkageModal
        selectedRecord={selectedRecord}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        itemList={itemList}
      />

    </Spin>
  );
}

export default ItemLinkageList;
