import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Card,
  Form,
  Input,
  Col,
  Collapse,
  Descriptions,
  Divider,
  notification,
  Radio,
  Row,
  Select,
  Tabs,
  Upload,
  message,
  Space,
  Drawer,
} from "antd";
import {
  PlusOutlined,
  InfoOutlined,
  HeartOutlined,
  CommentOutlined,
  ScheduleOutlined,
  CrownFilled,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Service from "../core/Service";
import { formItemLayout, tailLayout } from "./ModalLayout";

const { Option } = Select;
const { Panel } = Collapse;

const UserApproveForm = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { openDrawer, refresh } = props;
  const [show, setShow] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [forFormUseData, setForFormUseData] = useState({});
  const [selectedValue, setSelectValue] = useState(null);
  const [items, setItmes] = useState([
    t("reject_reasons_0"),
    t("reject_reasons_1"),
    t("reject_reasons_2"),
  ]);
  const [newReason, setNewReason] = useState("");

  useEffect(() => {
    // console.log(props);
    form.resetFields();
    setShow(false);
    if (!_.isEmpty(props.data)) {
      setUserInfo(props.data);
      form.setFieldsValue(props.data);
    }
  }, [props]);

  const displayDataMethod = (key, value) => {
    if (value === null || value === "") {
      return <b style={{ color: "red" }}>{t("null")}</b>;
    }
    if (key === "ctime") {
      return moment.unix(value).format("YYYY-MM-DD HH:mm");
    }
    switch (value) {
      case 1:
        if (key === "status") {
          return t("approve");
        }
        return t("verified");
      case 0:
        if (key === "status") {
          return t("not_approve");
        }
        return t("not_verified");
      default:
        return t(value);
    }
  };

  const onFinish = async (postObj) => {
    // append user_id
    postObj.user_id = userInfo.user_id;
    let reasons_string = _.map(postObj.reject_reasons, (value) => {
      return t(`reject_reasons_${value}`);
    });
    postObj.reasons_string = !_.isEmpty(postObj.reject_reasons)
      ? "Reasons: " + _.join(reasons_string, ", ")
      : "";
    postObj.reject_reasons = _.join(postObj.reject_reasons, "");

    let result = await Service.call(
      "patch",
      "/api/user/approve_account",
      postObj
    );
    console.log(result);
    if (result.status !== 1) {
      return message.error(result.errorMessage);
    }
    message.success("Succeed!");
    openDrawer(false);
    refresh();
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onNameChange = (event) => {
    setNewReason(event.target.value);
  };

  const addItem = () => {
    console.log("addItem");
    setItmes([...items, newReason]);
    setNewReason("");
  };

  return (
    <Row>
      <Col span={15}>
        <Card style={{ overflow: "auto", height: window.screen.height - 185 }}>
          <Descriptions bordered size="small">
            {Object.keys(userInfo).map((key) => {
              let name = key;
              if (key === 'first_name' || key === 'last_name') {
                return ""
              }
              if (key === 'status') {
                return;
              }
              if (key === 'nickname') {
                name = t('username');
              }

              return (
                <Descriptions.Item label={t(name)}>
                  {displayDataMethod(key, userInfo[key])}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Card>
      </Col>

      <Col span={9}>
        <Card size="small" title={t("approve_status")}>
          <Form
            {...formItemLayout}
            form={form}
            size="small"
            layout="horizontal"
            onFinish={onFinish}
          >
            <Form.Item label={t("sf_id")} name="sf_id">
              <Input />
            </Form.Item>
            <Form.Item label={t("company_name")} name="company_name">
              <Input />
            </Form.Item>
            <Form.Item label={t("username")} name="nickname">
              <Input />
            </Form.Item>
            {/* <Form.Item label={t("first_name")} name="first_name">
              <Input />
            </Form.Item>
            <Form.Item label={t("last_name")} name="last_name">
              <Input />
            </Form.Item> */}
            <Form.Item
              label={t("approve_status")}
              name="status"
              key="status"
              rules={[{ required: true }]}
              value={selectedValue}
            >
              <Radio.Group
                onChange={(e) => {
                  setSelectValue(e.target.value);
                  console.log("SelectedValue >>>", e.target.value);
                }}
              >
                <Radio value={1}>{t("approve")}</Radio>
                <Radio value={-1}>{t("reject")}</Radio>
              </Radio.Group>
            </Form.Item>
            {selectedValue === -1 ? (
              <Form.Item
                label={t("reject_reasons_label")}
                name="reject_reasons"
                key="reject_reasons"
                rules={[{ required: false }]}
              >
                <Select
                  style={{ width: 300 }}
                  placeholder={t("reject_reasons_label")}
                  optionFilterProp="value"
                  onChange={onChange}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={newReason}
                          onChange={onNameChange}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={addItem}
                        >
                          <PlusOutlined />
                          Add item
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {items.map((item) => (
                    <Option key={item}>{item}</Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null}
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserApproveForm;
