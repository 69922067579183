import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Modal
} from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'antd/dist/antd.css';
import _ from 'lodash';
import * as Service from '../core/Service';
import NavAndSideFrame from '../components/NavAndSideFrame';
import CompanyAdminGroupForm from '../components/companyAdminGroup/CompanyAdminGroupForm';
import CompanyAdminGroupTable from '../components/companyAdminGroup/CompanyAdminGroupTable';

const debug = require('debug')('app:admin:client:src:CompanyAdminGroup');

const selectedKey = "admin_group";
const openKey = "admin_group";

const CompanyAdminGroup = (props) => {
  const {t} = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_admin_group_w')
  );

  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    getAllData();
    console.log(disableEdit);
  }, []);

  const getAllData = async () => {
    try {
      let url = `/api/company_user_group/list`;
      let data = await Service.call('get', url);
      setDataList(data);
    } catch (error) {
      console.error('error >>> ', error);
    }
  }

  return (
    <NavAndSideFrame {...props} title={t("company_admin_group")} selectedKey={selectedKey} openKey={openKey}>
      {
        disableEdit ? '' : (
          <>
            <Button
              type="primary"
              onClick={() => {
                setSelectedRecord({ company_user_group_id: 0 });
                setModalVisible(true);
              }}
            >
              {t("create_new")}
            </Button>
            <Divider />
          </>
        )
      }
      <CompanyAdminGroupTable
        dataObj={
          dataList
        }
        changeData={getAllData}
        disableEdit={disableEdit}
      />

      <Modal
        destroyOnClose
        title="Edit"
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        onCancel={() => {
          setModalVisible(false)
        }}
      >
        <CompanyAdminGroupForm
          dataObj={
            selectedRecord
          }
          openModal={
            (value) => {
              setModalVisible(value)
              getAllData();
            }
          }
        />
      </Modal>
    </NavAndSideFrame>
  )
}

export default CompanyAdminGroup;
