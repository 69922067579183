import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Divider,
  Layout,
  Modal,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { EditOutlined, FileExcelOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "antd/dist/antd.css";
import _ from "lodash";
import Fuse from "fuse.js";
import * as Main from '../../core/Main';
import CompanyAdminGroupForm from "./CompanyAdminGroupForm";

const filterableFields = [
  'title', 'uiPermissionsTranslated'
];
const CompanyAdminGroupTable = (props) => {
  const [dataList, setDataList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [filterDataList, setFilterDataList] = useState([]);

  const tableIDName = "company_user_group_id";
  const { t } = useTranslation();

  const { dataObj, disableEdit } = props;

  useEffect(() => {
    const pList = _.map(dataObj, (d) => {
      return {
        ...d,
        uiPermissionsTranslated: d.uiPermissions.length > 0
          ? _.map(d.uiPermissions, (p) => t(p))
          : [t('no_permission')],
      }
    });

    setDataList(pList);
    setFilterDataList(pList);
  }, [dataObj]);

  const setTableHeader = () => {
    let columns = [
      {
        title: t("title"),
        dataIndex: "title",
      },
      {
        title: t("uiPermissions"),
        dataIndex: "uiPermissions",
        render: (value) => displayPermissions(value, 400),
      },
    ];

    if (!disableEdit) {
      columns.unshift({
        title: t("operation"),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={t("edit")}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedRecord(record);
                    setModalVisible(true);
                  }}
                  style={{ marginRight: 8, color: "#000000" }}
                />
              </Tooltip>
            </span>
          );
        },
      });
    }
    return columns;
  };

  const displayPermissions = (value, maxWidth) => {
    if (value.length <= 0) {
      return <Tag color="red">{t("no_permission")}</Tag>;
    }

    return (
      <div style={{ maxWidth, display: "flex", flexWrap: "wrap" }}>
        {_.map(value, (v) => {
          let color = '';
          if (v.substring(v.length - 2, v.length) === '_w') {
            // color = 'gold';
          }

          return <Tag color={color}>{t(v)}</Tag>;
        })}
      </div>
    );
  };

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0,
        keys: filterableFields,
        ignoreLocation: true,
      };
      const fuse = new Fuse(filterList, options);
      const result = fuse.search(filterObj);
      console.log(result);
      filterList = _.map(result, "item");
      console.log(filterList);
      filterList = _.orderBy(filterList, [tableIDName], ["asc"]);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      filterList = _.orderBy(filterList, tableIDName);
      setFilterDataList(filterList);
    }
  };

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  const onExportTable = () => {
    const exportDataList = _.map(filterDataList, (d) => {
      return {
        ...d,
        uiPermissions: d.uiPermissions.join(','),
        permissions: d.permissions.join(',')
      }
    });

    Main.exportTableToCSV(exportDataList, 'export');
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Row>
          <Col style={{ marginTop: 5 }}>{t(`search`) + `:`}</Col>
          <Col style={{ marginLeft: 20 }}>
            <Input
              placeholder={t(`please_input_keyword`)}
              style={{ minWidth: 270, width: "50%" }}
              onChange={(values) => {
                onChange(values.target.value);
              }}
            />
          </Col>
        </Row>
        <Button
          style={{float: 'right'}}
          icon={<FileExcelOutlined />}
          onClick={onExportTable}
        >
          {t('export_to_excel_file')}
        </Button>
      </div>
      <Divider />
      <Layout>
        <Table
          rowKey={tableIDName}
          scroll={{ x: "max-content" }}
          dataSource={filterDataList}
          columns={setTableHeader()}
        />

        <Modal
          destroyOnClose
          title={t("edit")}
          visible={modalVisible}
          footer={null}
          style={{ maxWidth: 800 }}
          width="90%"
          onCancel={() => {
            setModalVisible(false);
          }}
        >
          <CompanyAdminGroupForm
            dataObj={selectedRecord}
            openModal={(value) => {
              props.changeData();
              setModalVisible(value);
            }}
          />
        </Modal>
      </Layout>
    </>
  );
};

export default CompanyAdminGroupTable;
