import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Card, Form, Input, Col, Collapse, Descriptions, Spin,
  notification, Radio, Row, Select, Tabs, Upload, message, Space, Drawer
} from 'antd';
import {
  EditOutlined,
  InfoOutlined,
  HeartOutlined,
  CommentOutlined,
  ScheduleOutlined,
  CrownFilled,
  UserOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { T } from 'antd/lib/upload/utils';
import TextArea from 'antd/lib/input/TextArea';
import * as Service from '../core/Service';
import { formItemLayout, tailLayout } from './ModalLayout';

const UserAddTokenForm = (props) => {
  const { dataObj, setAddTokenModalVisible, waiveLocker } = props
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(dataObj);
  }, [dataObj])
  const onFinish = async (postObj) => {
    setLoading(true);
    if (_.isEmpty(dataObj)) {
      return message.error(t('user ID not Found'))
    }
    if (waiveLocker) {
      postObj.isWaiveLocker = waiveLocker;
      postObj.item_name = dataObj.item_name
    }
    postObj.user_id = dataObj.user_id
    let result = await Service.call('post', '/api/admin/user/add_token', postObj)
    if (result.status !== 1) {
      setLoading(false);
      return notification.error({
        message: 'Error',
        description:
          result.errorMessage,
      });
    }
    notification.success({ message: 'Success!' })
    setAddTokenModalVisible(false)
    setLoading(false);
  }

  return (
    <Spin spinning={loading}>
      <Form
        {...formItemLayout}
        form={form}
        size="small"
        layout="horizontal"
        onFinish={onFinish}
      >
        <Form.Item
          label={t('sf_id')}
          name="sf_id"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={t('name')}
          name="nickname"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={t('quantity')}
          rules={[{ required: true, message: 'Please input token number.' }]}
          name="quantity"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('reason')}
          name="remarks"
          rules={[{ required: true, message: 'Please input token number.' }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default UserAddTokenForm;
