import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Select,
  Spin,
  Radio,
  message,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import * as Enums from "../../enums";

const { Option } = Select;
const debug = require("debug")("app:admin:client:src:BookNowLockerForm");

const BookNowLockerForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [itemList, setItemList] = useState([]);

  const [userID, setUserID] = useState(null);
  const [selectedItemCategoryID, setSelectedItemCategoryID] = useState(null);
  const [selectedDurationType, setSelectedDurationType] = useState(null);

  const [existBooking, setExistBooking] = useState(false);
  const [userList, setUserList] = useState(null);

  const { t } = useTranslation();
  const { item_category_id: parent_category_id } = props;

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    getUserDetail();
  }, [userID]);

  useEffect(() => {
    getCategoryList();
  }, [props]);

  useEffect(() => {
    if (existBooking) {
      message.error(t('book_now_locker_user_has_booking'));
    }
  }, [existBooking])

  useEffect(() => {
    getItemList();
  }, [selectedItemCategoryID]);

  const getUserList = async () => {
    setLoading(true);
    let result = await Service.call("get", '/api/user/list/approved?user_id');

    setUserList(result);
    setLoading(false);
  };

  const getUserDetail = async () => {
    if (_.isNull(userID)) {
      return;
    }

    setLoading(true);
    setExistBooking(false);

    let userLockerBooking = await Service.call(
      "get",
      `/api/booking_item/locker/list?user_id=${userID}`
    );
    if (userLockerBooking.length >= 1) {
      setExistBooking(true);
    }

    setLoading(false);
  };

  const getCategoryList = async () => {
    setLoading(true);
    let req = await Service.call(
      "get",
      `/api/item/category/list/sub_category?parent_category_id=${parent_category_id}`
    );

    let { data } = req;
    setCategoryList(data);
    setLoading(false);
  };

  const getItemList = async () => {
    setLoading(true);
    let req = await Service.call(
      "get",
      `/api/item/locker/list?item_category_id=${selectedItemCategoryID}`
    );

    setItemList(req);
    setLoading(false);
  };

  const onCategoryOptionChange = (value) => {
    setSelectedItemCategoryID(value);
  };

  const onDurationTypeChange = (value) => {
    setSelectedDurationType(value);
  };

  const renderEstimatedTimeSlot = () => {
    let style = {
      paddingBottom: "16px",
    };

    let end_time = 0;
    switch (selectedDurationType) {
      case Enums.LOCKER_DURATION_TYPE.FULL_HOUR:
        end_time = moment().add(1, "hour");
        break;
      case Enums.LOCKER_DURATION_TYPE.HALF_DAY:
        end_time = moment().add(12, "hour");
        break;
      case Enums.LOCKER_DURATION_TYPE.FULL_DAY:
        end_time = moment().add(1, "day");
        break;
      case Enums.LOCKER_DURATION_TYPE.FULL_WEEK:
        end_time = moment().add(1, "week");
        break;
      case Enums.LOCKER_DURATION_TYPE.FULL_MONTH:
        end_time = moment().add(1, "month");
        break;
      case Enums.LOCKER_DURATION_TYPE.HALF_YEAR:
        end_time = moment().add(0.5, "year");
        break;
      case Enums.LOCKER_DURATION_TYPE.FULL_YEAR:
        end_time = moment().add(1, "year");
        break;
      default:
        return <div style={style} />;
    }

    return (
      <div style={style}>
        {`${moment().format(
          "YYYY-MM-DD HH:mm"
        )} - ${end_time.format("YYYY-MM-DD HH:mm")}`}

      </div>
    );
  };

  const onFinish = async (putObj) => {
    if (existBooking) {
      return;
    }

    setLoading(true);
    let { user_id, duration_type, item_id } = putObj;

    let finalPutObj = {
      user_id,
      duration_type,
      item_id,
    };
    let data = await Service.call("put", `/api/order/locker`, finalPutObj);
    if (data.errorMessage) {
      message.error(data.errorMessage);
      setLoading(false);
      return;
    }
    message.success(t("Success"));
    form.resetFields();
    props.openModal(false);

    setCategoryList([]);
    setItemList([]);
    setUserID(null);
    setSelectedItemCategoryID(null);
    setSelectedDurationType(null);

    setLoading(false);
  };

  const userOptionMap = () => {
    return (
      _.map(userList, (u) => {
        const { user_id, nickname, email } = u;
        return <Option value={user_id}>{`${nickname}(${email}) #${user_id}`}</Option>
      })
    )
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="BookNowLockerForm"
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label={t("book_now_form_user_id")}
          name="user_id"
          rules={[{ required: true }]}
        >
          <Select
            value={userID}
            onChange={(value) => { setUserID(value) }}
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) => optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())}
          >
            {userOptionMap()}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("book_now_form_category")}
          name="item_category_id"
          rules={[{ required: true }]}
        >
          <Select onChange={onCategoryOptionChange}>
            {_.map(categoryList, (room) => {
              let { item_category_id, category_name, is_active } = room;
              return (
                <Option
                  value={item_category_id}
                  disabled={!is_active}
                  key={item_category_id}
                >
                  {category_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("book_now_form_duration")}
          name="duration_type"
          rules={[{ required: true }]}
        >
          <Select allowClear onChange={onDurationTypeChange}>
            {_.map(Enums.LOCKER_DURATION_TYPE, (value, key) => {
              return (
                <Option value={value} key={value}>
                  {t("book_now_locker_type_" + key.toLowerCase())}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {renderEstimatedTimeSlot()}
        <Form.Item
          label={t("book_now_form_locker_slot")}
          name="item_id"
          rules={[{ required: true }]}
        >
          <Radio.Group
            style={{ display: "flex", flexWrap: "wrap" }}
            buttonStyle="solid"
          >
            {_.map(itemList, (item) => {
              let {
                item_id, item_name, item_desc, is_active, status
              } = item;

              return (
                <Radio.Button
                  value={item_id}
                  disabled={!is_active || status != 1}
                  style={{ width: "20%", fontSize: "0.8em" }}
                  key={item_id}
                >
                  {item_name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={existBooking}>
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default BookNowLockerForm;
