import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import _ from 'lodash';
import {
  Tabs, Spin, Button, Badge, Table, Modal, notification, Form, Input, Select, Tooltip, message, Popconfirm, Row, Col
} from 'antd';
import {
  EditOutlined, DeleteOutlined, PlusOutlined, FileExcelOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import FormUploadFile from '../components/form/FormUploadFile';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import NavAndSideFrame from '../components/NavAndSideFrame';
import 'react-quill/dist/quill.snow.css';

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const selectedKey = "general";
const openKey = "app_config";

const Setting = (props) => {
  const { t } = useTranslation()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    // Service.call('post', '/api/client/data', reqData)
  }

  return (
    <NavAndSideFrame {...props} title={t('app_config')} selectedKey={selectedKey} openKey={openKey}>
      <Tabs type="card">
        {/* <TabPane tab={t('area_code')} key="area_code">
          <TabComponent metaKey="area_code" pathname="/area_code" />
        </TabPane> */}
        <TabPane tab={t('tc')} key="tc">
          <TabComponent metaKey="tc" pathname="/tc" />
        </TabPane>
        <TabPane tab={t('privacy')} key="privacy">
          <TabComponent metaKey="privacy" pathname="/privacy" />
        </TabPane>
        <TabPane tab={t('booking_policy')} key="booking_policy">
          <TabComponent metaKey="booking_policy" pathname="/booking_policy" />
        </TabPane>
        <TabPane tab={t('home_page_slider')} key="home_page_slider">
          <TabComponent metaKey="home_page_slider" pathname="/home_page_slider" />
        </TabPane>
      </Tabs>
    </NavAndSideFrame>
  )
}

const TabComponent = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  const {
    metaKey, pathname
  } = props;
  console.log(metaKey)

  const [loading, setLoading] = useState(true)
  const [dataSource, setDataSource] = useState([])
  const [showAddButton, setShowAddButton] = useState(true)
  const [recordId, setRecordId] = useState(0);
  const [selectedFormData, setSelectedFormData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [createButtonHidden, setCreateButtonHidden] = useState(false);
  const disableEdit = useSelector(
    (state) => !_.includes(state.app.adminUIPermissions, 'ui_all') && !_.includes(state.app.adminUIPermissions, 'ui_meta_w')
  );

  useEffect(() => {
    fetchData();
    if (_.includes(['/tc', '/privacy', '/booking_policy'], props.pathname) || disableEdit) {
      setCreateButtonHidden(true)
    }
  }, [props.pathname]);

  const fetchData = async () => {
    const result = await Service.call('get', `/api/admin/meta/meta_by_path?pathname=${pathname}`)
    if (result.status !== 1) {
      setLoading(false);
      notification.error(result.errorMessage)
      return
    }
    setDataSource(result.data);
    console.log("result.data>>>", result.data);
    setLoading(false)
  }

  const getColumns = () => {
    let columns = [
      {
        title: t('key'),
        dataIndex: 'meta_key',
        key: 'meta_key',
        render: (value) => {
          return `${t(value)} (${value})`
        }
      },
      {
        title: metaKey === "home_page_slider" ? t('image') : t('value'),
        dataIndex: 'meta_value',
        key: 'meta_value',
        render: (value) => {
          if (metaKey === "home_page_slider") {
            return <img src={`${STATIC_SERVER_URL}/${value}`} style={{ maxWidth: 200, maxHeight: 200 }} alt="slider_image" />;
          }
          return value;
        }
      },
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: 'app_meta_id',
        key: 'app_meta_id',
        render: (value, record, index) => {
          return (
            <span>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                style={{ marginRight: 8 }}
                onClick={() => {
                  console.log('record >>>>>>', record)
                  setRecordId(value)
                  setSelectedFormData(record)
                  setModalVisible(true)
                }}
              />
              <Tooltip title={t('delete')}>
                <Button
                  hidden={createButtonHidden}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    // console.log(record)
                    onDelete(record.app_meta_id, record)
                  }}
                />
              </Tooltip>
            </span>
          )
        }
      });
    }
    return columns;
  }

  const onDelete = (id, record) => {
    confirm({
      title: `確認剛除${record.meta_key}？`,
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: () => {
        confirmDelete(id);
      },
      onCancel: () => {
        // console.log('Cancel');
      },
    });
  }

  const confirmDelete = async (app_meta_id) => {
    let result = await Service.call('put', '/api/admin/meta/delete_meta', { app_meta_id });
    if (!result || result.status <= 0) {
      notification.error({
        message: '刪除失敗，請稍後再試',
        description: '',
      });
      return;
    }

    notification.success({
      message: '成功刪除記綠',
      description: '',
    });

    fetchData();
  }

  const onExportTable = () => {
    Main.exportTableToCSV(dataSource, 'export');
  };

  return (
    <Spin spinning={loading}>
      <div
        style={{
          display: 'flex',
          justifyContent: createButtonHidden ? 'flex-end' : 'space-between',
        }}
      >
        <Button
          hidden={createButtonHidden}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVisible(true);
          }}
          style={{ marginBottom: 10 }}
        >
          {t('create')}
        </Button>
        <Button
          style={{
            marginBottom: 10
          }}
          icon={<FileExcelOutlined />}
          onClick={onExportTable}
        >
          {t('export_to_excel_file')}
        </Button>
      </div>
      <Table rowKey={() => 'app_meta'} scroll={{ x: true }} dataSource={dataSource} columns={getColumns()} />
      <Modal
        title={recordId > 0 ? t('edit') : t('create')}
        visible={modalVisible}
        footer={null}
        style={{ maxWidth: 800 }}
        width="90%"
        destroyOnClose
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <AppMetaForm
          pathname={pathname}
          recordId={recordId}
          selectedFormData={selectedFormData}
          closeMethod={async () => {
            fetchData();
            setSelectedFormData({})
            setModalVisible(false);
          }}
        />
      </Modal>
    </Spin>
  )
}

const AppMetaForm = (props) => {
  const { t } = useTranslation();
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );
  const {
    recordId, selectedFormData, closeMethod, pathname
  } = props
  const [fileURL, setFileURL] = useState(`${STATIC_SERVER_URL}/${selectedFormData.pathname}`)
  const [filePath, setFilePath] = useState('');
  console.log("props>>", props);

  const [contentValue, setContentValue] = useState(selectedFormData.meta_value);

  const uploadOnChange = async (info) => {
    const { status, response } = info.file;
    if (status === 'done') {
      message.destroy()
      if (response.status > 0) {
        message.success('Uploaded file');
        let data = {
          filename: response.filename,
          filepath: response.filepath,
          url: response.url,
        };
        setFilePath(data.filepath);
        setFileURL(data.url);
      } else {
        message.error(t('common:Failed'))
      }
    }
  };

  const determineInputType = (value) => {
    if (_.includes(['/tc', '/privacy', '/booking_policy'], value)) {
      return (
        <ReactQuill
          // readOnly
          // theme={"bubble"}
          theme="snow"
          value={contentValue}
          onChange={setContentValue}
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { align: 'center' },
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                ['link'],
                ['clean'],
              ],
            },
          }}
        />
      )
    }
    if (value.includes('/home_page_slider')) {
      return (
        _.isEmpty(fileURL) ? (
          <FormUploadFile
            data={{ scope: "public" }}
            onChange={uploadOnChange}
          />
        ) : (
          <Row style={{ alignItems: 'flex-end' }}>
            <img
              alt=""
              src={fileURL}
              style={{
                width: '275px',
                height: '155px',
                objectFit: 'cover',
              }}
            />
            <Col style={{ marginLeft: '10px' }}>
              <p style={{ marginBottom: '5px' }}>275 x 155</p>
              <Button
                type="default"
                onClick={() => setFileURL()}
              >
                Remove Current Image
              </Button>
            </Col>
          </Row>
        )
      )
    }
    return (<Input />)
  }
  const [form] = Form.useForm();
  // console.log('pathname >>>>>>', pathname)
  return (
    <Form
      form={form}
      initialValues={selectedFormData}
      onFinish={async (values) => {
        let method = 'post'
        if (recordId !== 0) { //  Edit
          method = 'patch'
          values.app_meta_id = recordId
        }
        values.pathname = pathname;
        if (pathname === '/home_page_slider') {
          values.meta_value = filePath;
        }
        let result = await Service.call(method, '/api/admin/meta', values)
        // console.log('result >>>>>>>>>>', result)

        if (result.status !== 1) {
          return notification.error({ message: '提交失敗', description: result.errorMessage });
        }

        notification.success({ message: '提交成功' });
        closeMethod()
      }}
    >
      <Form.Item
        label={pathname === "/home_page_slider" ? t('image') : t('value')}
        name="meta_key"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('value')}
        name="meta_value"
        rules={[{ required: true }]}
      >
        {determineInputType(pathname)}
      </Form.Item>

      <Button type="primary" htmlType="submit">
        {t('submit')}
      </Button>
    </Form>
  )
}

export default Setting;
