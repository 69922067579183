import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification, Button
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../NavAndSideFrame';
import CategoryInfoListTable from './CategoryInfoListTable';
import CategoryInfoModal from './CategoryInfoModal';


const debug = require('debug')('app:admin:client:src:AdvertisementList');

const involvedModelName = "company";
const selectedKey = "item_category_management";
const tableIDName = "item_category_id";

const CategoryInfoList = (props) => {
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const { disableEdit } = props;
  const title = t('item_category_management');

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true)
    let getdataList = [];
    try {
      let url = "/api/item/category/list/current";
      let response = await Service.call('get', url);
      if (response) {
        response = Main.mergeTranslationList(
          Main.TABLE_CONFIG.item_category,
          response.data,
          response.translationList
        )
      }
      getdataList = response;
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setDataList(getdataList);
      setLoading(false)
    }
  }

  const handleFormSubmit = (submitRecord) => {
    // console.log('submitRecord', submitRecord)

    if (selectedRecord.item_category_id === 0) {
      handlePutRecord(submitRecord)
    }
    if (selectedRecord.item_category_id > 0) {
      handlePatchRecord(submitRecord)
    }
  }

  const handlePutRecord = async (submitRecord) => {
    setLoading(true)
    let submit = await Service.call('put', '/api/item/category', submitRecord)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }


  const handlePatchRecord = async (submitRecord) => {
    setLoading(true)
    let patchData = {
      item_category_id: selectedRecord.item_category_id,
      ...submitRecord
    }
    let submit = await Service.call('patch', '/api/item/category', patchData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleDeleteRecord = async (deleteRecord) => {
    setLoading(true)
    let postData = {
      item_category_id: deleteRecord,
    }
    let submit = await Service.call('post', '/api/item/category/delete', postData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  const handleIsActiveChange = async (item_category_id, value) => {
    setLoading(true)
    let postData = {
      item_category_id,
      is_active: value
    }
    let submit = await Service.call('patch', '/api/item/category/is_active', postData)
    if (submit.status < 1) {
      notification.error({
        message: t('fail'),
      });
      setLoading(false)
    } else {
      notification.success({
        message: t('success'),
      });
      getAllData();
      setLoading(false)
      setModalVisible(false)
    }
  }

  return (
    <div>
      <CategoryInfoListTable
        dataObj={dataList}
        adminChangeData={getAllData}
        setSelectedRecord={(record) => setSelectedRecord(record)}
        deleteRecord={(record) => handleDeleteRecord(record)}
        setModalVisible={(value) => setModalVisible(value)}
        isActiveChangeCallBack={(item_category_id, value) => handleIsActiveChange(item_category_id, value)}
        loading={loading}
        disableEdit={disableEdit}
      />
      <CategoryInfoModal
        selectedRecord={selectedRecord}
        categoryList={dataList}
        modalVisible={modalVisible}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getAllData()
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </div>
  )
}

export default CategoryInfoList;