import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  Typography,
  Tag,
  Table,
  Popconfirm,
  Tooltip
} from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as debugLib from "debug";
import NavAndSideFrame from "../NavAndSideFrame";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import FormUploadFile from "../form/FormUploadFile";

const debug = debugLib("app:pages/company/item/list/Modal");

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ItemGalleryModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    galleryModalVisible,
    setGalleryModalVisible,
  } = props;
  const [originList, setOriginList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewImg, setPreviewImg] = useState();
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  const [ItemGalleryForm] = Form.useForm();

  useEffect(() => {
    if (selectedRecord) {
      fetchGallery(selectedRecord.item_id);
    }
  }, [selectedRecord, galleryModalVisible]);

  const fetchGallery = async (item_id) => {
    let imageList = [];
    try {
      let url = `/api/item/image/list/${item_id}`;
      let response = await Service.call('get', url);
      imageList = _.orderBy(response, ['sorting'], ['asc']);
    } catch (err) {
      // console.log(err);
    } finally {
      setImageList(imageList)
      setOriginList(_.map(imageList, 'item_image_id'))
    }
  };

  const uploadOnChange = async (info) => {
    setLoading(true);
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        setPreviewImg(info);
        message.success(t("uploaded"));
      } else {
        message.error(t("fail"));
      }
    }
    setLoading(false);
  };

  const confirmPreview = async () => {
    setLoading(true)
    let data = {
      filename: previewImg.file.response.filename,
      filepath: previewImg.file.response.filepath,
    }
    let newImage = {
      item_image_id: 0,
      is_active: 1,
      item_id: selectedRecord.item_id,
      path: data.filepath,
      sorting: !_.isEmpty(imageList) ? _.last(imageList).sorting + 1 : 0,
    }
    setImageList((prev) => [...prev, newImage]);
    setPreviewImg();
    setLoading(false)
  };

  const statusChange = async (record) => {
    let target = record.path;
    let newImageList = imageList;
    _.each(imageList, (imageObj, i) => {
      if (imageObj.path === target) {
        newImageList[i].is_active = newImageList[i].is_active ? 0 : 1;
      }
    })
    setImageList(_.orderBy(newImageList, ['sorting'], ['asc']));
  }

  const imageDelete = async (record) => {
    let target = record.path;
    let counter = 0;
    let newImageList = []
    _.each(imageList, (imageObj, i) => {
      if (imageObj.path !== target) {
        counter += 1
        newImageList.push({ ...imageObj, sorting: counter })
      }
    })
    setImageList(_.orderBy(newImageList, ['sorting'], ['asc']));
  }

  const moveUp = async (record) => {
    let target_sorting = record.sorting;
    let newImageList = imageList;
    _.each(imageList, (imageObj, i) => {
      if (imageObj.sorting === target_sorting - 1) {
        newImageList[i].sorting += 1;
      } else if (imageObj.sorting === target_sorting) {
        newImageList[i].sorting -= 1;
      }
    })
    setImageList(_.orderBy(newImageList, ['sorting'], ['asc']));
  }

  const moveDown = async (record) => {
    let target_sorting = record.sorting;
    let newImageList = imageList;
    _.each(imageList, (imageObj, i) => {
      if (imageObj.sorting === target_sorting) {
        newImageList[i].sorting += 1;
      } else if (imageObj.sorting === target_sorting + 1) {
        newImageList[i].sorting -= 1;
      }
    })
    setImageList(_.orderBy(newImageList, ['sorting'], ['asc']));
  }

  const onFinish = async () => {
    let deleteImageList = _.difference(originList, _.map(imageList, 'item_image_id'));
    _.each(imageList, async (imageObj) => {
      try {
        if (imageObj.item_image_id) {
          await Service.call('patch', "/api/item/image", imageObj);
        } else {
          await Service.call('put', "/api/item/image", imageObj);
        }
      } catch (error) {
        notification.error(t('failed'))
      }
    })
    _.each(deleteImageList, async (item_image_id) => {
      try {
        await Service.call('patch', "/api/item/image/delete", { item_image_id })
      } catch (error) {
        notification.error(t('failed'))
      }
    })
    notification.success({
      message: t('uploaded'),
    });
    // notification.success(t('uploaded'))
    setGalleryModalVisible(false);
  }

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: 'item_image_id',
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={() => statusChange(record)}
                />
              </Tooltip>
              <Popconfirm
                title={t('confirm_delete') + "?"}
                onConfirm={() => imageDelete(record)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          )
        }
      },
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value)
      },
      {
        title: t('sorting'),
        dataIndex: 'sorting',
        render: (value, record) => {
          return (
            <Row>
              <Space>
                <Col>
                  <Button
                    size="small"
                    onClick={() => moveUp(record)}
                    disabled={record.sorting === 1}
                  >
                    <UpOutlined />
                  </Button>
                </Col>
                <Col>
                  {value}
                </Col>
                <Col>
                  <Button
                    size="small"
                    onClick={() => moveDown(record)}
                    disabled={record.sorting === imageList.length}
                  >
                    <DownOutlined />
                  </Button>
                </Col>
              </Space>
            </Row>
          )
        }
      },
      {
        title: t('image'),
        dataIndex: 'path',
        render: (value) => {
          return <img src={`${STATIC_SERVER_URL}/${value}`} style={{ maxWidth: 200, maxHeight: 200 }} alt="item_image" />
        }
      },
    ];
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Modal
      title={t("edit_gallery")}
      visible={galleryModalVisible}
      footer={null}
      width={1000}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setGalleryModalVisible(false);
      }}
    >
      <Row>
        <Col style={{ width: "100%" }}>
          <FormUploadFile
            data={{ scope: "private" }}
            onChange={uploadOnChange}
            imageURL={null}
          />
        </Col>
      </Row>
      {!_.isEmpty(previewImg) ? (
        <Row style={styles.previewRow}>
          <Button
            type="default"
            onClick={() => setPreviewImg()}
          >
            Cancel
          </Button>
          <Row style={{ alignItems: 'flex-end' }}>
            <img
              alt=""
              src={`${STATIC_SERVER_URL}/${previewImg.file.response.filepath}`}
              style={styles.img}
            />
            <Col style={{ marginLeft: '10px', marginBottom: '0px' }}>
              350 x 200
            </Col>
          </Row>
          <Button
            type="primary"
            onClick={() => confirmPreview()}
          >
            Confirm
          </Button>
        </Row>
      ) : null }
      <Divider />
      <Table
        rowKey="item_image_id"
        scroll={{ x: 'max-content' }}
        dataSource={imageList}
        columns={setTableHeader()}
        bordered
        pagination={false}
        size="small"
        loading={loading}
      />
      <Divider />
      <Row justify="end">
        <Form
          form={ItemGalleryForm}
          name="ItemInfoForm"
          layout="vertical"
          onFinish={onFinish}
        >
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
    </Modal>
  );
};

export default ItemGalleryModal;

const styles = {
  previewRow: {
    marginTop: '30px',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
  },
  img: {
    width: '350px',
    height: '200px',
    objectFit: 'cover',
  },
};
