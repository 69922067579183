import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  TreeSelect
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import * as debugLib from "debug";
import NavAndSideFrame from "../NavAndSideFrame";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import FormUploadFile from "../form/FormUploadFile";

const debug = debugLib("app:pages/company/item/list/Modal");

const { Search } = Input;
const { Option, OptGroup } = Select;
const { TabPane } = Tabs;

const ItemInfoModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    categoryList,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
  } = props;
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [withNoEndDay, setWithNoEndDay] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [treeSelectOption, setTreesSelectOption] = useState([])
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  const [ItemInfoForm] = Form.useForm();

  useEffect(() => {
    RenderingCategoryList()
    let initialValues = {}
    if (selectedRecord) {
      initialValues = selectedRecord
      initialValues.utime = moment.unix(selectedRecord.utime).format("YYYY-MM-DD HH:mm");
      initialValues.ctime = moment.unix(selectedRecord.ctime).format("YYYY-MM-DD HH:mm");
      if (selectedRecord.section_duration) {
        initialValues.section_duration /= 60
      }
      if (selectedRecord.buffer && selectedRecord.buffer !== 0) {
        initialValues.buffer /= 60
      }

      setWithNoEndDay(Boolean(selectedRecord.no_end_day))

      if (selectedRecord.item_feature_image) {
        setImageURL(`${STATIC_SERVER_URL}/media/${selectedRecord.item_feature_image}`);
      } else {
        setImageURL("");
      }
    }

    if (selectedRecord.item_id !== 0) {
      setIsActive(selectedRecord.is_active)
      initialValues.start_day = selectedRecord.start_day ? moment.unix(selectedRecord.start_day) : null;
      initialValues.end_day = selectedRecord.start_day ? moment.unix(selectedRecord.end_day) : null;
    } else {
      setIsActive(false)
      initialValues.start_day = null;
      initialValues.end_day = null;
    }
    setInitialFormValues(initialValues)
  }, [selectedRecord]);

  useEffect(() => {
    ItemInfoForm.resetFields();
  }, [initialFormValues])

  const uploadOnChange = (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("uploaded"));
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        };
        let path = "../../" + info.file.response.filepath;
        setImageURL(path);
        setFileinfo(data);
      } else {
        message.error(t("fail"));
      }
    }
  };

  const RenderingCategoryList = async () => {
    let filteredCategoryList = categoryList.filter((item) => item.is_active === 1)
    // let parentList = _.filter(categoryList, (item) => item.parent_category_id === 0)
    // let ChildList = _.filter(categoryList, (item) => item.parent_category_id)
    // console.log('tes >>>>>>>t', parentList)
    // console.log('test 2 >>>>>', ChildList)
    let data = Main.fetchProductCategoryOption(filteredCategoryList);

    // console.log("categoryList", data)
    setTreesSelectOption(data)
  }

  return (
    <Modal
      title={
        selectedRecord.item_id === 0 ? t("add_new_record") : t("edit_record")
      }
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form
        form={ItemInfoForm}
        name="ItemInfoForm"
        layout="vertical"
        initialValues={initialFormValues}
        onFinish={(formData) => {
          formData.item_feature_image = fileinfo.filepath
            ? fileinfo.filepath
            : null;
          formData.company_id = selectedRecord.company_id;
          handleFormSubmit(formData);
        }}
      >
        <Row gutter={24}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label={t("name")}
              name="item_name"
              rules={[{ required: true, message: t('input_required') }]}
            >
              <Input maxLength={55} />
            </Form.Item>
            <Form.Item
              label={t("name_zh_hant")}
              name="item_name_zh_hant"
              rules={[{ required: true, message: t('input_required') }]}
            >
              <Input maxLength={55} />
            </Form.Item>

            <Form.Item
              label={t("description")}
              name="item_desc"
              rules={[{ required: true, message: t('input_required') }]}
            >
              <Input maxLength={500} />
            </Form.Item>
            <Form.Item
              label={t("description_zh_hant")}
              name="item_desc_zh_hant"
              rules={[{ required: true, message: t('input_required') }]}
            >
              <Input maxLength={500} />
            </Form.Item>

            <Form.Item
              label={t("category")}
              name="item_category_id"
              rules={[{ required: true, message: t('select_required') }]}
            >
              {/* <TreeSelect
                treeData={treeSelectOption}
                onChange={(e) => console.log('value >>>>>>>>', e)}
              /> */}
              <Select>
                {treeSelectOption.map((item) => {
                  return (
                    <OptGroup key={item.value} label={<span style={{ fontWeight: 'bold' }}>{item.label}</span>}>
                      {!_.isNull(item.children) && item.children.map((child) => {
                        return (
                          <Option key={child.value} value={child.value}>{child.label}</Option>
                        )
                      })}
                    </OptGroup>
                  )
                })}
              </Select>
              {/* <Select
                disabled={isActive}
              >
                {categoryList.map((category) => {
                  return (
                    <Option value={category.item_category_id} key={category.item_category_id}>
                      {category.category_name}
                    </Option>
                  );
                })}
              </Select> */}
            </Form.Item>
            <Form.Item
              label={t("sorting")}
              name="sorting"
              rules={[{ required: true, message: t('sorting_required') }]}
            >
              <Input type="number" />
            </Form.Item>
            {selectedRecord.item_id !== 0 ? (
              <Form.Item label={t("create_time")}>
                <p>{selectedRecord.ctime}</p>
              </Form.Item>
            ) : null}

            {selectedRecord.item_id !== 0 ? (
              <Form.Item label={t("utime")}>
                <p>{selectedRecord.utime}</p>
              </Form.Item>
            ) : null}
            {/* <Form.Item label={t("image")} name="item_feature_image">
              <FormUploadFile
                data={{ scope: "private" }}
                onChange={uploadOnChange}
                imageURL={imageURL}
              />
            </Form.Item> */}
            <Row gutter={12}>
              <Col lg={6} md={12} sm={12} xs={12}>
                <Form.Item
                  label={t("hourly_price")}
                  name="hourly_price"
                  rules={[{ required: true, message: t('input_required') }]}
                >
                  <InputNumber
                    disabled={isActive}
                  />
                </Form.Item>

                <Form.Item
                  label={t("half_day_price")}
                  name="half_day_price"
                  rules={[{ required: true, message: t('input_required') }]}
                >
                  <InputNumber
                    disabled={isActive}
                  />
                </Form.Item>

                <Form.Item
                  label={t("daily_price")}
                  name="daily_price"
                  rules={[{ required: true, message: t('input_required') }]}
                >
                  <InputNumber
                    disabled={isActive}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={12} sm={12} xs={12}>
                <Form.Item
                  label={t("weekly_price")}
                  name="weekly_price"
                  rules={[{ required: true, message: t('input_required') }]}
                >
                  <InputNumber
                    disabled={isActive}
                  />
                </Form.Item>

                <Form.Item
                  label={t("monthly_price")}
                  name="monthly_price"
                  rules={[{ required: true, message: t('input_required') }]}
                >
                  <InputNumber
                    disabled={isActive}
                  />
                </Form.Item>

                <Form.Item
                  label={t("half_year_price")}
                  name="half_year_price"
                  rules={[{ required: true, message: t('input_required') }]}
                >
                  <InputNumber
                    disabled={isActive}
                  />
                </Form.Item>

                <Form.Item
                  label={t("yearly_price")}
                  name="yearly_price"
                  rules={[{ required: true, message: t('input_required') }]}
                >
                  <InputNumber
                    disabled={isActive}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label={t("section_price")}
              name="section_price"
              rules={[{ required: true, message: t('input_required') }]}
            >
              <InputNumber
                disabled={isActive}
              />
            </Form.Item>

            <Form.Item
              label={t("section_duration") + "(" + t("mins") + ")"}
              name="section_duration"
              rules={[{ required: true, message: t('select_required') }]}
            >
              <Select
                disabled={isActive}
              >
                <Option key={15} value={15}>15</Option>
                <Option key={30} value={30}>30</Option>
                <Option key={45} value={45}>45</Option>
                <Option key={60} value={60}>60</Option>
                <Option key={90} value={90}>90</Option>
                <Option key={120} value={120}>120</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={t("with_no_end_day")}
              name="no_end_day"
              valuePropName="checked"
            >
              <Switch
                disabled={isActive}
                onChange={(value) => {
                  setWithNoEndDay(value);
                }}
              />
            </Form.Item>
            <Row>
              <Col flex="auto">
                <Form.Item
                  label={t("start_day")}
                  name="start_day"
                  rules={[{ required: true, message: t('select_required') }]}
                >
                  <DatePicker
                    disabled={isActive}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item
                  label={t("end_day")}
                  name="end_day"
                  rules={[
                    {
                      required: !withNoEndDay,
                      message: t('select_required')
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (getFieldValue('no_end_day') || !value || getFieldValue('start_day') <= value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(t('invalid_end_day'));
                      },
                    }),
                  ]}
                  style={{ display: withNoEndDay ? 'None' : null }}
                >
                  <DatePicker
                    disabled={isActive}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label={t("prebooking_type")}
              name="prebooking_type"
              rules={[{ required: true, message: t('select_required') }]}
            >
              <Select
                disabled={isActive}
              >
                <Option value={1}>{t("one_week_before")}</Option>
                <Option value={3}>{t("two_week_before")}</Option>
                <Option value={2}>{t("one_month_before")}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ItemInfoModal;
