import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
    Button, Col, Form, Input, Layout, Modal,
    notification, Radio, Row, Select, Tabs, Upload, message
} from 'antd';
import moment from 'moment';
import * as Service from '../core/Service';
import * as Main from '../core/Main';
import { formItemLayout, tailLayout } from '../components/ModalLayout'

const { Option } = Select;

const CompanyUserPasswordForm = (props) => {
    const [form] = Form.useForm();
    const [roleList, setRoleList] = useState([]);
    const [companyList, setCompanyList] = useState([]);

    useEffect(() => {
        getCompanyUserRole();
        form.resetFields();
    }, [props.dataObj]);

    const getCompanyUserRole = async () => {
        let roles = [];
        let companyList = [];
        try {
            // TODO :: remove company_id after have session
            let company_id = 1;
            let companyData = await Service.call('get', `/api/company/list`);
            let data = await Service.call('get', `/api/company/user_role/${company_id}`);
            let { userRoles } = data;
            roles = _.orderBy(userRoles, ['utime'], ['desc']);
            roles = _.uniqBy(roles, 'company_user_role');
            companyList = _.orderBy(companyData, ['company_id']);
        } catch (error) {
            console.error('error >>> ', error);
        } finally {
            setRoleList(roles);
            setCompanyList(companyList);
        }
    }

    const onFinish = async (postObj) => {
        let url = `/api/company/user/pw`;
        postObj.password = postObj.passwords;
        if (postObj.password != postObj.re_password) {
            notification.error({
                message: 'Error',
                description:
                  'Password not same, please input again',
                },
              );
            return;
        }
        postObj.company_user_id = props.dataObj.company_user_id;
        let data = await Service.call('patch', url, postObj);
        if(data.errorMessage) {
            message.error(data.errorMessage);
            return props.openModal(true);
        }
        message.success("Success");
        return props.openModal(false);
        //Patch
        // if (props.dataObj.company_user_id > 0) {
        //   postObj.company_user_id = props.dataObj.company_user_id;
        //   let data = await Service.call('patch', url, postObj);
        //   if (data.errorMessage) {
        //     message.error(data.errorMessage);
        //     return props.openModal(true);
        //   }
        //   message.success('success');
        //   return props.openModal(false);
        // }

        // // POST
        // postObj.create_time = moment().unix();
        // let data = await Service.call('post', url, postObj);
        // if (data.errorMessage) {
        //   message.error(data.errorMessage);
        //   return props.openModal(true);
        // }
        // message.success('success');
        // return props.openModal(false);
    };


    return (
        <Form
            {...formItemLayout}
            form={form}
            name="time_related_controls"
            onFinish={onFinish}
            initialValues={props.dataObj}
        >
            <Form.Item
                label="Company"
                name="company_id"
                rules={[{ required: true, message: 'Please select one.' }]}
            >
                <Select
                    placeholder="- select one -"
                    disabled={props.dataObj.company_id}
                >
                    {companyList.map(({ company_id, company_name }) =>
                        (<Option value={company_id}>{`(${company_id}) ` + company_name}</Option>)
                    )}
                </Select>
            </Form.Item>
            <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input username.' }]}
            >
                <Input disabled={props.dataObj.username} />
            </Form.Item>
            <Form.Item
                label="Password"
                name="passwords"
                rules={[{ required: true, message: 'Please input password.' }]}
            >
                <Input type='password'/>
            </Form.Item>
            <Form.Item
                label="Retype password"
                name="re_password"
                rules={[{ required: true, message: 'Please reinput password' }]}
            >
                <Input type='password'/>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Submit
        </Button>
            </Form.Item>
        </Form>
    )
}

export default CompanyUserPasswordForm;
