import React, { Component, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  message,
  Radio,
} from "antd";
import {
  EditOutlined,
  BackwardOutlined,
  WifiOutlined,
  BankOutlined,
  QrcodeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Service from '../core/Service';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;
const debug = require("debug")("app:admin:client:src:CompanyUserForm");

// const involvedModelName = "company";
const selectedKey = 4;
// const tableIDName = "company_user_access_log_id";

const TokenPackageForm = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [imageURL, setImageURL] = useState("");
  const [fileinfo, setFileinfo] = useState({});
  const [formValues, setFormValues] = useState({});
  const {
    dataObj,
    modalVisible,
    setModalVisible,
    getAllData
  } = props;

  useEffect(() => {
    console.log('dataObj', dataObj);
    setFormValues(dataObj);
    // console.log(dataObj);
  }, [dataObj]);

  useEffect(() => {
    form.resetFields();
  }, [formValues]);

  const onFinish = async (postObj) => {
    // POST
    let postData = {
      ...dataObj,
    };
    let url = '/api/token/package';
    let method = 'POST';
    postData.token_value = postObj.token_value
    if (dataObj.token_package_id > 0) {
      method = 'PATCH';
    }

    let result = await Service.call(method, url, postData)
    if (result.status !== 1) {
      message.error(result.errorMessage);
      return;
    }
    message.success('success');
    getAllData();
    setModalVisible(false);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="time_related_controls"
      onFinish={onFinish}
      initialValues={formValues}
    >
      <Form.Item
        label={t('token')}
        name="token_value"
        rules={[{ required: true, message: t('input_required') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TokenPackageForm;
