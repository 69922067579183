import React from "react";
import _ from "lodash";
import { Checkbox, Divider } from "antd";
import { useTranslation } from "react-i18next";

const PermissionSelect = (props) => {
  const { t } = useTranslation();
  const { permissionObj, onChange, disabled } = props;

  const CheckboxGroup = Checkbox.Group;

  return (
    <div style={{ padding: 10, display: "flex", flexDirection: "column" }}>
      {_.map(permissionObj, (permission, title) => {
        return (
          <>
            <Divider />
            <div style={{ display: "flex" }}>
              <span
                style={{ textAlign: "left", width: 100, paddingRight: "10px" }}
              >
                {`${t(title)}:`}
              </span>
              <CheckboxGroup
                options={permission.options}
                value={permission.checkedList}
                onChange={(e) => {
                  onChange(title, e);
                }}
                disabled={disabled}
              />
            </div>
          </>
        );
      })}
    </div>
  );
};

export default PermissionSelect;
