import React, { useState, useEffect } from "react";
import _, { initial } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  Typography,
  Tag,
  Table,
  Popconfirm,
  Tooltip
} from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as debugLib from "debug";
import NavAndSideFrame from "../NavAndSideFrame";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import FormUploadFile from "../form/FormUploadFile";
import ItemRulesetModal from "./ItemRulesetModal";

const debug = debugLib("app:pages/company/item/list/Modal");

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ItemFacilityModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    facilityModalVisible,
    setFacilityModalVisible,
  } = props;
  const [originList, setOriginList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewImg, setPreviewImg] = useState();
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );

  const [ItemFacilityForm] = Form.useForm();

  useEffect(() => {
    if (selectedRecord) {
      fetchFacility(selectedRecord.item_id);
    }
  }, [selectedRecord, facilityModalVisible]);

  const fetchFacility = async (item_id) => {
    try {
      let url = `/api/item/facility/list/${item_id}`;
      let response = await Service.call('get', url);
      if (!response || response.status !== 1) {
        return;
      }
      let { data, translationList } = response
      let result = Main.mergeTranslationList(Main.TABLE_CONFIG.item_facility, data, translationList)
      result = _.orderBy(result, ['sorting'], ['asc']);
      setFacilityList(result)
      setOriginList(_.map(result, 'item_facility_id'))
    } catch (err) {
      // console.log(err);
    }
  }

  const uploadOnChange = async (info) => {
    setLoading(true);
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        setPreviewImg(info);
        message.success(t("uploaded"));
      } else {
        message.error(t("fail"));
      }
    }
    setLoading(false);
  };

  const confirmPreview = async () => {
    setLoading(true)
    let data = {
      filename: previewImg.file.response.filename,
      filepath: previewImg.file.response.filepath,
    }
    let newFacility = {
      item_facility_id: 0,
      is_active: 1,
      item_id: selectedRecord.item_id,
      path: data.filepath,
      sorting: !_.isEmpty(facilityList) ? _.last(facilityList).sorting + 1 : 0,
      name: "",
    }
    setFacilityList((prev) => [...prev, newFacility]);
    setPreviewImg();
    setLoading(false)
  };

  // const uploadOnChange = async (info) => {
  //   setLoading(true)
  //   const { status, response } = info.file;
  //   if (status === "done") {
  //     message.destroy();
  //     if (response.status > 0) {
  //       message.success(t("uploaded"));
  //       let data = {
  //         filename: info.file.response.filename,
  //         filepath: info.file.response.filepath,
  //       }
  //       let newFacility = {
  //         item_facility_id: 0,
  //         is_active: 1,
  //         item_id: selectedRecord.item_id,
  //         path: data.filepath,
  //         sorting: !_.isEmpty(facilityList) ? _.last(facilityList).sorting + 1 : 0,
  //         name: "",
  //       }
  //       setFacilityList((prev) => [...prev, newFacility]);
  //     } else {
  //       message.error(t("fail"));
  //     }
  //   }
  //   setLoading(false)
  // };

  const nameChange = async (record, e) => {
    let target = record.path;
    let newFacilityList = facilityList;
    // console.log('new FacilityList >>>>>', newFacilityList)
    _.map(facilityList, (facilityObj, i) => {
      if (facilityObj.path === target) {
        newFacilityList[i].name = e.target.value;
      }
    })
    setFacilityList(newFacilityList);
  }

  const ZhHantNameChange = async (record, e) => {
    let target = record.path;
    let newFacilityList = facilityList;
    // console.log('new FacilityList >>>>>', newFacilityList)
    _.map(facilityList, (facilityObj, i) => {
      if (facilityObj.path === target) {
        newFacilityList[i].name_zh_hant = e.target.value;
      }
    })
    setFacilityList(newFacilityList);
  }

  const statusChange = async (record) => {
    let target = record.path;
    let newFacilityList = facilityList;
    _.each(facilityList, (facilityObj, i) => {
      if (facilityObj.path === target) {
        newFacilityList[i].is_active = newFacilityList[i].is_active ? 0 : 1;
      }
    })
    setFacilityList(_.orderBy(newFacilityList, ['sorting'], ['asc']));
  }

  const facilityDelete = async (record) => {
    let target = record.path;
    let counter = 0;
    let newFacilityList = []
    _.each(facilityList, (facilityObj, i) => {
      if (facilityObj.path !== target) {
        counter += 1
        newFacilityList.push({ ...facilityObj, sorting: counter })
      }
    })
    setFacilityList(_.orderBy(newFacilityList, ['sorting'], ['asc']));
  }

  const moveUp = async (record) => {
    let target_sorting = record.sorting;
    let newFacilityList = facilityList;
    _.each(facilityList, (facilityObj, i) => {
      if (facilityObj.sorting === target_sorting - 1) {
        newFacilityList[i].sorting += 1;
      } else if (facilityObj.sorting === target_sorting) {
        newFacilityList[i].sorting -= 1;
      }
    })
    setFacilityList(_.orderBy(newFacilityList, ['sorting'], ['asc']));
  }

  const moveDown = async (record) => {
    let target_sorting = record.sorting;
    let newFacilityList = facilityList;
    _.each(facilityList, (facilityObj, i) => {
      if (facilityObj.sorting === target_sorting) {
        newFacilityList[i].sorting += 1;
      } else if (facilityObj.sorting === target_sorting + 1) {
        newFacilityList[i].sorting -= 1;
      }
    })
    setFacilityList(_.orderBy(newFacilityList, ['sorting'], ['asc']));
  }

  const onFinish = async () => {
    let deleteFacilityList = _.difference(originList, _.map(facilityList, 'item_facility_id'));
    _.each(facilityList, async (facilityObj) => {
      try {
        if (facilityObj.item_facility_id) {
          await Service.call('patch', "/api/item/facility", facilityObj);
        } else {
          await Service.call('put', "/api/item/facility", facilityObj);
        }
      } catch (error) {
        notification.error(t('failed'))
      }
    })
    _.each(deleteFacilityList, async (item_facility_id) => {
      try {
        await Service.call('patch', "/api/item/facility/delete", { item_facility_id })
      } catch (error) {
        notification.error(t('failed'))
      }
    })
    notification.success(t('uploaded'))
    setFacilityModalVisible(false);
  }

  const NameInputField = ({ record }) => {
    const [name, setName] = useState(record.name)
    return (
      <Input
        value={name}
        onChange={(e) => {
          setName(e.target.value)
          nameChange(record, e)
        }}
      />
    )
  }

  const ZhHantNameInputField = ({ record }) => {
    const [name, setName] = useState(record.name_zh_hant)
    return (
      <Input
        value={name}
        onChange={(e) => {
          setName(e.target.value)
          ZhHantNameChange(record, e)
        }}
      />
    )
  }
  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: 'item_image_id',
        render: (value, record) => {
          return (
            <span>
              <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4 }}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={() => statusChange(record)}
                />
              </Tooltip>
              <Popconfirm
                title={t('confirm_delete') + "?"}
                onConfirm={() => {
                  facilityDelete(record)
                }}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          )
        }
      },
      {
        title: t('name'),
        dataIndex: 'name',
        render: (value, record) => {
          return (
            <NameInputField record={record} />
            // <Input
            //   value={record.name}
            //   onChange={(e) => {
            //     record.name = e.target.value;
            //     // nameChange(record, e)
            //   }}
            // />
          )
        }
      },
      {
        title: t('name_zh_hant'),
        dataIndex: 'name_zh_hant',
        name: 'name_zh_hant',
        render: (value, record) => {
          return (
            <ZhHantNameInputField record={record} />
            // <Input
            //   value={record.name}
            //   onChange={(e) => {
            //     record.name = e.target.value;
            //     // nameChange(record, e)
            //   }}
            // />
          )
        }
      },
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value)
      },
      {
        title: t('sorting'),
        dataIndex: 'sorting',
        render: (value, record) => {
          return (
            <Row>
              <Space>
                <Col>
                  <Button
                    size="small"
                    onClick={() => moveUp(record)}
                    disabled={record.sorting === 1}
                  >
                    <UpOutlined />
                  </Button>
                </Col>
                <Col>
                  {value}
                </Col>
                <Col>
                  <Button
                    size="small"
                    onClick={() => moveDown(record)}
                    disabled={record.sorting === facilityList.length}
                  >
                    <DownOutlined />
                  </Button>
                </Col>
              </Space>
            </Row>
          )
        }
      },
      {
        title: t('image'),
        dataIndex: 'path',
        render: (value) => {
          return <img src={`${STATIC_SERVER_URL}/${value}`} style={{ maxWidth: 200, maxHeight: 200 }} alt="item_image" />
        }
      },
    ];
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Modal
      title={t("edit_facility")}
      visible={facilityModalVisible}
      footer={null}
      width={1000}
      style={{ maxWidth: "95%" }}
      onCancel={() => {
        setFacilityModalVisible(false);
      }}
    >
      <Row>
        <Col style={{ width: "100%" }}>
          <FormUploadFile
            data={{ scope: "private" }}
            onChange={uploadOnChange}
            imageURL={null}
          />
        </Col>
      </Row>
      {!_.isEmpty(previewImg) ? (
        <Row style={styles.previewRow}>
          <Button
            type="default"
            onClick={() => setPreviewImg()}
          >
            Cancel
          </Button>
          <Row style={{ alignItems: 'flex-end' }}>
            <img
              alt=""
              src={`${STATIC_SERVER_URL}/${previewImg.file.response.filepath}`}
              style={styles.img}
            />
            <Col style={{ marginLeft: '10px', marginBottom: '0px' }}>
              350 x 200
            </Col>
          </Row>
          <Button
            type="primary"
            onClick={() => confirmPreview()}
          >
            Confirm
          </Button>
        </Row>
      ) : null}
      <Divider />
      <Table
        rowKey="item_image_id"
        scroll={{ x: 'max-content' }}
        dataSource={facilityList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={false}
        loading={loading}
      />
      <Divider />
      <Row justify="end">
        <Form
          form={ItemFacilityForm}
          name="ItemInfoForm"
          layout="vertical"
          onFinish={onFinish}
        >
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("submit")}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
    </Modal>
  );
};

export default ItemFacilityModal;

const styles = {
  previewRow: {
    marginTop: '30px',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
  },
  img: {
    width: '350px',
    height: '200px',
    objectFit: 'cover',
  },
};
