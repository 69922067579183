import React, { Component, useState, useEffect } from 'react';
import {
  Row, Col, Input, Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Select
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _, { set } from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import * as Main from '../../core/Main';

const filterableFields = [
  `category_name`,
  `category_desc`,
];
const {Option} = Select;

const CategoryInfoListTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Item Category";
  const selectedKey = 3;
  const tableIDName = "item_category_id";
  const app = useSelector((state) => state.app);
  let {
    dataObj, setModalVisible, setSelectedRecord, loading, deleteRecord, isActiveChangeCallBack, disableEdit
  } = props

  const [filterDataList, setFilterDataList] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [parentCategoryFilter, setParentCategoryFilter] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('');


  useEffect(() => {
    setDataList(props.dataObj);
    setFilterDataList(props.dataObj);
  }, [dataObj]);

  useEffect(() => {
    filterData();
  }, [
    parentCategoryFilter,
    searchKeyword
  ])

  useEffect(() => {
    let tempParentList = _.map(dataList, (object) => {
      if (object.parent_category_id === 0) return;
      let [parent] = _.filter(dataList, (item) => { return item.item_category_id === object.parent_category_id; });
      if (_.isEmpty(parent)) return;

      // console.log('parent', parent);
      return { category_name: parent.category_name, parent_category_id: parent.item_category_id };
    })
    tempParentList = _.compact(tempParentList);
    tempParentList = _.uniqBy(tempParentList, 'category_name');
    setParentList(tempParentList);
  }, [dataList]);

  const filterData = () => {
    debounce(() => {
      let searchedList = dataList;
      if (_.isEmpty(dataList)) return;
      console.log('unChangeData', dataList);
      if (!_.isEmpty(searchKeyword)) {
        searchedList = getDataBySearchKeyword(searchKeyword);
        // console.log(`searchedList >> keyword ${searchKeyword} >> `, searchedList)
      }

      if (parentCategoryFilter.length > 0) {
        searchedList = _.compact(searchedList);
        searchedList = _.map(searchedList, (rowData) => {
          if (_.isUndefined(rowData)) return;
          let isFound = false;
          _.each(parentCategoryFilter, (parent_category_id) => {
            if (rowData.parent_category_id === parent_category_id) isFound = true;
          })
          if (isFound) return rowData;
        });
        // console.log(`searchedList >> parentCategoryFilter ${parentCategoryFilter} >> `, searchedList)
      }

      searchedList = _.compact(searchedList);
      // console.log(`searchedList >> setFilterDataList >> `, searchedList)
      setFilterDataList(searchedList);
    }, 400);
  }

  const setTableHeader = () => {
    let columns = [
      {
        title: t('name'),
        dataIndex: 'category_name',
        sorter: (a, b) => a.category_name.localeCompare(b.category_name)
      },
      {
        title: t('active_status'),
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: t('description'),
        dataIndex: 'category_desc',
        sorter: (a, b) => a.category_desc.localeCompare(b.category_desc)
      },
      {
        title: t('sorting'),
        dataIndex: 'sorting',
        sorter: (a, b) => a.sorting - b.sorting,
      },
      {
        title: t('parent_category'),
        dataIndex: 'parent_category_id',
        render: (value) => {
          if (value === 0) return t('no_parent');
          let [parent] = _.filter(dataList, (item) => { return item.item_category_id === value; });
          if (_.isEmpty(parent)) return t('no_parent');
          return parent.category_name;
        },
        sorter: (a, b) => a.parent_category_id - b.parent_category_id
      },
      {
        title: t('utime'),
        dataIndex: 'utime',
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
    ];
    if (!disableEdit) {
      columns.unshift({
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8}}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedRecord(record)
                  }}
                />
              </Tooltip>
              <Tooltip title={record.is_active ? t('deactivate') : t('activate')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8}}
                  icon={record.is_active ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={() => {
                    let is_active = record.is_active ? 0 : 1
                    isActiveChangeCallBack(record.item_category_id, is_active)
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={t('confirm_delete') + "?"}
                onConfirm={() => deleteRecord(record.item_category_id)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Tooltip title={t('delete')}>
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </span>
          )
        }
      });
    }
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }
  const getDataBySearchKeyword = (keywords) => {
    let searchedList = [];
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      };
      const fuse = new Fuse(dataList, options);
      const result = fuse.search(keywords);
      searchedList = _.map(result, "item");
      searchedList = _.orderBy(searchedList, ["user_id"], ["desc"]);
      return searchedList
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      // setDataList(dataList);
    }
  };

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    setSearchKeyword(keyword);
  };

  const handleParentCategoryFilterChange = (value) => {
    console.log('handleParentCategoryFilterChange',value)
    setParentCategoryFilter(value)
  }

  const onExportTable = () => {
    Main.exportTableToCSV(filterDataList, 'export');
  };

  return (
    <>
      <Row>
        {
          disableEdit ? '' : (
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 10 }}
                onClick={() => {
                  setModalVisible(true)
                  setSelectedRecord({item_category_id: 0})
                }}
              >
                {t('create')}
              </Button>
            </Col>
          )
        }
        <Col style={{marginTop: 5, marginLeft: 10}}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{marginLeft: 20}}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
        <Col style={{ marginTop: 5, marginLeft: 10 }}>
          {t(`parent_category`) + `:`}
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Select
            mode="multiple"
            style={{ width: "100%", minWidth: 400 }}
            placeholder={t('parent_category')}
            onChange={(value) => handleParentCategoryFilterChange(value)}
          >
            {
              _.map(parentList, (eachItem) => {
                return <Option value={eachItem.parent_category_id} key={eachItem.parent_category_id}>{t(eachItem.category_name)}</Option>
              })
            }
          </Select>
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Button
            icon={<FileExcelOutlined />}
            onClick={onExportTable}
          >
            {t('export_to_excel_file')}
          </Button>
        </Col>
      </Row>
      <Layout>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          bordered
          size="small"
          pagination={{pageSize: 100}}
          loading={loading}
        />
      </Layout>
    </>
  )
}

export default CategoryInfoListTable;
