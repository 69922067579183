import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification, Calendar, Badge, Spin, Tabs
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as debugLib from 'debug';
import * as Service from '../../core/Service';
import UpcomingBookingTable from './UpcomingBookingTable';
import PastBookingTable from './PastBookingTable';
import BookingInfoModal from './BookingInfoModal';

// import BookingCalendar from '../../components/BookingCalendar';

const { Option } = Select;

const debug = debugLib('app:pages/UserbookingListTableView');

const { TabPane } = Tabs;

// let sampleText = "apple";

const UserbookingListTableView = (props) => {

  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [userList, setUserList] = useState([]);
  const [eventObj, setEventObj] = useState({});
  const [callRefresh, setCallRefresh] = useState(false);
  const { t } = useTranslation()
  const formType = 1

  useEffect(async() => {
    getUserList();
  }, [])

  const getUserList = async() => {
    let userArr = await Service.call('get', "/api/user/list/current");
    let userArrMap = []
    _.forEach(userArr, (user) => {
      userArrMap.push(<Option key={user.user_id} value={user.user_id}>{user.first_name + " " + user.last_name}</Option>)
    })

    setUserList(userArrMap)
  }

  const handleFormSubmit = async (submitRecord) => {
    // console.log('submitRecord>>>', submitRecord)
    // setLoading(true)
    let response

    if (submitRecord.user_option === 'user') {
      let patchData = {
        status: submitRecord.status,
        user_id: submitRecord.user_id || 'Guest',
        booking_id: submitRecord.booking_id
      }
      // console.log('patchData>>>', patchData)
      if (patchData) {
        response = await Service.call('patch', '/api/booking_item/cancel', patchData)
      }
    } else {
      let patchData = {
        status: submitRecord.status,
        email: submitRecord.email,
        mobile: submitRecord.mobile,
        first_name: submitRecord.first_name,
        last_name: submitRecord.last_name,
        nickname: submitRecord.nickname,
        remarks: submitRecord.remarks,
        booking_id: submitRecord.booking_id
      }
      // console.log('patchData>>>', patchData)
      if (patchData) {
        response = await Service.call('patch', '/api/booking_item/guest/cancel', patchData)
      }
    }

    if (response.status && response.status < 1) {
      notification.error({
        message: t('fail'),
      });
      return
    }
    if (response.status && response.status > 0) {
      notification.success({
        message: t('success'),
      });
      setModalVisible(false)
      setCallRefresh(!callRefresh)
    }
  }


  return (
    <div>
      <Tabs type="line" defaultActiveKey="upcoming_booking">
        <TabPane tab={t('upcoming_booking')} key="upcoming_booking">
          <UpcomingBookingTable
            loading={loading}
            setLoading={setLoading}
            setModalVisible={setModalVisible}
            setEventObj={setEventObj}
            callRefresh={callRefresh}
          />
        </TabPane>
        <TabPane tab={t('past_booking')} key="past_booking">
          <PastBookingTable
            loading={loading}
            setLoading={setLoading}
            setModalVisible={setModalVisible}
            setEventObj={setEventObj}
            callRefresh={callRefresh}
          />
        </TabPane>
      </Tabs>
      <BookingInfoModal
        formType={formType}
        userList={userList}
        dataObj={eventObj}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </div>
  )
}

export default UserbookingListTableView;
